import { Suspense } from "react";
import { Route } from "react-router-dom";
import { genericRoutes, personRoutes, recruiterRoutes } from "../paths";

import VacancyViewerPage from "../../ui/pages/recruiter/public/Viewer";
import PersonInvitePage from "../../ui/pages/persons/Invite";
import RegistrationCompletePage from "../../ui/pages/persons/RegistrationComplete";
import LoginPage from "../../ui/pages/miscellaneous/Login";
import PersonCredentialsPage from "../../ui/pages/persons/Credentials";
import ResetPasswordPage from "../../ui/pages/miscellaneous/ResetPassword";

export function AnonymousRoutes() {
    return <>
        <Route path={recruiterRoutes.inschrijven(":code")} element={<Suspense fallback={<></>}><VacancyViewerPage /></Suspense>} />
        <Route path={personRoutes.personInvite} element={<PersonInvitePage />} />
        <Route path={personRoutes.registered} element={<RegistrationCompletePage />} />
        <Route path={personRoutes.credentialsInvite} element={<PersonCredentialsPage />} />
        <Route path={genericRoutes.resetPassword} element={<ResetPasswordPage />} />
        <Route path="*" element={<LoginPage />} />
    </>
}
