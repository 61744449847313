
export enum Periods {
    Day = 1,
    Week = 2,
    FourWeeks = 3,
    Month = 4,
    Year = 5,
    Once = 6,
    Hour = 7
}
