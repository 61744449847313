import { FC } from "react";
import { ColumnProps, Table } from "../Table";

export const BaseItemTable: FC<
    {
        items: {
            _id: string;
            title: string;
            description: string;
        }[],
        onClick: (id: string) => void;
    }> = ({ items, onClick }) => {
        const colDefs: ColumnProps<{
            _id: string;
            title: string;
            description: string;
        }>[] = [
                {
                    field: "title",
                    headerName: "Titel",
                    flex: 1
                },
                {
                    field: "description",
                    headerName: "Omschrijving",
                    flex: 2
                }
            ];

        return <Table onRowClicked={(row) => onClick(row._id)} slim shrink rowHeight={items.length > 0 ? 35 : undefined} id="PDFs" columns={colDefs} data={items} />
    }