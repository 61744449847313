import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';

export function ConfirmDialog(
    {
        onCancel, message, onConfirm, title, open, fetching, confirmLabel
    }: {
        onConfirm: () => void;
        onCancel?: () => void;
        title: string;
        message: string;
        open: boolean;
        fetching?: boolean;
        confirmLabel?: string;
    }
) {
    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent sx={{ minWidth: 400 }}>
                <DialogContentText id="alert-dialog-description">
                    <Typography sx={{ whiteSpace: 'pre-line' }}>
                        {message}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {onCancel && <Button onClick={onCancel}>Nee</Button>}
                <LoadingButton loading={fetching} onClick={onConfirm} autoFocus>
                    {confirmLabel ?? "Ja"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}