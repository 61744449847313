import { Box, Button, Checkbox, Typography } from "@mui/material";
import { TextArea, TextInput } from "..";
import { primaryLight } from "../../theme";
import { useState } from "react";
import { EditTwoTone } from "@mui/icons-material";
import { DraggableComponent } from "../document-builder/DraggableComponent";
import {
    BreakComponent,
    CheckboxComponent,
    HeaderComponent,
    HorizontalLineComponent,
    PdfComponent,
    PdfComponents,
    SignatureComponent,
    SignaturesComponent,
    TextComponent,
    TextFieldComponent,
    TextProps,
    TextWeight
} from "../../../types/documents/Pdf";
import React from "react";
import { ComponentEditorContextMenu } from "./PdfEditorContextMenu";


export const PdfEditorComponentRenderer: React.FC<{
    item: PdfComponent,
    onChange: (details: PdfComponent) => void,
    onDelete: () => void
    editing: boolean
}> = ({ item, onChange, onDelete, editing }) => {
    const isHeaderOrFooter = item.component === PdfComponents.Header || item.component === PdfComponents.Footer;
    const [hovering, setHovering] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuRef = React.useRef<HTMLElement | null>(null);

    const onMouseEnter = (ev: any) => {
        setHovering(true);
        menuRef.current = ev.currentTarget;
    };

    const onMouseLeave = () => setHovering(false);

    return <DraggableComponent data={{ item }} disabled={!editing || isHeaderOrFooter}>
        {editing && <ComponentEditorContextMenu
            onChange={onChange}
            item={item}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            onDelete={onDelete} />}
        <div style={{ position: 'relative' }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Box sx={{ position: 'absolute', right: -50, top: 0, bottom: 0, zIndex: 100 }}>
                {
                    editing && hovering && <Button variant="text" onClick={_ => setAnchorEl(menuRef.current)} color='primary' sx={{ height: 20 }}>
                        <EditTwoTone fontSize="small" />
                    </Button>
                }
            </Box>
            {item.component === PdfComponents.Header && <HeaderRenderer item={item as HeaderComponent} onChange={i => onChange(i as any)} />}
            {item.component === PdfComponents.Text && <TextRenderer item={item as TextComponent} onChange={i => onChange(i as any)} />}
            {item.component === PdfComponents.HorizontalLine && <HorizontalLineRenderer item={item as HorizontalLineComponent} onChange={i => onChange(i as any)} />}
            {item.component === PdfComponents.Break && <BreakRenderer item={item as BreakComponent} onChange={i => onChange(i as any)} />}
            {item.component === PdfComponents.Checkbox && <CheckboxRenderer item={item as CheckboxComponent} onChange={i => onChange(i as any)} />}
            {item.component === PdfComponents.TextField && <InputFieldRenderer item={item as TextFieldComponent} />}
            {item.component === PdfComponents.Signatures && <SignaturesRenderer item={item as SignaturesComponent} onChange={i => onChange(i as any)} />}
            {item.component === PdfComponents.Signature && <SignatureRenderer item={item as SignatureComponent} onChange={i => onChange(i as any)} />}
            {item.component === PdfComponents.NextPage && <NextPageRenderer />}
        </div>
    </DraggableComponent>
};

const InputFieldRenderer: React.FC<{
    item: TextFieldComponent
}> = ({ item }) => {
    return <Box sx={{ width: '100%', height: item.height * 1.5, border: '1px solid black' }}>

    </Box>
}

const CheckboxRenderer: React.FC<{
    item: CheckboxComponent,
    onChange: (details: CheckboxComponent) => void
}> = (props) => {
    return <Box display={'flex'} alignItems={'center'}>
        <Checkbox size={"small"} />
        <TextInput
            inputSx={{
                fontSize: props.item.fontSize + 2,
                fontWeight: props.item.weight,
                fontStyle: props.item.italic ? 'italic' : 'normal',
                background: '#fff',
                pl: 0
            }}
            value={props.item.text}
            onChange={(e) => props.onChange({ ...props.item, text: e })}
        />
    </Box>
};

const HeaderRenderer: React.FC<{
    item: HeaderComponent,
    onChange: (details: HeaderComponent) => void
}> = (props) => {
    return <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <img src={props.item.imageUri} alt="logo" style={{ maxWidth: 200, maxHeight: 80 }} />
        <Box sx={{ flex: 1 }} />
        <Box sx={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column', zIndex: 10 }}>
            {
                props.item.lines.map((l, i) => <HeaderLineRenderer key={i} item={l}
                    onChange={item => props.onChange({
                        ...props.item,
                        lines: props.item.lines.map((_l, _i) => _i === i ? item : _l)
                    })}
                />)
            }
        </Box>
    </Box>
};

const SignaturesRenderer: React.FC<{
    item: SignaturesComponent,
    onChange: (details: SignaturesComponent) => void
}> = (props) => {
    return <Box>
        <Box sx={{ width: '100%', display: 'flex' }}>
            <Box flex={1} sx={{ height: 1, border: '.5px solid #000' }} />
            <Box width={200} />
            <Box flex={1} sx={{ height: 1, border: '.5px solid #000' }} />
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} pt={1}>
            <TextInput inputSx={{ fontSize: 14, background: '#fff', pl: 0, fontWeight: 100 }} value={props.item.leftContent} onChange={(e) => props.onChange({ ...props.item, leftContent: e })} />
            <TextInput inputSx={{ fontSize: 14, background: '#fff', pr: 0, fontWeight: 100, textAlign: 'end' }} value={props.item.rightContent} onChange={(e) => props.onChange({ ...props.item, rightContent: e })} />
        </Box>
    </Box>
};

const SignatureRenderer: React.FC<{
    item: SignatureComponent,
    onChange: (details: SignatureComponent) => void
}> = (props) => {
    return <Box>
        <Box sx={{ width: '100%', display: 'flex' }}>
            <Box flex={1} />
            <Box width={200} />
            <Box flex={1} sx={{ height: 1, border: '.5px solid #000' }} />
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'} pt={1}>
            <TextInput inputSx={{ fontSize: 14, background: '#fff', pr: 0, fontWeight: 100, textAlign: 'end' }} value={props.item.content} onChange={(e) => props.onChange({ ...props.item, content: e })} />
        </Box>
    </Box>
};

const BreakRenderer: React.FC<{
    item: BreakComponent,
    onChange: (details: BreakComponent) => void
}> = (props) => {
    return <Box sx={{ py: .5 }}>
        <div style={{ height: props.item.height, background: primaryLight, borderRadius: 4 }} />
    </Box>
};

const NextPageRenderer: React.FC = () => {
    return <Box sx={{ my: .5, height: 30, background: '#e74c3c52', borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ fontWeight: 'bold' }}>[VOLGENDE PAGINA]</Typography>
    </Box>
};

const HorizontalLineRenderer: React.FC<{
    item: HorizontalLineComponent,
    onChange: (details: HorizontalLineComponent) => void
}> = (props) => {
    return <Box sx={{ py: .5 }}>
        <hr style={{ width: '100%', height: props.item.height, background: '#000' }} />
    </Box>
};

const TextRenderer: React.FC<{
    item: TextComponent,
    onChange: (details: TextComponent) => void
}> = (props) => {
    let weight: any = 300;
    switch (props.item.weight) {
        case TextWeight.Bold:
            weight = 'bold';
            break;
        case TextWeight.Regular:
            weight = 400;
            break;
        case TextWeight.Light:
            weight = 100;
            break;
        case TextWeight.Thin:
            weight = 300;
    }

    return <TextArea
        inputSx={{
            fontSize: props.item.fontSize + 2,
            fontWeight: weight,
            fontStyle: props.item.italic ? 'italic' : 'normal',
            background: '#fff',
        }}
        value={props.item.text}
        onChange={(e) => props.onChange({ ...props.item, text: e })}
    />
};

const HeaderLineRenderer: React.FC<{
    item: TextProps,
    onChange: (details: TextProps) => void
}> = (props) => {
    let weight: any = 300;
    switch (props.item.weight) {
        case TextWeight.Bold:
            weight = 'bold';
            break;
        case TextWeight.Regular:
            weight = 400;
            break;
        case TextWeight.Light:
            weight = 100;
            break;
        case TextWeight.Thin:
            weight = 300;
    }

    return <TextInput
        sx={{ width: '100%', mb: .5 }}
        inputSx={{
            fontSize: props.item.fontSize + 2,
            fontWeight: weight,
            fontStyle: props.item.italic ? 'italic' : 'normal',
            backgroundColor: '#fff',
            width: 400,
            lineHeight: 0,
            height: 20,
            textAlign: "end"
        }}
        value={props.item.text}
        onChange={(e) => props.onChange({ ...props.item, text: e })}
    />
};