import { BaseDocument } from ".";

export enum DocumentListTargets {
    Onboarding = 1,
    Contract = 2
}

export interface DocumentList {
    _id: string;
    title: string;
    description: string;
    target: DocumentListTargets;
    items: BaseDocument[];
}