import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { updateRelationStatus, updateRelationComment } from "../../../../store/slices/vacanciesSlice";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { DotsLoader, ProfessionalVacanciesTable } from "../../../molecules";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import { useEffect, useState } from "react";
import api from "../../../../api/recruiter/relations";

export function RelatedVacanciesOrganism() {
    const vacanciesState = useAppSelector(state => state.vacancies);
    const params = useParams();
    const dispatch = useAppDispatch();

    const relations = vacanciesState.relations.filter(x => x.professionalId === params.id);
    const vacancies = vacanciesState.vacancies.filter(vacancy => relations.some(y => y.vacancyId === vacancy._id));
    const fetching = vacanciesState.fetchingList === "fetching" || vacanciesState.fetchingRelations === "fetching";

    const [scores, setScores] = useState<MatchScore[]>([]);

    useEffect(() => {
        if (!params.id) return;
        api.getProfessionalScores(params.id!).then(setScores);
    }, [params.id]);

    const onChangeRelation = (relation: VacancyRelation, status: number) => dispatch(updateRelationStatus({
        id: relation._id,
        status: status
    }));

    const onChangeComment = (relation: VacancyRelation, comment: string) => dispatch(updateRelationComment({
        id: relation._id,
        comment
    }));

    return <>
        {
            fetching && <DotsLoader message={"Vacatures worden opgehaald"} />
        }
        {
            !fetching && <ProfessionalVacanciesTable
                scores={scores}
                onCommentChange={onChangeComment}
                onRelationChange={onChangeRelation} vacancies={vacancies} relations={relations} />
        }
    </>
}