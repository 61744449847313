import { lazy } from "react";
import { PlatformUser } from "../../types/user";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { genericRoutes } from "../paths";

const DasboardPage = lazy(() => import("../../ui/pages/dashboard/Dashboard"));

export function DasboardRoutes(user: PlatformUser) {
    const pathsAndComponentsMap = {
        [genericRoutes.dashboard]: DasboardPage
    };

    return <>
        {ProtectedRoutes({
            user,
            pathsAndComponentsMap: pathsAndComponentsMap
        })}
    </>;
}
