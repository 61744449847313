import { Box, Container } from "@mui/material";
import { HeroPage } from "../../molecules";
import { PersonInviteFormOrganism } from "../../organisms";

const animation = require('../../../animations/people-org.json');

export default function PersonInvitePage() {
    return (
        <HeroPage top animation={animation} animationMaxWidth={430} animationMaxHeight={450}>
            <Container maxWidth="xl">
                <Box sx={{ py: 6, pl: 2 }}>
                    <PersonInviteFormOrganism />
                </Box>
            </Container>
        </HeroPage>
    );
}