import { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { AddInvoiceReferenceDialog, Toolbar } from "../../molecules";
import { AddTwoTone } from "@mui/icons-material";

import { showToast } from "../../../store/slices/toastSlice";
import { useAppDispatch } from "../../../store/hooks";

import { ContractInvoiceReferencesTable } from "../../molecules/tables/contracts";

import api from "../../../api/contracts/references";
import { InvoiceReference } from "../../../types/contracts";

export function ContractReferencesOrganism() {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState<InvoiceReference[]>([]);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setFetching(true);
        api
            .list()
            .then(setItems)
            .finally(() => setFetching(false));
    }, []);

    const onAddRef = async (name: string, ref: string) => {
        try {
            setFetching(true);
            const newItem = await api.create({
                _id: '',
                name: name,
                ref: ref,
            });

            setItems([...items, newItem]);
        }
        catch (e: any) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan:' + e.message,
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
            setShowDialog(false);
        }
    }

    const onDelete = async (item: InvoiceReference) => {
        try {
            setFetching(true);
            await api.delete(item._id);
            const newItems = items.filter(i => i._id !== item._id);
            setItems(newItems);
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het verwijderen van de lijst',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
        }
    }

    return <>
        <Typography sx={{ py: 1, mb: 2 }} variant="h6">Factuur referenties</Typography>

        <Toolbar sx={{ mt: 2 }}>
            <Button onClick={() => setShowDialog(true)}>
                <AddTwoTone fontSize="small" sx={{ mr: 1 }} />
                Referentie Toevoegen
            </Button>
        </Toolbar>

        {
            showDialog && <AddInvoiceReferenceDialog
                fetching={fetching}
                onAdd={onAddRef}
                onDismiss={() => setShowDialog(false)} />
        }
        {
            fetching && <Box sx={{ p: 8, display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={30} />
            </Box>
        }
        {
            !fetching && <ContractInvoiceReferencesTable items={items} onDelete={onDelete} />
        }
    </>
}