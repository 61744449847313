import { useEffect, useState } from "react";

import { WorkTwoTone } from "@mui/icons-material";
import { Button, SxProps } from "@mui/material";

import { Theme } from "@emotion/react";
import { onChangeText } from "./ProfessionalDetails";
import { Education, emptyEducation } from "../../../../types/recruiter/professional";
import { FormContainer, TextInput, DateInput } from "../../../molecules";
import { requiredValidation } from "../../../validations";


export function ProfessionalEducationForm(props: {
    onSubmit: (education: Education) => void;
    initialEducation?: Education;
    submitLabel: string;
    sx?: SxProps<Theme>;
}) {
    const [formId, setFormId] = useState<number>(1);
    const [education, setEducation] = useState<Education>({
        title: '',
        kind: '',
        institution: '',
        graduationDate: ''
    });

    useEffect(() => {
        if (props.initialEducation) {
            setEducation({ ...props.initialEducation });
        } else {
            setEducation(emptyEducation);
        }
    }, [props.initialEducation]);

    const { institution, title, graduationDate, kind } = education;

    const validateRequiredFields = (): boolean => {
        return Boolean(title);
    };

    const resetForm = () => {
        setEducation(emptyEducation);
        setFormId(formId + 1);
    };

    return <FormContainer key={formId} sx={props.sx}>
        <TextInput sx={{ mb: 1 }} required validator={requiredValidation} label="Titel" icon={WorkTwoTone} onChange={onChangeText((v) => setEducation({ ...education, title: v }))} value={title} />
        <TextInput sx={{ mb: 1 }} label="Instituut" icon={WorkTwoTone} onChange={onChangeText((v) => setEducation({ ...education, institution: v }))} value={institution} />
        <TextInput sx={{ mb: 1 }} label="Niveau" icon={WorkTwoTone} onChange={onChangeText((v) => setEducation({ ...education, kind: v }))} value={kind} />

        <DateInput sx={{ mb: 1 }}
            label="Behaald op"
            icon={"default"}
            onChange={(v) => setEducation({ ...education, graduationDate: v.toISOString() })}
            value={graduationDate && Boolean(graduationDate) ? new Date(graduationDate) : undefined} />

        <Button disabled={!validateRequiredFields()} sx={{ fontSize: 12, width: '100%', mt: 2 }} onClick={() => {
            props.onSubmit(education);
            resetForm();
        }} variant="text" color="primary">{props.submitLabel}</Button>
    </FormContainer>;
}
