import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../store/hooks";
import { deleteProfessional, professionalsSelector, updateProfessional } from "../../../../store/slices/professionalsSlice";
import { Professional, emptyProfessional, ProfessionalDetails } from "../../../../types/recruiter/professional";
import { DotsLoader } from "../../../molecules";

import Form from "../../../forms/recruiter/professional";
import { recruiterRoutes } from "../../../../router/paths";

export function ProfessionalDetailsOrganism() {
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const state = useSelector(professionalsSelector);
    const params = useParams();
    const [professional, setProfessional] = useState<Professional>(emptyProfessional);

    const onComplete = (value: ProfessionalDetails) => dispatch(updateProfessional({ ...value, _id: professional._id }));
    const onDelete = async () => {
        const result = await dispatch(deleteProfessional(professional._id));
        if (result.meta.requestStatus === 'fulfilled')
            nav(recruiterRoutes.kandidaten.list);
    }

    useEffect(() => {
        if (params.id) {
            const professional = state.professionals.find(p => p._id === params.id);
            if (professional)
                setProfessional(professional);
        }
    }, [params.id, state.professionals])

    if (!Boolean(professional._id))
        return <DotsLoader message="Professional word geladen" />

    return <Form
        onDelete={onDelete}
        initialProfessional={professional}
        completeLabel="Bijwerken"
        onComplete={onComplete}
        fetchingCreate={state.fetchingCreate === 'fetching'}
        fetchingDelete={state.fetchingDelete === 'fetching'}
    />
}