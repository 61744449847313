import { Box, SxProps, Theme } from "@mui/material";
import { EuroTwoTone, WatchLaterTwoTone } from "@mui/icons-material";
import { daysFromNow } from "../../../../utils/dateUtils";
import { FormContainer, TextInput, NumericSelection, DateInput } from "../../../molecules";

type PricingProps = {
    startDate: string;
    endDate: string;
    hoursPerWeek: number;
    hourPrice: number;
};

export function VacancyPricingForm(props: {
    value: PricingProps;
    onChange: (data: PricingProps) => void;
    sx?: SxProps<Theme>;
}) {

    const { endDate, startDate, hoursPerWeek, hourPrice } = props.value;

    return <FormContainer sx={props.sx}>
        <TextInput autoFocus={true} icon={EuroTwoTone} type="double" label="Prijs per uur"
            onChange={(hourPrice: any) => props.onChange({
                ...props.value,
                hourPrice
            })}
            value={hourPrice} />
        <Box sx={{ mt: 2 }} />
        <NumericSelection required icon={WatchLaterTwoTone} label="Uur per week" onChange={
            hoursPerWeek => props.onChange({
                ...props.value,
                hoursPerWeek
            })} value={hoursPerWeek} values={[8, 16, 24, 32, 36, 40]} />

        <Box sx={{ mt: 2 }} />

        <Box display={'flex'}>
            <DateInput minDate={daysFromNow(1)} required sx={{ flex: 1 }} icon="default"
                onChange={
                    startDate => props.onChange({
                        ...props.value,
                        startDate: startDate.toISOString()
                    })}
                value={new Date(startDate)} label="Start datum" />
            <DateInput required minDate={new Date(startDate)} icon="available"
                onChange={
                    endDate => props.onChange({
                        ...props.value,
                        endDate: endDate.toISOString()
                    })}
                value={new Date(endDate)} label="Eind datum" sx={{ pl: 2, flex: 1 }} />
        </Box>
    </FormContainer>;
}
