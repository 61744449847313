import { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";

import { VacancyDetailsForm } from "./VacancyDetails";
import { VacancyPricingForm } from "./VacancyPricing";
import { VacancyDetails, emptyVacancy } from "../../../../types/recruiter/vacancy";
import { ButtonInput, ConfirmDialog, ContextMenu, FormStepper, PrevNextButtons, Toolbar } from "../../../molecules";
import { validateVacancy } from "../../../validations/vacancy";
import { LoadingButton } from "@mui/lab";
import { ArrowDropDown, AutoAwesomeTwoTone, ChecklistTwoTone, DeleteTwoTone, EditTwoTone, FileUploadTwoTone, HandymanTwoTone, SaveTwoTone } from "@mui/icons-material";

import api from "../../../../api/recruiter/vacancy";
import { AnalysisReportDialog } from "../../../molecules/AnalysisReportDialog";
import { useAppDispatch } from "../../../../store/hooks";
import { showToast } from "../../../../store/slices/toastSlice";

export default function VacancyForm(props: {
    initialValues?: VacancyDetails;
    onComplete: (value: VacancyDetails) => void;
    onDelete?: () => void;
    completeLabel: string;
    fetchingSave: boolean;
    fetchingDelete?: boolean;
}) {
    const dispatch = useAppDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [vacancy, setVacancy] = useState<VacancyDetails>(emptyVacancy);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [zzpDignityAnalysis, setZzpDignityAnalysis] = useState<string>('');
    const [zzpDignityConclusion, setZzpDignityConclusion] = useState<string>('');
    const [importVacancyFetching, setImportVacancyFetching] = useState<boolean>(false);
    const [showZzpDignityDialog, setShowZzpDignityDialog] = useState<boolean>(false);
    const [cootjeAILoading, setCootjeAILoading] = useState(false);

    const updateVacancy = (result: any) => setVacancy({ ...vacancy, ...result });

    const onValidateZzpDignity = async () => {
        setZzpDignityAnalysis('');
        setZzpDignityConclusion('');

        setShowZzpDignityDialog(true);
        setCootjeAILoading(true);

        const result = (await api.validateZzpDignity(vacancy as any)).replaceAll('**', '');

        try {
            setZzpDignityAnalysis(result.split('### Analyse')[1].split('### Conclusie')[0].trim().replaceAll('- ', ''));
            setZzpDignityConclusion(result.split('### Conclusie')[1].trim());
        } catch (e) {
            setZzpDignityAnalysis(result);
        }

        setCootjeAILoading(false);
    };

    const onImproveText = async () => {
        setCootjeAILoading(true);
        await api
            .improve(vacancy.title, vacancy.description)
            .then((r) => setVacancy(_v => ({ ..._v, description: r })))
            .catch().finally(() => setCootjeAILoading(false));
    };

    const onImproveZzpDignity = async () => {
        setCootjeAILoading(true);

        await api
            .improveZzpDignity({
                title: vacancy.title,
                description: vacancy.description
            } as any)
            .then((r) => setVacancy(_v => ({ ..._v, description: r })))
            .catch().finally(() => setCootjeAILoading(false));
    };

    const importVacancy = async (file: File) => {
        setImportVacancyFetching(true);
        try {
            const _vacancy = await api.convertFromPdf(file);
            setVacancy(_vacancy);
        } catch (e) {
            dispatch(showToast({
                message: 'Er is een fout opgetreden bij het importeren van de vacature',
                type: 'error'
            }))
        }
        finally {
            setImportVacancyFetching(false);
        }
    }

    useEffect(() => {
        if (props.initialValues)
            setVacancy(props.initialValues);
    }, [props.initialValues]);

    const validationErrorMessage = validateVacancy(vacancy);

    const steps = [
        {
            label: 'Vacature omschrijving',
            content: <VacancyDetailsForm value={vacancy} onChange={updateVacancy} />
        },
        {
            label: 'Tarieven en beschikbaarheid',
            content: <VacancyPricingForm
                onChange={updateVacancy}
                value={vacancy}
            />
        }
    ];

    return <Box>
        <ConfirmDialog
            open={showDeleteDialog}
            onCancel={() => setShowDeleteDialog(false)}
            onConfirm={props.onDelete!}
            title="Vacature verwijderen"
            message="Weet je zeker dat je deze vacature wilt verwijderen?"
            fetching={props.fetchingDelete}
        />

        <Toolbar>
            <LoadingButton onClick={() => props.onComplete(vacancy)} loading={props.fetchingSave}>
                <SaveTwoTone sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption" color={validationErrorMessage ? 'error' : undefined}>
                    {validationErrorMessage ?? props.completeLabel}
                </Typography>
            </LoadingButton>

            <ButtonInput
                variant="text"
                icon={FileUploadTwoTone}
                key={Math.random()}
                fileTypes={["pdf", "eml", "msg", "docx"]}
                loading={importVacancyFetching}
                placeholder="Importeer vanuit bestand"
                onChange={importVacancy} />

            <ContextMenu
                sx={{ mr: cootjeAILoading ? 2 : 0 }}
                loading={cootjeAILoading}
                buttons={[
                    {
                        color: 'secondary',
                        label: 'Genereer Omschrijving',
                        onClick: onImproveText,
                        icon: <EditTwoTone />,
                    },
                    {
                        color: 'secondary',
                        label: 'Valideer ZZP Waardigheid',
                        onClick: onValidateZzpDignity,
                        icon: <ChecklistTwoTone />
                    },
                    {
                        color: 'secondary',
                        label: 'Verbeter ZZP Waardigheid',
                        onClick: onImproveZzpDignity,
                        icon: <HandymanTwoTone />
                    }
                ]}
            >
                <Button sx={{ display: 'flex', justifyContent: 'center', height: 30, pr: 0 }}>
                    <AutoAwesomeTwoTone fontSize="small" />
                    <Typography variant="overline" sx={{ mx: 1 }}>Verbeter (AI)</Typography>
                    <Divider sx={{ height: 20 }} orientation="vertical" />
                    <Box flex={1} />
                    <ArrowDropDown />
                </Button>
            </ContextMenu>


            {props.onDelete && <Button onClick={() => setShowDeleteDialog(true)}>
                <DeleteTwoTone color="error" sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption" color={'error'}>
                    Verwijderen
                </Typography>
            </Button>}
        </Toolbar>

        <AnalysisReportDialog title="Rapport ZZP Waardigheid"
            analysis={zzpDignityAnalysis}
            conclusion={zzpDignityConclusion}
            fetching={cootjeAILoading}
            open={showZzpDignityDialog}
            onClose={() => setShowZzpDignityDialog(false)}
        />

        <FormStepper activeStep={activeStep} steps={steps} onStepClicked={setActiveStep} maxWidth="xl">
            <PrevNextButtons
                variant="text"
                onNext={() => setActiveStep(activeStep + 1)}
                onPrev={() => setActiveStep(activeStep - 1)}
                showNext={activeStep < 1}
                showPrev={activeStep > 0}
            />
        </FormStepper>
    </Box>
}