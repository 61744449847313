import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '..';
import { PublishSettings, PublishedState, Vacancy } from '../../types/recruiter/vacancy';

import vacancyApi from '../../api/recruiter/vacancy';
import relationsApi from '../../api/recruiter/relations';

import { FetchingState } from '../../types/fetchingState';
import { showToast } from './toastSlice';
import { VacancyRelation, VacancyRelationState } from '../../types/recruiter/relations';

export interface VacanciesState {
    vacancies: Vacancy[];
    publishSettings: PublishSettings[];
    relations: VacancyRelation[];
    fetchingList: FetchingState;
    fetchingCreate: FetchingState;
    fetchingDelete: FetchingState;
    fetchingUpdate: FetchingState;
    fetchingRelations: FetchingState;
    fetchingProfessionalRelations: string[]
    fetchingSettings: FetchingState;
}

const intialState: VacanciesState = {
    vacancies: [],
    relations: [],
    fetchingList: "init",
    fetchingCreate: "init",
    fetchingDelete: "init",
    fetchingUpdate: "init",
    fetchingRelations: "init",
    fetchingSettings: "init",
    fetchingProfessionalRelations: [],
    publishSettings: []
}

export const listVacancies = createAsyncThunk(
    'vacancies/list',
    async (_, thunkApi) => {
        try {
            const vacancies = await vacancyApi.list();
            return vacancies;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const deleteVacancy = createAsyncThunk(
    'vacancies/delete',
    async (id: string, thunkApi) => {
        try {
            await vacancyApi.delete(id);
            thunkApi.dispatch(showToast({ message: "Vacature is verwijderd", type: "success" }));
            return id;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const listVacanciesSettings = createAsyncThunk(
    'vacancies/list-settings',
    async (_, thunkApi) => {
        try {
            const ids = (thunkApi.getState() as RootState).vacancies.vacancies.map(v => v._id);

            if (ids.length === 0)
                return [];

            const settings = await vacancyApi.listPublishSettings(ids);
            return settings;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const publishVacancy = createAsyncThunk(
    'vacancies/publish',
    async (id: string, thunkApi) => {
        try {
            const code = await vacancyApi.publish(id);
            thunkApi.dispatch(showToast({ message: "Vacature is gepubliceerd", type: "success" }));
            return { id, code };
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const unpublishVacancy = createAsyncThunk(
    'vacancies/unpublish',
    async (id: string, thunkApi) => {
        try {
            await vacancyApi.unpublish(id);
            thunkApi.dispatch(showToast({ message: "Vacature is gedepubliceerd", type: "success" }));
            return { id };
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const listVacanciesRelations = createAsyncThunk(
    'vacancies/listRelations',
    async (_, thunkApi) => {
        try {
            const ids = (thunkApi.getState() as RootState).vacancies.vacancies.map(v => v._id);

            if (ids.length === 0)
                return [];

            const relations = await relationsApi.getByVacancies(ids);
            return relations;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const createVacancyRelation = createAsyncThunk(
    'vacancies/createRelation',
    async (args: {
        vacancyId: string,
        professionalId: string,
        targetId: string
    }, thunkApi) => {
        try {
            thunkApi.dispatch(addRelationTarget(args.targetId));

            const relation = await relationsApi.create({
                _id: '',
                comment: '',
                vacancyId: args.vacancyId,
                professionalId: args.professionalId,
                status: VacancyRelationState.RecruiterApplied
            });

            return relation;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
        finally {
            thunkApi.dispatch(removeRelationTarget(args.targetId));
        }
    }
)

export const updateRelationStatus = createAsyncThunk(
    'vacancies/updateRelation',
    async (args: {
        id: string,
        status: VacancyRelationState
    }, thunkApi) => {
        relationsApi
            .updateStatus(args.id, args.status)
            .catch(e => thunkApi.dispatch(showToast({ message: e.message, type: "error" })));
        return args;
    }
)

export const updateRelationComment = createAsyncThunk(
    'vacancies/updateComment',
    async (args: {
        id: string,
        comment: string
    }, thunkApi) => {
        relationsApi
            .updateComment(args.id, args.comment)
            .catch(e => thunkApi.dispatch(showToast({ message: e.message, type: "error" })));
        return args;
    }
)

export const deleteVacancyRelation = createAsyncThunk(
    'vacancies/deleteRelation',
    async (args: {
        relation: VacancyRelation,
        targetId: string
    }, thunkApi) => {
        try {
            thunkApi.dispatch(addRelationTarget(args.targetId));
            await relationsApi
                .delete(args.relation._id)
                .catch(e => thunkApi.dispatch(showToast({ message: e.message, type: "error" })));

            return args;
        }
        finally {
            thunkApi.dispatch(removeRelationTarget(args.targetId));
        }
    }
)

export const createVacancy = createAsyncThunk(
    'vacancies/create',
    async (v: Vacancy, thunkApi) => {
        try {
            const vacancy = await vacancyApi.create(v);
            thunkApi.dispatch(showToast({ message: "Vacature is aangemaakt", type: "success" }));
            return vacancy;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const updateVacancy = createAsyncThunk(
    'vacancies/update',
    async (v: Vacancy, thunkApi) => {
        try {
            await vacancyApi.update(v);
            thunkApi.dispatch(showToast({ message: "Vacature is bijgewerkt", type: "success" }));
            return v;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: "Fout bij het bijwerken:" + e.message, type: "error" }));
            throw e;
        }
    }
)

export const vacanciesSlice = createSlice({
    name: 'vacancies',
    initialState: intialState,
    reducers: {
        addRelationTarget: (state, action: PayloadAction<string>) => {
            state.fetchingProfessionalRelations.push(action.payload);
        },
        removeRelationTarget: (state, action: PayloadAction<string>) => {
            state.fetchingProfessionalRelations = state.fetchingProfessionalRelations.filter(x => x !== action.payload);
        }
    },
    extraReducers: (builder) => {

        // List
        builder
            .addCase(listVacancies.pending, (state) => { state.fetchingList = "fetching"; })
            .addCase(listVacancies.rejected, (state) => { state.fetchingList = "error"; })
            .addCase(listVacancies.fulfilled, (state, action: PayloadAction<Vacancy[]>) => {
                state.fetchingList = "completed";
                state.vacancies = action.payload;
            })

        // Create
        builder
            .addCase(createVacancy.pending, (state) => { state.fetchingCreate = "fetching"; })
            .addCase(createVacancy.rejected, (state) => { state.fetchingCreate = "error"; })
            .addCase(createVacancy.fulfilled, (state, action: PayloadAction<Vacancy>) => {
                state.fetchingCreate = "completed";
                state.vacancies = [action.payload, ...state.vacancies];
            });

        // Create
        builder
            .addCase(updateVacancy.pending, (state) => { state.fetchingUpdate = "fetching"; })
            .addCase(updateVacancy.rejected, (state) => { state.fetchingUpdate = "error"; })
            .addCase(updateVacancy.fulfilled, (state, action: PayloadAction<Vacancy>) => {
                state.fetchingUpdate = "completed";
                state.vacancies = state.vacancies.map(p => p._id === action.payload._id ? action.payload : p);
            });

        // Delete        
        builder
            .addCase(deleteVacancy.pending, (state) => { state.fetchingDelete = "fetching"; })
            .addCase(deleteVacancy.rejected, (state) => { state.fetchingDelete = "error"; })
            .addCase(deleteVacancy.fulfilled, (state, action: PayloadAction<string>) => {
                state.fetchingDelete = "completed";
                state.vacancies = state.vacancies.filter(p => p._id !== action.payload);
            });

        // List Relations
        builder
            .addCase(listVacanciesRelations.pending, (state) => { state.fetchingRelations = "fetching"; })
            .addCase(listVacanciesRelations.rejected, (state) => { state.fetchingRelations = "error"; })
            .addCase(listVacanciesRelations.fulfilled, (state, action: PayloadAction<VacancyRelation[]>) => {
                state.fetchingRelations = "completed";
                state.relations = action.payload;
            })

        // List Relations
        builder
            .addCase(listVacanciesSettings.pending, (state) => { state.fetchingSettings = "fetching"; })
            .addCase(listVacanciesSettings.rejected, (state) => { state.fetchingSettings = "error"; })
            .addCase(listVacanciesSettings.fulfilled, (state, action) => {
                state.fetchingSettings = "completed";
                state.publishSettings = action.payload;
            })


        // Create Relation
        builder
            .addCase(createVacancyRelation.pending, (state) => { state.fetchingRelations = "fetching"; })
            .addCase(createVacancyRelation.rejected, (state) => { state.fetchingRelations = "error"; })
            .addCase(createVacancyRelation.fulfilled, (state, action: PayloadAction<VacancyRelation>) => {
                state.fetchingRelations = "completed";
                state.relations = [action.payload, ...state.relations];
            })

        // Update Relation
        builder
            .addCase(updateRelationStatus.pending, (state) => { state.fetchingRelations = "fetching"; })
            .addCase(updateRelationStatus.rejected, (state) => { state.fetchingRelations = "error"; })
            .addCase(updateRelationStatus.fulfilled, (state, action: PayloadAction<{ id: string, status: VacancyRelationState }>) => {
                state.fetchingRelations = "completed";
                state.relations = state.relations.map(r => {
                    if (r._id === action.payload.id)
                        return { ...r, status: action.payload.status };

                    return r;
                })
            })

        // Update comment
        builder
            .addCase(updateRelationComment.fulfilled, (state, action) => {
                state.fetchingRelations = "completed";
                state.relations = state.relations.map(r => {
                    if (r._id === action.payload.id)
                        return { ...r, comment: action.payload.comment };

                    return r;
                })
            })

        // Delete Relation
        builder
            .addCase(deleteVacancyRelation.pending, (state) => { state.fetchingRelations = "fetching"; })
            .addCase(deleteVacancyRelation.rejected, (state) => { state.fetchingRelations = "error"; })
            .addCase(deleteVacancyRelation.fulfilled, (state, action) => {
                state.fetchingRelations = "completed";
                state.relations = state.relations.filter(r => r._id !== action.payload.relation._id);
            })


        // Publish
        builder
            .addCase(publishVacancy.pending, (state) => { state.fetchingSettings = "fetching"; })
            .addCase(publishVacancy.rejected, (state) => { state.fetchingSettings = "error"; })
            .addCase(publishVacancy.fulfilled, (state, action) => {
                state.fetchingSettings = "completed";

                const existing = state.publishSettings.find(s => s._id === action.payload.id);
                if (!existing)
                    state.publishSettings = [{ _id: action.payload.id, published: PublishedState.Published, code: action.payload.code }, ...state.publishSettings];
                else
                    state.publishSettings = state.publishSettings.map(s => {
                        if (s._id === action.payload.id)
                            return { ...s, published: PublishedState.Published, code: action.payload.code };

                        return s;
                    })
            })

        // Unpublish
        builder
            .addCase(unpublishVacancy.pending, (state) => { state.fetchingSettings = "fetching"; })
            .addCase(unpublishVacancy.rejected, (state) => { state.fetchingSettings = "error"; })
            .addCase(unpublishVacancy.fulfilled, (state, action) => {
                state.fetchingSettings = "completed";

                state.publishSettings = state.publishSettings.map(s => {
                    if (s._id === action.payload.id)
                        return { ...s, published: PublishedState.Unpublished, code: "" };

                    return s;
                })
            })
    }
})

export const { addRelationTarget, removeRelationTarget } = vacanciesSlice.actions;
export const vacanciesSelector = (state: RootState) => state.vacancies;
export const publishSettingsSelector = (id: string) => (state: RootState) => state.vacancies.publishSettings.find(s => s._id === id);
export const publishSettingsLoadingSelector = (state: RootState) => state.vacancies.fetchingSettings === 'fetching';
export default vacanciesSlice.reducer