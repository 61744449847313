
import { Person } from "../types/company/person";
import { containsIgnoreCase } from "../utils/strings";

export const filterByFirstName = (person: Person, name: string): boolean => {
    return containsIgnoreCase(person.firstName, name);
}

export const filterByLastName = (person: Person, name: string): boolean => {
    return containsIgnoreCase(person.lastName, name);
}

export const filterByInitials = (person: Person, initials: string): boolean => {
    return containsIgnoreCase(person.initials, initials);
}

export const filterByPhoneNumber = (person: Person, phone: string): boolean => {
    return containsIgnoreCase(person.phoneNumber, phone) || containsIgnoreCase(person.mobileNumber, phone);
}

export const filterByEmail = (person: Person, email: string): boolean => {
    return containsIgnoreCase(person.email, email);
}

export const filterBySearch = (persons: Person[], search: string): Person[] => {
    return persons.filter(person =>
        filterByFirstName(person, search) ||
        filterByLastName(person, search) ||
        filterByInitials(person, search) ||
        filterByPhoneNumber(person, search) ||
        filterByEmail(person, search)
    );
}
