import { InfoTwoTone } from "@mui/icons-material";
import { Box, SxProps, Tooltip, Typography } from "@mui/material";


export function IconLabel(props: {
    label: string,
    tooltip?: string,
    icon?: any,
    sx?: SxProps,
    required?: boolean,
    optional?: boolean,
}) {
    const { icon: MuiIcon, label } = props;

    return <Box display='flex' alignItems='center' sx={{ mb: 1, ...props.sx }}>
        {MuiIcon && <MuiIcon color="primary" fontSize='small' />}
        <Typography sx={{ ml: .5, mb: 0, lineHeight: 1 }} variant="caption" color="secondary">{label}</Typography>
        {
            props.required && <Typography fontSize={'.6rem'} variant="subtitle2" color={'#7f8c8d'} sx={{ ml: 1, lineHeight: 0 }}>
                (verplicht)
            </Typography>
        }
        {
            props.optional && <Typography fontSize={'.6rem'} variant="subtitle2" color={'#7f8c8d'} sx={{ ml: 1, lineHeight: 0 }}>
                (optioneel)
            </Typography>
        }
        <Box flexGrow={1} />
        {
            props.tooltip &&
            <Tooltip title={props.tooltip} sx={{ ml: 1 }}>
                <InfoTwoTone color="primary" fontSize="small" />
            </Tooltip>
        }
    </Box>
}