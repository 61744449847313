import { useNavigate } from "react-router-dom";
import { recruiterRoutes } from "../../../../router/paths";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { createProfessional } from "../../../../store/slices/professionalsSlice";
import { ProfessionalDetails } from "../../../../types/recruiter/professional";

import Form from "../../../forms/recruiter/professional";

export function ProfessionalCreateOrganism() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const fetching = useAppSelector(state => state.professionals.fetchingCreate) === 'fetching';

    const onComplete = (professional: ProfessionalDetails) => {
        dispatch(createProfessional({
            ...professional,
            phone: professional.phone === '06' ? '' : professional.phone,
            _id: ""
        }))
            .then((result: any) => {
                if (!result.error)
                    navigate(recruiterRoutes.kandidaten.list);
            });
    };

    return <Form
        completeLabel="Aanmaken"
        onComplete={onComplete}
        fetchingCreate={fetching}
    />
}