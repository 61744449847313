import { lazy } from "react";
import { PlatformUser } from "../../types/user";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { personRoutes } from "../paths";

export const PersonsListPage = lazy(() => import("../../ui/pages/persons/List"));
export const PersonsCreatePage = lazy(() => import("../../ui/pages/persons/Create"));
export const PersonDetailsPage = lazy(() => import("../../ui/pages/persons/Details"));

export function PersonRoutes(user: PlatformUser) {
    const pathsAndComponentsMap = {
        [personRoutes.list]: PersonsListPage,
        [personRoutes.details(":id")]: PersonDetailsPage,
        [personRoutes.create]: PersonsCreatePage,
    };
    return <>
        {ProtectedRoutes({
            user,
            pathsAndComponentsMap: pathsAndComponentsMap
        })}
    </>;
}
