import { BankAccount, defaultBankAccount } from "./BankAccount";
import { Compensation } from "./Compensations";
import { defaultRate, Rate } from "./Rate";
import { Fine } from "./Fine";
import { Periods } from "./Periods";

export enum FincialTypes {
    Default = 0,
    TAO = 1,
    Company = 2
}


export enum TaoFinancialTypes {
    AllIn = 1,
    Montly = 2
}

export enum TaoPaymentTerm {
    SpecifiedDay = 1,
    TwentyFifthOfMonth = 2
}

export interface TaoSalaryDate {
    type: TaoPaymentTerm;
    day: number;
}

export enum TaoContractFase {
    A = 1,
    B = 2,
    C = 3
}

export interface ContractFinancial {
    type: FincialTypes;
    budget: number;
    redemtionAmount: number;
    paymentTermCustomer: number;
    endDateDeclarations: number;
    rebatePercentage: number;
    bankAccount: BankAccount;
    fines: Fine[];
    compensations: Compensation[];
    rateCustomer: Rate;
    commision: Rate;
    additionalProvisions: string;
    contractFees: Rate[];
    timesheetPeriod: Periods;
}

const defaultContractFinancial = (): ContractFinancial => ({
    type: FincialTypes.Default,
    budget: 0.00,
    redemtionAmount: 0.00,
    paymentTermCustomer: 30,
    endDateDeclarations: new Date(Date.now()).getTime(),
    rebatePercentage: 0.00,
    bankAccount: defaultBankAccount(),
    fines: [],
    compensations: [],
    rateCustomer: defaultRate(),
    commision: defaultRate(),
    additionalProvisions: '',
    contractFees: [],
    timesheetPeriod: Periods.Month
});

export interface CompanyFinancial extends ContractFinancial {
    maximumCoverage: number;
    maximumSum: number;
    rateProfessional: Rate;
    paymentTermSupplier: number;
}

export const defaultCompanyFinancial = (): CompanyFinancial => ({
    ...defaultContractFinancial(),
    type: FincialTypes.Company,
    maximumCoverage: 0.00,
    maximumSum: 0.00,
    rateProfessional: defaultRate(),
    paymentTermSupplier: 30,
});

export interface TAOFinancial extends ContractFinancial {
    cao?: string;
    taoType: TaoFinancialTypes;
    salaryDate: TaoSalaryDate;
    brutoMonthlySalary: number;
    hourlyRate: number;
    allInHourlyRate: number;
    pension: string;
    percentage13thMonth: number;
    hasHolidayPay: boolean;
    contractFase: TaoContractFase;
    companyVehicle: boolean;
    netExpenseReimbursment: number;
    vacationDays: number;
    advDays: number;
    premiumsEmployer: number;
    premiumsEmployee: number;
    applyPayrollTax: boolean;
    contributionOverhead: number;
    contributionZW: number;
    caoScale: number;
    caoScaleStep: number;
}

export const defaultTAOFinancial = (): TAOFinancial => ({
    ...defaultContractFinancial(),
    type: FincialTypes.TAO,
    taoType: TaoFinancialTypes.AllIn,
    salaryDate: {
        type: TaoPaymentTerm.SpecifiedDay,
        day: 1
    },
    brutoMonthlySalary: 0.00,
    hourlyRate: 0.00,
    allInHourlyRate: 0.00,
    percentage13thMonth: 0.00,
    hasHolidayPay: false,
    contractFase: TaoContractFase.A,
    companyVehicle: false,
    netExpenseReimbursment: 0.00,
    pension: '',
    vacationDays: 0,
    advDays: 0,
    premiumsEmployer: 0.00,
    premiumsEmployee: 0.00,
    applyPayrollTax: true,
    contributionOverhead: 0.00,
    contributionZW: 0.00,
    caoScale: 0,
    caoScaleStep: 0
});