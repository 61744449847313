export type PlatformUser = {
    id: string;
    permissions: Permissions[];
    companyPermissions: CompanyPermissions[];
    environment: string;
    environmentName: string;
    isAdmin: boolean;
    email: string;
}

export type CompanyPermissions = {
    companyId: string;
    permissions: Permissions[];
}

export enum Permissions {
    Admin = 1,
    Api = 2,
    WhitelabelSettings = 3,

    /// <summary>
    /// Recruiter module rechten
    /// </summary>
    KandidaatReader = 100,
    KandidaatWriter = 101,
    VacatureReader = 200,
    VacatureWriter = 201,
    VacaturePublisher = 202,

    /// <summary>
    /// Bedrijven Module Rechten
    /// </summary>
    CompanyReader = 300,
    CompanyWriter = 301,
    PermissionEditor = 302,

    /// <summary>
    /// Persoon Module Rechten
    /// </summary>
    PersonReader = 400,
    PersonWriter = 401,
    SelfReader = 402,
    SelfWriter = 403,


    // Contracts
    ContractReader = 500,
    ContractWriter = 501,
}