import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { OrganizationPdf, PdfPayload } from "../../types/documents/Pdf";
import { DocumentMergeRequest } from "../../types/documents";

class PdfTemplatesApi extends BaseApi {
    constructor() { super(apiRoutes.documents + "/pdf"); }

    public list = () => this.fetchJson<OrganizationPdf[]>();
    public get = (id: string) => this.fetchJson<OrganizationPdf>(id);
    public create = (pdfPayload: OrganizationPdf) => this.fetchJson<OrganizationPdf>('', "POST", pdfPayload);
    public update = (pdfPayload: OrganizationPdf) => this.fetch('', "PUT", pdfPayload);
    public delete = (id: string) => this.fetch(id, "DELETE");
    public previewPdfDocument = (payload: PdfPayload) => this.fetchBlob('/preview', "POST", payload);
    public previewMergeResult = (payload: DocumentMergeRequest) => this.fetchBlob('/preview-merge-result', "POST", payload);
}

const api = new PdfTemplatesApi();
export default api;