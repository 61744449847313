import { FC } from "react"
import { Checkbox, Typography } from "@mui/material"

import { IconLabel } from "../IconLabel"
import { Row } from "../Row"
import { TextInput } from "./TextInput"

export const CompensationInput: FC<{
    icon: any,
    title: string,

    enabled: boolean,
    onEnabledChanged?: () => void,

    purchaseValue: string,
    onPurchaseValueChanged?: (value: string) => void,

    saleValue?: string,
    onSaleValueChanged?: (value: string) => void,

    withSaleValue: boolean,
    onWithSalePriceChanged?: () => void,

    disabled?: boolean
}> = ({
    icon,
    title,
    disabled,

    purchaseValue,
    onPurchaseValueChanged,

    saleValue,
    onSaleValueChanged,

    withSaleValue,
    onWithSalePriceChanged,

    enabled,
    onEnabledChanged,
}) => {
        return <>
            <IconLabel sx={{ mt: 2 }}
                icon={icon}
                label={title}
            />
            <Row sx={{ alignItems: 'center !important' }}>
                <Checkbox
                    sx={{ p: 0, mr: 1 }}
                    checked={enabled}
                    onChange={onEnabledChanged}
                    disabled={disabled}
                />
                <TextInput
                    type="double"
                    sx={{ flex: 1 }}
                    disabled={!enabled || disabled}
                    value={purchaseValue}
                    placeholder="Inkoop"
                    onChange={onPurchaseValueChanged}
                />
                <TextInput
                    type="double"
                    sx={{ flex: 1, ml: 1 }}
                    disabled={!withSaleValue || disabled}
                    value={withSaleValue ? saleValue : ''}
                    placeholder="Verkoop"
                    onChange={onSaleValueChanged}
                />
                <Row sx={{ alignItems: 'center !important' }}>
                    <Checkbox
                        color="default"
                        sx={{ p: .5 }}
                        checked={withSaleValue}
                        disabled={!enabled || disabled}
                        onChange={onWithSalePriceChanged}
                    />
                    <Typography variant="caption">Doorbelast</Typography>
                </Row>
            </Row>
        </>
    }