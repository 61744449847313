import { Professional, ProfessionalDetails } from "../../types/recruiter/professional";
import { Vacancy } from "../../types/recruiter/vacancy";
import { retrieveToken } from "../../utils/cache";
import addressApi from "../addressApi";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class PublicApi extends BaseApi {
    constructor() {
        super(apiRoutes.recruiter + "/inschrijven");
    }

    public fetchVacature = (code: string) => this.fetchJson<Vacancy>(code);
    public apply = (code: string, payload: ProfessionalDetails) => this.fetch(`${code}/apply`, "POST", { ...payload, id: "" });

    public convertProfessionalFromFile = async (file: File): Promise<Professional> => {
        const formData = new FormData();
        formData.append("file", file);
        const professional: Professional = await fetch(apiRoutes.recruiter + "/inschrijven/extract", {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + retrieveToken(),
            },
        }).then((res) => res.json());

        //Fix professional phone
        if (professional.phone?.indexOf("+31") > -1)
            professional.phone = professional.phone.replace("+31", "0");

        //Remove whitespaces from phone
        professional.phone = professional.phone?.replace(/\s/g, "");

        //Fix professional experiences
        professional.experiences = professional.experiences?.filter(e => e.company?.length > 0 || e.title?.length > 0).map((exp) => {
            if (!exp.company)
                exp.company = exp.title ?? "Onbekend";

            if (!exp.title)
                exp.title = exp.company ?? "Onbekend";

            return exp;
        });

        //Fix professional educations
        professional.educations = professional.educations?.filter(e => e.institution?.length > 0 || e.title?.length > 0).map((edu) => {
            if (!edu.title)
                edu.title = edu.institution ?? "Onbekend";

            if (!edu.institution)
                edu.institution = edu.title ?? "Onbekend";

            return edu;
        });

        if (professional.zipCode && professional.houseNumber && !isNaN(parseInt(professional.houseNumber))) {
            try {
                const details = await addressApi.toAddress(professional.zipCode, professional.houseNumber);
                professional.city = details.city;
                professional.address = details.street;
            } catch { }

        }

        return professional;
    }
}

const api = new PublicApi();
export default api;