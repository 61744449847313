import { Box } from "@mui/material";
import { PublishSettings, PublishedState } from "../../types/recruiter/vacancy";

export function PublishedStateIndicator({ status, size = 12 }: { status?: PublishSettings, size?: number }) {
    let color;

    switch (status?.published) {
        case PublishedState.Draft:
            color = "#7f8c8d";
            break;
        case PublishedState.Published:
            color = "#27ae60";
            break;
        case PublishedState.Unpublished:
            color = "#c0392b";
            break;
        case PublishedState.Pauzed:
            color = "#f1c40f";
            break;

        default:
            color = "#7f8c8d";
    }

    return <Box display='flex' alignItems='center' justifyContent='center' minHeight={50}>
        <Box sx={{ width: size, height: size, borderRadius: '50%', background: color }} />
    </Box>
}