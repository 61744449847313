import { Box, SxProps, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FC } from 'react';
import styled from 'styled-components';
import { Periods } from '../../types/contracts';
import { IconLabel } from './IconLabel';
import { ScheduleTwoTone } from '@mui/icons-material';

const Toggle = styled(ToggleButton)({
    maxHeight: 30
});

export function SelectMolecule(props: {
    selected: any[],
    disabled?: boolean,
    sx?: SxProps,
    items: {
        label: string,
        value: any,
        flex?: number
    }[],
    allowMultiple?: boolean,
    onClick?: (value: any) => void
}) {
    const handleClick = (item: any) => (e: any) => {
        e.stopPropagation();
        props.onClick?.(item.value)
    }

    return (
        <ToggleButtonGroup
            disabled={props.disabled}
            sx={{ borderRadius: 4, ...props.sx }}
            color='primary'
            value={props.selected}>
            {
                props.items.map((item) => <Toggle sx={{ flex: item.flex }} key={item.value} value={item.value} onClick={handleClick(item)}>
                    <Typography sx={{ fontSize: 10, fontWeight: props.selected.some(i => i === item.value) ? 'bold' : 300 }} variant="caption" color="secondary">
                        {item.label}
                    </Typography>
                </Toggle>)
            }
        </ToggleButtonGroup>
    );
}

export const TimesheetPeriodPicker: FC<{
    sx?: SxProps,
    disabled?: boolean,
    onChange?: (period: Periods) => void,
    value: Periods,
    label: string
}> = ({ label, onChange, value, sx, disabled }) => {
    return <Box sx={sx}>
        <IconLabel icon={ScheduleTwoTone} label={label} />
        <SelectMolecule
            sx={{ width: '100%' }}
            disabled={disabled}
            selected={[value]}
            items={[
                { label: 'Week', value: Periods.Week, flex: 1 },
                { label: '4 Wekelijks', value: Periods.FourWeeks, flex: 1 },
                { label: 'Maand', value: Periods.Month, flex: 1 }
            ]}
            onClick={onChange}
        />
    </Box>
}