import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { Professional, ProfessionalDetails } from "../../types/recruiter/professional";

class ProfessionalsApi extends BaseApi {
    constructor() {
        super(apiRoutes.recruiter + "/professionals");
    }

    public list = () => this.fetchJson<Professional[]>();
    public get = (id: string) => this.fetchJson<Professional>(id);
    public create = (prof: ProfessionalDetails) => this.fetchJson<Professional>(undefined, "POST", prof);
    public update = (prof: Professional) => this.fetch(prof._id, "PUT", prof);
    public subset = (ids: string[]) => this.fetchJson<Professional[]>("subset?ids=" + ids.join("&ids="));
    public delete = (id: string) => this.fetch(id, "DELETE");
}

const api = new ProfessionalsApi();
export default api;