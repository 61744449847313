import { Box, SxProps, Theme, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useRef } from "react";
import { EventAvailableTwoTone, EventBusyTwoTone, CalendarMonthTwoTone } from "@mui/icons-material"

import dayjs from 'dayjs';
import moment from 'moment';
import { IconLabel } from "../IconLabel";
import { delay } from "../../../utils/delay";

const Container = styled(Box)({
    paddingLeft: 8,
    background: '#eee',
    borderRadius: 4,
});

const InputWrapper = styled(Box)({
    borderWidth: 0,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
});

export function DateInput(props: {
    label: string,
    value?: Date,
    onChange?: (value: Date) => void,
    disabled?: boolean;
    onFocusLost?: () => void;
    sx?: SxProps<Theme>;
    minDate?: Date;
    tooltip?: string;
    icon?: "default" | "busy" | "available";
    required?: boolean;
}) {
    const { label } = props;
    const ref = useRef<HTMLDivElement>(null);

    let icon = undefined;

    switch (props.icon) {
        case "default":
            icon = CalendarMonthTwoTone;
            break;
        case "busy":
            icon = EventBusyTwoTone;
            break;
        case "available":
            icon = EventAvailableTwoTone;
            break;
    }

    return <Box sx={props.sx}>
        <IconLabel label={label} icon={icon} tooltip={props.tooltip} required={props.required} />
        <Container>
            <InputWrapper onClick={() => ref.current?.click()}>
                <Typography variant="caption">{
                    props.value && moment(props.value).format('DD-MM-YYYY')
                }</Typography>
            </InputWrapper>
        </Container>

        <MobileDatePicker
            disablePast
            minDate={props.minDate ? dayjs(props.minDate) : dayjs(new Date(Date.now()))}
            closeOnSelect
            value={dayjs(props.value)}
            disabled={props.disabled}
            onChange={async (value: any) => {
                if (!props.onChange)
                    return;

                const d: dayjs.Dayjs = dayjs(value);
                props.onChange(d.toDate());

                if (props.onFocusLost) {
                    await delay(300);
                    props.onFocusLost();
                }
            }}
            sx={{
                display: 'none'
            }} ref={ref} />
    </Box>
}