import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { TextInput, HeroPage, CootjeTitle } from "../../molecules";
import { Button, Container, Typography } from "@mui/material";
import { combinedValidations, requiredValidation, passwordValidation } from "../../validations";
import { LoadingButton } from "@mui/lab";
import { ArrowRightAltTwoTone } from "@mui/icons-material";

import { showToast } from "../../../store/slices/toastSlice";
import Box from '@mui/material/Box';
import api from "../../../api/organization/credentials";
import { useNavigate, useParams } from "react-router-dom";

const animation = require('../../../animations/hero-enter-credentials.json');

export default function ResetPasswordPage() {
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const params = useParams<{ code: string }>();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleResetPassword = async () => {
        if (!params.code) return;

        setLoading(true);
        try {
            await api.processPasswordReset(params.code, password);
            nav('/');
        } catch {
            dispatch(showToast({
                message: 'Er is iets fout gegaan, probeer het later opnieuw',
                type: 'error'
            }))
        }
        finally {
            setLoading(false);
        }
    }

    const validator = combinedValidations(requiredValidation, passwordValidation);
    const validationResult = validator(password);

    return (<HeroPage animation={animation} animationMaxWidth={400}>
        <Container maxWidth="xs" sx={{ mb: 20 }}>
            <CootjeTitle title="Reset wachtwoord" />
            <TextInput
                sx={{ my: 4 }}
                label="Wachtwoord"
                type={showPassword ? 'text' : 'password'}
                onSubmit={handleResetPassword}
                required
                validator={validator}
                value={password} onChange={setPassword}
                rightNeighbour={
                    <Button onClick={() => setShowPassword(!showPassword)}>
                        <Typography variant="caption">
                            {showPassword ? 'Verbergen' : 'Tonen'}
                        </Typography>
                    </Button>
                }
            />

            <Box display={'flex'}>
                <Box flex={1} />
                <LoadingButton disabled={validationResult !== undefined} loading={loading} onClick={handleResetPassword} variant="text">
                    Submit
                    <ArrowRightAltTwoTone />
                </LoadingButton>
            </Box>
        </Container>
    </HeroPage>
    );
}