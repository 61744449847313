import { Link as MuiLink, SxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useCtrlPressed from "../hooks/CtrlPressed";

export function Link(props: {
    path: string,
    children: any,
    sx?: SxProps,
    fontSize?: number
}) {
    const nav = useNavigate();
    const isCtrlPressed = useCtrlPressed();

    return isCtrlPressed
        ? <MuiLink sx={props.sx} href={props.path}>{props.children}</MuiLink>
        : <MuiLink fontSize={props.fontSize} sx={props.sx} onClick={(e) => {
            e.preventDefault();
            nav(props.path);
        }}>{props.children}</MuiLink>
}