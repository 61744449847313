import { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { AddItemDialog, Toolbar } from "../../molecules";
import { AddTwoTone } from "@mui/icons-material";
import { settingRoutes } from "../../../router/paths";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../store/slices/toastSlice";
import { useAppDispatch } from "../../../store/hooks";

import api from "../../../api/documents/pdfs";
import { PdfTable } from "../../molecules/tables/documents";
import { OrganizationPdf } from "../../../types/documents/Pdf";

export function PdfsListOrganism() {
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState<OrganizationPdf[]>([]);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setFetching(true);
        api
            .list()
            .then(setItems)
            .finally(() => setFetching(false));
    }, []);

    const onAddList = async (title: string, description: string) => {
        try {
            setFetching(true);
            await api.create({
                _id: '',
                payload: {
                    components: []
                },
                title,
                description
            });
            const newItems = await api.list();
            setItems(newItems);
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het toevoegen van het pdf document',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
            setShowDialog(false);
        }
    }

    const onDelete = async (item: OrganizationPdf) => {
        try {
            setFetching(true);
            await api.delete(item._id);
            const newItems = items.filter(i => i !== item);
            setItems(newItems);
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het verwijderen van de pdf',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
        }
    }

    const onCopyList = async (item: OrganizationPdf) => {
        try {
            setFetching(true);

            var template = await api.get(item._id);
            var result = await api.create({
                ...template,
                _id: '',
                title: item.title + ' (Kopie)'
            });

            nav(settingRoutes.pdf(result._id));
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het dupliceren van de pdf',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
        }
    }

    return <>
        <Typography sx={{ py: 1, mb: 2 }} variant="h6">Pdf Documenten</Typography>

        <Toolbar sx={{ mt: 2 }}>
            <Button onClick={() => setShowDialog(true)}>
                <AddTwoTone fontSize="small" sx={{ mr: 1 }} />
                PDF Aanmaken
            </Button>
        </Toolbar>

        {
            showDialog && <AddItemDialog
                itemName="PDF Document"
                fetching={fetching}
                onAddItem={onAddList}
                onDismiss={() => setShowDialog(false)} />
        }
        {
            fetching && <Box sx={{ p: 8, display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={30} />
            </Box>
        }
        {
            !fetching && <PdfTable onCopy={onCopyList} onDelete={onDelete} items={items} />
        }
    </>
}