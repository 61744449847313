export type FieldValidation = (value?: any) => string | undefined;

export const combinedValidations = (...validations: FieldValidation[]): FieldValidation => (value) => {
    for (const validation of validations) {
        const error = validation(value);
        if (error)
            return error;
    }

    return undefined;
}

export const requiredValidation: FieldValidation = (value) => {
    return value ? undefined : 'Dit veld is verplicht';
}

export const passwordValidation: FieldValidation = (value) => {
    return value && value.length >= 8 ? undefined : 'Minimaal 8 karakters verplicht';
}

export const fullNameValidation: FieldValidation = (value) => {
    return value && value.split(' ').length >= 2 && value.split(' ')[1].length > 0 ? undefined : 'Vul een volledige naam in';
}

export const greaterThenZeroValidation: FieldValidation = (value) => {
    return value > 0 ? undefined : 'Waarde moet groter zijn dan 0';
}

export const requiredListValidation = (count: number): FieldValidation => (value: any[]) => {
    return (value && value.length >= count) ? undefined : 'Tenminste ' + count + ' items zijn verplicht';
}