import { retrieveToken } from "../utils/cache";
import { sanitizeUrl } from "../utils/strings";

type FetchMethod = "GET" | "POST" | "PUT" | "DELETE";

export abstract class BaseApi {
    constructor(private baseUrl: string) { }

    protected async sendFormData(path: string, formData: FormData, method?: string, authorize?: boolean): Promise<Response> {
        const response = await fetch(sanitizeUrl(this.baseUrl, path), {
            method: method ?? "POST",
            body: formData,
            headers: authorize ? {
                "Authorization": `Bearer ${retrieveToken()}`
            } : undefined
        });

        if (response.ok)
            return response;

        throw new Error(await this.parseApiError(response));
    }

    protected async fetchBlob(path: string, method?: string, body?: any): Promise<Blob> {
        const response = await fetch(sanitizeUrl(this.baseUrl, path),
            {
                headers: {
                    "Authorization": `Bearer ${retrieveToken()}`,
                    "Content-Type": "application/json"
                },
                body: body ? JSON.stringify(body) : undefined,
                method: method ?? "GET"
            });

        if (response.ok)
            return response.blob();

        throw new Error(await this.parseApiError(response));
    }

    protected async fetchJson<T>(path?: string, method: FetchMethod = "GET", body?: any): Promise<T> {
        const response = await fetch(sanitizeUrl(this.baseUrl, path), {
            method,
            headers: {
                "Authorization": `Bearer ${retrieveToken()}`,
                "Content-Type": "application/json"
            },
            body: body && JSON.stringify(body)
        });

        if (response.ok)
            return response.json();

        throw new Error(await this.parseApiError(response));
    }

    protected async fetch(path?: string, method: FetchMethod = "GET", body?: any): Promise<Response> {
        const response = await fetch(sanitizeUrl(this.baseUrl, path), {
            method,
            headers: {
                "Authorization": `Bearer ${retrieveToken()}`,
                "Content-Type": "application/json"
            },
            body: body && JSON.stringify(body)
        });

        if (response.ok)
            return response;

        throw new Error(await this.parseApiError(response));
    }

    private async parseApiError(response: Response) {
        if (response.status === 401)
            return "U heeft onvoldoende rechten.";

        if (response.status === 500)
            return "Er is iets fout gegaan bij het ophalen van de gegevens.";

        try {

            const errorResponse = await response.json();

            if (errorResponse.errors) {
                const errorMessages: any[] = Object.values(errorResponse.errors);
                return errorMessages[0].errorMessage ?? errorMessages[0] as string;
            }

            if (Array.isArray(errorResponse))
                return errorResponse[0].errorMessage as string;
        } catch (error) {
        }

        return await response.statusText;
    }
}