import { MembershipRole } from "../types/company/membership";

const translationsDict = {
    [MembershipRole.AuthorizedSignatory]: "Tekenbevoegde",
    [MembershipRole.Employed]: "Inhuur",
    [MembershipRole.Owner]: "Eigenaar",
    [MembershipRole.TimesheetApprover]: "Urenverantwoordelijke",
    [MembershipRole.TAO]: "TAO"
}

export const getMembershipFriendlyName = (
    state: MembershipRole
) => translationsDict[state];