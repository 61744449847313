import { Suspense } from "react";
import { Route } from "react-router-dom";
import { PlatformUser } from "../types/user";
import { createPermissionValidator, createWhitelabelPermissionValidator, getPathPermissions } from "../utils/permissions";

export function ProtectedRoutes({
    user,
    pathsAndComponentsMap,
    isWhitelabel
}: {
    user: PlatformUser;
    pathsAndComponentsMap: {
        [key: string]: React.ComponentType;
    };
    isWhitelabel?: boolean;
}) {
    const validator = isWhitelabel ? createWhitelabelPermissionValidator(user) : createPermissionValidator(user);
    const isPathAllowed = (path: string) => validator(getPathPermissions(path));

    return Object.entries(pathsAndComponentsMap).flatMap(([path, ProtectedComponent]) => isPathAllowed(path) && <Route path={path} key={path} element={<Suspense fallback={<></>}><ProtectedComponent /></Suspense>} />)
}
