import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Membership, MembershipRole } from "../../../types/company/membership";

import membershipsApi from "../../../api/organization/memberships";
import personApi from "../../../api/organization/person";

import { CompanyMembershipsTable } from "../../molecules/tables/company/Memberships";
import { Person } from "../../../types/company/person";
import { MembershipsDialog, ModifyMembershipDialog, Toolbar } from "../../molecules";
import { SendInviteOrganism } from "../persons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { personsSelector } from "../../../store/slices/personsSlice";
import { LinkTwoTone } from "@mui/icons-material";
import { Permissions } from "../../../types/user";
import { MembershipsScope } from "../../molecules/MembershipsDialog";
import { companySelector } from "../../../store/slices/companiesSlice";
import { CompanyType } from "../../../types/company/company";

export function CompanyMembershipsOrganism() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [showDialog, setShowDialog] = useState(false);

    const company = useAppSelector(state => companySelector(state, id ?? ''));
    const [memberships, setMemberships] = useState<Membership[]>([]);
    const [modifyMembership, setModifyMembership] = useState<Membership>();
    const [relatedPersons, setPersons] = useState<Person[]>([]);
    const [fetching, setFetching] = useState(false);

    const allPersons = useAppSelector(personsSelector).persons;

    useEffect(() => {
        if (!id) return;

        setFetching(true);
        membershipsApi.listCompanyMemberships(id).then(setMemberships).catch()
            .finally(() => setFetching(false));
    }, [id]);

    useEffect(() => {
        if (!memberships.length || memberships.length === 0) return;

        setFetching(true);
        personApi.list(memberships.map((m) => m.personId)).then(setPersons).catch()
            .finally(() => setFetching(false));
    }, [memberships]);

    const onMembershipAdded = (entityId: string, roles: MembershipRole[], permissions: Permissions[]) => {
        membershipsApi.createMembership(id!, entityId, {
            roles,
            permissions
        })
            .then((membership) => setMemberships([...memberships, membership]))
            .catch(e => dispatch({ type: 'error', payload: "Er is iets mis gegaan met het koppelen" }))
            .finally(() => setShowDialog(false));
    }

    const onRemoveMembership = (membership: Membership) => {
        membershipsApi.deleteMembership(id!, membership._id)
            .then(() => {
                setMemberships(memberships.filter((m) => m._id !== membership._id));
                setPersons(relatedPersons.filter((p) => p._id !== membership.personId));
            })
            .catch(e => dispatch({ type: 'error', payload: "Er is iets mis gegaan met het ontkoppelen" }));
    };

    const onUpdateMembership = (membershipId: string) => (roles: MembershipRole[], permissions: Permissions[]) => {
        membershipsApi.updateMembership(id!, membershipId, { roles, permissions })
            .then(() => {
                setMemberships(memberships.map((m) => m._id === membershipId ? { ...m, roles, permissions } : m));
                setModifyMembership(undefined);
            })
            .catch(_ => dispatch({ type: 'error', payload: "Er is iets mis gegaan met het updaten van de koppeling" }));
    }

    return <Box sx={{ pt: 2 }}>
        <MembershipsDialog
            scope={MembershipsScope.Company}
            onDismiss={() => setShowDialog(false)}
            visible={showDialog}
            entities={
                allPersons
                    .filter((p) => memberships.every((m) => m.personId !== p._id))
                    .map((p) => ({ name: `${p.firstName} ${p.lastName}`, email: p.email, id: p._id }))}
            onCompleted={onMembershipAdded}
        />

        {modifyMembership && <ModifyMembershipDialog
            scope={MembershipsScope.Company}
            visible={modifyMembership !== undefined}
            onDismiss={() => setModifyMembership(undefined)}
            fetching={false}
            initialValues={modifyMembership}
            onCompleted={onUpdateMembership(modifyMembership._id)}
        />}

        <Toolbar>
            <Button onClick={() => setShowDialog(true)} variant="text" sx={{ mr: 1 }}>
                <LinkTwoTone fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="caption">Persoon Koppelen</Typography>
            </Button>

            {
                company?.rechtsvorm !== CompanyType.ZZP &&
                <SendInviteOrganism companyId={id} />
            }
        </Toolbar>
        {
            fetching && <CircularProgress size={30} />
        }
        {
            !fetching &&
            <CompanyMembershipsTable
                onEditPermissions={setModifyMembership}
                onRemoveMembership={onRemoveMembership}
                persons={relatedPersons}
                memberships={memberships}
            />
        }
    </Box>
}