import { useState } from "react";
import { BankAccount, EmptyBankAccount } from "../../../types/company/bankAccount";
import { ContainedButton, FormStepper, PrevNextButtons } from "../../molecules";
import { OrganizationBankingForm } from "./CompanyBankingForm";
import { OrganizationDetailsForm } from "./CompanyDetailsForm";
import { AddCompanyPayload, EmptyAddCompanyPayload } from "../../../types/company/payloads";

export function OrganizationForm(props: {
    loading: boolean,
    onCompleted: (payload: AddCompanyPayload) => void
}
) {
    const [activeStep, setActiveStep] = useState(0);
    const [company, setCompany] = useState<AddCompanyPayload>(EmptyAddCompanyPayload());
    const [kvkValid, setKvkValid] = useState<boolean>(false);

    const setBankAccount = (bankAccount: BankAccount) => {
        setCompany({
            ...company,
            bankAccount
        });
    }

    return (
        <FormStepper
            onStepClicked={setActiveStep}
            activeStep={activeStep}
            steps={[
                {
                    label: "Bedrijfsgegevens",
                    content: <OrganizationDetailsForm onKvkValidated={setKvkValid} company={company} onChange={setCompany} />
                },
                {
                    label: "Fincanciële gegevens",
                    content: <OrganizationBankingForm bankAccount={company.bankAccount ?? new EmptyBankAccount()} onChange={setBankAccount} />
                }
            ]}
        >

            <PrevNextButtons
                variant="text"
                showNext
                showPrev
                prevDisabled={activeStep === 0}
                nextDisabled={activeStep === 1}
                onPrev={() => setActiveStep(activeStep - 1)}
                onNext={() => setActiveStep(activeStep + 1)}
            />            {
                activeStep === 1 &&
                <ContainedButton disabled={!kvkValid} loading={props.loading} sx={{ my: 2 }} variant="text" onClick={() => props.onCompleted(company)}>
                    Opslaan
                </ContainedButton>
            }
        </FormStepper>
    );
}