import { useState } from "react";
import { TextInput } from "./TextInput";

export function CommentEditorInput(props: {
    initialValue: string;
    onSubmit: (value: string) => void;
}) {
    const [value, setValue] = useState(props.initialValue ?? "");
    const dispatchChange = () => props.initialValue !== value && props.onSubmit(value);

    return <TextInput
        onSubmit={dispatchChange}
        onFocusLost={dispatchChange}
        value={value}
        onChange={v => setValue(v as string)}
    />
}