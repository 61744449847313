import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { login } from "../../../store/slices/authSlice";
import { TextInput, HeroPage, CootjeTitle } from "../../molecules";
import { Button, Container, Typography } from "@mui/material";
import { combinedValidations, requiredValidation, passwordValidation } from "../../validations";
import { emailValidation } from "../../validations/email";
import { LoadingButton } from "@mui/lab";
import { ArrowRightAltTwoTone } from "@mui/icons-material";

import api from "../../../api/organization/credentials";
import { showToast } from "../../../store/slices/toastSlice";
import Box from '@mui/material/Box';
import Lottie from "react-lottie-player";

const animation = require('../../../animations/hero-stats.json');
const email_animation = require('../../../animations/email-sent.json');

export default function LoginPage() {
    const dispatch = useAppDispatch();
    const isLoggingIn = useAppSelector(state => state.auth).loggingIn;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleLogin = async () => await dispatch(login({ email, password }));
    const handleResetPassword = async () => {
        try {
            setLoading(true);
            await api.requestPasswordReset(email);
            setShowForgotPassword(false);
            setPasswordResetSent(true);
        } catch {
            dispatch(showToast({
                message: 'Er is iets fout gegaan, probeer het later opnieuw',
                type: 'error'
            }))
        }
        finally {
            setLoading(false);
        }
    }

    return (<HeroPage animation={animation} animationMaxWidth={700} animationMaxHeight={400}>
        {
            passwordResetSent && <Container maxWidth="xs" sx={{ mb: 20 }}>

                <CootjeTitle title="Email verstuurd" />
                <Box mt={4} />
                <Typography my={4} variant="body2" sx={{ mt: 2 }}>Er is een email verstuurd om je wachtwoord te resetten</Typography>
                <Lottie loop play
                    style={{ width: 200, height: 200 }}
                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                    animationData={email_animation}
                /><Box mt={4} />
                <Button variant="text" onClick={() => {
                    setPasswordResetSent(false);
                }}>
                    Inloggen
                </Button>
            </Container>
        }

        {!passwordResetSent && showForgotPassword && <Container maxWidth="xs" sx={{ mb: 20 }}>
            <CootjeTitle title="Wachtwoord herstel" />

            <TextInput
                sx={{ my: 4 }}
                label="Email"
                required
                validator={combinedValidations(requiredValidation, emailValidation)}
                value={email} onChange={setEmail} />

            <Box display={'flex'}>
                <Button color="error" onClick={() => setShowForgotPassword(false)}>Terug</Button>
                <Box flex={1} />
                <LoadingButton loading={loading} onClick={handleResetPassword} variant="text">
                    Verzenden
                </LoadingButton>
            </Box>
        </Container>}

        {!passwordResetSent && !showForgotPassword && <Container maxWidth="xs" sx={{ mb: 20 }}>
            <Box display='flex' flexDirection={'row'} alignItems={'flex-end'} sx={{ mb: .3 }}>
                <Typography variant="overline" sx={{ fontSize: '1.4rem', lineHeight: .8, letterSpacing: 0 }} color={'primary'}>Inloggen</Typography>
                <Box
                    sx={{
                        borderRadius: '50%',
                        width: 6,
                        height: 6,
                        background: '#f39c12',
                        mb: .2,
                        ml: .2
                    }}
                />
            </Box>

            <TextInput
                sx={{ mt: 5 }}
                label="Email"
                required
                validator={combinedValidations(requiredValidation, emailValidation)}
                value={email} onChange={setEmail} />

            <TextInput
                sx={{ mt: 2 }}
                label="Wachtwoord"
                type="password"
                onSubmit={handleLogin}
                required
                validator={combinedValidations(requiredValidation, passwordValidation)}
                value={password} onChange={setPassword} />

            <Button variant="text" onClick={() => setShowForgotPassword(true)}>
                <Typography textTransform={'none'} variant="body1" fontSize={12}>Wachtwoord vergeten?</Typography>
            </Button>

            <Box display={'flex'}>
                <Box flex={1} />
                <LoadingButton loading={isLoggingIn} onClick={handleLogin} variant="text">
                    Submit
                    <ArrowRightAltTwoTone />
                </LoadingButton>
            </Box>
        </Container>
        }
    </HeroPage>
    );
}