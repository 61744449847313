import { ButtonView, Editor, Plugin, Position, Writer } from "ckeditor5";

const LINK_VARIABLE = "[LINK_VALUE]";
const NAME_VARIABLE = "[NAME]";

abstract class CustomPlugin extends Plugin {
    private readonly _componentName: string;
    private readonly _btnTitle: string;

    constructor(componentName: string, btnTitle: string, editor: Editor) {
        super(editor);

        this._componentName = componentName;
        this._btnTitle = btnTitle;
    }

    abstract write(writer: Writer, pos: Position): void;

    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add(this._componentName, locale => {
            const view = new ButtonView(locale);

            view.set({ label: this._btnTitle, tooltip: false, withText: true });
            view.on('execute', () => {
                editor.model.change(writer => {
                    const insertPosition = editor.model.document.selection.getFirstPosition();
                    if (!insertPosition)
                        return;

                    this.write(writer, insertPosition);
                });
            });

            return view;
        });
    }
}

class TextPlugin extends CustomPlugin {
    private readonly _text: string;

    constructor(componentName: string, title: string, text: string, editor: Editor) {
        super(componentName, title, editor);

        this._text = text;
    }

    write(writer: Writer, pos: Position) {
        writer.insertText(this._text, pos);
    }
}

class LinkPlugin extends CustomPlugin {
    private readonly _href: string;
    private readonly _title: string;

    constructor(componentName: string, btnTitle: string, href: string, title: string, editor: Editor) {
        super(componentName, btnTitle, editor);

        this._href = href;
        this._title = title;
    }

    write() {
        const viewFragment = this.editor.data.processor.toView(`<a href="${this._href}">${this._title}</a>`);
        const modelFragment = this.editor.data.toModel(viewFragment);
        this.editor.model.insertContent(modelFragment);
    }
}

export class UsernamePlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:name', 'Voornaam van Gebruiker', NAME_VARIABLE, editor);
    }
}

export class GenericNamePlugin extends TextPlugin {
    constructor(editor: Editor) {
        super('custom:genericName', 'Naam', NAME_VARIABLE, editor);
    }
}

export class PwdResetLinkPlugin extends LinkPlugin {
    constructor(editor: Editor) {
        super('custom:pwdResetLink', 'Wachtwoord Reset Link', LINK_VARIABLE, 'Wachtwoord herstellen', editor);
    }
}

export class CredentialsLinkPlugin extends LinkPlugin {
    constructor(editor: Editor) {
        super('custom:credentialsLink', 'Gegevens Reset Link', LINK_VARIABLE, "Inloggegevens instellen", editor);
    }
}

export class InviteLinkPlugin extends LinkPlugin {
    constructor(editor: Editor) {
        super('custom:inviteLink', 'Uitnodiging Link', LINK_VARIABLE, 'Gegevens opvoeren', editor);
    }
}

export class VacancyLinkPlugin extends LinkPlugin {
    constructor(editor: Editor) {
        super('custom:vacancyPublishLink', 'Vacature Link', LINK_VARIABLE, 'Vacature bekijken', editor);
    }
}