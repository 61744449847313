import { FC, useEffect, useState } from "react";
import { ContainedButton, DotsLoader, FormStepper, PrevNextButtons, StepProps } from "../../molecules";
import { ContractAssignmentEditForm, ContractDetailsEditForm, ContractFinancialEditForm } from "../../forms/contract";
import { InhuurContract, TaoContract, ZzpContract } from "../../../types/contracts/Contracts";
import { ContractInvoiceEditForm } from "../../forms/contract/ContractInvoiceDetailsEdit";

import api from '../../../api/contracts/defaultValues';
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";

export const ContractDefaultValuesOrganism: FC = () => {
    const { type } = useParams<{ type: string }>();
    const dispatch = useAppDispatch();
    const [contract, setContract] = useState<TaoContract | ZzpContract | InhuurContract>();
    const [saving, setSaving] = useState(false);
    const [step, setStep] = useState(0);

    const onSave = async () => {
        if (!contract) return;

        setSaving(true);
        try {
            await api.update(contract);
            setSaving(false);
            dispatch(showToast({
                message: 'Standaard waardes zijn opgeslagen',
                type: 'success'
            }))
        } catch (e: any) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan:' + e.message,
                type: 'error'
            }))
        } finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        if (!type) return;

        api.get(type).then(setContract);
    }, [type]);

    if (!contract)
        return <DotsLoader message="Standaard waardes word geladen" />;

    const steps: StepProps[] = [
        {
            label: 'Professional en Organisatie(s)',
            content: <ContractDetailsEditForm
                hirerMembers={[]}
                contract={contract}
                divisions={[]}
                professionals={[]}
                authorizedSignatoriesSupplier={[]}
                authorizedSignatoriesHirer={[]}
                timesheetApproversHirer={[]}
                companies={[]}
                onChange={setContract}
            />
        },
        {
            label: 'Werkzaamheden',
            content: <ContractAssignmentEditForm
                value={contract.assignment}
                onChange={assignment => setContract(c => ({ ...c!, assignment }))}
            />
        },
        {
            label: 'Financiele afspraken',
            content: <ContractFinancialEditForm
                caoList={[]}
                organizations={[]}
                financial={contract.financial}
                onChange={financial => setContract(c => ({ ...c!, financial }))}
            />
        },
        {
            label: 'Factuur gegevens',
            content: <ContractInvoiceEditForm
                invoiceDetails={contract.invoiceDetails}
                onChange={invoiceDetails => setContract(c => ({ ...c!, invoiceDetails }))}
            />
        }
    ];

    return <FormStepper
        maxWidth="xl"
        steps={steps}
        activeStep={step}
        onStepClicked={setStep}
    >
        <PrevNextButtons
            variant="text"
            onPrev={() => setStep(step - 1)}
            onNext={() => setStep(step + 1)}
            showNext={step < steps.length - 1}
            showPrev={step > 0}
        />

        {
            step === steps.length - 1 &&
            <ContainedButton onClick={onSave} loading={saving}>
                Opslaan
            </ContainedButton>
        }
    </FormStepper>
}