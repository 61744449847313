import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";

export const CootjeImg = React.memo((props: { assetName: string }) => {
    console.log('CootjeImg rendered');

    // Memoize the src attribute
    const src = useMemo(() => `/assets/${props.assetName}`, [props.assetName]);


    return <img src={src} alt="Logo" style={{ maxWidth: 40, height: 'auto' }} />;
});

export function CootjeLogo() {
    return <Box sx={{ display: 'flex', flex: 0, alignItems: 'center' }}>
        <Box>
            <CootjeImg assetName="cootje_logo.png" />
        </Box>
        <CootjeTitle title="Cootje" />
    </Box>
}

export function CootjeTitle(props: { title: string }) {
    return <Box display='flex' flexDirection={'row'} alignItems={'flex-end'} sx={{ mb: .3 }}>
        <Typography variant="overline" sx={{ fontSize: '1.4rem', lineHeight: .8, letterSpacing: 0 }} color={'primary'}>{props.title}</Typography>
        <Box
            sx={{
                borderRadius: '50%',
                width: 6,
                height: 6,
                background: '#f39c12',
                mb: .2,
                ml: .2
            }}
        />
    </Box>
}