import { Box, Button, Checkbox } from "@mui/material";
import { FormItem, FormItemType, FormMultipleChoice } from "../../../types/documents/Form";
import { DeleteButton, TextArea, TextInput } from "..";
import { primaryLight } from "../../theme";
import { useState } from "react";
import { DeleteTwoTone } from "@mui/icons-material";
import { DraggableComponent } from "../document-builder/DraggableComponent";

export const FormEditorComponentRenderer: React.FC<{
    item: FormItem,
    onChange?: (details: FormItem) => void,
    onDelete?: () => void,
    editing: boolean
}> = ({ item, onChange, onDelete, editing }) => {

    const onMultipleChoiceChange = (options: string[]) => {
        onChange && onChange({ ...item, options } as FormMultipleChoice);
    }

    const [hovering, setHovering] = useState(false);

    return <DraggableComponent data={{ item }} disabled={!editing}>
        <div style={{ position: 'relative' }} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            {
                editing && onDelete && hovering && <DeleteButton sx={{ position: 'absolute', right: 0, top: -15, zIndex: 100 }} variant="text" onClick={onDelete} />
            }
            {(item.title.length > 0 || editing) && <TextInput
                disabled={!editing}
                value={item.title}
                sx={{ mb: 1, pr: 20 }}
                onChange={(e) => onChange && onChange({ ...item, title: e })}
                inputSx={{ fontSize: 20, height: 30, background: item.title ? '#FFF' : primaryLight, color: '#34495e', pl: 0, fontWeight: 600 }}
            />}

            {(item.description.length || editing) && item.type === FormItemType.TextSection && <TextArea
                value={item.description}
                onChange={(e) => editing && onChange && onChange({ ...item, description: e })}
                inputSx={{ fontSize: 16, height: 20, background: item.description ? '#FFF' : primaryLight, pl: 0 }}

            />}

            {(item.description.length || editing) && item.type !== FormItemType.TextSection && <TextInput
                disabled={!editing}
                value={item.description}
                onChange={(e) => onChange && onChange({ ...item, description: e })}
                inputSx={{ fontSize: 14, height: 20, background: item.description ? '#FFF' : primaryLight, pl: 0 }}
            />}

            {item.type === FormItemType.Question && <QuestionInputRenderer />}
            {item.type === FormItemType.MultipleChoice && <MultipleChoiceInputRenderer editing={editing} options={(item as any).options} onChange={onMultipleChoiceChange} />}
        </div>
    </DraggableComponent>
};

const QuestionInputRenderer: React.FC = () => {
    return <Box mt={.5}>
        <Box sx={{ width: '100%', height: 50, border: '1px solid #eee' }} />
    </Box>
};

const MultipleChoiceInputRenderer: React.FC<{ options: string[], onChange: (options: string[]) => void, editing: boolean }> = ({ options, onChange, editing }) => {
    const [hovering, setHovering] = useState(false);
    const onDelete = (index: number) => {
        onChange(options.filter((_, i) => i !== index));
    }

    const onOptionChange = (index: number, value: string) => {
        const newOptions = [...options];
        newOptions[index] = value;
        onChange(newOptions);
    }

    const addOption = () => {
        onChange([...options, 'Nieuwe optie']);
    }

    return <div style={{ marginTop: 4 }} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        {options.map((option, index) => <div
            key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox title={option} checked={false} disabled />
            <TextInput disabled={!editing} value={option} onChange={(e) => onOptionChange(index, e)} inputSx={{ background: '#FFF', pl: 0, fontSize: 14 }} />
            {editing && hovering && <Button color="error" onClick={() => onDelete(index)}>
                <DeleteTwoTone />
            </Button>}
        </div>)}

        {editing && hovering && <Button onClick={addOption}>Keuze toevoegen</Button>}
    </div>
}