import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { InvoiceDetails, ReferenceValue } from "../../../types/contracts";
import { IconLabel, TextInput, YesNoToggle } from "../../molecules";
import { EmojiPeopleTwoTone, LowPriorityTwoTone, TransferWithinAStationTwoTone } from "@mui/icons-material";
import { Row, RowCentered } from "../../molecules/Row";
import { CustomerInvoiceDetails, SupplierInvoiceDetails, VatTypes } from "../../../types/contracts/InvoiceDetails";
import { SelectMolecule } from "../../molecules/MultiSelect";

const mw = 500;

export const ContractInvoiceDetailsForm: FC<{
    hideSupplier?: boolean,
    invoiceDetails: InvoiceDetails
}> = ({ invoiceDetails, hideSupplier }) => {
    return <Box>
        <Box display={'flex'} flexWrap={'wrap'}>
            <CustomerFinancial details={invoiceDetails.customerInvoiceDetails} />
            <Box width={16} />
            {
                !hideSupplier && <>
                    <SupplierFinancial details={invoiceDetails.supplierInvoiceDetails} />
                    <Box width={16} />
                </>
            }
            {
                invoiceDetails.customerInvoiceDetails.references.length > 0 && <>
                    <CustomerReferences references={invoiceDetails.customerInvoiceDetails.references} />
                    <Box width={16} />
                </>
            }
        </Box>
    </Box >
}

const BTWTypeInput: FC<{
    value: VatTypes
}> = ({ value }) => {
    return <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="secondary">BTW Situatie</Typography>
        <SelectMolecule
            sx={{ width: '100%' }}
            disabled
            selected={[value]}
            items={[
                { label: 'Standaard', value: VatTypes.Default },
                { label: 'KOR', value: VatTypes.KOR },
                { label: 'BTW Verlegd', value: VatTypes.BTWVerlegd, flex: 1 },
                { label: 'BTW Vrijgesteld', value: VatTypes.BTWVrijgesteld, flex: 1 },
                { label: 'Vrijstelling KO', value: VatTypes.VrijstellingKO, flex: 1 },
            ]}
        />
    </Box>
}

const CustomerFinancial: FC<{
    details: CustomerInvoiceDetails
}> = ({ details }) => {
    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: mw, mb: 2, borderRadius: 1 }}>
        <RowCentered>
            <EmojiPeopleTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Klant Factuur</Typography>
        </RowCentered>

        <BTWTypeInput value={details.vatType} />

        <TextInput sx={{ mt: 2 }}
            label="BTW-Verlegnummer"
            value={details.vatReverseChargeNumber}
            disabled
        />

        <TextInput sx={{ mt: 2 }}
            label="Ter attentie van"
            value={details.name}
            disabled
        />

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ pr: 1, flex: 2 }}
                label="Postcode"
                value={details.zipCode}
                disabled
            />

            <TextInput
                sx={{ flex: 2 }}
                label="Plaats"
                value={details.city}
                disabled
            />
        </Row>

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ pr: 1, flex: 3 }}
                label="Straat"
                value={details.address}
                disabled
            />
            <TextInput
                sx={{ flex: 2 }}
                label="Land"
                value={details.country}
                disabled
            />
        </Row>


        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ pr: 1, flex: 2 }}
                label="Telefoon"
                value={details.phone}
                disabled
            />

            <TextInput
                sx={{ flex: 3 }}
                label="Email"
                value={details.email}
                disabled
            />
        </Row>

    </Box>
}

const SupplierFinancial: FC<{
    details: SupplierInvoiceDetails
}> = ({ details }) => {
    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: mw, mb: 2, borderRadius: 1 }}>

        <RowCentered>
            <TransferWithinAStationTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Leverancier Factuur</Typography>
        </RowCentered>

        <BTWTypeInput value={details.vatType} />

        <IconLabel sx={{ mt: 2 }} label="SNA Gecertificeerd" />
        <YesNoToggle value={details.snaCertified} onChange={() => { }} />

        <Row sx={{ mt: 2 }}>
            <TextInput sx={{ flex: 3, pr: 1 }}
                label="G-rekening"
                value={details.gBankAccount}
                disabled

            />

            <TextInput sx={{ flex: 1 }}
                label="G-rekening Verdeling"
                value={details.gDivision}
                disabled
            />
        </Row>
    </Box>
}


const CustomerReferences: FC<{
    references: ReferenceValue[]
}> = ({ references }) => {

    const renderReference = (ref: ReferenceValue) => {
        return <TextInput
            key={ref._id}
            sx={{ flex: 1, pr: 1, mt: 2, minWidth: 300 }}
            label={ref.name}
            value={ref.value}
            disabled
        />
    }

    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: mw, mb: 2, borderRadius: 1 }}>
        <RowCentered>
            <LowPriorityTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Klant Referenties</Typography>
        </RowCentered>

        <Box display={'flex'} flexWrap={'wrap'}>
            {
                references.map(renderReference)
            }
        </Box>
    </Box>
}