import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '..';
import { FetchingState } from '../../types/fetchingState';
import { showToast } from './toastSlice';
import { EmailNotificationPayload, EmailNotificationSetting } from '../../types/recruiter/notification';

import api from '../../api/recruiter/notifications';

export interface RecruiterNotificationsState {
    deletesInProgress: string[];
    notifications: EmailNotificationSetting[];
    fetchingUpdate: FetchingState,
    fetchingList: FetchingState;
    fetchingCreate: FetchingState
}

const intialState: RecruiterNotificationsState = {
    notifications: [],
    fetchingList: "init",
    fetchingCreate: "init",
    fetchingUpdate: "init",
    deletesInProgress: []
}

export const listRecruiterNotifications = createAsyncThunk(
    'recruiter/notifications/list',
    async (_, thunkApi) => {
        try {
            const notifications = await api.list();
            return notifications;
        } catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const createRecruiterNotification = createAsyncThunk(
    'recruiter/notifications/create',
    async (v: EmailNotificationPayload, thunkApi) => {
        try {
            const settings = await api.create(v);
            return settings;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const deleteRecruiterNotification = createAsyncThunk(
    'recruiter/notifications/delete',
    async (id: string, thunkApi) => {
        try {
            await api.delete(id);
            return id;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const notificationsSlice = createSlice({
    name: 'recruiter/notifications',
    initialState: intialState,
    reducers: {},
    extraReducers: (builder) => {
        // List
        builder
            .addCase(listRecruiterNotifications.pending, (state) => {
                state.fetchingList = "fetching";
            })
            .addCase(listRecruiterNotifications.rejected, (state) => {
                state.fetchingList = "error";
            })
            .addCase(listRecruiterNotifications.fulfilled, (state, action: PayloadAction<EmailNotificationSetting[]>) => {
                state.fetchingList = "completed";
                state.notifications = action.payload;
            })

        // Create
        builder.addCase(createRecruiterNotification.pending, (state) => {
            state.fetchingCreate = "fetching";
        })
            .addCase(createRecruiterNotification.rejected, (state) => {
                state.fetchingCreate = "error";
            })
            .addCase(createRecruiterNotification.fulfilled, (state, action: PayloadAction<EmailNotificationSetting>) => {
                state.fetchingCreate = "completed";
                state.notifications = [action.payload, ...state.notifications];
            })

        // Delete
        builder.addCase(deleteRecruiterNotification.pending, (state, action) => {
            state.deletesInProgress.push(action.meta.arg);
        })
            .addCase(deleteRecruiterNotification.rejected, (state, action) => {
                state.deletesInProgress = state.deletesInProgress.filter(id => id !== action.meta.arg);
            })
            .addCase(deleteRecruiterNotification.fulfilled, (state, action: PayloadAction<string>) => {
                state.deletesInProgress = state.deletesInProgress.filter(id => id !== action.payload);
                state.notifications = state.notifications.filter(n => n._id !== action.payload);
            })


    }
})

export const recruiterNotificationsSelector = (state: RootState) => state.notifications;
export default notificationsSlice.reducer