import { Box, Container, Fade, Typography } from "@mui/material";
import { HeroTutorialState } from "../../store/slices/heroTutorialSlice";
import { CootjeTitle } from "./CootjeLogo";

export function HeroTutorial(props: HeroTutorialState) {
    return <Container maxWidth='sm'>
        <Box mb={6}>
            <CootjeTitle title="Uitleg" />
            <Fade in key={props.sections[0].description.length} timeout={700}>
                <Box mt={3}>
                    {props.sections.map((section, index) => {
                        return (
                            <Box key={index} mb={2}>
                                <Typography fontWeight={'bold'} color={'secondary'}>{section.title}</Typography>
                                <Typography whiteSpace={'pre-line'} fontWeight={100}>{section.description}</Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>
        </Box>
    </Container>
}