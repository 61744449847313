import { CootjeLogo } from ".";
import { ToastOrganism } from "../organisms";

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Lottie from "react-lottie-player";
import { useAppSelector } from "../../store/hooks";
import { heroTutorialSelector } from "../../store/slices/heroTutorialSlice";
import { HeroTutorial } from "./HeroTutorial";

export function HeroPage(props: {
    animation: any;
    animationMaxWidth?: number;
    animationMaxHeight?: number;
    children?: React.ReactNode;
    top?: boolean;

}) {
    const sections = useAppSelector(heroTutorialSelector).sections;

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                md={5}
                lg={6}
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: sections.length === 0 ? 'center' : 'flex-start',
                    pt: sections.length === 0 ? 0 : 8,
                    display: { xs: 'none', md: 'flex', lg: 'flex' },
                    overflow: 'auto',
                    height: '100vh'
                }}
            >
                <Box>
                    <Box sx={{ overflow: 'hidden', display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                        <CootjeLogo />
                        {sections.length === 0 && <Lottie loop play
                            style={{ width: props.animationMaxWidth, height: props.animationMaxHeight, zIndex: -1, maxWidth: '90%' }}
                            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                            animationData={props.animation}
                        />}
                        {
                            sections.length > 0 && <HeroTutorial sections={sections} />
                        }
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={6} component={Paper} elevation={6} square sx={{
                height: '100vh',
                overflow: 'auto',
                display: 'flex', alignItems: props.top ? 'flex-start' : 'center'
            }}>
                <ToastOrganism />
                {props.children}
            </Grid>
        </Grid>
    );
}
