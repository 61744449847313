export interface WorkExperience {
    title: string;
    company: string;
    startDate?: string;
    endDate?: string;
    location?: string;
    description?: string;
}

export interface Education {
    title: string;
    institution: string;
    kind?: string;
    graduationDate?: string;
}

export enum Gender {
    MALE = 1,
    FEMALE = 2
}

export enum ContractingType {
    ZZP = 0,
    Leverancier = 1,
    TAO = 2,
}

export interface ProfessionalDetails {
    contractingTypes: ContractingType[];
    name: string;
    email: string;

    zipCode: string;
    houseNumber: string;
    city: string;
    address: string;

    phone: string;
    gender: Gender;
    title: string;

    skills: string[];
    experiences: WorkExperience[];
    educations: Education[];
}

export interface Professional extends ProfessionalDetails {
    _id: string;
}

export const emptyProfessional: Professional = {
    _id: '',
    name: '',
    email: '',
    phone: '06',
    gender: Gender.MALE,
    title: '',
    educations: [],
    experiences: [],
    houseNumber: '',
    zipCode: '',
    skills: [],
    address: '',
    city: '',
    contractingTypes: [ContractingType.ZZP]
}

export const emptyEducation: Education = {
    title: '',
    institution: '',
    graduationDate: '',
    kind: ''
}

export const emptyExperience: WorkExperience = {
    title: '',
    company: '',
    startDate: '',
    endDate: '',
    location: '',
    description: ''
}