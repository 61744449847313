import { MergeField } from "../contracts";



export enum DocumentTarget {
    Person = 1,
    Supplier = 2,
    Hirer = 3
}

export enum DocumentType {
    Upload = 2,
    Form = 3,
    DocuSign = 4
}

export enum DocumentState {
    Requested = 1,
    Verification = 2,
    Denied = 3,
    Approved = 4,
    AwaitingSignature = 5,
    Signed = 6
}

export enum DocumentModule {
    Company = 1,
    Person = 2,
    Contract = 3
}

export interface BaseDocument {
    title: string;
    description?: string;
    targetType: DocumentTarget;
    documentReferenceId?: string;
    type: DocumentType;
}

export interface RequestedDocument extends BaseDocument {
    _id: string;
    targetId: string;
    state: DocumentState;
    module: DocumentModule;
    moduleId: string;
    updatedAt?: Date | null;
    userInput?: string | null;
}

export interface DocumentSigner {
    email: string;
    name: string;
}

export interface DocumentMergeRequest {
    fields: MergeField[];
    item: RequestedDocument;
    signers: DocumentSigner[];
    documentRefs: string[];
}