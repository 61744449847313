export enum InviteType {
    ZZP = 1,
    TAO = 2,
    Inhuur = 3,
    InhuurWithCompany = 4,
}

export interface PersonInvite {
    _id: string;
    email: string;
    code: string;
    type: InviteType;
    companyId?: string;
    requestedFiles: string[];
    registerCredentials: boolean;
}

export interface IdentificationDetails {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    validUntil: string;
    documentKind: string;
}