// Enum equivalent for FormItemType
export enum FormItemType {
    Question = 1,
    MultipleChoice = 2,
    TextSection = 3
}

// Interface equivalent for FormItem
export interface FormItem {
    _id: string;
    title: string;
    description: string;
    required: boolean;
    type: FormItemType;
}

// Interface equivalent for FormTextSection
export interface FormTextSection extends FormItem {
    type: FormItemType.TextSection;
}

export const EmptyTextSection = (): FormTextSection => ({
    _id: '',
    title: 'Text Sectie Titel',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    required: false,
    type: FormItemType.TextSection
});

// Interface equivalent for FormQuestion
export interface FormQuestion extends FormItem {
    answer: string;
    type: FormItemType.Question;
}

export const EmptyQuestion = (): FormQuestion => ({
    _id: '',
    title: 'Wat is uw vraag?',
    description: 'Optioneel een extra omschrijving',
    required: false,
    answer: '',
    type: FormItemType.Question
});

// Interface equivalent for FormMultipleChoice
export interface FormMultipleChoice extends FormItem {
    options: string[];
    answer: string;
    type: FormItemType.MultipleChoice;
}

export const EmptyMultipleChoice = (): FormMultipleChoice => ({
    _id: '',
    title: 'Kies een van de volgende opties',
    description: 'omschrijving',
    required: false,
    options: [
        'Ja',
        'Nee'
    ],
    answer: '',
    type: FormItemType.MultipleChoice
});

// Interface equivalent for FormAnswer
export interface FormAnswer {
    itemId: string;
    answer: string;
}

// Interface equivalent for OrganizationForm
export interface OrganizationForm {
    _id: string;
    title: string;
    description: string;
    storeReferenceId?: string;
    completedAt?: Date;
    completedBy?: string;
    formFields: FormItem[];
}
