import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..';

export interface HeroTutorialState { sections: { title: string, description: string }[] }

const intialState: HeroTutorialState = {
    sections: []
};

export const tutorialSlice = createSlice({
    name: 'heroTutorial',
    initialState: intialState,
    reducers: {
        setTutorial: (state, action: PayloadAction<HeroTutorialState>) => {
            state.sections = action.payload.sections;
        }
    }
})
export const { setTutorial } = tutorialSlice.actions;
export const heroTutorialSelector = (state: RootState) => state.heroTutorial;
export default tutorialSlice.reducer