import { ArrowBackTwoTone } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { Fade } from '@mui/material';
import { useNavigate } from "react-router-dom";

export function Page(props: {
    title?: string;
    children: React.ReactNode;
    canGoBack?: boolean;
}) {
    const nav = useNavigate();

    return <Box sx={{ p: 4, height: '100vh', overflow: 'auto' }}>
        {props.title && <Box sx={{ mb: 2 }}>
            <Fade appear in timeout={600}><Box sx={{ display: 'flex', alignItems: 'center' }}>
                {props.canGoBack && <IconButton onClick={() => nav(-1)}>
                    <ArrowBackTwoTone />
                </IconButton>}
                <Typography color="primary" variant="overline" sx={{ fontSize: '1.5rem', lineHeight: '1.5rem' }}>{props.title}</Typography>
            </Box>
            </Fade>
        </Box>
        }
        {
            props.children
        }
    </Box>
}