import { Credentials } from "../types/company/credentials";
import { PlatformUser } from "../types/user";
import { BaseApi } from "./base";
import { apiRoutes } from "./routes";

class AuthApi extends BaseApi {
    constructor() {
        super(apiRoutes.iam);
    }

    public getEnvironmentAdminToken = (environmentId: string) => this.fetch(`auth/${environmentId}/adminToken`).then(res => res.text());

    public fetchUser = () => this.fetchJson<PlatformUser>("auth/userinfo");

    public createWhitelabel = (name: string) => this.fetch("auth/register-whitelabel?name=" + name, "POST");

    public findPersonCredentials = (personId: string) =>
        this.fetchJson<Credentials>(`credentials/${personId}/find-by-person`)
            .catch(err => {
                if (err.message === "Not Found")
                    return;

                throw err;
            });


    public login = (email: string, password: string) => this.fetch("auth/login", "POST", { email, password }).then(res => res.text());

    public listEnvironments = () => this.fetchJson<{
        _id: string;
        description: string;
    }[]>("auth/list");
}

const api = new AuthApi();
export default api;