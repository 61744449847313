import { FC, useState } from "react";

import { Box } from "@mui/material";
import { BurstModeTwoTone, DriveFileRenameOutlineTwoTone, GestureTwoTone, RadioButtonCheckedTwoTone, RemoveRedEyeTwoTone, RemoveTwoTone, SaveAltTwoTone, SkipNextTwoTone, SpaceBarTwoTone, TextFieldsTwoTone, TitleTwoTone } from "@mui/icons-material";

import { ContainedButton, IconLabel, MergeFieldsDialog, TextArea, TextInput, Toolbar } from "..";
import { DroppableArea } from "../document-builder/DroppableArea";
import { PaperSheet } from "../document-builder/PaperSheet";
import { primaryLighter } from "../../theme";

import { generateGuid, isValidGuid } from "../../../utils/strings";
import { LoadingButton } from "@mui/lab";
import { ToolbarUIButton } from "../document-builder/ToolbarUIButton";
import { defaultBreakComonent, defaultCheckboxComponent, defaultHeaderComponent, defaultHorizontalLine, defaultNextPageComponent, defaultSignatureComponent, defaultSignaturesComponent, DefaultTextComponent, defaultTextFieldComponent, DefaultTitleComponent, PdfComponent, PdfComponents } from "../../../types/documents/Pdf";
import { PdfEditorComponentRenderer } from "./PdfEditorComponentRenderer";
import { previewDocumentInBrowser } from "../../../utils/downloadFile";

import api from "../../../api/documents/pdfs";

export function PdfEditorMolecule(props: {
    editing: boolean,
    saving: boolean,
    onSave: (items: PdfComponent[], title: string, description: string) => any,
    initialValues: {
        title: string,
        description: string,
        components: PdfComponent[]
    }
}) {
    const { saving, onSave } = props;
    const [title, setTitle] = useState(props.initialValues.title);
    const [description, setDescription] = useState(props.initialValues.description);
    const [items, setItems] = useState<PdfComponent[]>(props.initialValues.components);
    const [showMergeFields, setShowMergeFields] = useState(false);
    const { editing } = props;

    const onInsert = (item: PdfComponent, index: number) => {
        if (item.component === PdfComponents.Header)
            index = 0;

        console.log(`Dropped at index ${index}`);

        const newItem = { ...item, _id: isValidGuid(item._id) ? item._id : generateGuid() };

        setItems((prevItems) => {
            // Find the index of the existing item (if it exists)
            const existingIndex = prevItems.findIndex(i => i._id === newItem._id);

            // Remove the existing item if it's in the array
            let updatedItems = [...prevItems];
            if (existingIndex !== -1) {
                updatedItems.splice(existingIndex, 1);

                // Adjust the target index if the existing item was before the insert index
                if (existingIndex < index) {
                    index--;
                }
            }

            // Insert the new item at the specified index
            updatedItems.splice(index, 0, newItem);

            return updatedItems;
        });
    }

    const onUpdate = (index: number, item: PdfComponent) => {
        setItems((prevItems) => {
            return [
                ...prevItems.slice(0, index),
                item,
                ...prevItems.slice(index + 1),
            ];
        });
    }

    const onDelete = (index: number) => {
        setItems((prevItems) => {
            return [
                ...prevItems.slice(0, index),
                ...prevItems.slice(index + 1),
            ];
        });
    }

    const onPreview = async () => {
        var preview = await api.previewPdfDocument({
            components: items
        });

        previewDocumentInBrowser(preview);
    }

    const EditorToolbar: FC = () => <Toolbar sx={{ position: 'fixed', left: (34.5 * 8), bottom: 0, zIndex: 100, width: 'calc(100% - 276px)', background: '#fff' }}>
        <Box display={'flex'} alignItems={'center'} sx={{ background: primaryLighter, width: '100%', minHeight: 80, borderTop: '1px solid #eee' }}>
            <Box>
                <LoadingButton loading={saving} onClick={() => onSave(items, title, description)} sx={{ ml: 1, bacground: '#fff', mb: 1 }} color="secondary" variant="contained">
                    <SaveAltTwoTone fontSize="small" sx={{ mr: 1 }} />Opslaan
                </LoadingButton>
                <LoadingButton loading={saving} onClick={onPreview} sx={{ ml: 1, bacground: '#fff' }} color="secondary" variant="outlined">
                    <RemoveRedEyeTwoTone fontSize="small" sx={{ mr: 1 }} />Preview
                </LoadingButton>
            </Box>


            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <ToolbarUIButton icon={<BurstModeTwoTone color="secondary" />} title={'Header Element'} item={defaultHeaderComponent()} />
                <ToolbarUIButton icon={<TitleTwoTone color="secondary" />} title={'Titel Element'} item={DefaultTitleComponent()} />
                <ToolbarUIButton icon={<TextFieldsTwoTone color="secondary" />} title={'Tekst Element'} item={DefaultTextComponent()} />

                <ToolbarUIButton icon={<SpaceBarTwoTone color="secondary" />} title={'Ruimte (Klein)'} item={defaultBreakComonent(5)} />
                <ToolbarUIButton icon={<SpaceBarTwoTone color="secondary" />} title={'Ruimte (Middel)'} item={defaultBreakComonent(10)} />
                <ToolbarUIButton icon={<SpaceBarTwoTone color="secondary" />} title={'Ruimte (Groot)'} item={defaultBreakComonent(20)} />

                <ToolbarUIButton icon={<RadioButtonCheckedTwoTone color="secondary" />} title={'Checkbox'} item={defaultCheckboxComponent()} />
                <ToolbarUIButton icon={<RemoveTwoTone color="secondary" />} title={'Lijn'} item={defaultHorizontalLine()} />
                <ToolbarUIButton icon={<DriveFileRenameOutlineTwoTone color="secondary" />} title={'Invul veld'} item={defaultTextFieldComponent()} />

                <ToolbarUIButton icon={<GestureTwoTone color="secondary" />} title={'Handtekening'} item={defaultSignatureComponent()} />
                <ToolbarUIButton icon={<GestureTwoTone color="secondary" />} title={'Handtekening Dubbel'} item={defaultSignaturesComponent()} />
                <ToolbarUIButton icon={<SkipNextTwoTone color="secondary" />} title={'Volgende Pagina'} item={defaultNextPageComponent()} />

                <ContainedButton sx={{ height: 40, mt: 1 }} onClick={() => setShowMergeFields(true)}>
                    <RemoveRedEyeTwoTone sx={{ mr: 1 }} />
                    Contract Velden
                </ContainedButton>
            </Box>
        </Box>
    </Toolbar>

    const hasHeader = items.some(i => i.component === PdfComponents.Header);

    return <>
        <MergeFieldsDialog visible={showMergeFields} onDismiss={() => setShowMergeFields(false)} />
        <IconLabel label={'Titel'} />
        <TextInput value={title} onChange={editing ? setTitle : undefined} inputSx={{ background: '#FFF', fontSize: 18 }} />
        <TextArea
            label={'Omschrijving'}
            value={description}
            sx={{ my: 1 }}
            inputSx={{ background: '#FFF', fontSize: 14, minHeight: 0 }}
            onChange={editing ? setDescription : undefined}
        />
        {editing && <EditorToolbar />}

        <Box display={'flex'} mb={6}>
            <PaperSheet sx={{ flex: 1 }}>
                {editing && !hasHeader && <DroppableArea onDrop={(data) => onInsert(data.item, 0)} />}
                {
                    items.map((item, index) => <Box key={item._id ?? generateGuid()}>
                        <PdfEditorComponentRenderer editing={editing} onDelete={() => onDelete(index)} item={item} onChange={value => onUpdate(index, value)} />
                        {editing && <DroppableArea key={index} onDrop={(data) => onInsert(data.item, index + 1)} />}
                    </Box>
                    )
                }
            </PaperSheet>
        </Box>
    </>
}