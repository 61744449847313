export enum VacancyRelationState {
    RecruiterApplied = 0,
    AppliedByForm = 1,
    Rejected = 2,
    Accepted = 3,
    InvitedForInterview = 4,
    ToBeInterviewed = 5,
    Interviewed = 6,
    Hired = 7,
    WaitingForDocuments = 8
}

export interface VacancyRelation {
    _id: string;
    professionalId: string;
    vacancyId: string;
    status: VacancyRelationState;
    comment: string;
}