import { Professional } from "../types/recruiter/professional";
import { containsIgnoreCase } from "../utils/strings";

export const filterBySkills = (professionals: Professional[], skills: string[]): Professional[] => {
    return professionals.filter(professional => professional.skills.some(skill => skills.some(s => containsIgnoreCase(s, skill))));
}

export const filterByFunctie = (professionals: Professional[], title: string): Professional[] => {
    return professionals.filter(professional => containsIgnoreCase(professional.title, title));
}

export const filterByExperience = (professionals: Professional[], years: number): Professional[] => {
    const now = new Date();
    const yearsAgo = new Date(now.getFullYear() - years, now.getMonth(), now.getDate());

    return professionals.filter(professional => {
        return professional.experiences.some(experience => {
            const startDate = new Date(experience.startDate || "");
            return startDate < yearsAgo;
        });
    });
}

export const filterByName = (professionals: Professional[], name: string): Professional[] => {
    return professionals.filter(professional => containsIgnoreCase(professional.name, name));
}

export const filterBySearch = (professionals: Professional[], search: string): Professional[] => {
    return professionals.filter(professional => {
        return (
            containsIgnoreCase(professional.name, search) ||
            containsIgnoreCase(professional.title, search) ||
            containsIgnoreCase(professional.city, search) ||
            containsIgnoreCase(professional.address, search) ||
            professional.skills.some(skill => containsIgnoreCase(skill, search)) ||
            professional.experiences.some(experience =>
                [experience.title, experience.company, experience.location, experience.description]
                    .some(field => containsIgnoreCase(field || "", search))
            ) ||
            professional.educations.some(education =>
                [education.title, education.institution, education.kind]
                    .some(field => containsIgnoreCase(field || "", search))
            )
        );
    });
}

export const filterByZipCode = (professionals: Professional[], zipCode: string): Professional[] => {
    return professionals.filter(professional => containsIgnoreCase(professional.zipCode.replaceAll(' ', ''), zipCode.replaceAll(' ', '')));
}