import { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { DocumentListItemsTable } from "../../molecules/tables/documents";
import { AddDocumentDialog, TextInput, Toolbar } from "../../molecules";
import { DocumentList, DocumentListTargets, BaseDocument } from "../../../types/documents";

import { useNavigate, useParams } from "react-router-dom";
import { settingRoutes } from "../../../router/paths";
import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";

import { AddTwoTone } from "@mui/icons-material";
import api from "../../../api/documents/lists";



export function DocumentListOrganism() {
    const id = useParams<{ id: string }>().id;
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const [fetching, setFetching] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [template, setTemplate] = useState<DocumentList>({
        _id: "",
        title: "",
        description: "",
        items: [],
        target: DocumentListTargets.Onboarding
    });

    const onDelete = async (item: BaseDocument) => {
        try {
            const updated = { ...template, items: template.items.filter(i => i !== item) };
            await update(updated);
            setTemplate(updated);
        } catch (e) {
            dispatch(showToast({
                message: "Er is iets fout gegaan bij het verwijderen van het document",
                type: "error"
            }))
        }
    }

    const onAddDocument = async (item: BaseDocument) => {
        try {
            const updated = { ...template, items: [...template.items, item] };
            setTemplate(updated);
            await update(updated);
        }
        catch (e) {
            dispatch(showToast({
                message: "Er is iets fout gegaan bij het toevoegen van het document",
                type: "error"
            }))
        }
    }

    const update = async (value?: DocumentList) => {
        await api.update(value ?? template);
        setShowDialog(false);
    }

    useEffect(() => {
        setFetching(true);
        api
            .get(id!)
            .then(setTemplate)
            .catch(() => nav(settingRoutes.documentLists))
            .finally(() => setFetching(false));
    }, [id, nav]);

    return <>
        <TextInput
            value={template.title}
            sx={{ mb: 1 }}
            onChange={(e) => setTemplate({ ...template, title: e })}
            inputSx={{ fontSize: 30, height: 55, background: '#FFF', color: '#34495e' }}
            onFocusLost={update}
        />
        <TextInput
            value={template.description}
            onChange={(e) => setTemplate({ ...template, description: e })}
            inputSx={{ fontSize: 18, height: 30, background: '#FFF' }}
            onFocusLost={update}
        />

        {showDialog && <AddDocumentDialog
            listType={template.target}
            onAddDocument={onAddDocument} onDismiss={() => setShowDialog(false)} />}
        <Toolbar sx={{ mt: 2 }}>
            <Button onClick={() => setShowDialog(true)}>
                <AddTwoTone fontSize="small" sx={{ mr: 1 }} />
                Document Toevoegen
            </Button>
        </Toolbar>
        {
            fetching && <Box sx={{ p: 8, display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={30} />
            </Box>
        }
        {
            !fetching && <DocumentListItemsTable items={template.items} onDelete={onDelete} />
        }
    </>
}