import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { MembershipPayload } from "../../types/company/payloads";
import { Membership } from "../../types/company/membership";

class MembershipsApi extends BaseApi {
    constructor() { super(apiRoutes.organization + "/memberships"); }

    public listMembershipsByCompanies = (companyIds: string[]) => this.fetchJson<Membership[]>(`companies`, 'POST', companyIds);
    public listCompanyMemberships = (companyId: string) => this.fetchJson<Membership[]>(`companies/${companyId}`);
    public createMembership = (companyId: string, personId: string, payload: MembershipPayload) => this.fetchJson<Membership>(`companies/${companyId}/persons/${personId}`, 'POST', payload);
    public updateMembership = (companyId: string, membershipId: string, payload: MembershipPayload) => this.fetch(`companies/${companyId}/${membershipId}`, 'PUT', payload);
    public deleteMembership = (companyId: string, membershipId: string) => this.fetch(`companies/${companyId}/${membershipId}`, 'DELETE');

    public listPersonMemberships = (id: string) => this.fetchJson<Membership[]>(`persons/${id}`);
    public listMultiplePersonsMemberships = (ids: string[]) => this.fetchJson<Membership[]>('persons', 'POST', ids);

}

const api = new MembershipsApi();
export default api;