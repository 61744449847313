import { Box, Breadcrumbs, Typography } from "@mui/material";
import { capitalizeFirstLetter, isValidGuid } from "../../utils/strings";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackTwoTone } from "@mui/icons-material";
import { Person } from "../../types/company/person";
import { Company } from "../../types/company/company";
import { Professional } from "../../types/recruiter/professional";
import { Vacancy } from "../../types/recruiter/vacancy";
import { personsSelector } from "../../store/slices/personsSlice";
import { useAppSelector } from "../../store/hooks";
import { companiesSelector } from "../../store/slices/companiesSlice";
import { professionalsSelector } from "../../store/slices/professionalsSlice";
import { vacanciesSelector } from "../../store/slices/vacanciesSlice";
import { getTemplateForFriendlyName } from "../../utils/templateFor";

const breadcrumbs = (): string[] => {
    const paths = window.location.pathname.split('/').filter(p => p !== '').map(p => {
        if (isValidGuid(p)) return 'Details';

        return capitalizeFirstLetter(p);
    });
    return paths;
}

const detailsTitleSelector = (
    module: string,
    id: string,
    persons: Person[],
    companies: Company[],
    professionals: Professional[],
    vacancies: Vacancy[]
) => {
    switch (module) {
        case 'Bedrijven':
            return companies.find(c => c._id === id)?.naam;
        case 'Kandidaten':
            return professionals.find(c => c._id === id)?.name;
        case 'Vacatures':
            return vacancies.find(v => v._id === id)?.title;
        case 'Personen':
            const person = persons.find(p => p._id === id);
            return person ? `${person.firstName} ${person.lastName}` : 'Onbekend Persoon';

        default:
            return '';
    }
};

export function BreadCrumbsPage(props: {
    children: React.ReactNode;
}) {
    const id = useParams().id;
    const crumbs = breadcrumbs();

    const persons = useAppSelector(personsSelector).persons;
    const companies = useAppSelector(companiesSelector).companies;
    const professionals = useAppSelector(professionalsSelector).professionals;
    const vacancies = useAppSelector(vacanciesSelector).vacancies;

    if (id && crumbs.includes('Details')) {
        crumbs[crumbs.findIndex(c => c === 'Details')] = detailsTitleSelector(crumbs[0], id, persons, companies, professionals, vacancies)!;
    }

    if (crumbs[1] === 'Email-templates' && crumbs.length === 3) {
        const templateForStr = crumbs[2];
        const templateFor = parseInt(templateForStr);

        const friendlyName = getTemplateForFriendlyName(templateFor);
        crumbs[2] = friendlyName;
    }

    const nav = useNavigate();

    return <Box sx={{ p: 4, minHeight: '100vh' }}>
        <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {
                    <ArrowBackTwoTone sx={{ mr: 1, cursor: 'pointer' }} color="primary" onClick={() => nav(-1)} />
                }
                <Breadcrumbs>
                    {
                        crumbs.map((crumb, i) => <CrumbTitle key={i} title={crumb} i={i} />)
                    }
                </Breadcrumbs>
            </Box>
        </Box>
        {
            props.children
        }
    </Box>
}

function CrumbTitle({ title, i }: { title: string, i: number }) {
    return <Typography key={i} color={i === 0 ? "primary" : "secondary"} variant="caption" sx={{ fontSize: '1.1rem', fontWeight: 300 }}>{title}</Typography>
}