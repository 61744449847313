import { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, Typography, DialogTitle } from "@mui/material";

import { ProfessionalDetailsForm } from "./ProfessionalDetails";
import { ProfessionalEducationForm } from "./ProfessionalEducationForm";
import { ProfessionalExperienceForm } from "./ProfessionalExperienceForm";
import { ProfessionalSkillsForm } from "./ProfessionalSkillsForm";

import publicApi from "../../../../api/recruiter/public";
import { Professional, emptyProfessional, WorkExperience, Education, ProfessionalDetails } from "../../../../types/recruiter/professional";
import { ProfessionalExperienceTable, ProfessionalEducationTable, ConfirmDialog, ProgressBar, FormStepper, Toolbar, ButtonInput } from "../../../molecules";
import { professionalValidation } from "../../../validations/professional";
import { SaveTwoTone, DeleteTwoTone, AutoAwesomeTwoTone, FileUploadTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export default function ProfessionalForm(props: {
    fetchingCreate: boolean,
    fetchingDelete?: boolean,
    completeLabel: string,
    onComplete: (professional: Professional) => void,
    onDelete?: () => void,
    initialProfessional?: Professional,
    onImportPdfError?: (err: string) => void
}) {
    const [activeStep, setActiveStep] = useState(0);
    const [professional, setProfessional] = useState<Professional>(emptyProfessional);
    const [editingExperience, setEditingExperience] = useState<WorkExperience>();
    const [editingEducation, setEditingEducation] = useState<Education>();
    const [fetchingImportPdf, setFetchingImportPdf] = useState(false);
    const [showCreateEducation, setShowCreateEducation] = useState(false);
    const [showCreateExperience, setShowCreateExperience] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const validationResult = professionalValidation(professional);

    const update = (result: ProfessionalDetails) => setProfessional({ ...professional, ...result });
    const deleteExperience = () => setProfessional({ ...professional, experiences: professional.experiences.filter((i) => i !== editingExperience) });
    const deleteEducation = () => {
        setProfessional({ ...professional, educations: professional.educations.filter((i) => i !== editingEducation) });
        setEditingEducation(undefined);
    }

    const PrevNextButtons = () =>
        <Box sx={{ display: 'flex', width: '100%', my: 2, maxWidth: 800 }}>
            {activeStep > 0 && <Button sx={{ fontSize: 12 }} onClick={() => setActiveStep(activeStep - 1)} variant="text" color="primary">Vorige</Button>}
            {
                activeStep < components.length - 1 &&
                <Button sx={{ fontSize: 12, ml: activeStep > 0 ? 1 : 0 }} onClick={() => setActiveStep(activeStep + 1)} variant="text" color="primary">Volgende</Button>
            }
        </Box>

    const onImportPdf = async (file: File) => {
        setFetchingImportPdf(true);

        try {
            const pdf = await publicApi.convertProfessionalFromFile(file);
            setProfessional(p => ({
                educations: pdf.educations.length > 0 ? pdf.educations : p.educations,
                experiences: pdf.experiences.length > 0 ? pdf.experiences : p.experiences,
                name: pdf.name || p.name,
                title: pdf.title || p.title,
                email: pdf.email || p.email,
                phone: pdf.phone || p.phone,
                address: pdf.address || p.address,
                city: pdf.city || p.city,
                zipCode: pdf.zipCode || p.zipCode,
                houseNumber: pdf.houseNumber || p.houseNumber,
                gender: pdf.gender || p.gender,
                skills: pdf.skills || p.skills,
                _id: p._id,
                contractingTypes: p.contractingTypes
            }));
        }
        catch (e) {
            props.onImportPdfError?.('Er is een fout opgetreden bij het importeren van het bestand');
        }
        finally {
            setFetchingImportPdf(false);
        }
    }

    const components = [
        {
            label: 'NAW gegevens',
            content: <Box sx={{ pb: 2, maxWidth: 800 }}>
                <ProfessionalDetailsForm onChange={update} state={professional} />
                <PrevNextButtons />
            </Box>
        },
        {
            label: 'Werk ervaring',
            content: <>
                <Box>
                    <Dialog open={editingExperience !== undefined} maxWidth={false} onClose={() => setEditingExperience(undefined)}>
                        <DialogContent>
                            <Typography variant="h5" color="secondary">Bewerk Werkervaring</Typography>
                            <Button sx={{ fontSize: 12, mt: 2 }} onClick={() => {
                                deleteExperience();
                                setEditingExperience(undefined);
                            }} color="error">Verwijderen</Button>
                            <ProfessionalExperienceForm sx={{ minWidth: 600 }}
                                submitLabel={'Bijwerken'}
                                initialExperience={editingExperience}
                                onSubmit={experience => {
                                    setProfessional({ ...professional, experiences: professional.experiences.map((e, i) => i === professional.experiences.indexOf(editingExperience!) ? experience : e) });
                                    setEditingExperience(undefined);
                                }} />
                        </DialogContent>
                    </Dialog>

                    <Dialog open={showCreateExperience} maxWidth={false} onClose={() => setShowCreateExperience(false)}>
                        <DialogTitle>
                            <Typography variant="h5" color="secondary">Nieuwe Werkervaring</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <ProfessionalExperienceForm sx={{ minWidth: 600 }}
                                submitLabel={'Toevoegen'}
                                onSubmit={experience => {
                                    setProfessional({ ...professional, experiences: [...professional.experiences, experience] });
                                    setShowCreateExperience(false);
                                }}
                            />
                        </DialogContent>
                    </Dialog>

                </Box>
                <Box>
                    <Button variant="text" onClick={() => setShowCreateExperience(true)}>Toevoegen</Button>
                    <ProfessionalExperienceTable
                        onClick={setEditingExperience}
                        experiences={professional.experiences}
                    />
                    <PrevNextButtons />
                </Box>
            </>
        },
        {
            label: 'Opleidingen',
            content: <>
                <Box>
                    <Dialog open={editingEducation !== undefined} maxWidth={false} onClose={() => setEditingEducation(undefined)}>

                        <DialogContent>
                            <Typography variant="h5" color="Secondary">Bewerk Opleiding</Typography>
                            <Button sx={{ fontSize: 12, mt: 2 }} onClick={() => {
                                deleteEducation()
                            }} color="error">Verwijderen</Button>
                            <ProfessionalEducationForm sx={{ minWidth: 600 }}
                                initialEducation={editingEducation}
                                submitLabel={'Bijwerken'}
                                onSubmit={
                                    education => {
                                        setProfessional({ ...professional, educations: professional.educations.map((e, i) => i === professional.educations.indexOf(editingEducation!) ? education : e) });
                                        setEditingEducation(undefined);
                                    }}
                            />
                        </DialogContent>
                    </Dialog>

                    <Dialog open={showCreateEducation} maxWidth={false} onClose={() => setShowCreateEducation(false)}>
                        <DialogContent>
                            <Typography variant="h6" color="primary">Nieuwe opleiding</Typography>
                            <ProfessionalEducationForm sx={{ minWidth: 600 }}
                                initialEducation={editingEducation}
                                submitLabel={'Toevoegen'}
                                onSubmit={education => setProfessional({ ...professional, educations: [...professional.educations, education] })}
                            />
                        </DialogContent>
                    </Dialog>
                </Box>
                <Box sx={{ maxWidth: 800 }}>
                    <Button variant="text" onClick={() => setShowCreateEducation(true)}>Toevoegen</Button>
                    <ProfessionalEducationTable onEducationClick={setEditingEducation} educations={professional.educations} />
                    <PrevNextButtons />
                </Box>

            </>
        },
        {
            label: 'Vaardigheden',
            content: <Box sx={{ maxWidth: 800 }}>
                <ProfessionalSkillsForm sx={{ maxWidth: 800 }} skills={professional.skills} onChange={
                    skills => setProfessional({ ...professional, skills })
                } />
                <PrevNextButtons />
            </Box>
        },
    ];

    useEffect(() => {
        if (props.initialProfessional)
            setProfessional(props.initialProfessional);
    }, [props.initialProfessional]);

    return <>
        <ConfirmDialog
            open={showDeleteDialog}
            onCancel={() => setShowDeleteDialog(false)}
            onConfirm={props.onDelete!}
            fetching={props.fetchingDelete}
            title="Verwijderen"
            message="Weet u zeker dat u deze professional wilt verwijderen?"
        />

        <Toolbar sx={{ mb: 1 }}>
            <LoadingButton sx={{ mr: 1 }} onClick={() => props.onComplete(professional)} loading={props.fetchingCreate}>
                <SaveTwoTone sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption" color={validationResult ? 'error' : undefined}>
                    {validationResult ?? props.completeLabel}
                </Typography>
            </LoadingButton>

            <ButtonInput
                variant="text"
                icon={FileUploadTwoTone}
                key={Math.random()}
                fileTypes={["pdf", "eml", "msg", "docx"]}
                loading={fetchingImportPdf}
                placeholder="Importeer vanuit CV"
                onChange={onImportPdf} />

            <Box sx={{ flexGrow: 1 }} />
            {props.onDelete && <Button onClick={() => setShowDeleteDialog(true)}>
                <DeleteTwoTone color="error" sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption" color={'error'}>
                    Verwijderen
                </Typography>
            </Button>}
        </Toolbar>

        {fetchingImportPdf && <Box sx={{ pb: 2 }}>
            <ProgressBar duration={20000} width={405} />
        </Box>}

        <FormStepper maxWidth='lg' onStepClicked={setActiveStep} activeStep={activeStep} steps={components} />
    </>
}