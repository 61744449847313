
import { Box, Checkbox, Typography } from "@mui/material";
import { Permissions } from "../../types/user";
import { Table } from "./tables/Table";

export interface PermissionsDescription {
    description: string,
    readPermission?: Permissions,
    writePermission?: Permissions
}

function PermissionsTable(props: {
    permissions: PermissionsDescription[],
    selected: Permissions[],
    onChanged: (permissions: Permissions[]) => void
}) {
    const hasPermission = (permission: Permissions) => props.selected.includes(permission);
    const onSelect = (permission: Permissions) => {
        if (hasPermission(permission)) {
            props.onChanged(props.selected.filter(p => p !== permission));
        } else {
            props.onChanged([...props.selected, permission]);
        }
    }

    return <Table
        id="Permissions"
        shrink
        slim
        columns={[{
            headerName: 'Permissie',
            cellRenderer: (row) => <Box sx={{ display: 'flex', alignItems: 'center', height: 45 }}>
                <Typography variant="overline">{row.data.description}</Typography>
            </Box>
        }, {
            headerName: 'Lezen',
            width: 200,
            cellRenderer: (row) => row.data.readPermission && <Checkbox
                checked={hasPermission(row.data.readPermission)}
                onClick={() => onSelect(row.data.readPermission)}
            />
        }, {
            headerName: 'Bewerken',
            width: 200,
            flex: 1,
            cellRenderer: (row) => row.data.writePermission && <Checkbox
                checked={hasPermission(row.data.writePermission)}
                onClick={() => onSelect(row.data.writePermission)}
            />
        }]}
        data={props.permissions}
    />
}

export function OrganizationPermissionsTable(props: {
    selected: Permissions[],
    onChanged: (permissions: Permissions[]) => void
}) {
    return <PermissionsTable {...props}
        permissions={[
            { description: 'Bedrijf', readPermission: Permissions.CompanyReader, writePermission: Permissions.CompanyWriter },
            { description: 'Personen', readPermission: Permissions.PersonReader, writePermission: Permissions.PersonWriter },
            { description: 'Lidmaatschap Rechten', writePermission: Permissions.PermissionEditor },
            { description: 'Zichzelf', readPermission: Permissions.SelfReader, writePermission: Permissions.SelfWriter }
        ]}
    />
}

export function WhitelabelPermissionsTable(props: {
    selected: Permissions[],
    onChanged: (permissions: Permissions[]) => void
}) {
    return <PermissionsTable {...props}
        permissions={[
            { description: 'Kandidaten', readPermission: Permissions.KandidaatReader, writePermission: Permissions.KandidaatWriter },
            { description: 'Vacatures', readPermission: Permissions.VacatureReader, writePermission: Permissions.VacatureWriter },
            { description: 'Vacatures Publiceren', writePermission: Permissions.VacaturePublisher },
            { description: 'Bedrijf', readPermission: Permissions.CompanyReader, writePermission: Permissions.CompanyWriter },
            { description: 'Personen', readPermission: Permissions.PersonReader, writePermission: Permissions.PersonWriter },
            { description: 'Lidmaatschap Rechten', writePermission: Permissions.PermissionEditor }
        ]}
    />
}