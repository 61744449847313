import { Box, SxProps, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';
import styled from 'styled-components';
import { IconLabel } from '../IconLabel';

const Toggle = styled(ToggleButton)({
    maxHeight: 30
});
const diffLabel = 'anders';

export function NumericSelection(props: {
    label?: string,
    value: number,
    values: number[],
    sx?: SxProps,
    customOptions?: {
        label: string,
        value: number,
        width: number
    }[],
    icon?: any,
    required?: boolean,
    onChange?: (value: number) => void
}) {
    const [customValue, setCustomeValue] = useState<number | string>(diffLabel);
    const handleChange = (value: string) => {
        if (!props.onChange) return;

        const parsed = parseInt(value);
        if (!isNaN(parsed)) {
            setCustomeValue(parsed);
            props.onChange(parsed);
        }
        else
            setCustomeValue('');

    };

    return (
        <Box sx={props.sx}>
            {props.label && <IconLabel label={props.label} icon={props.icon} required={props.required} />}
            <ToggleButtonGroup
                color='primary'
                sx={{ borderRadius: 4, width: '100%' }}
                value={props.value}
                exclusive
                onChange={(_, value: number) => {
                    if (!value || !props.onChange) return;
                    props.onChange(value);
                }}
            >
                {
                    props.values.map(value => <Toggle key={value} value={value}>
                        <Typography variant="caption" color="secondary">
                            {value}
                        </Typography>
                    </Toggle>)
                }
                <Toggle sx={{ p: 0, width: '100%' }} value={customValue ?? ''}>
                    <input type='text'
                        onFocus={() => {
                            if (customValue === diffLabel)
                                setCustomeValue('');
                        }}
                        onChange={(e) => handleChange(e.currentTarget.value)} style={{
                            textAlign: 'center',
                            border: 0,
                            padding: 0,
                            margin: 0,
                            minWidth: '100%',
                            height: 30,
                            fontSize: 12,
                            background: 'transparent',
                            outline: 'none'
                        }} value={customValue}>
                    </input>
                </Toggle>
                {
                    props.customOptions?.map(opt => <Toggle key={opt.label} value={opt.value} sx={{ p: 0, minWidth: opt.width }}>
                        <Typography variant="subtitle2" color="secondary" textTransform={'none'} fontSize={12}>
                            {opt.label}
                        </Typography>
                    </Toggle>)
                }
            </ToggleButtonGroup>
        </Box >
    );
}