import { OrganizationForm } from '../../forms/company';
import { AddCompanyPayload } from '../../../types/company/payloads';
import { useEffect, useState } from 'react';

import api from '../../../api/organization/whitelabel';
import { WhitelabelOwner, WhitelabelTheme } from '../../../types/company';
import { DotsLoader, Row, TextInput } from '../../molecules';
import { Box, Typography } from '@mui/material';
import { companyTypeString } from '../../../utils/companyType';
import { humanReadableDate } from '../../../utils/dateUtils';
import { HandelsnamenTable } from '../../molecules/tables/company/Handelsnamen';
import { useAppDispatch } from '../../../store/hooks';
import { showToast } from '../../../store/slices/toastSlice';
import { TabsOrganism } from '../Tabs';
import { ThemeOrganism } from './ThemeOrganism';

const ReadOnlyInput = (props: { label: string, value: string, flex?: number, mr?: number }) => {
    const { label, value, flex, mr } = props;
    return <TextInput withCopy sx={{ mr, flex }} label={label} value={value} inputSx={{ fontWeight: 'bold' }} />
}

const OwnerDetails = (props: { owner: WhitelabelOwner }) => {
    const { owner } = props;

    return <>
        <Box pt={2}>
            <Typography sx={{ my: 1, mb: 3 }} variant="h5">{owner.naam}</Typography>
            <Row sx={{ mt: 2 }}>
                <ReadOnlyInput mr={1} flex={1} label="KvK" value={owner.kvkNummer} />
                <ReadOnlyInput mr={1} flex={1} label="Vestigingsnummer" value={owner.vestigingsNummer} />
                <ReadOnlyInput flex={1} label="BTW" value={owner.btw} />
            </Row>

            <Row sx={{ mt: 2 }}>
                <ReadOnlyInput flex={1} label="Registratiedatum" value={humanReadableDate(owner.materieleRegistratie)} />
            </Row>

            <Row sx={{ mt: 2 }}>
                <ReadOnlyInput mr={1} flex={1} label="Rechtsvorm" value={companyTypeString(owner.rechtsvorm)} />
                <TextInput withCopy sx={{ flex: 2 }} label="Telefoon" value={owner.phone} />
            </Row>

            <Row sx={{ mt: 2 }}>
                <TextInput withCopy sx={{ flex: 1, mr: 1 }} label="Algemeen email" value={owner.genericAddress} disabled />
                <TextInput withCopy sx={{ flex: 1 }} label="Facturen email" value={owner.billingAddress} />
            </Row>

            <Row sx={{ my: 2 }}>
                <ReadOnlyInput flex={1} label="Adres" value={owner.address.volledigAdres} />
            </Row>
        </Box>

        <Box display={'flex'}>
            <Box flex={1} pr={3}>
                <Typography sx={{ mt: 4 }} variant="h6">Handelsnamen</Typography>
                <HandelsnamenTable handelsnamen={owner.handelsnamen} />
            </Box>
        </Box>
    </>
}

const tabs = ["Organisatie", "Thema"];

export function CoOwnerOrganizationOrganism() {
    const dispatch = useAppDispatch();
    const [tab, setTab] = useState(tabs[0]);

    const [fetching, setFetching] = useState(false);
    const [owner, setOwner] = useState<WhitelabelOwner | null>(null);

    const onComplete = (payload: AddCompanyPayload) => {
        setFetching(true);

        api
            .registerOwner(payload)
            .then(r => setOwner(r.cootjeOwnerOrganization))
            .catch((e: any) => dispatch(showToast({ message: e.message, type: 'error' })))
            .finally(() => setFetching(false));
    }

    const fetchOwner = () => {
        setFetching(true);
        api.getOwner()
            .then(setOwner)
            .catch(() => setOwner(null))
            .finally(() => setFetching(false));
    }

    useEffect(fetchOwner, []);

    if (fetching)
        return <DotsLoader message='Moment geduld...' />

    if (!owner)
        return <OrganizationForm loading={false} onCompleted={onComplete} />

    return <>
        <TabsOrganism tabs={tabs.map(t => ({ name: t }))} queryParam='tab' onChange={setTab} />

        {tab === tabs[0] && <OwnerDetails owner={owner} />}
        {tab === tabs[1] && <ThemeOrganism />}

    </>
}
