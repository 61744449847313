import { FC } from "react"
import { Periods, Rate } from "../../../types/contracts"
import { Box, SxProps } from "@mui/material"
import { CheckboxInput } from "./CheckboxInput"
import { defaultRate } from "../../../types/contracts/Rate"
import { AsyncAutocomplete } from "./AsyncAutocomplete"
import { RowCentered } from "../Row"
import { BusinessTwoTone, EuroTwoTone } from "@mui/icons-material"
import { IconLabel } from "../IconLabel"
import { SelectMolecule } from "../MultiSelect"

export const FeeReceiverInput: FC<{
    title: string,
    defaultItemName: string,
    sx?: SxProps,
    value?: Rate,
    onChange?: (value?: Rate) => void,
    options: { label: string, id: string }[],
    disabled?: boolean
}> = ({ title, value, onChange, options, sx, defaultItemName, disabled }) => {
    const enabled = value !== undefined;
    const onEnabledChanged = () => onChange?.(value ? undefined : { ...defaultRate(), name: defaultItemName });
    const onChangeValue = (sum: any) => {
        value && onChange?.({
            ...value,
            sum
        });
    }

    return <Box sx={{ ...sx }}>
        <RowCentered>
            <CheckboxInput
                type="double"
                sx={{ flex: enabled ? 0 : 1 }}
                checked={enabled}
                onEnabledChanged={onEnabledChanged}
                onChange={onChangeValue}
                title={title}
                icon={EuroTwoTone}
                value={value?.sum.toString() ?? ''}
                disabled={disabled}
            />
            {enabled && <Box flex={1} ml={1}>
                <IconLabel icon={BusinessTwoTone} label="Ontvanger" />
                <RowCentered>
                    <AsyncAutocomplete
                        disabled={disabled}
                        sx={{ flex: 1 }}
                        options={options}
                        getTitleFromOption={o => o.label}
                        selected={options.find(o => o.id === value?.receiverId)}
                        onSelect={o => o && onChange?.({ ...value, receiverId: o.id })}
                    />
                </RowCentered>
            </Box>
            }
        </RowCentered>
        {enabled && <SelectMolecule
            sx={{ mt: 1, width: '100%' }}
            disabled={disabled}
            selected={[value?.period ?? Periods.Hour]}
            onClick={(period) => value && onChange?.({ ...value, period: period })}
            items={[
                {
                    label: 'Uur',
                    value: Periods.Hour
                },
                {
                    label: 'Dag',
                    value: Periods.Day
                },
                {
                    label: 'Week',
                    value: Periods.Week
                },
                {
                    label: 'Maand',
                    value: Periods.Month
                },
                {
                    label: 'Fixed Price',
                    value: Periods.Once,
                    flex: 1
                }
            ]}
        />}
    </Box>
}