import { Box } from "@mui/material";
import { BankAccount, BankAccountType } from "../../../types/company/bankAccount";
import { FormContainer, TextInput, IconLabel } from "../../molecules";
import { SelectMolecule } from "../../molecules/MultiSelect";
import { Row } from "../../molecules/Row";
import { requiredValidation } from "../../validations";

export function OrganizationBankingForm(props: {
    bankAccount?: BankAccount;
    onChange: (bankAccount: BankAccount) => void;
    hideG?: boolean;
}) {
    const { bankAccount, onChange } = props;

    if (!bankAccount)
        return <></>;

    return <FormContainer>
        <Row sx={{ mb: 2, alignItems: 'flex-start !important' }}>
            <TextInput value={bankAccount.iBAN} required validator={requiredValidation} label="IBAN" sx={{ flex: 1 }} onChange={(iBAN) => onChange({ ...bankAccount, iBAN })} />
        </Row>
        {props.hideG !== true && <Row sx={{ pb: 1 }}>
            <Box>
                <IconLabel label="Soort" />
                <SelectMolecule
                    items={[
                        { label: "Bedrijfsrekening", value: false },
                        { label: "G-rekening", value: true }
                    ]}
                    onClick={(isG) => onChange({ ...bankAccount, type: isG ? BankAccountType.G : BankAccountType.Company })}
                    selected={[bankAccount.type === BankAccountType.G]} />
            </Box>
            {bankAccount.type === BankAccountType.G && <TextInput label="Verdeling" sx={{ pl: 1, flex: 1 }}
                value={bankAccount.division}
                onChange={(division) => onChange({
                    ...bankAccount,
                    division: division
                })} />}
        </Row>}
    </FormContainer>;
}
