import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { DocumentMergeRequest, RequestedDocument } from "../../types/documents/Documents";

class DocumentsApi extends BaseApi {
    constructor() { super(apiRoutes.documents + "/documents"); }

    public merge = (payload: DocumentMergeRequest) => this.fetchJson<DocumentMergeRequest>('/merge', 'POST', payload);
    public requestNew = (doc: RequestedDocument) => this.fetchJson<RequestedDocument>('', 'POST', doc);
    public get = (env: string, id: string) => this.fetchJson<RequestedDocument>(`${env}/${id}`);
    public listByTargetId = (id: string) => this.fetchJson<RequestedDocument[]>(id);
    public list = () => this.fetchJson<RequestedDocument[]>();
    public reject = (id: string) => this.fetch(`${id}/reject`, 'PUT');
    public approve = (id: string) => this.fetch(`${id}/approve`, 'PUT');
    public download = (id: string) => this.fetchBlob(`${id}/download`);

    public setTargetFromInvite = (env: string, id: string, target: string) => this.fetch(`${env}/${id}/settarget/${target}`, 'PUT');
    public uploadFromInvite = (env: string, id: string, file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        this.sendFormData(`${env}/${id}/upload`, formData, 'PUT');
    };
}

const api = new DocumentsApi();
export default api;