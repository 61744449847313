import { Tab, Tabs } from "@mui/material";
import { primaryLighter } from "../theme";
import { useEffect } from "react";
import { createPermissionValidator } from "../../utils/permissions";
import { useAppSelector } from "../../store/hooks";
import { authSelector } from "../../store/slices/authSlice";
import { Permissions } from "../../types/user";

export interface SecuredTabProps {
    name: string;
    requiredPermission?: Permissions;
    companyId?: string;
}

export function TabsOrganism(props: {
    tabs: SecuredTabProps[];
    queryParam: string;
    onChange: (value: string) => void;

    secondaryTheme?: boolean;
}) {
    const user = useAppSelector(authSelector).user;
    const permissionValidator = createPermissionValidator(user);

    const theme = props.secondaryTheme ? 'secondary' : 'primary';
    const sx = props.secondaryTheme ? { background: primaryLighter, borderRadius: 1 } : undefined;
    const searchParams = new URLSearchParams(window.location.search);

    const { queryParam } = props;
    const activeTab = props.tabs.find(t => t.name === (searchParams.get(queryParam) ?? props.tabs[0].name));

    const updateQueryParams = (value: string) => {
        const tab = props.tabs.find(t => t.name === value);
        if (!tab || (tab.requiredPermission && !permissionValidator([tab.requiredPermission]))) return;

        searchParams.set(queryParam, value);
        window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    }

    useEffect(() => {
        if (!activeTab || (activeTab.requiredPermission && !permissionValidator([activeTab.requiredPermission]))) return;

        props.onChange(activeTab.name);
    }, [props, activeTab, permissionValidator]);

    return <Tabs sx={sx} indicatorColor={theme} value={activeTab?.name} onChange={(_, value) => {
        updateQueryParams(value);
        props.onChange(value);
    }}>
        {props
            .tabs
            .filter(tab => !tab.requiredPermission || permissionValidator([tab.requiredPermission], tab.companyId))
            .map((tab) => <Tab key={tab.name} label={tab.name} value={tab.name} />)}
    </Tabs>
}