import { generateGuid } from "../../utils/strings";

export interface Person {
    _id: string;
    name: string;
    email: string;
    telephone: string;
    bsn: string;
    function: string;
    poNumber: string;
}

export const defaultPerson = (): Person => ({
    _id: generateGuid(),
    name: '',
    email: '',
    telephone: '',
    bsn: '',
    function: '',
    poNumber: ''
})
