import { Box, Button, Dialog, DialogContent, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { InviteType } from "../../../types/company/invite";
import { ContainedButton, Dropdown, IconLabel, TextInput } from "../../molecules";
import { emailValidation } from "../../validations/email";
import { SelectMolecule } from "../../molecules/MultiSelect";
import { Row } from "../../molecules/Row";
import { LoadingButton } from "@mui/lab";

import documentListsApi from "../../../api/documents/lists";
import documentsApi from "../../../api/documents/requested";
import emailTemplatesApi from "../../../api/email/templates";
import inviteApi from "../../../api/organization/invite";

import { EditTwoTone, EmailTwoTone, MailTwoTone } from "@mui/icons-material";
import { getDefaultOnboardingList } from "../../../utils/documents";
import { EmailTemplateFor } from "../../../types/company/emailTemplates";
import { EmailEditorMolecule } from "../../molecules/email/EmailEditor";
import { DocumentList, DocumentListTargets, DocumentModule, DocumentState, DocumentTarget } from "../../../types/documents";

export function SendInviteOrganism(props: {
    companyId?: string
}) {
    const isEmployedType = props.companyId !== undefined;

    const [email, setEmail] = useState<string>('');
    const [templates, setTemplates] = useState<DocumentList[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<DocumentList>();
    const [type, setType] = useState<InviteType>(isEmployedType ? InviteType.Inhuur : InviteType.ZZP);
    const [open, setOpen] = useState<boolean>(false);
    const [registerCredentials, setRegisterCredentials] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [html, setHtml] = useState("");

    const isValid = emailValidation(email) === undefined;

    const sendInvite = async () => {
        if (!html)
            return;

        setLoading(true);
        try {
            const requestedDocs = [];
            const docList = await documentListsApi.get(selectedTemplate!._id);

            for (const item of docList.items) {

                const documentModule = item.targetType === DocumentTarget.Person ? DocumentModule.Person : DocumentModule.Company;

                const result = await documentsApi.requestNew({
                    _id: '',
                    state: DocumentState.Requested,
                    targetType: item.targetType,
                    title: item.title,
                    type: item.type,
                    description: item.description,
                    module: documentModule,
                    targetId: isEmployedType && documentModule === DocumentModule.Company ? props.companyId! : '',
                    moduleId: isEmployedType && documentModule === DocumentModule.Company ? props.companyId! : '',
                });

                requestedDocs.push(result._id);
            }

            await inviteApi.sendInvite(email, type, html, requestedDocs, registerCredentials, props.companyId);

            setOpen(false);
            setEmail('');
            setError(undefined);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    const fetchEmailTemplate = () => {
        switch (type) {
            case InviteType.ZZP:
                emailTemplatesApi.get(EmailTemplateFor.ZZP_Invite.toString()).then(setHtml).catch(_ => setOpen(false));
                break;
            case InviteType.Inhuur:
                emailTemplatesApi.get(EmailTemplateFor.INHUUR_INVITE.toString()).then(setHtml).catch(_ => setOpen(false));
                break;
            case InviteType.TAO:
                emailTemplatesApi.get(EmailTemplateFor.TAO_INVITE.toString()).then(setHtml).catch(_ => setOpen(false));
                break;
            case InviteType.InhuurWithCompany:
                emailTemplatesApi.get(EmailTemplateFor.INHUUR_COMPANY_INVITE.toString()).then(setHtml).catch(_ => setOpen(false));
        }
    }

    const inviteOptions = isEmployedType ?
        [
            { label: 'Inhuur (Dit bedrijf)', value: InviteType.Inhuur }
        ] :
        [
            { label: 'ZZP', value: InviteType.ZZP, flex: 1 },
            { label: 'Inhuur (Nieuw bedrijf)', value: InviteType.InhuurWithCompany, flex: 2 },
            { label: 'TAO', value: InviteType.TAO }
        ];

    // eslint-disable-next-line
    useEffect(() => { open && fetchEmailTemplate(); }, [type, open]);
    useEffect(() => {
        documentListsApi.list()
            .then(t => {
                setTemplates(t.filter(t => t.target === DocumentListTargets.Onboarding));
                setSelectedTemplate(getDefaultOnboardingList(inviteOptions[0].label, t));
            })
            .catch(console.error);
        // eslint-disable-next-line
    }, []);

    const onSelectType = (t: InviteType) => {
        const label = inviteOptions.find(i => i.value === t)?.label;
        const defaultList = getDefaultOnboardingList(label!, templates);
        setType(t);

        if (!defaultList)
            return;

        setSelectedTemplate(defaultList);
    }

    return <>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent sx={{ minWidth: 400 }}>
                <Typography sx={{ mb: 2 }} variant="h6">Uitnodiging Versturen</Typography>
                <TextInput sx={{ mb: 2 }} label="Email" required validator={emailValidation} value={email} onChange={setEmail} />

                <Box display={'flex'} alignItems={'flex-start'}>
                    <Box sx={{ width: isEmployedType ? 'unset' : '100%' }}>
                        <IconLabel label="Soort" />
                        <SelectMolecule
                            sx={{ width: '100%' }}
                            selected={[type]}
                            onClick={onSelectType}
                            items={inviteOptions}
                        />
                    </Box>
                    {
                        type === InviteType.Inhuur && (
                            <Box sx={{ ml: 1, flex: 1 }}>
                                <IconLabel label="Met inlog gegevens" />
                                <Button sx={{ height: 30 }} color={registerCredentials ? 'primary' : 'error'} fullWidth variant={"outlined"} onClick={() => setRegisterCredentials(!registerCredentials)}>
                                    <Typography variant="caption">{registerCredentials ? 'Aan' : 'Uit'}</Typography>
                                </Button>
                            </Box>
                        )
                    }
                </Box>

                <Box sx={{ mt: 3 }} />
                <IconLabel label="Documentenlijst" />
                <Dropdown
                    label="Documentenlijst"
                    options={templates.map(t => ({ label: t.title, value: t }))}
                    value={selectedTemplate ?? ''}
                    onChange={setSelectedTemplate}
                />

                {error && <Typography sx={{ my: 2 }} color="error">{error}</Typography>}

                <Row sx={{ mb: 2, mt: 4 }}>
                    <Button variant="text" onClick={() => setShowEditor(true)} color="primary">
                        <EditTwoTone fontSize="small" sx={{ mr: 2 }} />
                        <Typography variant="caption">Bewerk Email</Typography>
                    </Button>
                    <Box sx={{ flex: 1 }} />
                    <LoadingButton loading={loading} disabled={!isValid} variant="outlined" onClick={sendInvite}>
                        <EmailTwoTone fontSize="small" sx={{ mr: 2 }} />
                        <Typography variant="caption">Verzenden</Typography>
                    </LoadingButton>
                </Row>
            </DialogContent>
        </Dialog>

        <Modal open={showEditor} onClose={() => setShowEditor(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ p: 3, background: '#fff', maxWidth: 800, borderRadius: 1, maxHeight: '95vh', overflow: 'auto' }}>
                <Typography variant="h5" fontWeight={100}>Email Aanpassen</Typography>
                <Box sx={{ mt: 2 }} />
                <EmailEditorMolecule
                    html={html}
                    onChange={setHtml}
                    config={{
                        withInviteLinkButton: true,
                        withCredentialsLinkButton: false,
                        withNameButton: false,
                        withPwdResetLinkButton: false,
                    }} />

                <ContainedButton fullWidth sx={{ mt: 2 }} onClick={() => setShowEditor(false)}>Sluiten</ContainedButton>
            </Box>
        </Modal >

        <Button onClick={() => setOpen(true)} variant="text" sx={{ display: 'flex', justifyContent: 'center' }} >
            <MailTwoTone sx={{ mr: 1 }} fontSize={'small'} />
            <Typography variant="caption">Uitnodiging versturen</Typography>
        </Button>
    </>
}