import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { FormStepper, PrevNextButtons } from "../../molecules";
import { PersonDetailsForm } from "../person/PersonDetails";
import { EmptyPerson } from "../../../types/company/person";
import { OrganizationBankingForm } from "../company/CompanyBankingForm";
import { OrganizationDetailsForm } from "../company/CompanyDetailsForm";
import { BankAccount, EmptyBankAccount } from "../../../types/company/bankAccount";
import { OnboardingDocumentsForm } from "../person/OnboardingDocuments";
import { BaseDocument } from "../../../types/documents/Documents";
import { ZzpInvitePayload } from "../../../types/company/invitePayloads";
import { CredentialsForm } from "../person/CredentialsForm";
import { AddCompanyPayload, EmptyAddCompanyPayload } from "../../../types/company/payloads";

export function ZzpInviteForm(props: {
    loading: boolean,
    onCompleted: (payload: ZzpInvitePayload, files: (File | undefined)[]) => void,
    onboardingItems: BaseDocument[],
    initialEmail?: string
}) {
    const [activeStep, setActiveStep] = useState(0);
    const [person, setPerson] = useState(new EmptyPerson());
    const [company, setCompany] = useState<AddCompanyPayload>(EmptyAddCompanyPayload());
    const [files, setFiles] = useState<(File | undefined)[]>([undefined, undefined, undefined]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [kvkValid, setKvkValid] = useState<boolean>(false);

    const onCompleted = () => props.onCompleted({
        company,
        professional: person,
        credentials: { email, password }
    }, files);

    useEffect(() => {
        if (!props.initialEmail)
            return;

        setEmail(props.initialEmail);
        setPerson(p => ({ ...p, email: props.initialEmail ?? '' }));

    }, [props.initialEmail]);

    const setBankAccount = (bankAccount: BankAccount) => {
        setCompany({
            ...company,
            bankAccount
        });
    }

    const steps = [
        {
            label: "Inloggegevens",
            content: <CredentialsForm email={email} password={password} onChange={({ email, password: passsword }) => {
                setEmail(email);
                setPassword(passsword);
            }} />
        },
        {
            label: "Persoonlijke gegevens",
            content: <PersonDetailsForm
                showDateOfBirth
                showNationality
                person={person} onChange={setPerson} />
        },
        {
            label: "Bedrijfsgegevens",
            content: <OrganizationDetailsForm onKvkValidated={setKvkValid} company={company} onChange={setCompany} />
        },
        {
            label: "Financiële gegevens",
            content: <OrganizationBankingForm bankAccount={company.bankAccount ?? new EmptyBankAccount()} onChange={setBankAccount} />
        }
    ]

    if (props.onboardingItems.length > 0)
        steps.push({
            label: "Documenten",
            content: <OnboardingDocumentsForm
                files={files}
                onChange={setFiles}
                items={props.onboardingItems} />
        })

    return (<>
        <FormStepper
            onStepClicked={setActiveStep}
            activeStep={activeStep}
            steps={steps}
        >
            <PrevNextButtons
                variant="text"
                showNext
                showPrev
                prevDisabled={activeStep === 0}
                nextDisabled={activeStep === steps.length - 1}
                onPrev={() => setActiveStep(activeStep - 1)}
                onNext={() => setActiveStep(activeStep + 1)}
            />

        </FormStepper>
        <LoadingButton disabled={!kvkValid} loading={props.loading} sx={{ my: 2 }} variant="text" onClick={onCompleted}>Versturen</LoadingButton>
    </>
    );
}