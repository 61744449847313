import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { IconLabel } from '../IconLabel';
import { Typography } from '@mui/material';

let timeout: any;

export function AsyncAutocomplete<T>(props: {
    title?: string;
    fetching?: boolean;
    disabled?: boolean;
    required?: boolean;
    value?: string;
    onChange?: (search: string) => void;
    options: T[];
    getTitleFromOption: (option: T) => string;
    onSelect?: (option: T | null) => void;
    sx?: React.CSSProperties;
    selected?: T;
    onSubmitted?: () => void;
}) {
    const { options, fetching, title, getTitleFromOption } = props;
    const [open, setOpen] = React.useState(false);

    const scheduleOnChange = (value: string) => {
        if (!props.onChange) return;

        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => props.onChange!(value), 300);
    }

    return (
        <Autocomplete
            disabled={props.disabled}
            value={props.selected ?? undefined}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, value) => getTitleFromOption(option) === getTitleFromOption(value)}
            getOptionLabel={(option) => getTitleFromOption(option)}
            renderOption={(html, option) => <li {...html}>
                <Typography sx={{ fontSize: 14, px: 1, py: .5 }}>{getTitleFromOption(option)}</Typography>
            </li>}
            options={options}
            loadingText='Laden...'
            noOptionsText='Geen resultaten'
            onChange={(e, value) => {
                if (!props.onSelect) return;

                e.preventDefault();
                e.stopPropagation();
                props.onSelect(value)
            }
            }
            loading={fetching}
            style={{ ...props.sx }}
            renderInput={(params) => (
                <>
                    {title && <IconLabel label={title} required={props.required} />}
                    <TextField
                        {...params}
                        sx={{
                            background: '#eee',
                            borderRadius: 1,
                            borderWidth: 0,
                            ".MuiOutlinedInput-root .MuiAutocomplete-input": { padding: 0, px: 1 },
                            ".MuiInputBase-input": { padding: 0 },
                            "fieldset": {
                                borderWidth: 0,
                                fontSize: 120
                            },
                            ".MuiInputBase-root": {
                                padding: "0 !important",
                                borderWidth: 0,
                                height: 30
                            },
                            "div": {
                                padding: 0,
                                height: 30
                            }
                        }}
                        variant='outlined'
                        onChange={(e) => scheduleOnChange(e.target.value)}
                        disabled={props.disabled}
                        InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 12 },
                            endAdornment: fetching ? <CircularProgress color="inherit" size={20} /> : null,
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                props.onSubmitted && props.onSubmitted();
                                setOpen(false);
                            }
                        }}
                    />
                </>
            )}
        />
    );
}