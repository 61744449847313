import { ColumnProps, Table } from "../Table";
import { Handelsnaam } from "../../../../types/company/kvk";

export function HandelsnamenTable(props: {
    handelsnamen: Handelsnaam[];
}) {
    const colDefs: ColumnProps<Handelsnaam>[] = [
        {
            headerName: "Naam",
            field: "naam",
            flex: 1
        }
    ];

    return <Table id="Handelsnamen" rowHeight={30} slim columns={colDefs} data={props.handelsnamen} />;
}

