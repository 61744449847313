import { FC } from "react"
import { Box, Checkbox as MuiCheckbox, SxProps, Typography } from "@mui/material"

import { IconLabel } from "../IconLabel"
import { Row } from "../Row"
import { TextInput } from "./TextInput"

export const Checkbox: FC<{
    title: string,
    checked: boolean,
    onChange: () => void,
    sx?: SxProps,
    disabled?: boolean
}> = ({ title, checked, onChange: onEnabledChanged, sx, disabled }) => {
    return <Box display={'flex'} key={title} alignItems={'center'} sx={sx}>
        <MuiCheckbox
            sx={{ p: 0, mr: 1 }}
            disabled={disabled}
            title={title}
            checked={checked}
            onChange={onEnabledChanged}
        />
        <Typography variant="overline">
            {title}
        </Typography>
    </Box>
}

export const CheckboxInput: FC<{
    icon?: any,
    title: string,
    type?: React.HTMLInputTypeAttribute | 'double',

    checked: boolean,
    onEnabledChanged: () => void,

    value: string,
    onChange: (value: string) => void,

    sx?: SxProps,
    sxInput?: SxProps,
    disabled?: boolean

}> = ({
    icon,
    title,

    value,
    onChange,

    checked,
    onEnabledChanged,
    type,

    sx, sxInput,
    disabled
}) => {
        return <Box sx={sx}>
            <IconLabel
                icon={icon}
                label={title}
            />
            <Row sx={{ alignItems: 'center !important' }}>
                <MuiCheckbox
                    sx={{ p: 0, mr: 1 }}
                    checked={checked}
                    onChange={onEnabledChanged}
                    disabled={disabled}
                />
                <TextInput
                    type={type ?? 'text'}
                    sx={{ flex: 1, ...sxInput }}
                    disabled={!checked || disabled}
                    value={value}
                    onChange={onChange}
                />
            </Row>
        </Box>
    }