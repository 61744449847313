import { CircularProgress, Link } from "@mui/material";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { Vacancy } from "../../../../types/recruiter/vacancy";
import { Table, RelationButton } from "../..";
import { SkillsRenderer } from "./Professionals";
import { ColumnProps } from "../Table";
import { MatchScore } from "../../../../types/recruiter/matchScore";

type RowData = {
    vacancy: Vacancy;
    relation?: VacancyRelation;
    score?: number;
}

export function ApplyVacancyTable({
    vacancies, processing, relations,
    onApply, onRemove, onVacancyClick, scores
}: {
    vacancies: Vacancy[];
    relations: VacancyRelation[];
    scores: MatchScore[];
    processing: string[];
    onApply: (vacancy: Vacancy) => void;
    onRemove: (relation: VacancyRelation) => void;
    onVacancyClick: (vacancy: Vacancy) => void;
}) {
    const data: RowData[] = vacancies.map(vacancy => ({
        vacancy: vacancy,
        relation: relations.find(relation => relation.vacancyId === vacancy._id),
        score: scores.find(score => score.vacancyId === vacancy._id)?.score
    }))

    const columns: ColumnProps<RowData>[] = [
        {
            valueGetter: (params) => params.data.relation ? 1 : 0,
            headerName: "Inschrijven",
            width: 200,
            cellRenderer: (row: { data: RowData }) => <RelationButton
                loading={processing.some(x => x === row.data.vacancy._id)}
                onAdd={() => onApply(row.data.vacancy)}
                onRemove={() => onRemove(row.data.relation!)}
                relation={row.data.relation !== undefined} />
        },
        {
            width: 120,
            field: "score",
            headerName: "Score",
            sort: 'desc',
            valueGetter: (data) => data.data.score !== undefined ? data.data.score : 0,
            cellRenderer: (data) => data.data.score !== undefined ? `${data.data.score}%` : <CircularProgress size={'small'} color="primary" sx={{ width: 15 }} />
        },
        {
            headerName: "Klant", field: "vacancy.company", width: 200
        },
        {
            headerName: "Vacature", field: "vacancy.title", minWidth: 200,
            cellRenderer: (row: { data: RowData }) => <Link onClick={() => onVacancyClick(row.data.vacancy)}>{row.data.vacancy.title}</Link>,
        },
        { headerName: "Locatie", field: "vacancy.location" },
        { headerName: "Vaardigheden", field: "vacancy.requiredSkills", cellRenderer: SkillsRenderer, flex: 1 },
    ]

    return <Table id="ApplyVac" data={data} columns={columns} />
}