import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '..';
import { FetchingState } from '../../types/fetchingState';
import { showToast } from './toastSlice';
import { Tag } from '../../types/company/Tag';

import api from '../../api/organization/tags';

export interface TagsState {
    tags: Tag[];
    fetchingList: FetchingState;
    fetchingCreate: FetchingState
}

const intialState: TagsState = {
    tags: [],
    fetchingList: "init",
    fetchingCreate: "init"
}

export const listTags = createAsyncThunk(
    'tags/list',
    async (_, thunkApi) => {
        try {
            const tags = await api.list();
            return tags;
        } catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const createTag = createAsyncThunk(
    'companies/create',
    async (tagName: string, thunkApi) => {
        try {
            const tag = await api.create(tagName);
            return tag;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const tagsSlice = createSlice({
    name: 'tags',
    initialState: intialState,
    reducers: {},
    extraReducers: (builder) => {
        // List
        builder
            .addCase(listTags.pending, (state) => { state.fetchingList = "fetching"; })
            .addCase(listTags.rejected, (state) => { state.fetchingList = "error"; })
            .addCase(listTags.fulfilled, (state, action: PayloadAction<Tag[]>) => {
                state.fetchingList = "completed";
                state.tags = action.payload;
            })

        // Create
        builder
            .addCase(createTag.pending, (state) => { state.fetchingCreate = "fetching"; })
            .addCase(createTag.rejected, (state) => { state.fetchingCreate = "error"; })
            .addCase(createTag.fulfilled, (state, action: PayloadAction<Tag>) => {
                state.tags = [action.payload, ...state.tags];
                state.fetchingCreate = "completed";
            })
    }
})

export const tagsSelector = (state: RootState) => state.tags;
export default tagsSlice.reducer