
import { useState } from 'react';
import { PersonDetailsForm } from '../../forms/person/PersonDetails';
import { EmptyPerson } from '../../../types/company/person';
import { FormStepper } from '../../molecules';
import { LoadingButton } from '@mui/lab';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createPerson, personsSelector } from '../../../store/slices/personsSlice';
import { useNavigate } from 'react-router-dom';
import { personRoutes } from '../../../router/paths';
import { Box, Typography } from '@mui/material';
import { SelectMolecule } from '../../molecules/MultiSelect';
import api from '../../../api/organization/credentials';

export function PersonCreateOrganism() {
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const isCreating = useAppSelector(personsSelector).fetchingCreate === 'fetching';
    const [person, setPerson] = useState(new EmptyPerson());
    const [step, setStep] = useState(0);
    const [withLogin, setWithLogin] = useState(false);

    const onCreate = async () => {
        let payload: any = { person };

        const result = await dispatch(createPerson(payload));
        if (result.meta.requestStatus === 'fulfilled') {
            if (withLogin)
                api.sendCredentialsInvite((result.payload as any)._id);

            nav(personRoutes.list);
        }
    }

    const steps: { label: string, content: JSX.Element }[] = [
        {
            label: 'Persoonlijke gegevens',
            content: <>
                <PersonDetailsForm person={person} onChange={setPerson} />
                <Box mt={3} />
                <Typography variant="overline">Inloggen toegestaan?</Typography><br />
                <Typography variant='caption' fontWeight={100}>Er zal een email worden verzonden om het wachtwoord in te stellen naar de gebruiker</Typography>
                <Box mt={1} />
                <SelectMolecule
                    onClick={setWithLogin}
                    selected={[withLogin]}
                    items={[{
                        label: 'Ja',
                        value: true
                    }, {
                        label: 'Nee',
                        value: false
                    }]}
                />
                <Box mt={3} />
            </>
        }
    ]

    return <FormStepper
        steps={steps}
        onStepClicked={setStep}
        activeStep={step}
    >
        {
            step === steps.length - 1 &&
            <LoadingButton loading={isCreating} onClick={onCreate}>Aanmaken</LoadingButton>
        }
    </FormStepper>
}