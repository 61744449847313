import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class EmailTemplatesApi extends BaseApi {
    constructor() { super(apiRoutes.email + "/templates"); }

    public get = (id: string) => this.fetch(id).then(res => res.text());
    public update = (id: string, body: string) => this.fetch(id, "PUT", body);
}

const api = new EmailTemplatesApi();
export default api;