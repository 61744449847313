import { Link as MuiLink } from "@mui/material";
import { Membership } from "../../../../types/company/membership";
import { Person } from "../../../../types/company/person";
import { getMembershipFriendlyName } from "../../../../utils/membershipRoles";
import { ColumnProps, Table } from "../Table";
import { ContextMenuMore } from "../../ContextMenu";
import { personRoutes } from "../../../../router/paths";
import { Link } from "../../Link";

type RowProps = { data: Person }

export function CompanyMembershipsTable(props: {
    persons: Person[];
    memberships: Membership[];
    onRemoveMembership: (membership: Membership) => void;
    onEditPermissions: (membership: Membership) => void;

    removeMembershipLabel?: string;
    editPermissionsLabel?: string;
}) {
    const getMembership = (person: Person) => props.memberships.find((m) => m.personId === person._id);
    const colDefs: ColumnProps<Person>[] = [
        {
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: props.removeMembershipLabel ?? "Lidmaatschap Verwijderen",
                    onClick: () => props.onRemoveMembership(getMembership(row.data)!),
                    color: "error"
                },
                {
                    label: props.editPermissionsLabel ?? "Rollen/Rechten Bewerken",
                    onClick: () => props.onEditPermissions(getMembership(row.data)!),
                    color: "info"
                }
            ]} />,
            width: 70
        },
        {
            headerName: "Naam",
            cellRenderer: (row) => <Link path={personRoutes.details(row.data._id)}>{row.data.firstName} {row.data.lastName}</Link>
        },
        {
            field: "email",
            headerName: "Email",
            cellRenderer: (row) => <MuiLink href={`mailto:${row.data.email}`}>{row.data.email}</MuiLink>
        },
        {
            field: "phoneNumber",
            headerName: "Telefoon"
        },
        {
            headerName: "Rollen",
            valueGetter: (row: RowProps) => getMembership(row.data)?.roles.map(getMembershipFriendlyName).join(", "),
            flex: 1
        }
    ];

    return <Table id="CompanyMemberships" slim columns={colDefs} data={props.persons} />;
}

