import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { SearchTwoTone } from "@mui/icons-material";
import { filterBySearch } from "../../../../filters/professionalFilters";
import { recruiterRoutes } from "../../../../router/paths";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { professionalsSelector } from "../../../../store/slices/professionalsSlice";
import { vacanciesSelector, createVacancyRelation, deleteVacancyRelation } from "../../../../store/slices/vacanciesSlice";
import { Professional } from "../../../../types/recruiter/professional";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { TextInput } from "../../../molecules";
import { ApplyProfessionalTable } from "../../../molecules/tables/recruiter/ApplyProfessional";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import api from "../../../../api/recruiter/relations";

export function ApplyProfessionalOrganism(props: {
    vacancyId: string;
}) {
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState<string>("");
    const [scores, setScores] = useState<MatchScore[]>([]);
    const professionals = useAppSelector(professionalsSelector).professionals;
    const vacanciesState = useAppSelector(vacanciesSelector);

    const updateScores = useCallback(async () => {

        const scores = await api.getVacancyScores(props.vacancyId);
        setScores(scores);

        if (scores.length < professionals.length)
            setTimeout(updateScores, 5000);

    }, [props.vacancyId, professionals.length]);

    useEffect(() => {
        updateScores();
    }, [props.vacancyId, updateScores]);

    const addRelation = (professional: Professional) => {
        dispatch(createVacancyRelation({
            professionalId: professional._id,
            targetId: professional._id,
            vacancyId: props.vacancyId
        }));
    };

    const removeRelation = (relation: VacancyRelation) => {
        dispatch(deleteVacancyRelation({ relation, targetId: relation.professionalId }));
    };

    return <Box>
        <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2 }} value={search} onChange={v => setSearch(v as string)} />
        <ApplyProfessionalTable
            scores={scores}
            processing={vacanciesState.fetchingProfessionalRelations}
            professionals={filterBySearch(professionals, search)}
            relations={vacanciesState.relations.filter(x => x.vacancyId === props.vacancyId)}
            onApply={addRelation}
            onRemove={removeRelation}
            onProfessionalClick={(professional) => nav(recruiterRoutes.kandidaten.details(professional._id))}
        />
    </Box>
}