import { FC } from "react";
import { DotsLoader } from "../../molecules";
import { useAppSelector } from "../../../store/hooks";

import { useParams } from "react-router-dom";
import { contractsSelector } from "../../../store/slices/contractsSlice";
import { ContractInvoiceDetailsForm } from "../../forms/contract";
import { Box, Typography } from "@mui/material";
import { Contracts } from "../../../types/contracts";

export const ContractInvoiceOrganism: FC = () => {
    const { id } = useParams<{ id: string }>();
    const contracts = useAppSelector(contractsSelector).contracts;
    const contract = contracts?.find(c => c.contractnumber === id);

    if (!contract)
        return <DotsLoader message={"Contract word geladen"} />;

    return <Box mt={2}>
        <Typography color="secondary" variant="h6" mb={2}>Factuur Instellingen</Typography>
        <ContractInvoiceDetailsForm
            invoiceDetails={contract.invoiceDetails}
            hideSupplier={contract.contractKind === Contracts.Tao}
        />
    </Box>
}