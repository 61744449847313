import { useNavigate } from "react-router-dom";

import { recruiterRoutes } from "../../../../router/paths";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { vacanciesSelector, createVacancy } from "../../../../store/slices/vacanciesSlice";
import { VacancyDetails } from "../../../../types/recruiter/vacancy";

import Form from "../../../forms/recruiter/vacancy";

export function VacancyCreateOrganism() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const state = useAppSelector(vacanciesSelector);

    const onCompleted = (v: VacancyDetails) => {
        dispatch(createVacancy({ ...v, _id: "" }))
            .then((result: any) => {
                if (!result.error)
                    navigate(recruiterRoutes.vacatures.list);
            });
    };

    return <Form
        onComplete={onCompleted}
        completeLabel="Vacature aanmaken"
        fetchingSave={state.fetchingCreate === 'fetching'}
    />
}