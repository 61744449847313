import { FC } from "react";
import { Contracts } from "../../../types/contracts/Contracts";
import { Box } from "@mui/material";
import { TileMolecule } from "../../molecules";
import { HandymanTwoTone, HistoryEduTwoTone, TransferWithinAStationTwoTone } from "@mui/icons-material";

export const ContractTypeEditForm: FC<{
    value: Contracts,
    onChange: (value: Contracts) => void,
}> = ({ onChange, value }) => {
    return <Box display={'flex'} flexWrap={'wrap'}>
        <TileMolecule
            spacing={1}
            onClick={() => onChange(Contracts.Zzp)}
            width={250}
            height={150}
            title="ZZP"
            description="Zelfstandige ondernemers"
            selected={value === Contracts.Zzp}
            icon={<HandymanTwoTone color='primary' fontSize="large" />} />

        <TileMolecule
            spacing={1}
            onClick={() => onChange(Contracts.Tao)}
            width={250}
            height={150}
            title="TAO"
            description="Tijdelijke Arbeidsovereenkomst"
            selected={value === Contracts.Tao}
            icon={<HistoryEduTwoTone color='primary' fontSize="large" />} />

        <TileMolecule
            spacing={1}
            onClick={() => onChange(Contracts.Inhuur)}
            width={250}
            height={150}
            title="Inhuur"
            description="Inhuur derde van professionals"
            selected={value === Contracts.Inhuur}
            icon={<TransferWithinAStationTwoTone color='primary' fontSize="large" />} />
    </Box>
}