import { Address } from "./address";

export enum Gender {
    MALE = 1,
    FEMALE = 2
}

export interface Person {
    _id: string;
    firstName: string;
    lastName: string;
    initials: string;
    gender: Gender;
    email: string;
    phoneNumber: string;
    mobileNumber: string;
    dateOfBirth: string;
    nationality: string;
    bsn: string;
    function: string;
    address?: Address;
}

export class EmptyPerson implements Person {
    _id = '';
    firstName = '';
    lastName = '';
    initials = '';
    dateOfBirth = '';
    function = '';
    gender = Gender.MALE;
    email = '';
    phoneNumber = '';
    mobileNumber = '';
    nationality = "Nederland";
    bsn = '';
}