import { settingRoutes } from "../../../../router/paths";
import { OrganizationForm } from "../../../../types/documents/Form";
import { ContextMenuMore } from "../../ContextMenu";
import { ColumnProps, Table } from "../Table";
import { Link } from "../../Link";

export function OrganizationFormsTable(props: {
    templates: OrganizationForm[];
    onDelete: (template: OrganizationForm) => void;
    onCopy: (template: OrganizationForm) => void;
}) {
    const colDefs: ColumnProps<OrganizationForm>[] = [
        {
            width: 70,
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: "Dupliceren",
                    color: "info",
                    onClick: () => props.onCopy(row.data)
                },
                {
                    label: "Verwijderen",
                    color: "error",
                    onClick: () => props.onDelete(row.data)
                }
            ]} />
        },
        {
            field: "title",
            headerName: "Titel",
            flex: 1,
            cellRenderer: (row) => <Link path={settingRoutes.form(row.data._id)}>{row.data.title}</Link>
        },
        {
            field: "description",
            headerName: "Omschrijving",
            flex: 2
        }
    ];

    return <Table id="Forms" columns={colDefs} data={props.templates} />
}