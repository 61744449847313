import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PlatformUser } from '../../types/user'
import { RootState } from '..';
import { getTokenParam, removeTokenParam } from '../../utils/queryParams';
import { retrieveLogo, retrieveTheme, retrieveToken, storeLogo, storeTheme, storeToken } from '../../utils/cache';
import { showToast } from './toastSlice';

import AuthApi from '../../api/auth';
import WhiteLabelApi from '../../api/organization/whitelabel';

export interface AuthState { user?: PlatformUser; hydratingToken: boolean; loggingIn: boolean }

const intialState: AuthState = {
    user: undefined,
    hydratingToken: retrieveToken() != null,
    loggingIn: false
};

export const hydrateToken = createAsyncThunk(
    'auth/hydrateToken',
    async () => {
        const tokenParamValue = getTokenParam();
        if (tokenParamValue) {
            storeToken(tokenParamValue);
            removeTokenParam();
        }

        const token = retrieveToken();
        if (!token) throw new Error('No token found');

        const user = await AuthApi.fetchUser();

        WhiteLabelApi
            .getThemeSettings()
            .then((theme) => {
                const cachedTheme = retrieveTheme();
                const cachedLogo = retrieveLogo();

                storeTheme(theme.themeColor ?? "");
                storeLogo(theme.imageUri);

                if (
                    (cachedTheme !== theme.themeColor && theme.themeColor?.length > 0) ||
                    (cachedLogo !== theme.imageUri && theme.imageUri?.length > 0)
                )
                    window.location.reload();
            });

        return user;
    }
)

export const login = createAsyncThunk(
    'auth/login',
    async (args: {
        email: string;
        password: string;
    }, thunkApi) => {

        try {
            const token = await AuthApi.login(args.email, args.password);
            storeToken(token);
            thunkApi.dispatch(hydrateToken());
        } catch {
            thunkApi.dispatch(showToast({
                message: 'Onjuiste gebruikersnaam of wachtwoord',
                type: 'error'
            }))
        }
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState: intialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(hydrateToken.pending, (state) => {
            state.hydratingToken = true;
        })
        builder.addCase(hydrateToken.rejected, (state) => {
            state.hydratingToken = false;
        })
        builder.addCase(hydrateToken.fulfilled, (state, action: PayloadAction<PlatformUser | undefined>) => {
            state.hydratingToken = false;
            state.user = action.payload;
        })

        builder.addCase(login.pending, (state) => {
            state.loggingIn = true;
        })
        builder.addCase(login.rejected, (state) => {
            state.loggingIn = false;
        })
        builder.addCase(login.fulfilled, (state) => {
            state.loggingIn = false;
        })
    }
})

export const authSelector = (state: RootState) => state.auth;
export default authSlice.reducer