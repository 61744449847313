import { AddOutlined, DeleteTwoTone, FormatBoldTwoTone, FormatItalicOutlined, RemoveOutlined } from "@mui/icons-material";
import { Menu, Box, Divider, MenuItem, ListItemIcon, Typography, Button } from "@mui/material";
import { HeaderComponent, HorizontalLineComponent, PdfComponent, PdfComponents, TextComponent, TextWeight } from "../../../types/documents/Pdf";
import { primaryLight } from "../../theme";
import { Row } from "../Row";
import { TextInput } from "../inputs/TextInput";

const BoldTextItem = (props: {
    component: TextComponent,
    onChange: (i: TextComponent) => void
}) => <MenuItem
    onClick={() => props.onChange({ ...props.component, weight: props.component.weight === TextWeight.Bold ? TextWeight.Regular : TextWeight.Bold })}
    sx={{
        backgroundColor: props.component.weight === TextWeight.Bold ? primaryLight : 'transparent',
        mx: 1,
        my: .5,
        px: 1,
        borderRadius: 2
    }}>
        <ListItemIcon>
            <FormatBoldTwoTone fontSize="small" />
        </ListItemIcon>
        <Row alignItems={'center'} width='100%' pl={2}>
            <Typography variant='overline' fontWeight={'bold'}>
                Bold
            </Typography>
        </Row>
    </MenuItem>

const ItalicTextItem = (props: {
    component: TextComponent,
    onChange: (i: TextComponent) => void
}) => <MenuItem
    onClick={() => props.onChange({ ...props.component, italic: !props.component.italic })}
    sx={{
        backgroundColor: props.component.italic ? primaryLight : 'transparent',
        mx: 1,
        px: 1,
        my: .5,
        borderRadius: 2
    }}>
        <ListItemIcon>
            <FormatItalicOutlined fontSize="small" />
        </ListItemIcon>
        <Row alignItems={'center'} width='100%' pl={2}>
            <Typography variant='overline' fontStyle={'italic'}>
                Italic
            </Typography>
        </Row>
    </MenuItem>

const FontSizeItem = (props: {
    component: TextComponent,
    onChange: (i: TextComponent) => void
}) => <MenuItem
    disableRipple
    sx={{
        mx: 1,
        px: 1,
        borderRadius: 2,
        background: '#f6f6f6'
    }}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} height={30}>
            <Button sx={{ minWidth: 0, width: 10 }} onClick={() => props.onChange({ ...props.component, fontSize: props.component.fontSize + 2 })}>
                <AddOutlined fontSize="small" color="success" />
            </Button>
            <Typography variant="overline" fontSize={14}>
                {props.component.fontSize}
            </Typography>
            <Button sx={{ minWidth: 0, width: 10 }} onClick={() => props.onChange({ ...props.component, fontSize: props.component.fontSize - 2 })}>
                <RemoveOutlined fontSize="small" color="secondary" />
            </Button>
        </Box>
    </MenuItem>

const LineThicknessItem = (props: {
    component: HorizontalLineComponent,
    onChange: (i: HorizontalLineComponent) => void
}) => <MenuItem
    disableRipple
    sx={{
        mx: 1,
        px: 1,
        borderRadius: 2,
        background: '#f6f6f6'
    }}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} height={30}>
            <Button sx={{ minWidth: 0, width: 10 }} onClick={() => props.onChange({ ...props.component, height: props.component.height + 1 })}>
                <AddOutlined fontSize="small" color="success" />
            </Button>
            <Typography variant="overline" fontSize={14}>
                {props.component.height}
            </Typography>
            <Button sx={{ minWidth: 0, width: 10 }} onClick={() => props.onChange({ ...props.component, height: props.component.height - 1 })}>
                <RemoveOutlined fontSize="small" color="secondary" />
            </Button>
        </Box>
    </MenuItem>


const ImageUriItem = (props: {
    component: HeaderComponent,
    onChange: (i: HeaderComponent) => void
}) => <Box
    sx={{
        mx: 1,
        mb: 1,
        px: 1,
        borderRadius: 2,
        display: 'block',
        width: 300,
    }}>
        <Typography variant="overline" color="text.secondary">
            Afbeelding Url
        </Typography>
        <TextInput
            autoFocus
            value={props.component.imageUri}
            onChange={(e) => props.onChange({ ...props.component, imageUri: e })}
        />
    </Box>

const MenuItemsFactory: React.FC<{
    item: PdfComponent,
    onChange: (i: any) => void
}> = ({ item, onChange }) => {
    switch (item.component) {
        case PdfComponents.Checkbox:
        case PdfComponents.Text:
            const textProps = item as TextComponent;
            return <>
                <FontSizeItem component={textProps} onChange={onChange} />
                <BoldTextItem component={textProps} onChange={onChange} />
                <ItalicTextItem component={textProps} onChange={onChange} />
                <Box sx={{ px: 1, py: .5 }}>
                    <Divider />
                </Box>
            </>

        case PdfComponents.Break:
        case PdfComponents.HorizontalLine:
            const lineProps = item as HorizontalLineComponent;
            return <>
                <LineThicknessItem component={lineProps} onChange={onChange} />
                <Box sx={{ px: 1, py: .5 }}>
                    <Divider />
                </Box>
            </>

        case PdfComponents.Header:
            const headerProps = item as HeaderComponent;
            return <>
                <ImageUriItem component={headerProps} onChange={onChange} />
                <Box sx={{ px: 1, py: .5 }}>
                    <Divider />
                </Box>
            </>

        default:
            return null;
    }
}

export const ComponentEditorContextMenu: React.FC<{
    item: PdfComponent,
    anchorEl: HTMLElement | null,
    onClose: () => void,
    onChange: (i: any) => void,
    onDelete?: () => void
}> = ({
    item,
    onChange,
    anchorEl,
    onClose,
    onDelete
}) => {
        const open = Boolean(anchorEl);
        const handleClose = () => onClose();

        return <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: -1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItemsFactory item={item} onChange={onChange} />
            <MenuItem onClick={onDelete}>
                <ListItemIcon>
                    <DeleteTwoTone fontSize="small" color="error" />
                </ListItemIcon>
                <Typography variant="overline" color='error' sx={{ mt: .5, fontWeight: 'bold' }}>
                    Verwijderen
                </Typography>
            </MenuItem>
        </Menu>
    };
