import { Box, Fade, Typography } from "@mui/material";
import Lottie from "react-lottie-player";
import animation from '../../animations/dots.json';
import { ReactNode } from "react";
import { isStringType } from "../../utils/strings";

export function DotsLoader(props: {
    message: string,
    subtitle?: string
}) {
    return (
        <Box sx={{
            minHeight: 500,
            width: "100%",
            display: "flex",
            alignItems: "center",
            pt: 4,
            justifyContent: "flex-start",
            flexDirection: "column"
        }}>

            <Box sx={{ maxWidth: 500, mb: -8 }}>
                <Lottie loop play
                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                    animationData={animation}
                />
            </Box>
            <Box sx={{ zIndex: 10 }}>
                <Typography fontWeight={'bold'} variant="overline">{props.message}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} zIndex={10} mt={2}>
                <Typography textAlign={'center'} variant="caption" color={'secondary'}>{props.subtitle}</Typography>
            </Box>
        </Box>
    );
}

export function FullScreenLoader(props: {
    title: string | ReactNode;
    subtitle: string;
    animation: any;
}) {
    return <Box sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh"
    }}>
        <Box>
            <Fade appear in timeout={600}>
                {
                    isStringType(props.title)
                        ? <Typography textAlign={'center'} color={'primary'} variant="overline" fontSize={20}>{props.title}</Typography>
                        : <Box>{props.title}</Box>
                }

            </Fade>
        </Box>
        <Box>
            <Fade appear in timeout={800}>
                <Typography color={'secondary'} variant="overline">- {props.subtitle} -</Typography>
            </Fade>
        </Box>
        <Box sx={{ maxHeight: 200, maxWidth: 300 }}>
            <Lottie loop play
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                animationData={props.animation}
            />
        </Box>
    </Box>
}