import { Box, SxProps, Theme } from "@mui/material";
import styled from "styled-components";

const Container = styled(Box)({
    marginTop: 16
});

export function FormContainer(props: { children: any, sx?: SxProps<Theme> }) {
    return <Container sx={props.sx}>
        {props.children}
    </Container>
}