import { Box, Typography } from "@mui/material";
import { FormContainer, FileInput } from "../../molecules";
import { BaseDocument, DocumentTarget } from "../../../types/documents/Documents";

export function OnboardingDocumentsForm(props: {
    items: BaseDocument[];
    hidePersonDocs?: boolean;
    files: (File | undefined)[];
    allowedTypes?: string[];
    onChange: (files: (File | undefined)[]) => void;
}) {
    const files = props.files;

    return <FormContainer>
        <Box sx={{ alignItems: 'flex-start !important', flexDirection: 'column' }}>
            {
                props.items.map((item, index) => {
                    return <Box key={index} sx={{ mb: 4, display: props.hidePersonDocs && item.targetType === DocumentTarget.Person ? 'none' : undefined }}>
                        <Typography sx={{ fontWeight: 400, pl: .5 }}>{item.title}</Typography>
                        <FileInput
                            fileTypes={props.allowedTypes}
                            value={files[index]}
                            sx={{ minWidth: 300 }}
                            onChange={(file) => {
                                const newFiles = [...files];
                                newFiles[index] = file;
                                props.onChange(newFiles);
                            }} label={item.description ?? ''} />
                    </Box>
                })
            }
        </Box>
    </FormContainer>;
}
