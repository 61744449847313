import { Person } from "../../types/company/person";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { CreatePersonPayload } from "../../types/company/payloads";

class PersonApi extends BaseApi {
    constructor() { super(apiRoutes.organization); }
    public list = (ids?: string[]) => this.fetchJson<Person[]>(ids ? "persons/filter" : "persons", ids ? 'POST' : 'GET', ids);
    public create = (payload: CreatePersonPayload) => this.fetchJson<Person>("persons", "POST", payload);
    public update = (payload: Person) => this.fetch(`persons/${payload._id}`, "PUT", payload);
    public delete = (id: string) => this.fetch(`persons/${id}`, "DELETE");
}

const api = new PersonApi();
export default api;