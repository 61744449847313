import { personRoutes } from "../../../../router/paths";
import { ContactPersonProps } from "../../../../types/company/contactPerson";
import { Membership } from "../../../../types/company/membership";
import { Person } from "../../../../types/company/person";
import { getMembershipFriendlyName } from "../../../../utils/membershipRoles";
import { ContextMenuMore } from "../../ContextMenu";
import { Link } from "../../Link";
import { ColumnProps, Table } from "../Table";
import { Link as MuiLink } from "@mui/material";

export function PersonsTable(props: {
    persons: Person[];
    memberships: Membership[];
}) {
    const getMembership = (person: Person) => props.memberships.find((m) => m.personId === person._id);
    const colDefs: ColumnProps<Person>[] = [
        {
            headerName: "Naam",
            cellRenderer: (row) =>
                <Link path={personRoutes.details(row.data._id)}>{row.data.firstName} {row.data.lastName}</Link>
        },
        {
            field: "email",
            headerName: "Email",
            cellRenderer: (row) => <MuiLink href={`mailto:${row.data.email}`}>{row.data.email}</MuiLink>
        },
        {
            field: "phoneNumber",
            headerName: "Telefoon"
        },
        {
            headerName: "Rol",
            cellRenderer: (row) => getMembership(row.data)?.roles.map(getMembershipFriendlyName).join(', ') || "Geen",
            flex: 1
        }
    ];

    return <Table id="Persons" slim columns={colDefs} data={props.persons} />;
}


export function ContactPersonsTable(props: {
    persons: ContactPersonProps[],
    onRemove: (person: ContactPersonProps) => void
}) {

    const colDefs: ColumnProps<ContactPersonProps>[] = [
        {
            width: 50,
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    color: 'error',
                    label: "Verwijder",
                    onClick: () => props.onRemove(row.data)
                }
            ]} />
        },
        {
            headerName: "Naam",
            valueGetter: (row) => row.data.details.firstName + " " + row.data.details.lastName
        },
        {
            field: "details.email",
            headerName: "Email"
        },
        {
            headerName: "Rol",
            cellRenderer: (row) => row.data.roles.map(getMembershipFriendlyName).join(', ') || "Geen",
            flex: 1
        }
    ];

    return <Table id="Contacts" slim columns={colDefs} data={props.persons} />
}