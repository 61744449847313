import { CompanyType } from "../types/company/company";

export const companyTypeString = (type: CompanyType) => {
    switch (type) {
        case CompanyType.BV: return 'BV';
        case CompanyType.NV: return 'NV';
        case CompanyType.VOF: return 'VOF';
        case CompanyType.ZZP: return 'ZZP';
        case CompanyType.STICHTING: return 'Stichting';
        case CompanyType.VERENIGING: return 'Vereniging';
        case CompanyType.CV: return 'CV';
        case CompanyType.Ltd: return 'Ltd';
        case CompanyType.Inc: return 'Inc';
        case CompanyType.Other: return 'Other';
    }
}