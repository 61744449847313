import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";

import { Box, Button, Typography } from "@mui/material";
import { AddTwoTone, SearchTwoTone } from "@mui/icons-material";
import animation from "../../../../animations/swipe.json";
import { filterBySearch } from "../../../../filters/professionalFilters";
import { recruiterRoutes } from "../../../../router/paths";
import { refreshRecruiterModuleData } from "../../../../store/combinedActions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { professionalsSelector } from "../../../../store/slices/professionalsSlice";
import { DotsLoader, TextInput, ProfessionalsTable, Toolbar } from "../../../molecules";

export function ProfessionalsListOrganism() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { professionals, fetchingList } = useAppSelector(professionalsSelector);
    const [search, setSearch] = useState<string>('');

    return <Box>
        <Toolbar>
            <Button onClick={() => navigate(recruiterRoutes.kandidaten.create)} variant="text">
                <AddTwoTone fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="caption">Kandidaat Toevoegen</Typography>
            </Button>
        </Toolbar>
        {fetchingList === 'fetching' && <DotsLoader message="- Professionals worden opgehaald -" />}
        {fetchingList === 'completed' && professionals.length > 0 && <>
            <Box display={'flex'} alignItems={'flex-end'} mb={-1}>
                <Box mt={2}>
                    <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ mb: 2, minWidth: 300 }} value={search} onChange={v => setSearch(v as string)} />
                </Box>
                <Box>
                    <Button onClick={() => refreshRecruiterModuleData(dispatch)} variant="text" sx={{ mb: 2, ml: 1 }}>
                        <Typography variant="caption">Vernieuwen</Typography>
                    </Button>
                </Box>
            </Box>
            <ProfessionalsTable professionals={filterBySearch(professionals, search)} />
        </>
        }
        {fetchingList === 'completed' && professionals.length === 0 && <Box display={'flex'} flexDirection={'column'} alignItems='center' width="100%">
            <Box sx={{ maxWidth: 300, mb: -8 }}>
                <Lottie loop play
                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                    animationData={animation}
                />
            </Box>
            <Box sx={{ zIndex: 10 }} flexDirection={'column'} display={'flex'}>
                <Typography sx={{ mt: 2, textAlign: 'center' }} variant="overline">- Geen Kandidaten Geregistreerd -</Typography>
                <Button sx={{ mt: 2 }} onClick={() => navigate(recruiterRoutes.kandidaten.create)} variant="text">Kandidaat Toevoegen</Button>
            </Box>
        </Box>}

    </Box>
}