import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { professionalsSelector } from "../../../../store/slices/professionalsSlice";
import { updateRelationStatus, updateRelationComment } from "../../../../store/slices/vacanciesSlice";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { DotsLoader, VacancyProfessionalsTable } from "../../../molecules";
import { useEffect, useState } from "react";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import api from "../../../../api/recruiter/relations";


export function RelatedProfessionalsOrganism() {
    const vacanciesState = useAppSelector(state => state.vacancies);
    const professionalsState = useAppSelector(professionalsSelector);
    const params = useParams();
    const dispatch = useAppDispatch();

    const [scores, setScores] = useState<MatchScore[]>([]);
    const relations = vacanciesState.relations.filter(x => x.vacancyId === params.id);
    const professionals = professionalsState.professionals.filter(prof => relations.some(y => y.professionalId === prof._id));
    const fetching = professionalsState.fetchingList === "fetching" || vacanciesState.fetchingRelations === "fetching";

    useEffect(() => {
        if (!params.id) return;

        api.getVacancyScores(params.id!).then(setScores);

    }, [params.id]);

    const onChangeRelation = (relation: VacancyRelation, status: number) => dispatch(updateRelationStatus({
        id: relation._id,
        status: status
    }));

    const onChangeComment = (relation: VacancyRelation, comment: string) => dispatch(updateRelationComment({
        id: relation._id,
        comment
    }));

    return <>
        {
            fetching && <DotsLoader message={"Professionals worden opgehaald"} />
        }
        {
            !fetching && <VacancyProfessionalsTable scores={scores} onCommentChange={onChangeComment} onRelationChange={onChangeRelation} professionals={professionals} relations={relations} />
        }
    </>
}