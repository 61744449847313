import addressApi from "../../../api/addressApi";
import { Address } from "../../../types/company/address";
import { FormContainer, TextInput } from "../../molecules";
import { Row } from "../../molecules/Row";
import { requiredValidation } from "../../validations";

export function AddressForm(props: {
    address: Address;
    onChange: (address: Address) => void;
}) {
    const { address, onChange } = props;
    const onAddressFilled = async () => {
        if (address.postcode.length > 0 && address.huisnummer) {
            try {
                const result = await addressApi.toAddress(address.postcode, address.huisnummer.toString());

                if (!result.city || !result.street)
                    throw new Error();

                onChange({
                    ...address,
                    plaats: result.city,
                    straatnaam: result.street
                });
            } catch {
            }
        }
    }

    return <FormContainer>
        <Row sx={{ mb: 1 }}>
            <TextInput onFocusLost={onAddressFilled} required validator={requiredValidation} label="Postcode" sx={{ flex: 2 }} value={address.postcode} onChange={(postalCode) => onChange({ ...address, postcode: postalCode })} />
            <TextInput onFocusLost={onAddressFilled} sx={{ flex: 1, pl: 1 }} value={address.huisnummer} required validator={requiredValidation} label="Huisnummer" onChange={(huisnummer) => onChange({ ...address, huisnummer })} />
        </Row>

        <Row sx={{ mb: 1 }}>
            <TextInput disabled sx={{ flex: 2 }} value={address.straatnaam} label="Straat" />
            <TextInput disabled label="Plaats" sx={{ pl: 1, flex: 1 }} value={address.plaats} />
        </Row>
    </FormContainer>;
}
