import { MaterieleRegistratie } from "../types/company/kvk";

export const daysFromNow = (days: number) => {
    const weekFromNow = new Date();
    weekFromNow.setDate(weekFromNow.getDate() + days);

    return weekFromNow
};

export const humanReadableDate = (registratie: MaterieleRegistratie) => {
    const year = registratie.datumAanvang.substring(0, 4);
    const month = registratie.datumAanvang.substring(4, 6);
    const day = registratie.datumAanvang.substring(6, 8);

    return `${day}/${month}/${year}`;
}