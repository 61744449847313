import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { PersonAddTwoTone, SearchTwoTone } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { DotsLoader, TextInput, Toolbar } from "../../molecules";
import { hydrateOrgModule } from "../../../store/combinedActions";
import { SendInviteOrganism } from "./SendInvite";
import { personsSelector } from "../../../store/slices/personsSlice";
import { PersonsTable } from "../../molecules/tables/person/Persons";
import { filterBySearch } from "../../../filters/personsFilter";
import { Membership } from "../../../types/company/membership";

import { createPermissionValidator } from "../../../utils/permissions";
import { authSelector } from "../../../store/slices/authSlice";
import { Permissions } from "../../../types/user";
import { useNavigate } from "react-router-dom";
import { personRoutes } from "../../../router/paths";

import membershipsApi from "../../../api/organization/memberships";

export function PersonsListOrganism() {
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const { persons, fetchingList } = useAppSelector(personsSelector);
    const user = useAppSelector(authSelector).user;

    const [search, setSearch] = useState<string>('');
    const [memberships, setMemberships] = useState<Membership[]>([]);

    const filteredPersons = filterBySearch(persons, search);
    const hasPermissions = createPermissionValidator(user);

    useEffect(() => {
        if (persons.length === 0)
            return;

        membershipsApi.listMultiplePersonsMemberships(persons.map(p => p._id))
            .then(setMemberships)
            .catch(console.error);
    }, [persons]);

    const onAddUserClick = () => nav(personRoutes.create);

    return <Box>
        {hasPermissions([Permissions.PersonWriter]) &&
            <Toolbar>
                <SendInviteOrganism />
                <Button onClick={onAddUserClick} variant="text" sx={{ px: 2, pr: 3 }}>
                    <PersonAddTwoTone sx={{ mx: 1 }} fontSize="small" />
                    <Typography variant="caption" >Aanmaken</Typography>
                </Button>
            </Toolbar>
        }

        {fetchingList === 'fetching' && <DotsLoader message="- Personen worden opgehaald -" />}
        {
            fetchingList === 'completed' && <>
                <Box display={'flex'} alignItems={'flex-end'}>
                    <Box>
                        <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2, minWidth: 300 }} value={search} onChange={v => setSearch(v as string)} />
                    </Box>
                    <Box>
                        <Button onClick={() => hydrateOrgModule(dispatch)} variant="text" sx={{ mb: 2, ml: 1 }}>
                            <Typography variant="caption" >Vernieuwen</Typography>
                        </Button>
                    </Box>
                </Box>
                <PersonsTable memberships={memberships} persons={filteredPersons} />
            </>
        }
    </Box >
}