import { DocumentList } from "../../types/documents";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class DocumentListsApi extends BaseApi {
    constructor() { super(apiRoutes.documents + "/documentlists"); }

    public list = () => this.fetchJson<DocumentList[]>();
    public get = (id: string) => this.fetchJson<DocumentList>(id);
    public create = (template: DocumentList) => this.fetchJson<DocumentList>('', "POST", template);
    public update = (template: DocumentList) => this.fetch('', "PUT", template);
    public delete = (id: string) => this.fetch(id, "DELETE");
}

const api = new DocumentListsApi();
export default api;