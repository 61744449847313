import { Box, Button, Chip } from "@mui/material";
import { TextInput } from "..";
import { useState } from "react";
import { FieldValidation } from "../../validations";

export function TagsInput(props: {
    tags: string[],
    onTagAdded?: (tag: string) => void;
    onTagClicked?: (tag: string) => void;
    label: string,
    required?: boolean,
    subtitle?: string,
    tooltip?: string,
    icon?: any,
    validator?: FieldValidation
}) {
    const [value, setValue] = useState('');
    const onTagClicked = (tag: string) => props.onTagClicked && props.onTagClicked(tag);

    const addToArray = () => {
        const trimmer = (v: string) => v.trim().toLocaleLowerCase();
        const trimmed = trimmer(value);

        if (trimmed.length === 0 || props.tags.map(trimmer).includes(trimmed))
            return;

        props.onTagAdded && props.onTagAdded(value);
        setValue('');
    }

    const validation = () => props.validator && props.validator(props.tags);

    return <Box>
        <Box display='flex' alignItems='center'>
            <TextInput
                icon={props.icon}
                validator={validation}
                tooltip={props.tooltip}
                subtitle={props.subtitle}
                required={props.required}
                label={props.label}
                sx={{ flex: 1 }}
                value={value}
                onChange={v => setValue(v as string)}
                onSubmit={addToArray}
                rightNeighbour={<Button sx={{ fontSize: 8, height: 30 }} variant="text" onClick={addToArray}>Toevoegen</Button>}
            />
        </Box>
        <Box>{
            props.tags.map(tag => <Chip key={tag} clickable onClick={() => onTagClicked(tag)} label={tag} />)
        }</Box>
    </Box>
}