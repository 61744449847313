import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Chip, CircularProgress, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { companiesSelector, companySelector, deleteCompany, syncWithKvK, updateCompany } from '../../../store/slices/companiesSlice';
import { AsyncAutocomplete, ConfirmDialog, TextInput, Toolbar } from '../../molecules';
import { humanReadableDate } from '../../../utils/dateUtils';
import { Row } from '../../molecules/Row';
import { companyTypeString } from '../../../utils/companyType';
import { HandelsnamenTable } from '../../molecules/tables/company/Handelsnamen';
import { useEffect, useState } from 'react';
import { organizationRoutes } from '../../../router/paths';
import { DeleteTwoTone, SyncTwoTone } from '@mui/icons-material';
import { requiredValidation } from '../../validations';
import { emailValidation } from '../../validations/email';
import { showToast } from '../../../store/slices/toastSlice';
import { Tag } from '../../../types/company/Tag';
import { createTag, tagsSelector } from '../../../store/slices/tagsSlice';

import companyApi from '../../../api/organization/company';

const ReadOnlyInput = (props: { label: string, value: string, flex?: number, mr?: number }) => {
    const { label, value, flex, mr } = props;
    return <TextInput withCopy sx={{ mr, flex }} label={label} value={value} inputSx={{ fontWeight: 'bold' }} />
}

export function CompanyDetailsOrganism() {
    const { id } = useParams();
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const tagsStore = useAppSelector(tagsSelector);
    const fetching = useAppSelector(companiesSelector).deletesInProgress.includes(id ?? '');
    const fetchingUpdate = useAppSelector(companiesSelector).fetchingUpdate === 'fetching';
    const company = useAppSelector(state => companySelector(state, id ?? ''));
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);

    const [facturenEmail, setFacturenEmail] = useState(company?.billingAddress ?? '');
    const [genericEmail, setGenericEmail] = useState(company?.genericAddress ?? '');
    const [phone, setPhone] = useState(company?.phone ?? '');
    const [companyTags, setCompanyTags] = useState<Tag[]>([]);
    const [newTag, setNewTag] = useState('');

    useEffect(() => {
        setFacturenEmail(company?.billingAddress ?? '');
        setGenericEmail(company?.genericAddress ?? '');
        setPhone(company?.phone ?? '');
    }, [company]);

    useEffect(() => {
        if (!company?._id)
            return;

        companyApi.getTags(id!).then(setCompanyTags);
    }, [id, company?._id]);

    if (!company)
        return <CircularProgress />

    const onAddTag = async (name: string) => {
        if (!Boolean(name))
            return;

        let existingTags = tagsStore.tags.filter(t => t.name.toLocaleLowerCase().trim() === name.toLocaleLowerCase().trim());
        let tag: Tag | undefined;

        if (existingTags.length === 0) {

            const result = await dispatch(createTag(name));
            if (result.meta.requestStatus === 'fulfilled')
                tag = result.payload as Tag;
        }


        tag = tag ?? tagsStore.tags.find(t => t.name.toLocaleLowerCase().trim() === name.toLocaleLowerCase().trim());

        if (companyTags.find(t => t._id === tag!._id))
            return;

        await companyApi.setTags(id!, [...companyTags, tag!]);
        setCompanyTags([...companyTags, tag!]);
        setNewTag('');
    };

    const onRemoveTag = async (tag: Tag) => {
        await companyApi.setTags(id!, companyTags.filter(t => t._id !== tag._id));
        setCompanyTags(companyTags.filter(t => t._id !== tag._id));
    }

    const onDeleteCompany = async () => {
        const result = await dispatch(deleteCompany(id!));
        if (result.meta.requestStatus === 'fulfilled')
            nav(organizationRoutes.customers);
    }

    const onSyncCompany = async () => {
        try {
            await dispatch(syncWithKvK(id!));
            setShowUpdateDialog(false);
            dispatch(showToast({
                message: 'Gegevens zijn succesvol gesynchroniseerd met de Kamer van Koophandel',
                type: 'success'
            }));
        } catch {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het synchroniseren met KvK',
                type: 'error'
            }));
        }
    }

    const handleChanged = async () => {
        if (company.phone === phone && company.billingAddress === facturenEmail && company.genericAddress === genericEmail)
            return;

        await dispatch(updateCompany({ id: id!, payload: { billingAddress: facturenEmail, genericAddress: genericEmail, phone } }));
    }

    return <Box>
        <Toolbar sx={{ mt: 2 }}>
            <Button sx={{ mr: 2 }} onClick={() => setShowUpdateDialog(true)}>
                <SyncTwoTone sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption">
                    Synchroniseren met KvK
                </Typography>
            </Button>
            <Button onClick={() => setShowDeleteDialog(true)}>
                <DeleteTwoTone color="error" sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption" color={'error'}>
                    Verwijderen
                </Typography>
            </Button>
        </Toolbar>
        <ConfirmDialog
            fetching={fetching}
            message='Weet je zeker dat je deze organisatie wilt verwijderen?'
            open={showDeleteDialog}
            onCancel={() => setShowDeleteDialog(false)}
            title='Verwijderen'
            onConfirm={onDeleteCompany}
        />
        <ConfirmDialog
            fetching={fetchingUpdate}
            message='Gegevens opnieuw ophalen vanuit KvK?'
            open={showUpdateDialog}
            onCancel={() => setShowUpdateDialog(false)}
            title='Synchroniseren'
            onConfirm={onSyncCompany}
        />

        <Box pt={2} maxWidth={900}>
            <Typography sx={{ my: 1, mb: 3 }} variant="h5">{company.naam}</Typography>
            <Row sx={{ mt: 2 }}>
                <ReadOnlyInput mr={1} flex={1} label="KvK" value={company.kvkNummer} />
                <ReadOnlyInput mr={1} flex={1} label="Vestigingsnummer" value={company.vestigingsNummer} />
                <ReadOnlyInput flex={1} label="BTW" value={company.btw} />
            </Row>

            <Row sx={{ mt: 2 }}>
                <ReadOnlyInput flex={1} label="Registratiedatum" value={humanReadableDate(company.materieleRegistratie)} />
            </Row>

            <Row sx={{ mt: 2 }}>
                <ReadOnlyInput mr={1} flex={1} label="Rechtsvorm" value={companyTypeString(company.rechtsvorm)} />
                <TextInput withCopy sx={{ flex: 2 }} label="Telefoon" value={phone} validator={requiredValidation} onChange={setPhone} onFocusLost={handleChanged} />
            </Row>

            <Row sx={{ mt: 2 }}>
                <TextInput withCopy sx={{ flex: 1, mr: 1 }} label="Algemeen email" validator={emailValidation} value={genericEmail} onChange={setGenericEmail} onFocusLost={handleChanged} />
                <TextInput withCopy sx={{ flex: 1 }} label="Facturen email" validator={emailValidation} value={facturenEmail} onChange={setFacturenEmail} onFocusLost={handleChanged} />
            </Row>

            <Row sx={{ my: 2 }}>
                <ReadOnlyInput flex={1} label="Adres" value={company.address.volledigAdres} />
            </Row>

            <AsyncAutocomplete
                options={tagsStore.tags}
                fetching={tagsStore.fetchingList === 'fetching'}
                getTitleFromOption={t => t.name}
                onChange={setNewTag}
                onSubmitted={() => {
                    onAddTag(newTag);
                    setNewTag('');
                }}
                onSelect={(tag) => {
                    if (!tag)
                        return;

                    onAddTag(tag.name);
                    setNewTag('');
                }}
                title='Tags'
            />
            <Box>{
                companyTags.map(tag => <Chip key={tag._id} clickable onClick={() => onRemoveTag(tag)} label={tag.name} />)
            }</Box>

        </Box>

        <Box display={'flex'}>
            <Box flex={1} pr={3}>
                <Typography sx={{ mt: 4 }} variant="h6">Handelsnamen</Typography>
                <HandelsnamenTable handelsnamen={company.handelsnamen} />
            </Box>
        </Box>



    </Box >
}
