
import { Address } from "../types/company/address";
import { Company } from "../types/company/company";
import { containsIgnoreCase } from "../utils/strings";

export const filterByName = (company: Company, name: string): boolean => {
    if (!company) return false;

    return containsIgnoreCase(company.naam, name);
}

export const filterByKvk = (company: Company, kvk: string): boolean => {
    if (!company) return false;

    return containsIgnoreCase(company.kvkNummer, kvk);
}

export const filterByAddress = (address: Address, search: string): boolean => {
    if (!address) return false;

    const { land, plaats, postcode, straatnaam, type, volledigAdres } = address;
    return (
        containsIgnoreCase(land, search) ||
        containsIgnoreCase(plaats, search) ||
        containsIgnoreCase(postcode, search) ||
        containsIgnoreCase(straatnaam, search) ||
        containsIgnoreCase(type, search) ||
        containsIgnoreCase(volledigAdres, search)
    );
}

export const filterByPhone = (company: Company, phone: string): boolean => {
    if (!company) return false;
    return containsIgnoreCase(company.phone, phone);
}

export const filterByBillingAddress = (company: Company, email: string): boolean => {
    return containsIgnoreCase(company.billingAddress, email);
}

export const filterBySearch = (companies: Company[], search: string): Company[] => {
    return companies.filter(company =>
        filterByName(company, search) ||
        filterByKvk(company, search) ||
        filterByAddress(company.address, search) ||
        filterByPhone(company, search) ||
        filterByBillingAddress(company, search)
    );
}