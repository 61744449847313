import { EmailTemplateFor } from "../types/company/emailTemplates";

const translationsDict = {
    [EmailTemplateFor.CREDENTIALS]: "Inloggegevens",
    [EmailTemplateFor.INHUUR_COMPANY_INVITE]: "Inhuur (Nieuw)",
    [EmailTemplateFor.INHUUR_INVITE]: "Inhuur (Bestaand)",
    [EmailTemplateFor.PASSWORD_RESET]: "Wachtwoord Reset",
    [EmailTemplateFor.TAO_INVITE]: "TAO",
    [EmailTemplateFor.ZZP_Invite]: "ZZP",
    [EmailTemplateFor.VACANCY_LINK_SHARE]: "Vacature Publicatie Link"
}

export const getTemplateForFriendlyName = (
    state: EmailTemplateFor
) => translationsDict[state];