import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { EmailNotificationPayload, EmailNotificationSetting } from "../../types/recruiter/notification";

class RecruiterNotificationsApi extends BaseApi {
    constructor() { super(apiRoutes.recruiter + "/notifications"); }

    public list = () => this.fetchJson<EmailNotificationSetting[]>();
    public create = (payload: EmailNotificationPayload) => this.fetchJson<EmailNotificationSetting>("", "POST", payload);
    public delete = (id: string) => this.fetch(id, "DELETE");
}

const api = new RecruiterNotificationsApi();
export default api;