export interface Assignment {
    hoursPerWeek: number;
    startDate: number;
    endDate: number;
    role: string;
    description: string;
    location: string;
    remarks: string;
    agreementDocumentId: string;
    expectedOutcome: string;
    exlusions: string;
    noticePeriodInDays: number;
}

export const defaultAssignment = (): Assignment => ({
    hoursPerWeek: -1,
    startDate: Date.now(),
    endDate: Date.now(),
    role: '',
    description: '',
    location: '',
    remarks: '',
    agreementDocumentId: '',
    exlusions: '',
    expectedOutcome: '',
    noticePeriodInDays: 30
})