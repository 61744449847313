import { VacancyRelationState } from "../types/recruiter/relations";
const opacity = 0.3;

const translationsDict = {
    [VacancyRelationState.RecruiterApplied]: "Handmatig Gekoppeld",
    [VacancyRelationState.AppliedByForm]: "Via formulier",
    [VacancyRelationState.Accepted]: "Geaccepteerd",
    [VacancyRelationState.Rejected]: "Afgewezen",
    [VacancyRelationState.Hired]: "Aangenomen",
    [VacancyRelationState.Interviewed]: "Geïnterviewd",
    [VacancyRelationState.InvitedForInterview]: "Uitgenodigd voor interview",
    [VacancyRelationState.ToBeInterviewed]: "Moet geïnterviewd worden",
    [VacancyRelationState.WaitingForDocuments]: "Wacht op documenten"
}

const colorsDict = {
    [VacancyRelationState.Accepted]: `rgba(46, 204, 113, ${opacity})`,
    [VacancyRelationState.AppliedByForm]: `rgba(241, 196, 15, ${opacity})`,
    [VacancyRelationState.Hired]: `rgba(52, 73, 94, ${opacity})`,
    [VacancyRelationState.Interviewed]: `rgba(155, 89, 182, ${opacity})`,
    [VacancyRelationState.InvitedForInterview]: `rgba(22, 160, 133, ${opacity})`,
    [VacancyRelationState.RecruiterApplied]: `rgba(127, 140, 141, ${opacity})`,
    [VacancyRelationState.Rejected]: `rgba(192, 57, 43, ${opacity})`,
    [VacancyRelationState.ToBeInterviewed]: `rgba(243, 156, 18, ${opacity})`,
    [VacancyRelationState.WaitingForDocuments]: `rgba(52, 152, 219, ${opacity})`
}

export const getRelationStatusFriendlyName = (
    state: VacancyRelationState
) => translationsDict[state];

export const getRelationStatusColor = (
    state: VacancyRelationState
) => colorsDict[state];

