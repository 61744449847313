
export interface Address {
    type: string;
    indAfgeschermd: string;
    volledigAdres: string;
    straatnaam: string;
    huisnummer?: number;
    postcode: string;
    plaats: string;
    land: string;
    postbusnummer?: number;
}


export const EmptyAddress = (): Address => ({
    type: '',
    indAfgeschermd: '',
    volledigAdres: '',
    straatnaam: '',
    huisnummer: 0,
    postcode: '',
    plaats: '',
    land: '',
    postbusnummer: 0
});