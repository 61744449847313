import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { primaryLight } from '../theme';

export function AnalysisReportDialog(
    {
        analysis, conclusion, fetching, onClose, open, title
    }: {
        open: boolean;
        title: string;
        analysis: string;
        conclusion: string;
        fetching?: boolean;
        onClose: () => void;
    }
) {
    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent sx={{ minWidth: 400 }}>

                <Box display={'flex'}>
                    {analysis.length > 0 && <Box flex={1} p={2}>
                        <Typography fontWeight={'bold'} variant='h6' mb={2}>Analyse</Typography>
                        <Typography sx={{ whiteSpace: 'pre-line' }}>
                            {analysis}
                        </Typography>
                    </Box>}
                    {conclusion.length > 0 && <Box flex={1} p={2} sx={{ backgroundColor: primaryLight }}>
                        <Typography fontWeight={'bold'} variant='h6' sx={{ mb: 2 }}>Conclusie</Typography>
                        <Typography sx={{ whiteSpace: 'pre-line' }}>
                            {conclusion}
                        </Typography>
                    </Box>}
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={fetching} onClick={onClose} autoFocus>
                    Sluiten
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}