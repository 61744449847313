import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { SearchTwoTone } from "@mui/icons-material";
import { filterBySearch } from "../../../../filters/vacatureFilters";
import { recruiterRoutes } from "../../../../router/paths";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { vacanciesSelector, createVacancyRelation, deleteVacancyRelation } from "../../../../store/slices/vacanciesSlice";
import { VacancyRelation } from "../../../../types/recruiter/relations";
import { Vacancy } from "../../../../types/recruiter/vacancy";
import { TextInput } from "../../../molecules";
import { ApplyVacancyTable } from "../../../molecules/tables/recruiter/ApplyVacancy";
import { MatchScore } from "../../../../types/recruiter/matchScore";
import api from "../../../../api/recruiter/relations";

export function ApplyVacancy({
    professionalId
}: {
    professionalId: string;
}) {
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState<string>("");
    const [scores, setScores] = useState<MatchScore[]>([]);

    const vacanciesState = useAppSelector(vacanciesSelector);

    const updateScores = useCallback(async () => {

        const scores = await api.getProfessionalScores(professionalId);
        setScores(scores);

        if (scores.length < vacanciesState.vacancies.length)
            setTimeout(updateScores, 5000);

    }, [professionalId, vacanciesState.vacancies.length]);

    useEffect(() => {
        updateScores();
    }, [professionalId, updateScores]);

    const addRelation = (vacancy: Vacancy) => {
        dispatch(createVacancyRelation({
            professionalId: professionalId!,
            vacancyId: vacancy._id,
            targetId: vacancy._id
        }));
    };

    const removeRelation = (relation: VacancyRelation) => {
        dispatch(deleteVacancyRelation({ relation, targetId: relation.vacancyId }));
    };

    return <Box>
        <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2 }} value={search} onChange={v => setSearch(v as string)} />
        <ApplyVacancyTable
            scores={scores}
            processing={vacanciesState.fetchingProfessionalRelations}
            vacancies={filterBySearch(vacanciesState.vacancies, search)}
            relations={vacanciesState.relations.filter(x => x.professionalId === professionalId)}
            onApply={addRelation}
            onRemove={removeRelation}
            onVacancyClick={(vacancy) => nav(recruiterRoutes.vacatures.details(vacancy._id))}
        />
    </Box>
}