import { FC } from "react";
import { Row } from "../../molecules/Row";
import { Box, Checkbox } from "@mui/material";
import { Assignment, Contracts } from "../../../types/contracts";
import { DateInput, IconLabel, NumericSelection, TextArea, TextInput } from "../../molecules";
import { BrowseGalleryTwoTone, DoneTwoTone, EngineeringTwoTone, FmdBadTwoTone, HistoryEduTwoTone, LocationOnTwoTone, MessageTwoTone, UpdateTwoTone } from "@mui/icons-material";
import { primaryLighter } from "../../theme";

export const ContractAssignmentForm: FC<{ value: Assignment, cao?: string, kind: Contracts }> = ({ value, cao, kind }) => {
    const withCao = !!cao;

    return <Box sx={{ minWidth: 600, background: primaryLighter, pl: 2, py: 2, borderRadius: 1 }}>
        {
            kind === Contracts.Tao &&
            <Row sx={{ mb: 2 }} alignItems={'center'}>
                <Box sx={{ width: '100%' }}>
                    <IconLabel label="CAO" icon={HistoryEduTwoTone} />
                    <Row>
                        <Checkbox sx={{ p: .5 }} checked={withCao} />
                        <TextInput
                            sx={{ flex: 1 }}
                            value={cao}
                            disabled
                        />
                    </Row>
                </Box>
                <Box sx={{ width: 16 }} />
            </Row>
        }

        <Row sx={{ mb: 2, flexWrap: 'wrap' }}>
            <TextInput
                icon={EngineeringTwoTone}
                sx={{ flex: 1 }}
                label="Functie m.b.t. de opdracht"
                value={value.role}
                disabled
            />
            <Box sx={{ width: 16 }} />
            <TextInput
                icon={LocationOnTwoTone}
                sx={{ flex: 1 }}
                label="Plaats"
                value={value.location}
                disabled
            />
            <Box sx={{ width: 16 }} />
        </Row>

        <Row sx={{ mb: 2, flexWrap: 'wrap' }}>
            <TextArea
                sx={{ flex: 1 }}
                inputSx={{ minHeight: 60 }}
                icon={MessageTwoTone}
                label="Omschrijving van werkzaamheden"
                value={value.description}
            />
            <Box sx={{ width: 16 }} />
            <TextArea
                sx={{ flex: 1 }}
                inputSx={{ minHeight: 60 }}
                label="Beoogde resultaat"
                icon={DoneTwoTone}
                value={value.expectedOutcome}
            />
            <Box sx={{ width: 16 }} />
        </Row>

        <Row sx={{ mb: 2, flexWrap: 'wrap' }}>
            <Box sx={{ mt: 2 }}>
                <NumericSelection required icon={BrowseGalleryTwoTone} label="Uur per week"
                    onChange={() => { }}
                    value={value.hoursPerWeek}
                    values={[8, 16, 24, 32, 36, 40]}
                    customOptions={[
                        { label: 'nader overeen te komen', value: -1, width: 200 }
                    ]}
                />
            </Box>

            <Box sx={{ width: 16 }} />

            <DateInput
                sx={{ flex: 1, mt: 2, minWidth: 260 }}
                label="Startdatum"
                icon={"available"}
                value={new Date(value.startDate)}
                onChange={() => { }}
            />
            <Box sx={{ width: 16 }} />

            <DateInput
                sx={{ flex: 1, mt: 2, minWidth: 260 }}
                label="Einddatum"
                icon="busy"
                value={new Date(value.endDate)}
                onChange={() => { }}
            />
            <Box sx={{ width: 16 }} />
        </Row>

        <Row sx={{ flexWrap: 'wrap' }}>
            <TextArea
                sx={{ flex: 1, mb: 2 }}
                inputSx={{ minHeight: 60 }}
                icon={FmdBadTwoTone}
                label="Bijzonderheden"
                value={value.remarks}
            />
            <Box sx={{ width: 16 }} />
        </Row>

        <Row sx={{ mb: 2, flexWrap: 'wrap' }}>
            <NumericSelection required icon={UpdateTwoTone} label="Opzegtermijn in dagen"
                onChange={() => { }}
                value={value.noticePeriodInDays}
                values={[7, 14, 28, 30, 60, 90]}
            />
            <Box sx={{ width: 16 }} />
        </Row>
    </Box>
}