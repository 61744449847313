import { listRecruiterNotifications } from "./slices/notificationsSlice";
import { listProfessionals } from "./slices/professionalsSlice";
import { listCompanies } from "./slices/companiesSlice";
import { listVacanciesRelations, listVacancies, listVacanciesSettings } from "./slices/vacanciesSlice";
import { listPersons } from "./slices/personsSlice";
import { listTags } from "./slices/tagsSlice";
import { listContracts } from "./slices/contractsSlice";

export const refreshRecruiterModuleData = async (
    dispatch: (action: any) => Promise<void>
) => {
    await Promise.all([
        dispatch(listProfessionals()),
        dispatch(listVacancies())
    ]);

    dispatch(listVacanciesRelations());
    dispatch(listVacanciesSettings());
}

export const hydrateRecruiterModule = async (
    dispatch: (action: any) => void) => {

    console.log("===== Hydrating Recruiter Module =====");

    dispatch(listRecruiterNotifications());

    const promises = [];
    promises.push(dispatch(listProfessionals()));
    promises.push(dispatch(listVacancies()));

    await Promise.all(promises);

    dispatch(listVacanciesRelations());
    dispatch(listVacanciesSettings());
}

export const hydrateOrgModule = async (
    dispatch: (action: any) => void) => {
    console.log("===== Hydrating Organization Module =====");
    dispatch(listCompanies());
    dispatch(listPersons());
    dispatch(listTags());
}

export const hydrateContractsModule = async (
    dispatch: (action: any) => void) => {
    console.log("===== Hydrating Contracts Module =====");
    dispatch(listContracts());
}

