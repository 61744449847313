import { BaseContract, InhuurContract, TaoContract, ZzpContract } from "../../types/contracts";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class ContractsApi extends BaseApi {
    constructor() { super(apiRoutes.contracts + "/contracten"); }

    public list = async () => await this.fetchJson<BaseContract[]>();
    public get = async (id: string) => await this.fetchJson<BaseContract>(id);
    public create = async (contract: BaseContract): Promise<ZzpContract | TaoContract | InhuurContract> => await this.fetchJson(undefined, 'POST', contract);
}

const contractsApi = new ContractsApi();
export default contractsApi;