import { Box } from "@mui/material";
import { Row } from "../../molecules/Row";
import { Gender, Person } from "../../../types/company/person";
import { FormContainer, TextInput, IconLabel, DateInput } from "../../molecules";
import { SelectMolecule } from "../../molecules/MultiSelect";
import { requiredValidation } from "../../validations";
import { emailValidation } from "../../validations/email";

export function PersonDetailsForm(props: {
    person: Person;
    showBsn?: boolean;
    showDateOfBirth?: boolean;
    showFunction?: boolean;
    showNationality?: boolean;
    onFocusLost?: () => void;
    onChange: (person: Person) => void;
}) {
    const { person, onChange } = props;

    return <FormContainer>
        {props.showBsn &&
            <Row sx={{ mb: 2 }}>
                <TextInput onFocusLost={props.onFocusLost} sx={{ flex: 1 }} value={(person).bsn} label="Burgerservicenummer (BSN)" onChange={(bsn) => onChange({ ...person, bsn: bsn })} />
            </Row>
        }
        <TextInput onFocusLost={props.onFocusLost} withCopy label="Email" value={person.email} required validator={emailValidation} sx={{ flex: 1, mb: 2 }} onChange={(email) => onChange({ ...person, email })} />
        <Row sx={{ mb: 2 }}>
            <TextInput onFocusLost={props.onFocusLost} value={person.firstName} required validator={requiredValidation} label="Voornaam" sx={{ flex: 3, pr: 1 }} onChange={(firstName) => onChange({ ...person, firstName })} />
            <TextInput value={person.lastName} required validator={requiredValidation} label="Achternaam" sx={{ flex: 3 }} onChange={(lastName) => onChange({ ...person, lastName })}
                onFocusLost={() => {
                    if (
                        (person.initials === undefined || person.initials === "") &&
                        (person.firstName !== undefined && person.firstName.length > 0) &&
                        (person.lastName !== undefined && person.lastName.length > 0)
                    )
                        onChange({ ...person, initials: `${person.firstName[0].toUpperCase()}.${person.lastName[0].toUpperCase()}.` })

                    if (props.onFocusLost)
                        props.onFocusLost();
                }}
            />
        </Row>
        <Row>
            <TextInput onFocusLost={props.onFocusLost} value={person.initials} required validator={requiredValidation} label="Initialen" sx={{ flex: 1 }} onChange={(initialen) => onChange({ ...person, initials: initialen })} />
            {
                props.showDateOfBirth &&
                <DateInput sx={{ flex: 1, pl: 1 }}
                    label="Geboortedatum"
                    onChange={(v) => onChange({ ...person, dateOfBirth: v.toISOString() })}
                    value={(person).dateOfBirth && Boolean((person).dateOfBirth) ? new Date((person).dateOfBirth) : undefined} />
            }
            {
                props.showFunction &&
                <TextInput onFocusLost={props.onFocusLost} value={(person).function} label="Functie" sx={{ flex: 1, pl: 1 }} onChange={(functie) => onChange({ ...person, function: functie })} />
            }
            <Box sx={{ pl: 1 }}>
                <IconLabel label="Geslacht" />
                <SelectMolecule selected={[person.gender]} items={[
                    { label: 'Man', value: Gender.MALE },
                    { label: 'Vrouw', value: Gender.FEMALE }
                ]}
                    onClick={async gender => {
                        onChange({ ...person, gender });
                    }}
                />
            </Box>
        </Row>

        <Row sx={{ mt: 2 }}>
            <TextInput onFocusLost={props.onFocusLost} withCopy sx={{ flex: 2 }} value={person.phoneNumber} label="Telefoon" onChange={(phoneNumber) => onChange({ ...person, phoneNumber })} />
            {props.showNationality && <TextInput onFocusLost={props.onFocusLost} value={(person).nationality} label="Nationaliteit" sx={{ flex: 2, pl: 1 }}
                onChange={(nationality) => onChange({ ...person, nationality })} />}
        </Row>
    </FormContainer>;
}
