import { Company } from "../../../../types/company/company";
import { Membership } from "../../../../types/company/membership";
import { companyTypeString } from "../../../../utils/companyType";
import { getMembershipFriendlyName } from "../../../../utils/membershipRoles";
import { ColumnProps, Table } from "../Table";
import { ContextMenuMore } from "../../ContextMenu";
import { Link } from "../../Link";
import { organizationRoutes } from "../../../../router/paths";

type RowProps = { data: Company }

export function PersonMembershipsTable(props: {
    companies: Company[];
    memberships: Membership[];
    onRemoveMembership: (membership: Membership) => void;
    onEditPermissions: (membership: Membership) => void;
}) {
    const getMembership = (company: Company) => props.memberships.find((m) => m.companyId === company._id);

    const colDefs: ColumnProps<Company>[] = [
        {
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: "Verwijderen uit bedrijf",
                    onClick: () => props.onRemoveMembership(getMembership(row.data)!),
                    color: "error"
                },
                {
                    label: "Lidmaatschap Bewerken",
                    onClick: () => props.onEditPermissions(getMembership(row.data)!),
                    color: "info"
                }
            ]} />,
            width: 70
        },
        {
            headerName: "Naam",
            cellRenderer: (row) => <Link path={organizationRoutes.details(row.data._id)}>{row.data.naam}</Link>
        },
        {
            field: "kvkNummer",
            headerName: "KvK",
            width: 150
        },
        {
            headerName: "Bedrijfsvorm",
            valueGetter: (row) => companyTypeString(row.data.rechtsvorm),
            width: 150
        },
        {
            headerName: "Rollen",
            valueGetter: (row: RowProps) => getMembership(row.data)?.roles.map(getMembershipFriendlyName).join(", "),
            flex: 1
        },

    ];

    return <Table id="PersonMemberships" slim columns={colDefs} data={props.companies} />
}

