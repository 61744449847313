import { daysFromNow } from "../../utils/dateUtils";

export interface VacancyDetails {
    //Vacancy
    company: string;
    title: string;
    branche: string;
    description: string;
    location: string;
    hourPrice: number;
    hoursPerWeek: number;
    closureDateTime: string;
    startDate: string;
    endDate: string;
    reference?: string;
    requiredSkills: string[];
}

export interface Vacancy extends VacancyDetails {
    _id: string;
}

export enum PublishedState {
    Draft = 0,
    Published = 1,
    Unpublished = 2,
    Pauzed = 3
}

export interface PublishSettings {
    _id: string;
    published: PublishedState;
    code: string;
}

export const emptyVacancy: Vacancy = {
    branche: "",
    description: "",
    company: "",
    title: "",
    location: "",
    closureDateTime: daysFromNow(7).toISOString(),
    endDate: daysFromNow(7).toISOString(),
    startDate: daysFromNow(1).toISOString(),
    hourPrice: 0,
    hoursPerWeek: 40,
    requiredSkills: [],
    _id: ""
}