import { Tag } from "./Tag";

// Enum corresponding to the C# enum CompanyGroupType
export enum CompanyGroupType {
    Static = 1,
    Smart = 2
}

// Interface corresponding to the C# interface ICompanyGroup
export interface CompanyGroup {
    _id: string;
    name: string;
    type: CompanyGroupType;
}

// Class corresponding to the C# class StaticCompanyGroup
export interface StaticCompanyGroup extends CompanyGroup {
    companies: string[];
}

// Class corresponding to the C# class SmartCompanyGroup
export interface SmartCompanyGroup extends CompanyGroup {
    tags: Tag[];
}