import { ProfessionalDetails } from "../../types/recruiter/professional";

export const professionalValidation = (professional: ProfessionalDetails): string | null => {
    if (!Boolean(professional.name))
        return 'Naam is verplicht';

    if (!Boolean(professional.title))
        return 'Functie is verplicht';

    if (!Boolean(professional.email))
        return 'Email is verplicht';

    return null;
}