import { Box, Button, SvgIconTypeMap, SxProps, Theme, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useRef, useState } from "react";
import { IconLabel } from "../IconLabel";
import { LoadingButton } from "@mui/lab";
import { primaryLight } from "../../theme";
import { OverridableComponent } from "@mui/material/OverridableComponent";

const Container = styled(Box)({
    background: '#eee',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    borderBottom: `1px solid ${primaryLight}`,
});

const InputWrapper = styled(Box)({
    borderWidth: 0,
    borderLeft: `1px solid ${primaryLight}`,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: 8,
});

export function FileInput(props: {
    label?: string,
    value?: File,
    fileTypes?: string[],
    onChange: (value: File) => void,
    sx?: SxProps<Theme>;
    tooltip?: string;
    required?: boolean;
    icon?: any;
}) {
    const { label } = props;
    const ref = useRef<HTMLInputElement>(null);

    return <Box sx={props.sx}>
        {label && <IconLabel label={label} icon={props.icon} tooltip={props.tooltip} required={props.required} />}
        <Container>
            <Button color="secondary" variant="text" onClick={() => ref.current?.click()}>
                <Typography fontWeight={'bold'} variant="caption" color={'primary'}>Kies bestand</Typography>
            </Button>
            <InputWrapper onClick={() => ref.current?.click()}>
                <Typography variant="caption">{
                    props.value?.name || 'Geen bestand gekozen'
                }</Typography>

            </InputWrapper>
        </Container>

        <input
            style={{ display: 'none' }}
            type="file"
            ref={ref}
            accept={props.fileTypes?.map(f => `.${f}`).join(',')}
            onChange={e => {
                const file = e.target.files?.[0];
                if (!file) return;

                props.onChange(file);
            }}
        />
    </Box>
}

export function ButtonInput(props: {
    loading?: boolean,
    value?: File,
    placeholder?: string,
    fileTypes?: string[],
    onChange: (value: File) => void,
    sx?: SxProps<Theme>;
    tooltip?: string;
    required?: boolean;
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    };
    variant?: 'text' | 'contained' | 'outlined';
}) {
    const ref = useRef<HTMLInputElement>(null);
    const [isDragOver, setIsDragOver] = useState(false);

    return <>
        <LoadingButton
            loading={props.loading}
            sx={{
                ...props.sx,
                ...(isDragOver && { backgroundColor: 'rgba(0, 0, 0, 0.1)' }),
            }}
            color="secondary"
            variant={props.variant ?? "contained"}
            onClick={() => ref.current?.click()}
            onDragEnter={(e) => {
                e.preventDefault();
                setIsDragOver(true);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                setIsDragOver(true);
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                setIsDragOver(false);
            }}
            onDrop={(e) => {
                e.preventDefault();
                setIsDragOver(false);

                const file = e.dataTransfer.files[0];

                if (!file) {
                    alert('Geen bestand gevonden');
                    return;
                }

                const allowedExtensions = props.fileTypes?.map(f => f.toLowerCase());
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                if (allowedExtensions && fileExtension && !allowedExtensions.includes(fileExtension))
                    return;

                props.onChange(file);
            }}
        >
            {
                props.icon && <props.icon sx={{ mr: 1, fontSize: 20 }} />
            }
            <Typography variant="caption">
                {props.value?.name || props.placeholder || 'Geen bestand gekozen'}
            </Typography>
        </LoadingButton>

        <input
            style={{ display: 'none' }}
            type="file"
            ref={ref}
            accept={props.fileTypes?.map(f => `.${f}`).join(',')}
            onChange={e => {
                const file = e.target.files?.[0];
                if (!file) return;

                props.onChange(file);
            }}
        />
    </>
}