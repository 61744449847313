import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Membership, MembershipRole } from "../../../types/company/membership";
import { Company } from "../../../types/company/company";

import { PersonMembershipsTable } from "../../molecules/tables/person/Memberships";
import { BusinessTwoTone } from "@mui/icons-material";
import { MembershipsDialog, ModifyMembershipDialog, Toolbar } from "../../molecules";
import { useAppSelector } from "../../../store/hooks";
import { companiesSelector } from "../../../store/slices/companiesSlice";
import { Permissions } from "../../../types/user";
import { MembershipsScope } from "../../molecules/MembershipsDialog";

import membershipsApi from "../../../api/organization/memberships";
import companyApi from "../../../api/organization/company";

export function PersonMembershipsOrganism() {
    const { id } = useParams();

    const [memberships, setMemberships] = useState<Membership[]>([]);
    const [modifyMembership, setModifyMembership] = useState<Membership>();
    const [memberOfCompanies, setCompanies] = useState<Company[]>([]);
    const [fetching, setFetching] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const allCompanies = useAppSelector(companiesSelector).companies;


    useEffect(() => {
        if (!id)
            return;

        setFetching(true);
        membershipsApi.listPersonMemberships(id)
            .then(setMemberships)
            .finally(() => setFetching(false));

    }, [id]);

    useEffect(() => {
        if (memberships.length === 0)
            return;

        setFetching(true);
        companyApi.list(memberships.map((m) => m.companyId))
            .then(setCompanies)
            .finally(() => setFetching(false));

    }, [memberships]);

    const onMembershipAdded = (entityId: string, roles: MembershipRole[], permissions: Permissions[]) => {
        membershipsApi.createMembership(entityId, id!, {
            roles,
            permissions
        })
            .then((membership) => setMemberships([...memberships, membership]))
            .catch(e => console.error(e))
            .finally(() => setShowDialog(false));
    }

    const onRemoveMembership = (membership: Membership) => {
        membershipsApi.deleteMembership(membership.companyId, membership._id)
            .then(() => {
                setMemberships(memberships.filter((m) => m._id !== membership._id));
                setCompanies(memberOfCompanies.filter((c) => c._id !== membership.companyId));
            })
            .catch(e => console.error(e));
    }

    const onUpdateMembership = (membershipId: string) => (roles: MembershipRole[], permissions: Permissions[]) => {
        membershipsApi.updateMembership(memberships.find(m => m._id === membershipId)!.companyId, membershipId, { roles, permissions })
            .then(() => {
                setMemberships(memberships.map((m) => m._id === membershipId ? { ...m, roles, permissions } : m));
                setModifyMembership(undefined);
            })
            .catch(e => console.error(e));
    }

    return <Box sx={{ pt: 1 }}>
        <MembershipsDialog
            scope={MembershipsScope.Company}
            entities={
                allCompanies.filter(c => !memberships.find(m => m.companyId === c._id))
                    .map((c) => ({ id: c._id, name: c.naam, email: c.billingAddress })
                    )}
            visible={showDialog}
            onDismiss={() => setShowDialog(false)}
            onCompleted={onMembershipAdded} />

        {modifyMembership && <ModifyMembershipDialog
            scope={MembershipsScope.Company}
            visible={modifyMembership !== undefined}
            onDismiss={() => setModifyMembership(undefined)}
            fetching={false}
            onCompleted={onUpdateMembership(modifyMembership!._id)}
            initialValues={modifyMembership!}
        />}

        <Toolbar>
            <Button onClick={() => setShowDialog(true)}>
                <BusinessTwoTone fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="caption">Bedrijf Koppelen</Typography>
            </Button>
        </Toolbar>
        {
            fetching && <CircularProgress size={30} />
        }
        {
            !fetching && <PersonMembershipsTable
                onEditPermissions={setModifyMembership}
                companies={memberOfCompanies}
                memberships={memberships}
                onRemoveMembership={onRemoveMembership}

            />
        }
    </Box>
}