import { HandymanTwoTone } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";
import { FormContainer, TagsInput } from "../../../molecules";


export function ProfessionalSkillsForm(props: {
    skills: string[];
    onChange: (skills: string[]) => void;
    sx?: SxProps<Theme>;
}) {
    const { skills, onChange } = props;
    const setSkills = (skills: string[]) => onChange(skills);

    return <FormContainer sx={props.sx}>
        <TagsInput
            label="Vaardigheden"
            tooltip="Tiep de vaardigheid in en druk op enter om toe te voegen. Klik op een vaardigheid om te verwijderen."
            tags={skills}
            icon={HandymanTwoTone}
            onTagClicked={(tag) => setSkills(skills.filter(s => s !== tag))}
            onTagAdded={(skill) => setSkills([...skills, skill])} />
    </FormContainer>;
}
