import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '..';
import { FetchingState } from '../../types/fetchingState';
import { showToast } from './toastSlice';
import { Company } from '../../types/company/company';
import api from '../../api/organization/company';
import { AddCompanyPayload, UpdateCompanyPayload } from '../../types/company/payloads';

export interface CompaniesState {
    deletesInProgress: string[];
    companies: Company[];
    fetchingUpdate: FetchingState,
    fetchingList: FetchingState;
    fetchingCreate: FetchingState
}

const intialState: CompaniesState = {
    companies: [],
    fetchingList: "init",
    fetchingCreate: "init",
    fetchingUpdate: "init",
    deletesInProgress: []
}

export const listCompanies = createAsyncThunk(
    'companies/list',
    async (_, thunkApi) => {
        try {
            const companies = await api.list();
            return companies;
        } catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const syncWithKvK = createAsyncThunk(
    'companies/syncKvK',
    async (args: string, thunkApi) => {
        try {
            const company = await api.syncKvK(args);
            return company;
        } catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const createCompany = createAsyncThunk(
    'companies/create',
    async (v: AddCompanyPayload, thunkApi) => {
        try {
            if (v.bankAccount && v.bankAccount.iBAN.length === 0)
                delete v.bankAccount;

            const company = await api.create(v);
            return company;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const updateCompany = createAsyncThunk(
    'companies/update',
    async (args: {
        id: string,
        payload: UpdateCompanyPayload
    }, thunkApi) => {
        try {
            const company = await api.update(args.id, args.payload);
            return company;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const deleteCompany = createAsyncThunk(
    'companies/delete',
    async (id: string, thunkApi) => {
        try {
            await api.delete(id);
            return id;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const companiesSlice = createSlice({
    name: 'companies',
    initialState: intialState,
    reducers: {},
    extraReducers: (builder) => {
        // List
        builder
            .addCase(listCompanies.pending, (state) => { state.fetchingList = "fetching"; })
            .addCase(listCompanies.rejected, (state) => { state.fetchingList = "error"; })
            .addCase(listCompanies.fulfilled, (state, action: PayloadAction<Company[]>) => {
                state.fetchingList = "completed";
                state.companies = action.payload;
            })

        // Create
        builder
            .addCase(createCompany.pending, (state) => { state.fetchingCreate = "fetching"; })
            .addCase(createCompany.rejected, (state) => { state.fetchingCreate = "error"; })
            .addCase(createCompany.fulfilled, (state, action: PayloadAction<Company>) => {
                state.companies = [action.payload, ...state.companies];
                state.fetchingCreate = "completed";
            })

        // Update
        builder
            .addCase(updateCompany.pending, (state) => { state.fetchingUpdate = "fetching"; })
            .addCase(updateCompany.rejected, (state) => { state.fetchingUpdate = "error"; })
            .addCase(updateCompany.fulfilled, (state, action: PayloadAction<Company>) => {
                state.companies = state.companies.map(n => n._id === action.payload._id ? action.payload : n);
                state.fetchingUpdate = "completed";
            })

        // Sync
        builder
            .addCase(syncWithKvK.pending, (state) => { state.fetchingUpdate = "fetching"; })
            .addCase(syncWithKvK.rejected, (state) => { state.fetchingUpdate = "error"; })
            .addCase(syncWithKvK.fulfilled, (state, action: PayloadAction<Company>) => {
                state.companies = state.companies.map(n => n._id === action.payload._id ? action.payload : n);
                state.fetchingUpdate = "completed";
            })

        // Delete
        builder
            .addCase(deleteCompany.pending, (state, action) => { state.deletesInProgress.push(action.meta.arg); })
            .addCase(deleteCompany.rejected, (state, action) => { state.deletesInProgress = state.deletesInProgress.filter(id => id !== action.meta.arg); })
            .addCase(deleteCompany.fulfilled, (state, action: PayloadAction<string>) => {
                state.deletesInProgress = state.deletesInProgress.filter(id => id !== action.payload);
                state.companies = state.companies.filter(n => n._id !== action.payload);
            })


    }
})

export const companiesSelector = (state: RootState) => state.companies;
export const companySelector = (state: RootState, id: string) => state.companies.companies.find(n => n._id === id);
export default companiesSlice.reducer