import { Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { primary } from "../theme";

export function TileMolecule(props: {
    icon: JSX.Element;
    title: string;
    height?: number;
    selected?: boolean;
    width?: number;
    description: string;
    onClick?: () => void;
    spacing: number;
}) {
    const [hovering, setHovering] = useState(false);
    const handleMouseEnter = () => setHovering(true);
    const handleMouseLeave = () => setHovering(false);

    return <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={props.onClick}>
        <Card sx={{ background: 'rgb(252,252,252)', mr: props.spacing, mt: props.spacing, cursor: 'pointer', border: props.selected ? `1px solid ${primary}` : undefined }} elevation={hovering ? 5 : 2}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: props.width ?? 250, height: props.height ?? 200 }}>
                {props.icon}
                <Typography color={'secondary'} fontWeight={'bold'} mb={2} mt={2}>{props.title}</Typography>
                <Typography textAlign={'center'} variant="subtitle2" color={'secondary'}>{props.description}</Typography>
            </CardContent>
        </Card>
    </div>
}
