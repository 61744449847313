import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { VacancyRelationState } from "../../types/recruiter/relations";
import { getRelationStatusColor, getRelationStatusFriendlyName } from "../../utils/relationStatus";
import { useState } from "react";

type Props = {
    state: VacancyRelationState;
    onChange?: (status: VacancyRelationState) => void;
}

export function RelationStatusButton(props: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => { setAnchorEl(null); };

    return <Box sx={{ background: getRelationStatusColor(props.state), display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: props.onChange && 'pointer' }}>
        <Box onClick={handleClick} sx={{ px: 1 }}>
            <Typography variant="overline">
                {
                    getRelationStatusFriendlyName(props.state)
                }
            </Typography>
        </Box>

        {props.onChange &&

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiList-root': {
                        py: 0
                    }
                }}
            >
                {
                    Object
                        .keys(VacancyRelationState)
                        .filter(key => !isNaN(parseInt(key)))
                        .map((status) => <MenuItem key={status} sx={{ background: getRelationStatusColor(parseInt(status)) }}
                            onClick={() => {
                                if (!props.onChange) return;

                                props.onChange(parseInt(status))
                                handleClose()
                            }}>
                            <Box sx={{ p: 1 }}>
                                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                    {
                                        getRelationStatusFriendlyName(parseInt(status))
                                    }
                                </Typography>
                            </Box>
                        </MenuItem>)
                }
            </Menu>
        }
    </Box>
}