import React, { FC } from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, DialogTitle, Container, Box } from "@mui/material";
import { TextInput } from "./inputs";
import { contractMergeFields, declarationMergeFields, endCustomerMergeFields, finesMergeFields, hirerMergeFields, professionalFinancialMergeFields, professionalMergeFields, supplierMergeFields, taoFinancialMergeFields } from "../../utils/contractMergeFields";
import { ContainedButton } from "./Buttons";
import { ConfirmationNumberTwoTone, ContentCopyTwoTone, DocumentScannerTwoTone, EmojiPeopleTwoTone, EuroSymbolTwoTone, GavelTwoTone, HandymanTwoTone, HistoryEduTwoTone, HomeTwoTone, SearchTwoTone, TransferWithinAStationTwoTone } from "@mui/icons-material";
import { RowCentered } from "./Row";

export function CardDialog(props: {
    title?: string;
    closeText?: string;
    visible?: boolean;
    children?: React.ReactNode;
    onDismiss?: () => void;
}) {
    return <Dialog
        sx={{ '& .MuiDialog-paper': { maxWidth: '100%' } }}
        maxWidth="lg"
        onKeyDown={(e) => {
            if (e.key !== 'Escape')
                return;

            props.onDismiss?.();
        }}
        open={Boolean(props.visible)}>
        {
            props.title && <DialogTitle>
                <Typography variant="h5" fontWeight={300} color="Secondary">{props.title}</Typography>
            </DialogTitle>
        }
        <DialogContent dividers>
            {
                props.children
            }
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={props.onDismiss}>
                {props.closeText ?? "Sluiten"}
            </Button>
        </DialogActions>
    </Dialog>
}

export const MergeFieldsDialog: FC<{
    visible: boolean;
    onDismiss: () => void;
}> = ({ visible, onDismiss }) => {
    const [search, setSearch] = React.useState('');
    const copyClipboard = (text: string) => () => {
        navigator.clipboard.writeText(text);
        onDismiss();
    }

    const MergeFieldBtn = (field: string) => <ContainedButton sx={{ mr: 1, mb: 1 }} key={field} onClick={copyClipboard(field)}>
        {field} <ContentCopyTwoTone fontSize="small" sx={{ ml: 1 }} />
    </ContainedButton>

    const filterMergeFields = (fields: string[]) => search.length > 0 ? fields.filter(f => f.toLowerCase().includes(search.toLowerCase())) : fields;

    return <CardDialog title={"Veld referenties"} closeText={"Sluiten"} visible={visible} onDismiss={onDismiss}>
        <Container maxWidth='md'>
            <Box sx={{ minWidth: 800 }}>
                <TextInput icon={SearchTwoTone} label="Zoeken" value={search} onChange={setSearch} />

                <RowCentered sx={{ mt: 2 }}>
                    <DocumentScannerTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Contract</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(contractMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <TransferWithinAStationTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Leverancier</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(supplierMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <EmojiPeopleTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Inlener</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(hirerMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <HomeTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Eindklant</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(endCustomerMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <HandymanTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Professional</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(professionalMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <HistoryEduTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">TAO</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(taoFinancialMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <EuroSymbolTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Leverancier Financieel</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(professionalFinancialMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <ConfirmationNumberTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Declaraties</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(declarationMergeFields).map(MergeFieldBtn)}

                <RowCentered sx={{ mt: 2 }}>
                    <GavelTwoTone sx={{ mr: 1 }} color="primary" />
                    <Typography color={'secondary'} variant="h6">Boetes</Typography>
                </RowCentered>
                <hr />
                {filterMergeFields(finesMergeFields).map(MergeFieldBtn)}

            </Box>
        </Container>
    </CardDialog>
}