import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { primary } from "../theme";

export function ProgressBar({ duration, width }: { duration: number, width?: number }) {
    const [progress, setProgress] = useState(0);
    const [start] = useState(Date.now());
    const [end] = useState(Date.now() + duration);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = Date.now();
            const progress = (now - start) / (end - start) * 100;
            setProgress(progress);

            if (progress >= 100)
                clearInterval(interval);

        }, 30);

        return () => clearInterval(interval);
    }, [start, end]);

    return <Box sx={{ width: width ?? '100%', height: '.5rem', backgroundColor: '#eee', borderRadius: '0.5rem' }}>
        <Box sx={{ width: `${progress}%`, height: '100%', backgroundColor: primary, borderRadius: '0.5rem' }} />
    </Box>
}