import { FC } from "react";
import { DotsLoader } from "../../molecules";
import { useAppSelector } from "../../../store/hooks";

import { useParams } from "react-router-dom";
import { contractsSelector } from "../../../store/slices/contractsSlice";
import { ContractFinancialForm } from "../../forms/contract";
import { Box, Typography } from "@mui/material";
import { companiesSelector } from "../../../store/slices/companiesSlice";

export const ContractFinancialOrganism: FC = () => {
    const { id } = useParams<{ id: string }>();
    const contracts = useAppSelector(contractsSelector).contracts;
    const companies = useAppSelector(companiesSelector).companies;
    const contract = contracts?.find(c => c.contractnumber === id);

    if (!contract)
        return <DotsLoader message={"Contract word geladen"} />;

    return <Box mt={2}>
        <Typography color="secondary" variant="h6" mb={2}>Financiele Afspraken</Typography>
        <ContractFinancialForm companies={companies} financial={contract.financial} />
    </Box>
}