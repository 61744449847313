import { FC } from "react";
import { Row } from "../../molecules/Row";
import { Box } from "@mui/material";
import { Assignment } from "../../../types/contracts";
import { DateInput, NumericSelection, TextArea, TextInput } from "../../molecules";
import { BrowseGalleryTwoTone, DoneTwoTone, EngineeringTwoTone, FmdBadTwoTone, LocationOnTwoTone, MessageTwoTone, UpdateTwoTone } from "@mui/icons-material";
import { primaryLighter } from "../../theme";

export const ContractAssignmentEditForm: FC<{
    value: Assignment,
    onChange: (value: Assignment) => void,
}> = ({ onChange, value }) => {
    return <Box sx={{ minWidth: 600, background: primaryLighter, pl: 4, py: 4, pr: 2, borderRadius: 2 }}>
        <Row sx={{ mb: 2, flexWrap: 'wrap' }}>
            <TextInput
                icon={EngineeringTwoTone}
                sx={{ flex: 1 }}
                label="Functie m.b.t. de opdracht"
                value={value.role}
                onChange={assignmentFunction => onChange({ ...value, role: assignmentFunction })}
            />
            <Box sx={{ width: 16 }} />
            <TextInput
                icon={LocationOnTwoTone}
                sx={{ flex: 1 }}
                label="Plaats"
                value={value.location}
                onChange={place => onChange({ ...value, location: place })}
            />
            <Box sx={{ width: 16 }} />
        </Row>

        <Row sx={{ mb: 2, flexWrap: 'wrap' }}>
            <TextArea
                sx={{ flex: 1 }}
                inputSx={{ minHeight: 60 }}
                icon={MessageTwoTone}
                label="Omschrijving van werkzaamheden"
                value={value.description}
                onChange={description => onChange({ ...value, description })}

            />
            <Box sx={{ width: 16 }} />
            <TextArea
                sx={{ flex: 1 }}
                inputSx={{ minHeight: 60 }}
                label="Beoogde resultaat"
                icon={DoneTwoTone}
                value={value.expectedOutcome}
                onChange={expectedResult => onChange({ ...value, expectedOutcome: expectedResult })}
            />
            <Box sx={{ width: 16 }} />
        </Row>

        <Row sx={{ mb: 2, flexWrap: 'wrap' }}>
            <Box sx={{ mt: 2 }}>
                <NumericSelection required icon={BrowseGalleryTwoTone} label="Uur per week"
                    onChange={
                        hoursPerWeek => onChange({
                            ...value,
                            hoursPerWeek
                        })}
                    value={value.hoursPerWeek}
                    values={[8, 16, 24, 32, 36, 40]}
                    customOptions={[
                        { label: 'nader overeen te komen', value: -1, width: 200 }
                    ]}
                />
            </Box>

            <Box sx={{ width: 16 }} />

            <DateInput
                sx={{ flex: 1, mt: 2, minWidth: 260 }}
                label="Startdatum"
                icon={"available"}
                value={new Date(value.startDate)}
                onChange={startDate => onChange({ ...value, startDate: startDate.getTime() })}
            />
            <Box sx={{ width: 16 }} />

            <DateInput
                sx={{ flex: 1, mt: 2, minWidth: 260 }}
                label="Einddatum"
                icon="busy"
                value={new Date(value.endDate)}
                onChange={endDate => onChange({ ...value, endDate: endDate.getTime() })}
            />
            <Box sx={{ width: 16 }} />
        </Row>

        <Row sx={{ flexWrap: 'wrap' }}>
            <TextArea
                sx={{ flex: 1, mb: 2 }}
                inputSx={{ minHeight: 60 }}
                icon={FmdBadTwoTone}
                label="Bijzonderheden"
                value={value.remarks}
                onChange={remarks => onChange({ ...value, remarks })}
            />
            <Box sx={{ width: 16 }} />
        </Row>

        <Row sx={{ flexWrap: 'wrap' }}>
            <NumericSelection required icon={UpdateTwoTone} label="Opzegtermijn in dagen"
                onChange={
                    noticePeriodInDays => onChange({
                        ...value,
                        noticePeriodInDays
                    })}
                value={value.noticePeriodInDays}
                values={[7, 14, 28, 30, 60, 90]}
            />
            <Box sx={{ width: 16 }} />
        </Row>
    </Box>
}