import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { CompanyGroup } from "../../types/company/companyGroup";

class CompanyApi extends BaseApi {
    constructor() { super(apiRoutes.organization + '/companygroups'); }

    public list = () => this.fetchJson<CompanyGroup[]>();
    public get = (id: string) => this.fetchJson<CompanyGroup>(id);
    public create = (companyGroup: CompanyGroup) => this.fetchJson<CompanyGroup>(undefined, 'POST', companyGroup);
    public update = (companyGroup: CompanyGroup) => this.fetchJson<CompanyGroup>(undefined, 'PUT', companyGroup);
    public delete = (id: string) => this.fetch(id, 'DELETE');
    public listMembers = (groupId: string) => this.fetchJson<{
        _id: string;
        naam: string;
        genericAddress: string;
        vestigingsNummer: string;
    }[]>(`${groupId}/companies`);
}

const api = new CompanyApi();
export default api;