import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { OrganizationForm } from "../../types/documents/Form";

class FormTemplatesApi extends BaseApi {
    constructor() { super(apiRoutes.documents + "/formtemplates"); }

    public list = () => this.fetchJson<OrganizationForm[]>();
    public get = (id: string) => this.fetchJson<OrganizationForm>(id);
    public create = (template: OrganizationForm) => this.fetchJson<OrganizationForm>('', "POST", template);
    public update = (template: OrganizationForm) => this.fetch('', "PUT", template);
    public delete = (id: string) => this.fetch(id, "DELETE");
}

const api = new FormTemplatesApi();
export default api;