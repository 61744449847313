import { FC } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { CompanyFinancial, Compensations, ContractFinancial } from "../../../types/contracts";
import { CompensationInput, DateInput, FeeReceiverInput, IconLabel, NumericSelection, SimpleRateInput, TextArea, TextInput } from "../../molecules";
import { AddCardTwoTone, AirplaneTicketTwoTone, BarChartTwoTone, BedTwoTone, CalendarMonthTwoTone, CrisisAlertTwoTone, CurrencyExchangeTwoTone, DirectionsBusTwoTone, DirectionsCarTwoTone, ElderlyTwoTone, EmojiPeopleTwoTone, EuroTwoTone, EventRepeatTwoTone, GavelTwoTone, HandshakeTwoTone, HandymanTwoTone, HealthAndSafetyTwoTone, HistoryEduTwoTone, HomeTwoTone, InfoTwoTone, Looks3TwoTone, Looks4TwoTone, SavingsTwoTone } from "@mui/icons-material";
import { primaryLighter } from "../../theme";
import { Row } from "../../molecules/Row";
import { SelectMolecule, TimesheetPeriodPicker } from "../../molecules/MultiSelect";
import { FincialTypes, TaoContractFase, TAOFinancial, TaoFinancialTypes, TaoPaymentTerm } from "../../../types/contracts/ContractFinancial";
import { compensationTypeString } from "../../../utils/compensations";
import { Company } from "../../../types/company/company";

export const ContractFinancialForm: FC<{
    financial: ContractFinancial,
    companies: Company[]
}> = ({ financial, companies }) => {
    return <Box>
        <Box display={'flex'} flexWrap={'wrap'}>
            <HirerFinancial financial={financial} />
            <Box width={16} />

            {financial.type !== FincialTypes.TAO && <SupplierFinancial financial={financial as CompanyFinancial} />}
            {financial.type === FincialTypes.TAO && <TaoFinancial financial={financial as TAOFinancial} />}
            <Box width={16} />
        </Box>

        <Box display={'flex'} flexWrap={'wrap'}>
            <FinesFinancialForm financial={financial} />
            <Box width={16} />

            <CompensationsFinancialForm organizations={companies} financial={financial} />
            <Box width={16} />
        </Box>
    </Box>
}


const HirerFinancial: FC<{ financial: ContractFinancial }> = ({ financial }) => {
    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 350, mb: 2 }}>
        <Row>
            <EmojiPeopleTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Inlener</Typography>
        </Row>

        <Box mb={2} />
        <IconLabel icon={EuroTwoTone} label="Tarief" />
        <SimpleRateInput value={financial.rateCustomer} />

        <Box mb={2} />
        <NumericSelection
            value={financial.paymentTermCustomer}
            icon={CurrencyExchangeTwoTone}
            values={[7, 14, 30, 60, 90]}
            label="Betaaltermijn"
        />

        <TextInput
            icon={SavingsTwoTone}
            sx={{ my: 2 }}
            label="Budget"
            type="double"
            value={financial.budget}
            disabled
        />


        <TimesheetPeriodPicker
            sx={{ mt: 2, width: '100%' }}
            label="Timesheet Periodiciteit"
            value={financial.timesheetPeriod}
            disabled
        />

        <TextInput
            sx={{ mt: 2 }}
            icon={HandshakeTwoTone}
            label="Afkoopbedrag incl BTW"
            value={financial.redemtionAmount.toString()}
            disabled
        />

        <TextArea
            icon={InfoTwoTone}
            sx={{ mt: 2 }}
            inputSx={{ minHeight: 60 }}
            label="Aanvullende bepalingen"
            value={financial.additionalProvisions}
        />
    </Box>
}

const SupplierFinancial: FC<{ financial: CompanyFinancial }> = ({ financial }) => {
    return <Box flex={1} p={2} sx={{ background: primaryLighter, minWidth: 350, mb: 2 }}>
        <Row>
            <HandymanTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Leverancier (Professional)</Typography>
        </Row>
        <Box mb={2} />
        <IconLabel icon={EuroTwoTone} label="Tarief" />
        <SimpleRateInput value={financial.rateProfessional} disabled />

        <Box mt={2} />
        <NumericSelection
            value={financial.paymentTermSupplier}
            icon={CurrencyExchangeTwoTone}
            values={[7, 14, 30, 60, 90]}
            label="Betaaltermijn"
        />


        <TextInput
            icon={CrisisAlertTwoTone}
            sx={{ mt: 2 }}
            label="Max. aansprakelijkheid"
            type="nubmer"
            disabled
            value={(financial as CompanyFinancial).maximumSum}
        />

        <TextInput
            sx={{ mt: 2 }}
            icon={HealthAndSafetyTwoTone}
            label="Max. dekking verzekering"
            value={(financial as CompanyFinancial).maximumCoverage}
            disabled
        />

    </Box>
}


const TaoFinancial: FC<{
    financial: TAOFinancial
}> = ({ financial }) => {
    return <Box flex={1} p={2} sx={{ background: primaryLighter, minWidth: 350, mb: 2 }}>
        <Row sx={{ mb: 2 }}>
            <HandymanTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">TAO (Professional)</Typography>
        </Row>

        <Box sx={{ width: '100%' }}>
            <IconLabel label="CAO" icon={HistoryEduTwoTone} />
            <TextInput
                sx={{ flex: 1 }}
                value={financial.cao}
                disabled
            />
        </Box>

        <Row sx={{ mt: 2 }}>
            <TextInput
                label="CAO Schaal"
                icon={Looks3TwoTone}
                sx={{ flex: 1 }}
                type="number"
                value={financial.caoScale}
                disabled
            />

            <TextInput
                label="CAO Trede"
                icon={Looks4TwoTone}
                sx={{ flex: 1, pl: 1 }}
                type="number"
                value={financial.caoScaleStep}
                disabled
            />

        </Row>

        <SelectMolecule
            sx={{ mt: 2, width: '100%' }}
            disabled
            selected={[financial.taoType]}
            items={[
                { label: 'All-In', value: TaoFinancialTypes.AllIn, flex: 1 },
                { label: 'Maandloon', value: TaoFinancialTypes.Montly, flex: 1 }
            ]}
        />

        {
            financial.taoType === TaoFinancialTypes.Montly &&
            <Row sx={{ mt: 2 }}>
                <TextInput
                    sx={{ flex: 1, pr: 1 }}
                    icon={EuroTwoTone}
                    type="double"
                    label="Kostprijs per uur"
                    value={financial.hourlyRate}
                    disabled
                />
                <TextInput
                    sx={{ flex: 1 }}
                    icon={EuroTwoTone}
                    type="double"
                    label="Bruto maandsalaris"
                    value={financial.brutoMonthlySalary}
                    disabled
                />
            </Row>
        }
        {
            financial.taoType === TaoFinancialTypes.AllIn && <Row sx={{ mt: 2 }}>
                <TextInput
                    sx={{ flex: 1, pr: 1 }}
                    icon={EuroTwoTone}
                    type="double"
                    label="Bruto uurloon"
                    value={financial.hourlyRate}
                    disabled
                />

                <TextInput
                    sx={{ flex: 1 }}
                    icon={EuroTwoTone}
                    type="double"
                    label="AllIn tarief minus verloningskosten"
                    value={financial.allInHourlyRate}
                    disabled
                />
            </Row>
        }


        <IconLabel
            sx={{ mt: 2 }}
            icon={CurrencyExchangeTwoTone}
            label="Betaaldag"
        />

        <Box sx={{ mb: 2 }}>
            <SelectMolecule
                sx={{ width: '100%' }}
                disabled
                selected={[financial.salaryDate.type]}
                items={[{
                    label: 'Specifieke dag na afloop vd maand',
                    value: TaoPaymentTerm.SpecifiedDay,
                    flex: 1
                }, {
                    label: '25e van huidige maand',
                    flex: 1,
                    value: TaoPaymentTerm.TwentyFifthOfMonth,
                }
                ]}
            />

            {financial.salaryDate.type === TaoPaymentTerm.SpecifiedDay && <NumericSelection
                sx={{ mt: 2 }}
                value={financial.salaryDate.day}
                values={[1, 14, 28]}
            />}
        </Box>

        <IconLabel label="Contractfase" icon={BarChartTwoTone} />
        <SelectMolecule
            sx={{ width: '100%' }}
            selected={[financial.contractFase]}
            disabled
            items={[
                { label: 'A', value: TaoContractFase.A, flex: 1 },
                { label: 'B', value: TaoContractFase.B, flex: 1 },
                { label: 'C', value: TaoContractFase.C, flex: 1 }
            ]}
        />

        <TextInput
            sx={{ mt: 2 }}
            icon={EuroTwoTone}
            type="double"
            label="Netto onkostenvergoeding"
            value={financial.netExpenseReimbursment}
            disabled
        />

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ flex: 1, pr: 1 }}
                icon={EuroTwoTone}
                type="double"
                label="Premies werkgever"
                value={financial.premiumsEmployer}
                disabled
            />

            <TextInput
                sx={{ flex: 1 }}
                icon={EuroTwoTone}
                type="double"
                label="Premies werknemer"
                value={financial.premiumsEmployee}
                disabled
            />
        </Row>

        <Checkbox
            title="Loonheffing toepassen"
            checked={financial.applyPayrollTax}
            disabled
        />

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ flex: 1, pr: 1 }}
                icon={AddCardTwoTone}
                type="double"
                label="Bijdrage overhead"
                value={financial.contributionOverhead}
                disabled
            />

            <TextInput
                sx={{ flex: 1 }}
                icon={HealthAndSafetyTwoTone}
                type="double"
                label="Bijdrage ZW"
                value={financial.contributionZW}
                disabled
            />
        </Row>

        <TextInput
            sx={{ mt: 2 }}
            icon={ElderlyTwoTone}
            label="Pensioen"
            value={financial.pension}
            disabled
        />

        <IconLabel
            sx={{ mt: 2 }}
            label="13e maand"
            icon={EuroTwoTone}
        />
        <SelectMolecule
            selected={[financial.percentage13thMonth]}
            disabled
            items={[
                { label: 'Geen', value: 0, flex: 1 },
                { label: '6,75%', value: 6.75, flex: 1 },
                { label: '8,33%', value: 8.33, flex: 1 },
                { label: '8,00%', value: 8.00, flex: 1 }
            ]}
        />

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ flex: 1, pr: 1 }}
                icon={CalendarMonthTwoTone}
                label="Vakantiedagen"
                type="double"
                value={financial.vacationDays}
                disabled
            />


            <TextInput
                sx={{ flex: 1 }}
                icon={BedTwoTone}
                label="ADV/ATV Dagen per jaar"
                type="double"
                value={financial.advDays}
                disabled
            />
        </Row>

        <Row>
            <Box sx={{ flex: 1 }}>
                <IconLabel
                    sx={{ mt: 2 }}
                    label="Vakantiegeld"
                    icon={AirplaneTicketTwoTone}
                />
                <Checkbox
                    title="Vakantiegeld"
                    checked={financial.hasHolidayPay}
                    disabled
                />
            </Box>

            <Box sx={{ flex: 1 }}>
                <IconLabel
                    sx={{ mt: 2 }}
                    label="Vervoer"
                    icon={DirectionsCarTwoTone}
                />
                <Checkbox
                    title="Bedrijfsauto"
                    checked={financial.companyVehicle}
                    disabled
                />
            </Box>

        </Row>
    </Box>
}

const FinesFinancialForm: FC<{ financial: ContractFinancial }> = ({ financial }) => {
    const getFineValue = (title: string) => {
        const fine = financial.fines.find(f => f.name === title);
        return fine ? fine.sum.toString() : '';
    }

    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 350, mb: 2 }}>
        <Row sx={{ mb: 2 }}>
            <InfoTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Boetes</Typography>
        </Row>

        <TextInput
            label="Boete 1: Direct opeisbaar"
            icon={GavelTwoTone}
            value={getFineValue("Boete 1: Direct opeisbaar")}
            disabled
        />
        <TextInput
            sx={{ mt: 2 }}
            label="Boete 2: Per dag"
            icon={EventRepeatTwoTone}
            value={getFineValue("Boete 2: Per dag")}
            disabled
        />
        <TextInput
            sx={{ mt: 2 }}
            label="Boete 3: Direct opeisbaar"
            icon={GavelTwoTone}
            value={getFineValue("Boete 3: Direct opeisbaar")}
            disabled
        />
        <TextInput
            sx={{ mt: 2 }}
            label="Boete 4: Per dag"
            icon={EventRepeatTwoTone}
            value={getFineValue("Boete 4: Per dag")}
            disabled
        />
    </Box>
}

const CompensationsFinancialForm: FC<{ financial: ContractFinancial, organizations: Company[] }> = ({ financial, organizations }) => {

    const findVergoedingByType = (type: Compensations) => {
        const result = financial.compensations.find(c => c.type === type);
        return result;
    }

    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: 350, mb: 2 }}>
        <Row sx={{ mb: 2 }}>
            <InfoTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Vergoedingen</Typography>
        </Row>

        <DateInput
            sx={{ my: 2 }}
            label="Einddatum declaraties"
            icon="default"
            value={new Date(financial.endDateDeclarations)}
            disabled
        />

        <FeeReceiverInput
            title="Kickback-fee"
            defaultItemName="Kickback"
            options={organizations.map(o => ({ label: o.naam, id: o._id }))}
            value={financial.contractFees.find(f => f.name === 'Kickback')}
            disabled
        />
        {
            [Compensations.KM, Compensations.OV, Compensations.THUISWERK].map(c => {
                const vergoeding = findVergoedingByType(c);
                const iconMap = {
                    [Compensations.KM]: DirectionsCarTwoTone,
                    [Compensations.OV]: DirectionsBusTwoTone,
                    [Compensations.THUISWERK]: HomeTwoTone
                }

                return <CompensationInput
                    key={c}
                    enabled={!!vergoeding}
                    title={compensationTypeString(c)}
                    purchaseValue={vergoeding?.purchasePrice || ''}
                    saleValue={vergoeding?.salePrice || ''}
                    icon={iconMap[c]}
                    withSaleValue={vergoeding?.salePrice !== undefined}
                    disabled={true}
                />
            })
        }
    </Box>
}