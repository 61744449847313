import { WorkExperience } from "../../../../types/recruiter/professional";
import { ColumnProps, Table } from "../Table";
import { Box, Link, Typography } from "@mui/material";
import moment from "moment";

export function ProfessionalExperienceTable(props: {
    experiences: WorkExperience[];
    onClick: (experience: WorkExperience) => void;
}) {
    const colDefs: ColumnProps<WorkExperience>[] = [
        {
            field: "title",
            headerName: "Titel",
            cellRenderer: (row) => <Link onClick={() => props.onClick(row.data)}>{row.data.title}</Link>,
            width: 270
        },
        {

            field: "company",
            headerName: "Bedrijf",
            width: 300,
        },
        {
            headerName: "Periode",
            cellRenderer: (row) => <Box display={'flex'} alignItems={'center'} height={40}>
                <Typography variant="caption">{(row.data.startDate && moment(row.data.startDate).format("MMM DD YYYY"))}</Typography>
                {
                    row.data.endDate && row.data.startDate && <Typography sx={{ mx: 2 }} variant="caption">-</Typography>
                }
                <Typography variant="caption">{(row.data.endDate && moment(row.data.endDate).format("MMM DD YYYY"))}</Typography>
            </Box>,
            valueGetter: (row) => row.data.startDate,
            width: 250,
        },
        {

            field: "location",
            headerName: "Locatie",
            flex: 1
        }
    ];

    return <Table id="Exps" columns={colDefs} data={props.experiences} />;
}

