import { defaultRevision, Revision } from "./Revision";
import { defaultPerson, Person } from "./Person";
import { defaultOrganisation, Organisation } from "./Organisation";
import { defaultInvoiceDetails, InvoiceDetails } from "./InvoiceDetails";
import { ContractFinancial, defaultCompanyFinancial, defaultTAOFinancial } from "./ContractFinancial";
import { defaultDivision, Division } from "./Division";
import { Assignment, defaultAssignment } from "./Assignment";

export enum Contracts {
    Zzp = 1,
    Tao = 2,
    Inhuur = 3
}

export interface BaseContract {
    _id: string;
    contractnumber: string;
    contractKind: Contracts;
    signedDate: number;
    creationDate: number;
    isArchived: boolean;
    hirer: Organisation;
    contactHirer: Person;
    supplier: Organisation;
    endCustomer: Organisation;
    professional: Person;
    authorizedSignatorySupplier: Person;
    authorizedSignatoryHirer: Person;
    timesheetApproversHirer: Person[];
    contractManager: string;
    recruiter: string;
    revision: Revision;
    invoiceDetails: InvoiceDetails;
    financial: ContractFinancial;
    assignment: Assignment;
    documents: Document[];
    division: Division;
}

export interface ZzpContract extends BaseContract {
    contractKind: Contracts.Zzp;
}

export interface TaoContract extends BaseContract {
    contractKind: Contracts.Tao;
}

export interface InhuurContract extends BaseContract {
    contractKind: Contracts.Inhuur;
}

export const defaultZzpContract = (): ZzpContract => ({
    _id: '',
    documents: [],
    recruiter: '',
    contractnumber: '',
    contractKind: Contracts.Zzp,
    signedDate: 0,
    creationDate: 0,
    isArchived: false,
    hirer: defaultOrganisation(),
    supplier: defaultOrganisation(),
    endCustomer: defaultOrganisation(),
    professional: defaultPerson(),
    authorizedSignatorySupplier: defaultPerson(),
    assignment: defaultAssignment(),
    authorizedSignatoryHirer: defaultPerson(),
    contractManager: '',
    revision: defaultRevision(),
    division: defaultDivision(),
    contactHirer: defaultPerson(),
    invoiceDetails: defaultInvoiceDetails(),
    financial: defaultCompanyFinancial(),
    timesheetApproversHirer: [defaultPerson()]
});

export const defaultTaoContract = (): TaoContract => ({
    _id: '',
    contractnumber: '',
    contractKind: Contracts.Tao,
    signedDate: 0,
    creationDate: 0,
    isArchived: false,
    hirer: defaultOrganisation(),
    supplier: defaultOrganisation(),
    endCustomer: defaultOrganisation(),
    professional: defaultPerson(),
    contactHirer: defaultPerson(),
    timesheetApproversHirer: [defaultPerson()],
    authorizedSignatorySupplier: defaultPerson(),
    authorizedSignatoryHirer: defaultPerson(),
    contractManager: '',
    documents: [],
    recruiter: '',
    revision: defaultRevision(),
    division: defaultDivision(),
    invoiceDetails: defaultInvoiceDetails(),
    financial: defaultTAOFinancial(),
    assignment: defaultAssignment()
});

export const defaultInhuurContract = (): InhuurContract => ({
    _id: '',
    contractnumber: '',
    contractKind: Contracts.Inhuur,
    signedDate: 0,
    creationDate: 0,
    isArchived: false,
    hirer: defaultOrganisation(),
    timesheetApproversHirer: [defaultPerson()],
    supplier: defaultOrganisation(),
    contactHirer: defaultPerson(),
    endCustomer: defaultOrganisation(),
    professional: defaultPerson(),
    authorizedSignatorySupplier: defaultPerson(),
    authorizedSignatoryHirer: defaultPerson(),

    contractManager: '',
    documents: [],
    recruiter: '',
    revision: defaultRevision(),
    division: defaultDivision(),
    invoiceDetails: defaultInvoiceDetails(),
    financial: defaultCompanyFinancial(),
    assignment: defaultAssignment()
});
