import { Plugin, View } from "ckeditor5";


class ToolbarLabelPlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('toolbarLabel', locale => {
            const view = new View(locale);

            const labelElement = document.createElement('span');

            labelElement.style.paddingLeft = '10px';
            labelElement.style.paddingRight = '10px';

            labelElement.textContent = 'Variabelen:';
            labelElement.style.fontWeight = 'bold';
            labelElement.style.marginLeft = '10px';

            labelElement.style.backgroundColor = '#f3f3f3';
            labelElement.style.borderRadius = '5px';


            view.element = labelElement;

            return view;
        });
    }
}

export default ToolbarLabelPlugin;
