import { DynamicFormTwoTone, EmojiPeopleTwoTone, HandymanTwoTone, PictureAsPdfTwoTone, TransferWithinAStationTwoTone, UploadTwoTone } from "@mui/icons-material";
import { BaseDocument, DocumentList, DocumentListTargets, DocumentTarget, DocumentType } from "../types/documents";


export const getDefaultOnboardingList = (inviteType: string, documentLists: DocumentList[]) =>
    documentLists.find(d => d.title.toLocaleLowerCase().startsWith(inviteType.toLocaleLowerCase()));

export const documentListTargetString = (type: DocumentListTargets) => {
    switch (type) {
        case DocumentListTargets.Contract: return 'Contract';
        case DocumentListTargets.Onboarding: return 'Onboarding';
    }
}

export const documentTypeIcon = (type: DocumentType) => {
    switch (type) {
        case DocumentType.DocuSign: return PictureAsPdfTwoTone;
        case DocumentType.Form: return DynamicFormTwoTone;
        case DocumentType.Upload: return UploadTwoTone;
    }
}

export const documentTargetIcon = (type: DocumentTarget) => {
    switch (type) {
        case DocumentTarget.Person: return HandymanTwoTone;
        case DocumentTarget.Hirer: return EmojiPeopleTwoTone;
        case DocumentTarget.Supplier: return TransferWithinAStationTwoTone;
    }
}

export const documentTargetString = (type: DocumentTarget) => {
    switch (type) {
        case DocumentTarget.Person: return "Professional";
        case DocumentTarget.Hirer: return "Inlener";
        case DocumentTarget.Supplier: return "Leverancier";
    }
}

export const documentTypeString = (type: DocumentType) => {
    switch (type) {
        case DocumentType.DocuSign: return 'Docusign';
        case DocumentType.Form: return 'Formulier';
        case DocumentType.Upload: return 'Bestand';
    }
}

export const createBaseDocumentId = (doc: BaseDocument) => `${doc.documentReferenceId}-${doc.targetType}-${doc.title}`;