import { InvoiceReference, } from "../../types/contracts";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class InvoiceReferencesApi extends BaseApi {
    constructor() { super(apiRoutes.contracts + "/invoicereferences"); }

    public list = async () => await this.fetchJson<InvoiceReference[]>();
    public get = async (id: string) => await this.fetchJson<InvoiceReference>(id);
    public create = async (invoiceRef: InvoiceReference): Promise<InvoiceReference> => await this.fetchJson(undefined, 'POST', invoiceRef);
    public update = async (invoiceRef: InvoiceReference) => await this.fetch(undefined, 'PUT', invoiceRef);
    public delete = async (id: string) => await this.fetch(id, 'DELETE');
}

const invoiceReferencesApi = new InvoiceReferencesApi();
export default invoiceReferencesApi;