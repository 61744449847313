import { Box } from "@mui/material";

export function SideMenuLayout({
    sideMenu,
    children
}: {
    sideMenu: React.ReactNode,
    children: React.ReactNode
}) {
    return <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', background: '#f9fafb', flexDirection: 'column' }}>
            {sideMenu}
        </Box>
        <Box sx={{ flex: 1, maxHeight: '100vh', overflow: 'auto' }}>
            {children}
        </Box>
    </Box>
}