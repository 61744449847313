import { FC } from "react"
import { Box, Typography } from "@mui/material"
import { Periods, Rate } from "../../../types/contracts"
import { SelectMolecule } from "../MultiSelect"
import { TextInput } from "./TextInput"

export const SimpleRateInput: FC<{
    value: Rate,
    onChange?: (value: Rate) => void,
    disabled?: boolean
}> = ({ value, onChange, disabled }) => {

    const onPeriodChange = (period: Periods) => {
        if (!onChange)
            return;

        onChange({ ...value, period });
    }

    const onSumChange = (sum: any) => {
        if (!onChange)
            return;

        onChange({ ...value, sum });
    }

    return <Box display={"flex"} alignItems={'center'} sx={{ width: '100%' }}>
        <TextInput
            type="double"
            inputSx={{ maxWidth: 80 }}
            value={value.sum}
            onChange={onSumChange}
            disabled={disabled}
        />
        <Typography sx={{ px: 1, m: 0, py: 0, lineHeight: 0, fontSize: 12 }} variant="body2">Per</Typography>
        <SelectMolecule
            disabled={disabled}
            selected={[value.period]}
            onClick={onPeriodChange}
            items={[
                { label: 'Uur', value: Periods.Hour },
                { label: 'Dag', value: Periods.Day },
                { label: 'Week', value: Periods.Week },
                { label: 'Maand', value: Periods.Month }
            ]}
        />
    </Box>
}