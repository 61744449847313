import { Theme } from "@emotion/react";
import { AbcTwoTone, AlternateEmailTwoTone, HouseTwoTone, LocationOnTwoTone, Looks3TwoTone, MyLocationTwoTone, PhoneTwoTone, WorkTwoTone } from "@mui/icons-material";
import { Box, Button, SxProps, Typography } from "@mui/material";

import addressApi from "../../../../api/addressApi";
import { ProfessionalDetails, Gender, ContractingType } from "../../../../types/recruiter/professional";
import { FormContainer, TextInput, IconLabel, Dropdown } from "../../../molecules";
import { SelectMolecule } from "../../../molecules/MultiSelect";
import { combinedValidations, requiredValidation, fullNameValidation } from "../../../validations";

export const onChangeText = (setter: (value: string) => void) => (v: string | number) => setter(v as string);

export function ProfessionalDetailsForm(props: {
    state: ProfessionalDetails,
    onChange: (data: ProfessionalDetails) => void,
    hideContract?: boolean,
    sx?: SxProps<Theme>
}) {
    const { state } = props;
    const { title, email, houseNumber, name, phone, zipCode, gender, city, address } = state;

    const fetchAddress = async (zipCode: string, houseNumber: string) => {
        await addressApi.toAddress(zipCode, houseNumber)
            .then(({ city, street }) => props.onChange({ ...state, address: street, city }))
            .catch(_ => {
                alert('Onjuiste adres gegevens. Probeer opnieuw.')
            });
    }

    return <FormContainer sx={props.sx}>

        <Box display={'flex'} width={'100%'} mt={1} alignItems={'flex-start'} sx={{
            '& .MuiSelect-select': {
                padding: 0
            }
        }}>
            <TextInput sx={{ mr: 2, flex: 1 }} required validator={
                combinedValidations(requiredValidation, fullNameValidation)
            } label="Naam"
                icon={AbcTwoTone}
                onChange={onChangeText(name => {
                    props.onChange({ ...state, name });
                })} value={name} />

            <Box>
                <Box mt={1} />
                <IconLabel label="Geslacht" required />
                <Dropdown label="Geslacht" value={gender}
                    onChange={gender => props.onChange({ ...state, gender })}
                    options={[
                        { label: 'Man', value: Gender.MALE },
                        { label: 'Vrouw', value: Gender.FEMALE },
                    ]} />
            </Box>
        </Box>

        {!props.hideContract && <Box mt={1} mb={2}>
            <IconLabel label="Contractvorm" icon={AbcTwoTone} />
            <SelectMolecule
                items={[
                    { label: "ZZP", value: ContractingType.ZZP },
                    { label: "Leverancier", value: ContractingType.Leverancier },
                    { label: "Tijdelijke arbeidsovereenkomst", value: ContractingType.TAO },
                ]}
                onClick={(item) => {
                    const newContractingTypes = state.contractingTypes.includes(item)
                        ? state.contractingTypes.filter(c => c !== item)
                        : [...state.contractingTypes, item];
                    props.onChange({ ...state, contractingTypes: newContractingTypes });
                }}
                selected={state.contractingTypes}
            />
        </Box>}

        <TextInput sx={{ mt: 1 }} required validator={requiredValidation}
            label="Functie" icon={WorkTwoTone}
            onChange={onChangeText(title => props.onChange({ ...state, title }))}
            value={title} />

        <Box display={'flex'} width={'100%'} mt={1}>
            <TextInput sx={{ flex: 1 }} label="Email" required validator={requiredValidation}
                icon={AlternateEmailTwoTone}
                onChange={onChangeText(email => props.onChange({ ...state, email }))}
                value={email} />

            <Box mx={1} />
            <TextInput type="tel" label="Telefoon"
                icon={PhoneTwoTone}
                onChange={onChangeText(phone => props.onChange({ ...state, phone }))}
                value={phone} />
        </Box>

        <Box display={'flex'} width={'100%'} mt={1} alignItems={'flex-end'}>
            <TextInput sx={{ flex: 1, mr: 2 }} label="Postcode" icon={LocationOnTwoTone}
                onChange={onChangeText(zipCode => props.onChange({ ...state, zipCode }))}
                value={zipCode} />

            <TextInput label="Huisnummer"
                sx={{ flex: 1 }}
                icon={Looks3TwoTone}
                onChange={onChangeText(houseNumber => props.onChange({ ...state, houseNumber }))}
                value={houseNumber} />
        </Box>

        <Box display={'flex'} width={'100%'} mt={1} alignItems={'flex-end'}>
            <TextInput label="Adres" disabled icon={HouseTwoTone} value={
                city && address
                    ? city + ", " + address
                    : ''
            } sx={{ flex: 2 }} />

            <Button
                sx={{ flex: 1, height: 30, ml: 2 }}
                disabled={!zipCode || !houseNumber}
                onClick={() => fetchAddress(zipCode, houseNumber)} variant="outlined" color="primary">
                <MyLocationTwoTone fontSize="small" />
                <Typography sx={{ ml: 2 }} variant="caption">
                    Invullen
                </Typography>

            </Button>
        </Box>
    </FormContainer >
}
