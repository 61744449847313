import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { KvKListVestigingItem, KvKSearchResult } from "../../types/company/kvk";

class KvkApi extends BaseApi {
    constructor() { super(apiRoutes.organization); }

    public searchKvk = (naam: string) => this.fetchJson<KvKSearchResult[]>(`kvk/zoeken/${naam}`).catch(() => []);
    public searchKvkFromInvite = (env: string, code: string, naam: string) => this.fetchJson<KvKSearchResult[]>(`invite/${env}/${code}/kvk/zoeken/${naam}`).catch(() => []);

    public listKvkVestigingen = (kvk: string) => this.fetchJson<KvKListVestigingItem[]>(`kvk/${kvk}/vestigingen`).catch(() => []);
    public listKvkVestigingenFromInvite = (env: string, code: string, kvk: string) => this.fetchJson<KvKListVestigingItem[]>(`invite/${env}/${code}/kvk/${kvk}/vestigingen`).catch(() => []);

    public getNaamgeving = (kvk: string) => this.fetchJson<{ statutaireNaam: string, naam: string }>(`kvk/${kvk}/naamgeving`);
    public getNaamgevingFromInvite = (env: string, code: string, kvk: string) => this.fetchJson<{ statutaireNaam: string, naam: string }>(`invite/${env}/${code}/validatekvk?kvk=${kvk}`);
}

const api = new KvkApi();
export default api;