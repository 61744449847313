import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Membership, MembershipRole } from "../../../types/company/membership";

import whitelabelApi from "../../../api/organization/whitelabel";
import personApi from "../../../api/organization/person";

import { CompanyMembershipsTable } from "../../molecules/tables/company/Memberships";
import { Person } from "../../../types/company/person";
import { MembershipsDialog, ModifyMembershipDialog, Toolbar } from "../../molecules";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { personsSelector } from "../../../store/slices/personsSlice";
import { LinkTwoTone } from "@mui/icons-material";
import { Permissions } from "../../../types/user";
import { MembershipsScope } from "../../molecules/MembershipsDialog";

export function WhitelabelMembershipsOrganism() {
    const dispatch = useAppDispatch();
    const [showDialog, setShowDialog] = useState(false);

    const [memberships, setMemberships] = useState<Membership[]>([]);
    const [modifyMembership, setModifyMembership] = useState<Membership>();
    const [relatedPersons, setPersons] = useState<Person[]>([]);
    const [fetching, setFetching] = useState(false);

    const allPersons = useAppSelector(personsSelector).persons;

    useEffect(() => {
        setFetching(true);
        whitelabelApi.listMemberships().then(setMemberships).catch()
            .finally(() => setFetching(false));
    }, []);

    useEffect(() => {
        if (!memberships.length || memberships.length === 0) return;

        setFetching(true);
        personApi.list(memberships.map((m) => m.personId)).then(setPersons).catch()
            .finally(() => setFetching(false));
    }, [memberships]);

    const onMembershipAdded = (entityId: string, roles: MembershipRole[], permissions: Permissions[]) => {
        whitelabelApi.createMembership(entityId, {
            roles,
            permissions
        })
            .then((membership) => setMemberships([...memberships, membership]))
            .catch(e => dispatch({ type: 'error', payload: "Er is iets mis gegaan met het koppelen" }))
            .finally(() => setShowDialog(false));
    }

    const onRemoveMembership = (membership: Membership) => {
        whitelabelApi.deleteMembership(membership._id)
            .then(() => {
                setMemberships(memberships.filter((m) => m._id !== membership._id));
                setPersons(relatedPersons.filter((p) => p._id !== membership.personId));
            })
            .catch(e => dispatch({ type: 'error', payload: "Er is iets mis gegaan met het ontkoppelen" }));
    }

    const onUpdateMembership = (membershipId: string) => (roles: MembershipRole[], permissions: Permissions[]) => {
        whitelabelApi.updateMembership(membershipId, { roles, permissions })
            .then(() => {
                setMemberships(memberships.map((m) => m._id === membershipId ? { ...m, roles, permissions } : m));
                setModifyMembership(undefined);
            })
            .catch(_ => dispatch({ type: 'error', payload: "Er is iets mis gegaan met het updaten van de koppeling" }));
    }

    return <Box sx={{ pt: 2 }}>
        <MembershipsDialog
            scope={MembershipsScope.Whitelabel}
            onDismiss={() => setShowDialog(false)}
            visible={showDialog}
            entities={
                allPersons
                    .filter((p) => memberships.every((m) => m.personId !== p._id))
                    .map((p) => ({ name: `${p.firstName} ${p.lastName}`, email: p.email, id: p._id }))}
            onCompleted={onMembershipAdded}
        />

        {modifyMembership && <ModifyMembershipDialog
            scope={MembershipsScope.Whitelabel}
            visible={modifyMembership !== undefined}
            onDismiss={() => setModifyMembership(undefined)}
            fetching={false}
            initialValues={modifyMembership}
            onCompleted={onUpdateMembership(modifyMembership._id)}
        />}

        <Toolbar>
            <Button onClick={() => setShowDialog(true)} variant="text" sx={{ mr: 1 }}>
                <LinkTwoTone fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="caption">Persoon Koppelen</Typography>
            </Button>
        </Toolbar>
        {
            fetching && <CircularProgress size={30} />
        }
        {
            !fetching &&
            <CompanyMembershipsTable
                removeMembershipLabel="Beheerders rechten verwijderen"
                editPermissionsLabel="Rechten bewerken"
                onEditPermissions={setModifyMembership}
                onRemoveMembership={onRemoveMembership}
                persons={relatedPersons}
                memberships={memberships}
            />
        }
    </Box>
}