import { styled } from "@mui/material";

export const PaperSheet = styled('div')(() => ({
    maxWidth: '8.5in',
    minHeight: '11in',
    backgroundColor: 'white',
    border: '1px solid #ddd',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    margin: '20px auto',
    padding: '.5in',
    boxSizing: 'border-box',
    position: 'relative',
}));