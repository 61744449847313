import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { Company } from "../../types/company/company";
import { AddCompanyPayload, UpdateCompanyPayload } from "../../types/company/payloads";
import { Tag } from "../../types/company/Tag";

class CompanyApi extends BaseApi {
    constructor() { super(apiRoutes.organization); }

    public list = (ids?: string[]) => this.fetchJson<Company[]>(ids ? "companies/filter" : "companies", ids ? 'POST' : 'GET', ids);
    public create = (payload: AddCompanyPayload) => this.fetchJson<Company>("companies", "POST", payload);
    public update = (id: string, payload: UpdateCompanyPayload) => this.fetchJson<Company>(`companies/${id}`, "PUT", payload);
    public syncKvK = (id: string) => this.fetchJson<Company>(`companies/${id}/sync-kvk`, "PUT");
    public delete = (id: string) => this.fetch(`companies/${id}`, "DELETE");

    public getTags = (companyId: string) => this.fetchJson<Tag[]>(`companies/${companyId}/tags`);
    public setTags = (companyId: string, tags: Tag[]) => this.fetch(`companies/${companyId}/tags`, "PUT", tags);
}

const api = new CompanyApi();
export default api;