
export interface InvoiceReference {
    _id: string;
    name: string;
    ref: string;
}

export interface ReferenceValue extends InvoiceReference {
    value: string;
}

export const defaultReference = (template: InvoiceReference): ReferenceValue => ({
    ...template,
    value: ''
})
