import { Education } from "../../../../types/recruiter/professional";
import { sanitizeIsoStringToDate } from "../../../../utils/strings";
import { ColumnProps, Table } from "../Table";
import { Link } from "@mui/material";

export function ProfessionalEducationTable(props: {
    educations: Education[];
    onEducationClick: (education: Education) => void;
}) {
    const colDefs: ColumnProps<Education>[] = [
        {
            field: "title",
            headerName: "Titel",
            cellRenderer: (row) => <Link onClick={() => props.onEducationClick(row.data)}>{row.data.title}</Link>,
            flex: 1
        },
        {

            field: "graduationDate",
            headerName: "Behaald op",
            cellRenderer: (row) => sanitizeIsoStringToDate(row.data.graduationDate),
            width: 150,
        },
        {

            field: "institution",
            headerName: "Instituut",
            flex: 1
        },
    ];

    return <Table id="Edus" columns={colDefs} data={props.educations} />;
}

