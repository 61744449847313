import { configureStore } from '@reduxjs/toolkit'

import auth from './slices/authSlice'
import vacancies from './slices/vacanciesSlice'
import persons from './slices/personsSlice'
import professionals from './slices/professionalsSlice'
import toast from './slices/toastSlice'
import notifications from './slices/notificationsSlice'
import companies from './slices/companiesSlice'
import tags from './slices/tagsSlice'
import heroTutorial from './slices/heroTutorialSlice'
import contracts from './slices/contractsSlice'

export const store = configureStore({
    reducer: {
        auth,
        vacancies,
        persons,
        toast,
        notifications,
        professionals,
        companies,
        tags,
        heroTutorial,
        contracts
    }
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch