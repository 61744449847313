import { Permissions } from "../user";

export enum MembershipRole {
    Owner = 1,
    AuthorizedSignatory = 2,
    Employed = 3,
    TimesheetApprover = 4,
    TAO = 5
}

export interface Membership {
    _id: string;
    companyId: string;
    personId: string;
    roles: MembershipRole[];
    permissions: Permissions[];
}