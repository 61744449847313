import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { StoreItem } from "../../types/documents/Store";

class DocumentsStoreApi extends BaseApi {
    constructor() { super(apiRoutes.documents + "/store"); }

    public list = () => this.fetchJson<StoreItem[]>();
    public get = (id: string) => this.fetchJson<StoreItem>(id);
    public import = (id: string) => this.fetch(id + "/import", 'POST');
    public create = (storeItem: StoreItem) => this.fetchJson<StoreItem>('', "POST", storeItem);
    public update = (storeItem: StoreItem) => this.fetch('', "PUT", storeItem);
    public delete = (id: string) => this.fetch(id, "DELETE");
}

const api = new DocumentsStoreApi();
export default api;