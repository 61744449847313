import { Box, IconButton, Slide, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hideToast, toastSelector } from "../../store/slices/toastSlice";
import { CloseTwoTone } from "@mui/icons-material";
import { CootjeImg } from "../molecules";

export function ToastOrganism(props: {
    paddingLeft?: number;
}) {
    const dispatch = useAppDispatch();
    const state = useAppSelector(toastSelector);
    const theme = useTheme();

    const colorFactory = () => {
        switch (state.type) {
            case 'success':
                return theme.palette.primary.light;
            case 'error':
                return 'rgba(231, 76, 60, .2)';
        }
    }

    const Emoji = () => {
        switch (state.type) {
            case 'success':
                return <CootjeImg assetName="cootje_logo.png" />;
            case 'error':
                return <></>
        }
    }

    const onClose = () => dispatch(hideToast());

    return <Slide appear={!state.visible} in={state.visible}>
        <Box sx={{ paddingLeft: props.paddingLeft, width: '100%', height: 60, background: 'transparent', position: 'fixed', top: 0, left: 0, zIndex: 100000 }}>
            <Box sx={{ width: '100%', height: 60, background: '#FFF' }}>
                <Box sx={{ width: '100%', height: 60, background: colorFactory(), p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <IconButton onClick={onClose}>
                            <CloseTwoTone />
                        </IconButton>
                    </Box>

                    <Emoji />
                    <Typography variant="body1" sx={{ fontSize: '1rem', mx: 2 }} color={'secondary'}>
                        {state.message}
                    </Typography>
                    <Box sx={{ flex: 1 }} />
                </Box>
            </Box>
        </Box>
    </Slide>
}