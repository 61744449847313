import { InvoiceReference } from "../../../../types/contracts";
import { ContextMenuMore } from "../../ContextMenu";
import { ColumnProps, Table } from "../Table";

export function ContractInvoiceReferencesTable(props: {
    items: InvoiceReference[];
    onDelete: (item: InvoiceReference) => void;
}) {
    const colDefs: ColumnProps<InvoiceReference>[] = [
        {
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: "Referentie Verwijderen",
                    onClick: () => props.onDelete(row.data),
                    color: "error"
                }
            ]} />,
            width: 65
        },
        {
            field: "name",
            headerName: "Naam"
        },
        {
            field: "ref",
            headerName: "Referentie",
            flex: 1
        }
    ];

    return <Table id="DocLists" columns={colDefs} data={props.items} />
}