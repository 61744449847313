import { ReferenceValue } from "./Reference";

export enum VatTypes {
    Default = 1,
    KOR = 2,
    BTWVerlegd = 3,
    BTWVrijgesteld = 4,
    VrijstellingKO = 5
}

// Interface for InvoiceDetails
export interface InvoiceDetails {
    customerInvoiceDetails: CustomerInvoiceDetails;
    supplierInvoiceDetails: SupplierInvoiceDetails;
}

export const defaultInvoiceDetails = (): InvoiceDetails => ({
    customerInvoiceDetails: defaultCustomerInvoiceDetails(),
    supplierInvoiceDetails: defaultSupplierInvoiceDetails()
})

// Interface for SupplierInvoiceDetails
export interface SupplierInvoiceDetails {
    vatType: VatTypes;
    snaCertified: boolean;
    gBankAccount: string;
    gDivision: number;
}

const defaultSupplierInvoiceDetails = (): SupplierInvoiceDetails => ({
    vatType: VatTypes.Default,
    snaCertified: false,
    gBankAccount: '',
    gDivision: 0
})

// Interface for HirerInvoiceDetails
export interface CustomerInvoiceDetails {
    name: string;
    address: string;
    zipCode: string;
    city: string;
    country: string;
    phone: string;
    email: string;
    references: ReferenceValue[];
    vatType: VatTypes;
    vatReverseChargeNumber: string;
}

const defaultCustomerInvoiceDetails = (): CustomerInvoiceDetails => ({
    name: '',
    address: '',
    zipCode: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    references: [],
    vatReverseChargeNumber: '',
    vatType: VatTypes.Default
})