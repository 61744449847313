import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { Tag } from "../../types/company/Tag";

class CompanyApi extends BaseApi {
    constructor() { super(apiRoutes.organization + '/tags'); }

    public list = () => this.fetchJson<Tag[]>();
    public create = (tag: string) => this.fetchJson<Tag>(undefined, 'POST', tag);
}

const api = new CompanyApi();
export default api;