import { Box } from "@mui/material";
import { useState, ReactNode } from "react";
import { MembershipRole } from "../../../types/company/membership";
import { EmptyPerson } from "../../../types/company/person";
import { RolesListMolecule, OrganizationPermissionsTable, ContainedButton, FormStepper, YesNoToggle } from "../../molecules";
import { personValidation } from "../../validations/person";
import { PersonDetailsForm } from "./PersonDetails";
import { Permissions } from "../../../types/user";
import { ContactPersonProps } from "../../../types/company/contactPerson";

export function AddContactPersonForm(props: {
    onComplete: (p: ContactPersonProps) => void;
}) {
    const [step, setStep] = useState(0);
    const [withCredentials, setWithCredentials] = useState(true);
    const [person, setPerson] = useState(new EmptyPerson());
    const [roles, setRoles] = useState<MembershipRole[]>([MembershipRole.AuthorizedSignatory]);
    const [permissions, setPermissions] = useState<Permissions[]>([
        Permissions.CompanyReader,
        Permissions.CompanyWriter,
        Permissions.PermissionEditor,
        Permissions.PersonReader,
        Permissions.PersonWriter,
        Permissions.SelfReader,
        Permissions.SelfWriter

    ]);

    const steps: { label: string, content: ReactNode }[] = [
        {
            label: "Contactpersoon",
            content: <>
                <YesNoToggle value={withCredentials} onChange={setWithCredentials} title="Mag deze gebruiker inloggen?" />
                <PersonDetailsForm showFunction person={person} onChange={setPerson} />
            </>
        },
        {
            label: "Rollen",
            content: <RolesListMolecule onChanged={setRoles} selectedRoles={roles} />
        },
        {
            label: "Permissies",
            content: <OrganizationPermissionsTable selected={permissions} onChanged={setPermissions} />
        }
    ]

    const error = personValidation(person, roles, permissions);

    return <Box sx={{ minWidth: 600 }}>
        <ContainedButton
            onClick={() => props.onComplete({
                withLogin: withCredentials,
                details: person,
                roles,
                permissions
            })}
            disabled={error !== null} sx={{ position: 'absolute', top: 8, right: 8 }}>{error ?? "Contactpersoon Toevoegen"}</ContainedButton>
        <FormStepper steps={steps}
            activeStep={step}
            onStepClicked={setStep}
        />
    </Box>
}