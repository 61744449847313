import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { vacanciesSelector, updateVacancy, deleteVacancy } from "../../../../store/slices/vacanciesSlice";
import { Vacancy, VacancyDetails, emptyVacancy } from "../../../../types/recruiter/vacancy";
import { DotsLoader } from "../../../molecules";

import Form from "../../../forms/recruiter/vacancy";
import { recruiterRoutes } from "../../../../router/paths";

export function VacancyDetailsOrganism() {
    const params = useParams();
    const nav = useNavigate();
    const [vacancy, setVacancy] = useState<Vacancy>(emptyVacancy);

    const dispatch = useAppDispatch();
    const state = useAppSelector(vacanciesSelector);

    useEffect(() => {
        if (params.id) {
            const result = state.vacancies.find(p => p._id === params.id);
            if (result)
                setVacancy(result);
        }
    }, [params.id, state.vacancies])

    if (!Boolean(vacancy._id))
        return <DotsLoader message="Vacature word geladen" />

    const onCompleted = (details: VacancyDetails) => dispatch(updateVacancy({ ...vacancy, ...details }));
    const onDelete = async () => {
        const result = await dispatch(deleteVacancy(vacancy._id));
        if (result.meta.requestStatus === 'fulfilled')
            nav(recruiterRoutes.vacatures.list);
    };

    return <Form
        initialValues={vacancy}
        onComplete={onCompleted}
        onDelete={onDelete}
        completeLabel="Opslaan"
        fetchingSave={state.fetchingCreate === 'fetching'}
        fetchingDelete={state.fetchingDelete === 'fetching'}
    />
}