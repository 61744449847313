import { AccountBalanceTwoTone, LocationOnTwoTone, WorkTwoTone } from "@mui/icons-material";
import { Box, Button, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import { Theme } from "@emotion/react";
import { onChangeText } from "./ProfessionalDetails";
import { WorkExperience, emptyExperience } from "../../../../types/recruiter/professional";
import { FormContainer, TextInput, DateInput, TextArea } from "../../../molecules";
import { requiredValidation } from "../../../validations";


export function ProfessionalExperienceForm(props: {
    onSubmit: (experience: WorkExperience) => void;
    initialExperience?: WorkExperience;
    submitLabel: string;
    sx?: SxProps<Theme>;
}) {
    const [formId, setFormId] = useState<number>(1);
    const [experience, setExperience] = useState<WorkExperience>({
        title: '',
        company: ''
    });

    useEffect(() => {
        if (props.initialExperience) {
            setExperience({ ...props.initialExperience });
        } else {
            setExperience(emptyExperience);
        }
    }, [props.initialExperience]);

    const validateRequiredFields = (): boolean => {
        return Boolean(experience.title) && Boolean(experience.company);
    };

    const resetForm = () => {
        setExperience(emptyExperience);
        setFormId(formId + 1);
    };

    const { startDate, company, title, description, endDate, location } = experience;

    return <FormContainer key={formId} sx={props.sx}>
        <TextInput sx={{ mb: 1 }} required validator={requiredValidation} label="Functie" icon={WorkTwoTone} onChange={onChangeText((v) => setExperience({ ...experience, title: v }))} value={title} />
        <TextInput sx={{ mb: 1 }} required validator={requiredValidation} label="Klant" icon={AccountBalanceTwoTone} onChange={onChangeText((v) => setExperience({ ...experience, company: v }))} value={company} />
        <TextInput sx={{ mb: 1 }} label="Locatie" icon={LocationOnTwoTone} onChange={onChangeText((v) => setExperience({ ...experience, location: v }))} value={location} />

        <Box display={'flex'}>
            <DateInput sx={{ flex: 1 }} icon="default" onChange={v => setExperience({ ...experience, startDate: v.toISOString() })} value={startDate ? new Date(startDate) : undefined} label="Start datum" />
            <DateInput minDate={experience.startDate ? new Date(experience.startDate) : undefined} icon="available" onChange={v => setExperience({ ...experience, endDate: v.toISOString() })} value={endDate ? new Date(endDate) : undefined} label="Eind datum" sx={{ pl: 2, flex: 1 }} />
        </Box>

        <TextArea sx={{ mt: 1 }} label="Omschrijving" icon={WorkTwoTone} onChange={onChangeText((v) => setExperience({ ...experience, description: v }))} value={description} />

        <Button sx={{ fontSize: 12, width: '100%', mt: 2 }} disabled={!validateRequiredFields()} onClick={() => {
            props.onSubmit(experience);
            resetForm();
        }} variant="outlined" color="primary">{props.submitLabel}</Button>

    </FormContainer>;
}
