import { FC, useState } from "react";

import { Box } from "@mui/material";
import { CheckBoxTwoTone, QuestionAnswerTwoTone, SaveAltTwoTone, TitleTwoTone } from "@mui/icons-material";

import { IconLabel, TextArea, TextInput, Toolbar } from "..";
import { EmptyMultipleChoice, EmptyQuestion, EmptyTextSection, FormItem } from "../../../types/documents/Form";
import { DroppableArea } from "../document-builder/DroppableArea";
import { PaperSheet } from "../document-builder/PaperSheet";
import { primary, primaryLighter } from "../../theme";
import { FormEditorComponentRenderer } from "./FormEditorComponentRenderer";

import { generateGuid, isValidGuid } from "../../../utils/strings";
import { LoadingButton } from "@mui/lab";
import { ToolbarUIButton } from "../document-builder/ToolbarUIButton";

export function FormEditorMolecule(props: {
    editing: boolean,
    saving: boolean,
    onSave: (items: FormItem[], title: string, description: string) => any,
    initialValues: {
        title: string,
        description: string,
        items: FormItem[]
    }
}) {
    const { saving, onSave } = props;
    const [title, setTitle] = useState(props.initialValues.title);
    const [description, setDescription] = useState(props.initialValues.description);
    const [items, setItems] = useState<FormItem[]>(props.initialValues.items);
    const { editing } = props;

    const onInsert = (item: FormItem, index: number) => {
        console.log('inserting', item, index);

        const newItem = { ...item, _id: isValidGuid(item._id) ? item._id : generateGuid() };

        setItems((prevItems) => {
            // Find the index of the existing item (if it exists)
            const existingIndex = prevItems.findIndex(i => i._id === newItem._id);

            // Remove the existing item if it's in the array
            let updatedItems = [...prevItems];
            if (existingIndex !== -1) {
                updatedItems.splice(existingIndex, 1);

                // Adjust the target index if the existing item was before the insert index
                if (existingIndex < index) {
                    index--;
                }
            }

            // Insert the new item at the specified index
            updatedItems.splice(index, 0, newItem);

            return updatedItems;
        });
    };


    const onUpdate = (index: number, item: FormItem) => {
        setItems((prevItems) => {
            return [
                ...prevItems.slice(0, index),
                item,
                ...prevItems.slice(index + 1),
            ];
        });
    }

    const onDelete = (index: number) => {
        setItems((prevItems) => {
            return [
                ...prevItems.slice(0, index),
                ...prevItems.slice(index + 1),
            ];
        });
    }

    const EditorToolbar: FC = () => <Toolbar sx={{ position: 'fixed', left: (34.5 * 8), bottom: 0, zIndex: 100, width: '100%', background: '#fff' }}>
        <Box display={'flex'} alignItems={'center'} sx={{ background: primaryLighter, width: '100%', height: 80, borderTop: '1px solid #eee' }}>
            <LoadingButton loading={saving} onClick={() => onSave(items, title, description)} sx={{ ml: 1, bacground: '#fff' }} color="secondary" variant="text">
                <SaveAltTwoTone fontSize="small" sx={{ mr: 1 }} />Opslaan
            </LoadingButton>

            <Box sx={{ border: `1px solid ${primary}`, height: 20, ml: 1, mr: 2 }} />

            <ToolbarUIButton icon={<QuestionAnswerTwoTone color="secondary" />} title={'Vraag Element'} item={EmptyQuestion()} />
            <ToolbarUIButton icon={<CheckBoxTwoTone color="secondary" />} title={'Meerkeuze Element'} item={EmptyMultipleChoice()} />
            <ToolbarUIButton icon={<TitleTwoTone color="secondary" />} title={'Tekst Element'} item={EmptyTextSection()} />

        </Box>
    </Toolbar>

    return <>
        {
            editing && <EditorToolbar />
        }

        <Box display={'flex'} mb={6}>
            <PaperSheet sx={{ flex: 1 }}>

                <IconLabel label={'Titel'} />
                <TextInput value={title} onChange={editing ? setTitle : undefined} inputSx={{ background: '#FFF', pl: 0, fontSize: 18 }} />
                <TextArea
                    label={'Omschrijving'}
                    value={description}
                    sx={{ my: 1 }}
                    onChange={editing ? setDescription : undefined}
                />

                {editing && <DroppableArea onDrop={(data) => onInsert(data.item, 0)}>
                    <Box sx={{ width: '100%', height: 20 }}></Box>
                </DroppableArea>}
                {
                    items.map((item, index) => <Box key={index + item._id}>
                        <Box sx={{ py: 2 }}>
                            <FormEditorComponentRenderer editing={editing} onDelete={() => onDelete(index)} item={item} onChange={value => onUpdate(index, value)} />
                        </Box>
                        {editing && <DroppableArea key={index} onDrop={(data) => onInsert(data.item, index + 1)}>
                            <Box sx={{ width: '100%', height: 20 }}></Box>
                        </DroppableArea>}
                    </Box>
                    )
                }
            </PaperSheet>
        </Box>
    </>
}