import { settingRoutes } from "../../../../router/paths";
import { StoreItem, StoreItemType } from "../../../../types/documents/Store";
import { ContextMenuMore } from "../../ContextMenu";
import { ColumnProps, Table } from "../Table";
import { Link } from "../../Link";

export function DocumentStoreTable(props: {
    items: StoreItem[];
    onDelete: (item: StoreItem) => void;
    onCopy: (item: StoreItem) => void;
}) {
    const colDefs: ColumnProps<StoreItem>[] = [
        {
            width: 70,
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: "Dupliceren",
                    color: "info",
                    onClick: () => props.onCopy(row.data)
                },
                {
                    label: "Verwijderen",
                    color: "error",
                    onClick: () => props.onDelete(row.data)
                }
            ]} />
        },
        {
            headerName: "Soort",
            cellRenderer: (row) => row.data.type === StoreItemType.Form ? 'Formulier' : 'PDF'
        },
        {
            field: "title",
            headerName: "Titel",
            cellRenderer: (row) => <Link path={settingRoutes.documentStoreItem(row.data._id, row.data.type)}>{row.data.title}</Link>
        },
        {
            field: "description",
            headerName: "Omschrijving",
            flex: 4
        },
    ];

    return <Table id="DocStore" columns={colDefs} data={props.items} />
}