import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deletePerson, personSelector, personsSelector, updatePerson } from '../../../store/slices/personsSlice';
import { PersonDetailsForm } from '../../forms/person/PersonDetails';
import { useEffect, useState } from 'react';
import { personRoutes } from '../../../router/paths';
import { ConfirmDialog } from '../../molecules/ConfirmDialog';
import { DeleteTwoTone, LockOpenTwoTone, VpnKeyTwoTone } from '@mui/icons-material';
import { CardDialog, Toolbar } from '../../molecules';
import { Person } from '../../../types/company/person';
import { Credentials } from '../../../types/company/credentials';

import authApi from '../../../api/auth';
import credsApi from '../../../api/organization/credentials';

export function PersonDetailsOrganism() {
    const { id } = useParams();
    const nav = useNavigate();
    const person = useAppSelector(state => personSelector(state, id ?? ''));
    const [editedPerson, setEditedPerson] = useState(person);
    const dispatch = useAppDispatch();

    const deleteInProgress = useAppSelector(personsSelector).deletesInProgress.includes(id ?? '');
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [credentials, setCredentials] = useState<Credentials | undefined>();
    const [showCredentials, setShowCredentials] = useState(false);

    const [showRequestCredsDialog, setShowRequestCredsDialog] = useState(false);
    const [fetching, setFetching] = useState(false);

    const handleUpdate = async (p: Person) => {
        const personJson = JSON.stringify(person);
        const editedPersonJson = JSON.stringify(p);
        if (personJson === editedPersonJson)
            return;

        const result = await dispatch(updatePerson(p));
        if (result.meta.requestStatus === 'rejected')
            setEditedPerson(person);
    };

    const handleChange = (p: Person) => {
        setEditedPerson(p);
        if (p.gender !== person!.gender || p.dateOfBirth !== person!.dateOfBirth)
            handleUpdate(p);
    };

    const onDeletPerson = async () => {
        const result = await dispatch(deletePerson(id!));
        if (result.meta.requestStatus === 'fulfilled')
            nav(personRoutes.list);
    }

    const onSendCredentials = async () => {
        if (!person) return;

        setFetching(true);
        await credsApi.sendCredentialsInvite(person._id);
        setFetching(false);
        setShowRequestCredsDialog(false);
    }

    useEffect(() => {
        setEditedPerson(person);

        if (person) {
            setFetching(true);
            authApi
                .findPersonCredentials(person._id)
                .then(creds => {
                    if (!creds) return;
                    setCredentials(creds);
                })
                .finally(() => setFetching(false));
        }

    }, [person]);

    if (!person || !editedPerson)
        return <CircularProgress />

    return <Box>
        <Toolbar sx={{ mt: 2 }}>
            {!fetching && !credentials && <Button onClick={() => setShowRequestCredsDialog(true)}>
                <VpnKeyTwoTone sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption">
                    Inlog gegevens aanmaken
                </Typography>
            </Button>}

            {!fetching && credentials && <Button onClick={() => setShowCredentials(true)}>
                <LockOpenTwoTone sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption">
                    Inlog gegevens tonen
                </Typography>
            </Button>}

            {person && <Button onClick={() => setShowDeleteDialog(true)}>
                <DeleteTwoTone color="error" sx={{ mr: 1 }} fontSize="small" />
                <Typography variant="caption" color={'error'}>
                    Archiveren
                </Typography>
            </Button>}
        </Toolbar>

        <ConfirmDialog
            fetching={deleteInProgress}
            message='Weet je zeker dat je deze persoon wilt archiveren?'
            open={showDeleteDialog}
            onCancel={() => setShowDeleteDialog(false)}
            title='Verwijderen'
            onConfirm={onDeletPerson} />

        <ConfirmDialog
            fetching={fetching}
            message='Email versturen met activatie link?'
            open={showRequestCredsDialog}
            onCancel={() => setShowRequestCredsDialog(false)}
            title='Inloggegevens registreren'
            onConfirm={onSendCredentials} />

        <CardDialog
            visible={showCredentials}
            onDismiss={() => setShowCredentials(false)}>
            <Box display={'flex'} flexDirection={'column'} sx={{ minWidth: 400 }}>
                <Typography variant="overline" sx={{ mb: 2 }}>
                    {person.firstName} {person.lastName} logt in met:
                </Typography>
                <Typography variant="body1" fontWeight={'bold'} sx={{ mb: 4 }}>
                    {credentials?.email}
                </Typography>
                <Typography variant="caption">
                    Via de inlogpagina kan {person.firstName} zijn/haar wachtwoord herstellen.
                </Typography>
            </Box>
        </CardDialog>

        <Box sx={{ maxWidth: 700 }}>
            <PersonDetailsForm
                onFocusLost={() => handleUpdate(editedPerson!)}
                onChange={handleChange}
                showBsn
                showDateOfBirth
                showFunction
                showNationality
                person={editedPerson!} />
        </Box>
    </Box>
}
