import { InviteType, PersonInvite, IdentificationDetails } from "../../types/company/invite";
import {
    InhuurFormData, InhuurWithCompanyFormData, ZzpFormData, TAOFormData,
    ZzpInvitePayload, TAOInvitePayload, InhuurInvitePayload, InhuurWithCompanyInvitePayload,
} from "../../types/company/invitePayloads";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class InviteApi extends BaseApi {
    constructor() { super(apiRoutes.organization + "/invite"); }

    public sendInvite = (email: string, type: InviteType, html: string, requestedFiles: string[], registerCredentials: boolean, companyId?: string) => this.fetch("send", "POST", { email, type, requestedFiles, companyId, registerCredentials, emailBody: html });
    public getInvite = (env: string, code: string) => this.fetchJson<PersonInvite>(`${env}/${code}`);

    public postInhuurWithCompanyRegisterForm = (env: string, code: string, payload: InhuurWithCompanyInvitePayload): Promise<any> =>
        this.sendFormData(`${env}/${code}/registerInhuurWithCompany`, InhuurWithCompanyFormData(payload));

    public postZzpRegisterForm = (env: string, code: string, payload: ZzpInvitePayload): Promise<any> =>
        this.sendFormData(`${env}/${code}/registerZzp`, ZzpFormData(payload));

    public postInhuurRegisterForm = (env: string, code: string, payload: InhuurInvitePayload): Promise<any> =>
        this.sendFormData(`${env}/${code}/registerInhuur`, InhuurFormData(payload));

    public postTAORegisterForm = (env: string, code: string, payload: TAOInvitePayload): Promise<any> =>
        this.sendFormData(`${env}/${code}/registerTAO`, TAOFormData(payload));

    public readIdentificationFront = async (env: string, code: string, id: File) => {
        const formData = new FormData();
        formData.append("id", id);
        try {
            let response: IdentificationDetails = await this.sendFormData(`${env}/${code}/validateIdentificationFront`, formData)
                .then((response) => response.json())
                .catch();

            return response;
        } catch (error) {
            return undefined;
        }
    }

    public readIdentificationBack = async (env: string, code: string, id: File) => {
        const formData = new FormData();
        formData.append("id", id);
        try {
            var response = await this.sendFormData(`${env}/${code}/validateIdentificationBack`, formData).then((response) => response.text()).catch();
            return response;
        } catch (error) {
            return undefined;
        }
    }

}

const api = new InviteApi();
export default api;