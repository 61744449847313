import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchingState } from '../../types/fetchingState';
import { showToast } from './toastSlice';
import { BaseContract, InhuurContract, TaoContract, ZzpContract } from '../../types/contracts';
import { RootState } from '..';

import contractsApi from '../../api/contracts';
import documentListsApi from '../../api/documents/lists';
import requestedDocumentsApi from '../../api/documents/requested';

import { createBaseDocumentId } from '../../utils/documents';
import { DocumentModule, DocumentState, DocumentTarget, DocumentType, DocumentMergeRequest, RequestedDocument } from '../../types/documents';
import { generateContractMergeFields } from '../../utils/contractMergeFields';

export interface ContractsState {
    contracts: BaseContract[];
    fetchingList: FetchingState;
    fetchingCreate: FetchingState;
}

const intialState: ContractsState = {
    contracts: [],
    fetchingList: "init",
    fetchingCreate: "init"
}

export const listContracts = createAsyncThunk(
    'contracts/list',
    async (_, thunkApi) => {
        try {
            const contracts = await contractsApi.list();
            return contracts;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

const createRequestedDocuments = async (ids: string[], c: BaseContract) => {
    const lists = await documentListsApi.list();
    const documents = lists.flatMap(l => l.items);

    const requestedPayloads: RequestedDocument[] = [];
    const mergeFields = generateContractMergeFields(c);

    const supplierMergeRequest: DocumentMergeRequest = {
        documentRefs: [],
        signers: [],
        fields: mergeFields,
        item: {
            _id: '',
            title: 'Overeenkomst Opdracht: ' + c.contractnumber,
            description: 'Overeenkomst van Opdracht voor leverancier',
            module: DocumentModule.Contract,
            moduleId: c._id,
            state: DocumentState.Requested,
            targetType: DocumentTarget.Supplier,
            targetId: c.supplier._id,
            type: DocumentType.DocuSign
        }
    }

    const hirerMergeRequest: DocumentMergeRequest = {
        documentRefs: [],
        signers: [],
        fields: mergeFields,
        item: {
            _id: '',
            title: 'Overeenkomst Opdracht: ' + c.contractnumber,
            description: 'Overeenkomst van Opdracht voor inlener',
            module: DocumentModule.Contract,
            moduleId: c._id,
            state: DocumentState.Requested,
            targetType: DocumentTarget.Hirer,
            targetId: c.hirer._id,
            type: DocumentType.DocuSign
        }
    }

    ids.forEach(documentId => {
        const item = documents.find(d => (d.documentReferenceId ?? createBaseDocumentId(d)) === documentId);
        if (!item)
            return;

        let targetId = '';

        switch (item.targetType) {
            case DocumentTarget.Supplier:
                targetId = c.supplier._id;
                break;
            case DocumentTarget.Hirer:
                targetId = c.hirer._id;
                break;
            case DocumentTarget.Person:
                targetId = c.professional._id;
                break;
        }

        if (item.type === DocumentType.DocuSign) {
            if (item.targetType === DocumentTarget.Supplier)
                supplierMergeRequest.documentRefs.push(item.documentReferenceId!);

            if (item.targetType === DocumentTarget.Hirer)
                hirerMergeRequest.documentRefs.push(item.documentReferenceId!);
        }
        else
            requestedPayloads.push({
                ...item,
                _id: '',
                targetId,
                module: DocumentModule.Contract,
                state: DocumentState.Requested,
                moduleId: c._id
            })
    });

    if (supplierMergeRequest.documentRefs.length > 0)
        await requestedDocumentsApi.merge({
            ...supplierMergeRequest,
            signers: [
                { email: c.authorizedSignatorySupplier.email, name: c.authorizedSignatorySupplier.name },
                { email: "dev@compliancefactory.nl", name: "R&D CF" }
            ]
        });

    if (hirerMergeRequest.documentRefs.length > 0)
        await requestedDocumentsApi.merge({
            ...hirerMergeRequest,
            signers: [
                { email: c.authorizedSignatoryHirer.email, name: c.authorizedSignatoryHirer.name },
                { email: "dev@compliancefactory.nl", name: "R&D CF" }
            ]
        });

    if (requestedPayloads.length > 0)
        await Promise.all(requestedPayloads.map(r => requestedDocumentsApi.requestNew(r)));
}

export const createContract = createAsyncThunk(
    'contracts/create',
    async (
        args: {
            contract: ZzpContract | TaoContract | InhuurContract,
            selectedDocuments: string[]
        },
        thunkApi
    ) => {
        try {
            const contract = await contractsApi.create(args.contract);
            await createRequestedDocuments(args.selectedDocuments, contract);

            thunkApi.dispatch(showToast({ message: "Contract aangemaakt", type: "success" }));

            return contract;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));

            throw e;
        }
    }
)

export const contractsSlice = createSlice({
    name: 'contracts',
    initialState: intialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listContracts.pending, (state) => { state.fetchingList = "fetching"; })
            .addCase(listContracts.rejected, (state) => { state.fetchingList = "error"; })
            .addCase(listContracts.fulfilled, (state, action: any) => {
                state.contracts = [...action.payload];
                state.fetchingList = "completed";
            });

        builder
            .addCase(createContract.pending, (state) => { state.fetchingCreate = "fetching"; })
            .addCase(createContract.rejected, (state) => { state.fetchingCreate = "error"; })
            .addCase(createContract.fulfilled, (state, action: any) => {
                state.contracts = [...state.contracts, action.payload];
                state.fetchingCreate = "completed";
            });
    }
})

export const contractsSelector = (state: RootState) => state.contracts;
export default contractsSlice.reducer;