import { CAO } from "../types/contracts";

class CAOApi {
    public getCAOData = async (): Promise<CAO[]> => {
        const response = await fetch('/assets/CAOLijst.csv')
            .then(r => r.text())
            .then(t => t.split('\n').map(l => l.split(',')));

        const mapped = response.map(r => ({
            caoNumber: r[0],
            name: r[1].replaceAll('"', ''),
        }));

        //return wihtout first item to remove headers
        return mapped.slice(1).sort((a, b) => parseInt(a.caoNumber) - parseInt(b.caoNumber));
    }
}

const api = new CAOApi();
export default api;