import { Box, Button } from "@mui/material";
import { CardDialog } from "./Dialog";
import { FormStepper } from "./FormStepper";
import { Table } from "./tables/Table";
import { useEffect, useState } from "react";
import { MembershipRole } from "../../types/company/membership";
import { PrevNextButtons } from "./Buttons";
import { LoadingButton } from "@mui/lab";
import { WhitelabelPermissionsTable, OrganizationPermissionsTable, RolesListMolecule } from ".";
import { Permissions } from "../../types/user";

export enum MembershipsScope {
    Company = 1,
    Whitelabel = 2
}

export function MembershipsDialog(props: {
    visible?: boolean,
    scope: MembershipsScope,
    entities: {
        name: string,
        email: string,
        id: string
    }[],
    fetching?: boolean,
    onCompleted: (entityId: string, roles: MembershipRole[], permission: Permissions[]) => void;
    onDismiss?: () => void;
}) {
    const [selectedEntity, setSelectedEntity] = useState<string | null>(null);
    const [selectedRoles, setSelectedRoles] = useState<MembershipRole[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>([]);
    const [activeStep, setActiveStep] = useState(0);

    const onSelectEntity = (id: string) => () => {
        setSelectedEntity(id);
        setActiveStep(1);
    }

    const steps = [
        {
            label: "Selecteer Entiteit",
            content: <Table
                id="EnitityTable"
                data={props.entities}
                columns={[
                    {
                        headerName: "Selecteer", cellRenderer: (row) => <Button
                            onClick={onSelectEntity(row.data.id)}
                            variant="text">
                            Selecteer
                        </Button>
                    },
                    { headerName: "Naam", field: "name" },
                    { headerName: "Email", field: "email", flex: 1 }
                ]}
            />
        },
        {
            label: "Selecteer Rollen",
            content: <RolesListMolecule onChanged={setSelectedRoles} selectedRoles={selectedRoles} />
        },
        {
            label: "Selecteer Rechten",
            content: props.scope === MembershipsScope.Company ?
                <OrganizationPermissionsTable
                    selected={selectedPermissions}
                    onChanged={setSelectedPermissions}
                /> :
                <WhitelabelPermissionsTable
                    selected={selectedPermissions}
                    onChanged={setSelectedPermissions}
                />
        }
    ];

    useEffect(() => {
        if (!props.visible) {
            setActiveStep(0);
            setSelectedEntity(null);
            setSelectedRoles([]);
            setSelectedPermissions([]);
        }
    }, [props.visible]);

    return <CardDialog visible={props.visible} title="Persoon koppelen aan bedrijf" onDismiss={props.onDismiss}>
        <Box sx={{ width: 800 }}>
            <FormStepper activeStep={activeStep} steps={steps}>
                {
                    activeStep > 0 && <PrevNextButtons
                        variant="text"
                        onPrev={() => setActiveStep(activeStep - 1)}
                        onNext={() => setActiveStep(activeStep + 1)}
                        nextDisabled={activeStep === (steps.length - 1)}
                        showNext={true}
                        showPrev={true}
                    />
                }
                {
                    activeStep === (steps.length - 1) && <LoadingButton variant="contained" sx={{ mb: 4 }} onClick={() => {
                        if (!selectedEntity) return;
                        props.onCompleted(selectedEntity, selectedRoles, selectedPermissions);
                    }}>
                        Voltooien
                    </LoadingButton>
                }
            </FormStepper>
        </Box>
    </CardDialog>
}

export function ModifyMembershipDialog(props: {
    initialValues: {
        roles: MembershipRole[];
        permissions: Permissions[];
    }
    scope: MembershipsScope,
    visible?: boolean,
    fetching?: boolean,
    onCompleted: (roles: MembershipRole[], permission: Permissions[]) => void;
    onDismiss?: () => void;
}) {
    const [selectedRoles, setSelectedRoles] = useState<MembershipRole[]>(props.initialValues.roles);
    const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>(props.initialValues.permissions);
    const [activeStep, setActiveStep] = useState(0);

    const steps = [
        {
            label: "Selecteer Rollen",
            content: <RolesListMolecule onChanged={setSelectedRoles} selectedRoles={selectedRoles} />
        },
        {
            label: "Selecteer Rechten",
            content: props.scope === MembershipsScope.Company ?
                <OrganizationPermissionsTable
                    selected={selectedPermissions}
                    onChanged={setSelectedPermissions}
                /> :
                <WhitelabelPermissionsTable
                    selected={selectedPermissions}
                    onChanged={setSelectedPermissions}
                />
        }
    ];

    useEffect(() => {
        if (!props.visible) {
            setActiveStep(0);
            setSelectedRoles([]);
            setSelectedPermissions([]);
        }
    }, [props.visible]);

    return <CardDialog visible={props.visible} title="Lidmaatschap Bewerken" onDismiss={props.onDismiss}>
        <Box sx={{ width: 800 }}>
            <FormStepper activeStep={activeStep} steps={steps}>
                {
                    <PrevNextButtons
                        variant="text"
                        onPrev={() => setActiveStep(activeStep - 1)}
                        onNext={() => setActiveStep(activeStep + 1)}
                        nextDisabled={activeStep === (steps.length - 1)}
                        showNext={true}
                        showPrev={true}
                    />
                }
                {
                    activeStep === (steps.length - 1) && <LoadingButton variant="contained" sx={{ mb: 4 }} onClick={() => {
                        props.onCompleted(selectedRoles, selectedPermissions);
                    }}>
                        Voltooien
                    </LoadingButton>
                }
            </FormStepper>
        </Box>
    </CardDialog>
}