import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '..';

export interface ToastState { message: string, type: 'success' | 'error', visible: boolean }
const intialState: ToastState = { message: '', type: 'success', visible: false };

let toast_timer: any = null;
export const showToast = createAsyncThunk(
    'toast/show',
    async (args: { message: string, type: 'success' | 'error' }, thunkApi) => {
        clearTimeout(toast_timer);
        toast_timer = setTimeout(() => {
            thunkApi.dispatch(hideToast());
        }, 8000);

        return args;
    }
)

export const toastSlice = createSlice({
    name: 'auth',
    initialState: intialState,
    reducers: {
        hideToast: (state) => {
            clearTimeout(toast_timer);
            state.visible = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(showToast.fulfilled, (state, { payload }) => {
            state.message = payload.message;
            state.type = payload.type;
            state.visible = true;
        });
    }
})
export const { hideToast } = toastSlice.actions;
export const toastSelector = (state: RootState) => state.toast;
export default toastSlice.reducer