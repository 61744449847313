import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { nlNL } from '@mui/x-date-pickers/locales';
import { getColorParam } from '../../utils/queryParams';
import { retrieveTheme, storeTheme } from '../../utils/cache';

const customTheme = getColorParam();
if (customTheme)
    storeTheme(customTheme);

export const primary = customTheme ?? retrieveTheme() ?? "#2aa3b9";
export const primaryLight = `${primary}36`;
export const primaryLighter = `${primary}0a`;

const theme = createTheme({
    typography: {
        fontFamily: 'Rubik, Arial, sans-serif',
    },

    palette: {
        primary: { main: primary, light: primaryLight },
        secondary: { main: "#2c3e50" },

        error: { main: "#c0392b" },
        success: { main: "#2ecc71" },
        warning: { main: "#e67e22" },
        info: { main: "#2980b9" },

        divider: `${primary}45`
    },

    //Overwrite default styles
    components: {
        MuiChip: {
            defaultProps: {
                sx: {
                    backgroundColor: primaryLight,
                    fontSize: 10,
                    height: 24,
                    cursor: 'pointer',
                    fontWeight: 800,
                    mr: 1,
                    mt: 1
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                "html": {
                    overflow: 'hidden',
                    fontFamily: 'Rubik, Arial, sans-serif',
                },
                "body": {
                    margin: 0,
                    padding: 0,
                    fontFamily: 'Rubik, Arial, sans-serif',
                },
                ".ag-row:hover": {
                    backgroundColor: primaryLighter
                },
                ".ag-theme-material .ag-row:hover::before": {
                    backgroundColor: 'transparent !important',
                    opacity: '0 !important'
                },
                ".ag-theme-material .ag-layout-auto-height .ag-center-cols-viewport": {
                    minHeight: '30px !important'
                },
                ".slim .ag-cell, .slim .ag-header-cell": {
                    paddingLeft: 4,
                    paddingRight: 4
                },
                ".ck-powered-by": {
                    display: 'none'
                },
                ".ag-root-wrapper": {
                    borderRadius: 4
                },
                "input:focus": {
                    outline: '2px solid ' + primary
                },
                "textarea:focus": {
                    outline: '2px solid ' + primary
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: `${primary}45`,
                }
            },
        }
    }

}, nlNL);

export function ApplicationThemeProvider(props: { children: React.ReactNode }) {
    return <ThemeProvider theme={theme}>
        <CssBaseline />
        {
            props.children
        }
    </ThemeProvider>
}