import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '..';
import { FetchingState } from '../../types/fetchingState';
import { showToast } from './toastSlice';
import api from '../../api/organization/person';
import { Person } from '../../types/company/person';
import { CreatePersonPayload } from '../../types/company/payloads';

export interface PersonsState {
    deletesInProgress: string[];
    persons: Person[];
    fetchingUpdate: FetchingState,
    fetchingList: FetchingState;
    fetchingCreate: FetchingState
}

const intialState: PersonsState = {
    persons: [],
    fetchingList: "init",
    fetchingCreate: "init",
    fetchingUpdate: "init",
    deletesInProgress: []
}

export const listPersons = createAsyncThunk(
    'persons/list',
    async (_, thunkApi) => {
        try {
            const persons = await api.list();
            return persons;
        } catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const createPerson = createAsyncThunk(
    'persons/create',
    async (v: CreatePersonPayload, thunkApi) => {
        try {
            const person = await api.create(v);
            return person;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const updatePerson = createAsyncThunk(
    'persons/update',
    async (p: Person, thunkApi) => {
        try {
            await api.update(p);
            return p;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const deletePerson = createAsyncThunk(
    'persons/delete',
    async (id: string, thunkApi) => {
        try {
            await api.delete(id);
            return id;
        }
        catch (e: any) {
            thunkApi.dispatch(showToast({ message: e.message, type: "error" }));
            throw e;
        }
    }
)

export const personsSlice = createSlice({
    name: 'persons',
    initialState: intialState,
    reducers: {},
    extraReducers: (builder) => {
        // List
        builder
            .addCase(listPersons.pending, (state) => {
                state.fetchingList = "fetching";
            })
            .addCase(listPersons.rejected, (state) => {
                state.fetchingList = "error";
            })
            .addCase(listPersons.fulfilled, (state, action: PayloadAction<Person[]>) => {
                state.fetchingList = "completed";
                state.persons = action.payload;
            })

        // Create
        builder
            .addCase(createPerson.pending, (state) => {
                state.fetchingCreate = "fetching";
            })
            .addCase(createPerson.rejected, (state) => {
                state.fetchingCreate = "error";
            })
            .addCase(createPerson.fulfilled, (state, action: PayloadAction<Person>) => {
                state.persons = [action.payload, ...state.persons];
                state.fetchingCreate = "completed";
            });

        // Update
        builder
            .addCase(updatePerson.pending, (state) => {
                state.fetchingUpdate = "fetching";
            })
            .addCase(updatePerson.rejected, (state) => {
                state.fetchingUpdate = "error";
            })
            .addCase(updatePerson.fulfilled, (state, action: PayloadAction<Person>) => {
                state.persons = state.persons.map(n => n._id === action.payload._id ? action.payload : n);
                state.fetchingUpdate = "completed";
            });

        // Delete
        builder
            .addCase(deletePerson.pending, (state, action) => {
                state.deletesInProgress.push(action.meta.arg);
            })
            .addCase(deletePerson.rejected, (state, action) => {
                state.deletesInProgress = state.deletesInProgress.filter(id => id !== action.meta.arg);
            })
            .addCase(deletePerson.fulfilled, (state, action: PayloadAction<string>) => {
                state.deletesInProgress = state.deletesInProgress.filter(id => id !== action.payload);
                state.persons = state.persons.filter(n => n._id !== action.payload);
            })


    }
})

export const personsSelector = (state: RootState) => state.persons;
export const personSelector = (state: RootState, id: string) => state.persons.persons.find(n => n._id === id);
export default personsSlice.reducer;