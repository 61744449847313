export const storeToken = (token: string) => {
    localStorage.setItem('token', token);
    sessionStorage.setItem('token', token);
}

export const retrieveToken = (): string | null => {
    return sessionStorage.getItem('token') ?? localStorage.getItem('token');
}

export const storeTheme = (theme?: string) => {
    if (!theme) localStorage.removeItem('theme');
    else
        localStorage.setItem('theme', theme);
}
export const retrieveTheme = (): string | null => localStorage.getItem('theme');


export const storeLogo = (logo: string) => localStorage.setItem('logo', logo);
export const retrieveLogo = (): string | null => localStorage.getItem('logo');

export const retrieveAppliedVacancies = (): string[] => {
    const payload = localStorage.getItem('appliedVacancies');
    return payload ? payload.split(',') : [];
}

export const storeAppliedVacancy = (code: string) => {
    const payload = retrieveAppliedVacancies();
    payload.push(code);
    localStorage.setItem('appliedVacancies', payload.join(','));
}

export const storeDisabledColumns = (tableId: string, columns: string[]) => {
    localStorage.setItem(tableId, columns.join(','));
}

export const retrieveDisabledColumns = (tableId: string): string[] => {

    const payload = localStorage.getItem(tableId);
    return payload ? payload.split(',') : [];
}