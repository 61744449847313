import { lazy } from "react";
import { settingRoutes } from "../paths";
import { PlatformUser } from "../../types/user";
import { ProtectedRoutes } from "../ProtectedRoutes";

const CoOwnerPage = lazy(() => import("../../ui/pages/settings/Owner"));
const UsersPage = lazy(() => import("../../ui/pages/settings/Users"));
const DocListsPage = lazy(() => import("../../ui/pages/settings/documenten/DocumentLists"));
const DocListPage = lazy(() => import("../../ui/pages/settings/documenten/DocumentList"));
const EmailTemplatesPage = lazy(() => import("../../ui/pages/settings/email/EmailTemplatesPage"));
const EmailTemplatePage = lazy(() => import("../../ui/pages/settings/email/EmailTemplatePage"));

const DocumentsNavPage = lazy(() => import("../../ui/pages/settings/documenten/DocumentsPage"));
const PdfsPage = lazy(() => import("../../ui/pages/settings/documenten/PdfListPage"));
const DocumentStorePage = lazy(() => import("../../ui/pages/settings/documenten/DocumentStore"));
const StoreItemEditorPage = lazy(() => import("../../ui/pages/settings/documenten/StoreItemEditorPage"));
const FormsPage = lazy(() => import("../../ui/pages/settings/documenten/FormsPage"));

const FormViewerPage = lazy(() => import("../../ui/pages/settings/documenten/FormEditorPage"));
const PdfViewerPage = lazy(() => import("../../ui/pages/settings/documenten/PdfEditorPage"));

const CategoryListPage = lazy(() => import("../../ui/pages/settings/documenten/CategoryListPage"));
const CategoryEditorPage = lazy(() => import("../../ui/pages/settings/documenten/CategoryEditorPage"));

const ContractSettingsPage = lazy(() => import("../../ui/pages/settings/contracten/ContractSettingsPage"));
const ContractReferencesPage = lazy(() => import("../../ui/pages/settings/contracten/ContractReferencesPage"));
const ContractDefaultValuesSelectorPage = lazy(() => import("../../ui/pages/settings/contracten/ContractDefaultValuesSelectorPage"));
const ContractDefaultValuesPage = lazy(() => import("../../ui/pages/settings/contracten/ContractDefaultValuesPage"));


export function WhitelabelSettingsRoutes(user: PlatformUser) {
    const pathsAndComponentsMap = {
        [settingRoutes.users]: UsersPage,
        [settingRoutes.owner]: CoOwnerPage,
        [settingRoutes.documentLists]: DocListsPage,
        [settingRoutes.emailTemplates]: EmailTemplatesPage,
        [settingRoutes.emailTemplate(":type")]: EmailTemplatePage,
        [settingRoutes.documentList(":id")]: DocListPage,
        [settingRoutes.documents]: DocumentsNavPage,

        [settingRoutes.pdfs]: PdfsPage,
        [settingRoutes.pdf(":id")]: PdfViewerPage,

        [settingRoutes.documentStore]: DocumentStorePage,
        [settingRoutes.documentStoreItem(":id", ":type")]: StoreItemEditorPage,
        [settingRoutes.forms]: FormsPage,
        [settingRoutes.form(":id")]: FormViewerPage,

        [settingRoutes.categories]: CategoryListPage,
        [settingRoutes.category(":id")]: CategoryEditorPage,

        [settingRoutes.contracts]: ContractSettingsPage,
        [settingRoutes.contractReferences]: ContractReferencesPage,
        [settingRoutes.contractDefaultValuesSelector]: ContractDefaultValuesSelectorPage,
        [settingRoutes.contractDefaultValues(":type")]: ContractDefaultValuesPage,

    };

    return <>
        {ProtectedRoutes({
            user,
            pathsAndComponentsMap: pathsAndComponentsMap,
            isWhitelabel: true
        })}
    </>;
}
