import { lazy } from "react";
import { PlatformUser } from "../../types/user";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { organizationRoutes } from "../paths";

export const OrgCreatePage = lazy(() => import("../../ui/pages/company/Create"));
export const OrgListPage = lazy(() => import("../../ui/pages/company/List"));
export const OrgDetailsPage = lazy(() => import("../../ui/pages/company/Details"));

export function CompanyRoutes(user: PlatformUser) {
    const pathsAndComponentsMap = {
        [organizationRoutes.create]: OrgCreatePage,
        [organizationRoutes.customers]: OrgListPage,
        [organizationRoutes.all]: OrgListPage,
        [organizationRoutes.suppliers]: OrgListPage,
        [organizationRoutes.details(":id")]: OrgDetailsPage,
    };
    return <>
        {ProtectedRoutes({
            user,
            pathsAndComponentsMap: pathsAndComponentsMap
        })}
    </>;
}
