import { settingRoutes } from "../../../../router/paths";
import { DocumentList } from "../../../../types/documents";
import { BaseDocument } from "../../../../types/documents/Documents";
import { documentListTargetString, documentTargetIcon, documentTargetString, documentTypeIcon, documentTypeString } from "../../../../utils/documents";
import { ContextMenuMore } from "../../ContextMenu";
import { Link } from "../../Link";
import { RowCentered } from "../../Row";
import { ColumnProps, Table } from "../Table";

export function DocumentListItemsTable(props: {
    items: BaseDocument[];
    onDelete: (item: BaseDocument) => void;
}) {
    const colDefs: ColumnProps<BaseDocument>[] = [
        {
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: "Document Verwijderen",
                    onClick: () => props.onDelete(row.data),
                    color: "error"
                }
            ]} />,
            width: 65
        },
        {
            headerName: "Soort",
            width: 170,
            cellRenderer: (row) => {
                const Icon = documentTypeIcon(row.data.type);
                return <RowCentered>
                    <Icon color="primary" sx={{ mr: 2 }} />
                    {documentTypeString(row.data.type)}
                </RowCentered>
            }
        },
        {
            headerName: "Aanleveraar",
            width: 150,
            cellRenderer: (row) => {
                const Icon = documentTargetIcon(row.data.targetType);
                const label = documentTargetString(row.data.targetType);

                return <RowCentered>
                    <Icon color="primary" sx={{ mr: 1 }} />
                    {label}
                </RowCentered>
            }
        },
        {
            field: "title",
            headerName: "Titel"
        },
        {
            field: "description",
            headerName: "Omschrijving",
            flex: 1
        }
    ];

    return <Table id="DocLists" columns={colDefs} data={props.items} />
}

export function DocumentListsTable(props: {
    templates: DocumentList[];
    onDelete: (template: DocumentList) => void;
    onCopy: (template: DocumentList) => void;
}) {
    const colDefs: ColumnProps<DocumentList>[] = [
        {
            width: 70,
            cellRenderer: (row) => <ContextMenuMore buttons={[
                {
                    label: "Dupliceren",
                    color: "info",
                    onClick: () => props.onCopy(row.data)
                },
                {
                    label: "Verwijderen",
                    color: "error",
                    onClick: () => props.onDelete(row.data)
                }
            ]} />
        },
        {
            field: "target",
            headerName: "Soort",
            width: 150,
            cellRenderer: (row) => documentListTargetString(row.data.target)
        },
        {
            field: "title",
            headerName: "Titel",
            flex: 1,
            cellRenderer: (row) => <Link path={settingRoutes.documentList(row.data._id)}>{row.data.title}</Link>
        },
        {
            field: "description",
            headerName: "Omschrijving",
            flex: 2
        }
    ];

    return <Table id="TemplatesLists" columns={colDefs} data={props.templates} />
}