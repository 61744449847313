import { Periods } from "./Periods";

export enum Rates {
    Percentage = 1,
    Amount = 2
}

export interface Rate {
    period: Periods;
    name: string;
    kind: Rates;
    sum: number;
    receiverId: string;
}

export const defaultRate = (): Rate => ({
    name: '',
    period: Periods.Hour,
    kind: Rates.Percentage,
    sum: 0,
    receiverId: ''
});
