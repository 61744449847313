import { Box } from "@mui/material";
import styled from "styled-components";

export const Row = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
});

export const RowCentered = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
});
