import { Box, Container, Typography } from "@mui/material";
import { CootjeTitle, HeroPage } from "../../molecules";
import { CredentialsForm } from "../../forms/person/CredentialsForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { emailValidation } from "../../validations/email";
import { passwordValidation } from "../../validations";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";
import { personRoutes } from "../../../router/paths";
import { getQueryParam } from "../../../utils/queryParams";

import credsApi from "../../../api/organization/credentials";

const animation = require('../../../animations/hero-enter-credentials.json');

export default function PersonCredentialsPage() {
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const params = useParams<{
        environment: string;
        code: string;
    }>();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fetching, setFetching] = useState(false);

    const isValid = () => emailValidation(email) === undefined && passwordValidation(password) === undefined;

    const onRegister = async () => {
        setFetching(true);
        try {
            await credsApi.registerCredentials(params.environment!, params.code!, email, password);
            nav(personRoutes.registered);
        } catch (e: any) {
            dispatch(showToast({
                message: e.message,
                type: 'error'
            }));
        } finally {
            setFetching(false);
        }
    }

    useEffect(() => {
        const email = getQueryParam('email');
        if (email)
            setEmail(email);

    }, []);

    return (
        <HeroPage animation={animation} animationMaxWidth={400}>
            <Container maxWidth="sm" sx={{ mb: 20 }}>
                <CootjeTitle title="Registreren" />
                <Box sx={{ mt: 3 }} />
                <Typography variant="caption">Geef hier het email adres en wachtwoord op waar u mee wilt inloggen.</Typography><br />
                <Typography variant="caption">Deze gegevens worden automatisch met uw Cootje profiel gekoppeld.</Typography>
                <Box sx={{ mt: 3 }} />
                <CredentialsForm
                    email={email}
                    onChange={(creds) => {
                        setEmail(creds.email);
                        setPassword(creds.password);
                    }}
                    password={password}
                />

                <LoadingButton loading={fetching} onClick={onRegister} disabled={!isValid()} variant="text">Versturen</LoadingButton>

            </Container>
        </HeroPage>
    );
}