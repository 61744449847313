import { Division } from "../../types/contracts";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class DivisionsApi extends BaseApi {
    constructor() { super(apiRoutes.contracts + "/divisies"); }
    public list = async () => await this.fetchJson<Division[]>();
}

const contractsApi = new DivisionsApi();
export default contractsApi;