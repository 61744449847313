import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, CircularProgress } from "@mui/material";

import { FormEditorMolecule, Toolbar } from "../../molecules";
import { FormItem } from "../../../types/documents/Form";

import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";

import formTemplatesApi from "../../../api/documents/forms";
import storeApi from "../../../api/documents/store";
import { StoreFormItem, StoreItemType } from "../../../types/documents/Store";
import { CancelTwoTone, DownloadTwoTone, EditTwoTone } from "@mui/icons-material";

export function WhitelabelFormEditorOrganism() {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [items, setItems] = useState<FormItem[]>([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!id)
            return;

        setFetching(true);
        formTemplatesApi.get(id)
            .then((form) => {
                setTitle(form.title);
                setDescription(form.description);
                setItems(form.formFields);
            })
            .finally(() => setFetching(false));
    }, [id]);

    const onSave = async (items: FormItem[], title: string, description: string) => {
        setSaving(true);
        await formTemplatesApi.update({
            _id: id!,
            title,
            description,
            formFields: items
        } as any).then((result) => {

            if (result.ok) {
                dispatch(showToast({
                    message: 'Formulier opgeslagen',
                    type: 'success'
                }))
            } else {
                dispatch(showToast({
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }))
            }
        })
            .finally(() => setSaving(false));
    }

    return <>
        {!fetching && <FormEditorMolecule
            editing={true}
            onSave={onSave}
            saving={saving}
            initialValues={{ title, description, items }}
        />}
        {fetching && <CircularProgress />}
    </>
}

export function StoreFormEditorOrganism() {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [items, setItems] = useState<FormItem[]>([]);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!id)
            return;

        setFetching(true);
        storeApi.get(id)
            .then((storeItem) => {
                const item = storeItem as StoreFormItem;
                setTitle(item.title);
                setDescription(item.description);
                setItems(item.formFields);
            })
            .finally(() => setFetching(false));
    }, [id]);

    const onSave = async (items: FormItem[], title: string, description: string) => {
        setSaving(true);
        await storeApi.update({
            _id: id!,
            title,
            description,
            formFields: items,
            type: StoreItemType.Form
        } as any).then((result) => {

            if (result.ok) {
                dispatch(showToast({
                    message: 'Formulier opgeslagen',
                    type: 'success'
                }))
            } else {
                dispatch(showToast({
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }))
            }
        })
            .finally(() => setSaving(false));
    }

    const onImportDocument = async () => {
        try {
            await storeApi.import(id!);
            dispatch(showToast({
                message: 'Formulier geïmporteerd',
                type: 'success'
            }))
        } catch (e) {
            dispatch(showToast({
                message: 'Er is iets misgegaan',
                type: 'error'
            }))
        }
    };

    return <>
        <Toolbar sx={{ mt: 2 }}>
            <Button color='secondary' onClick={onImportDocument}>
                <DownloadTwoTone fontSize="small" sx={{ mr: 1 }} />
                Importeren naar Whitelabel
            </Button>
            {!editing && <Button onClick={() => setEditing(true)}>
                <EditTwoTone fontSize="small" sx={{ mr: 1 }} />
                Bewerken
            </Button>}
            {editing && <Button onClick={() => setEditing(false)} color='error'>
                <CancelTwoTone fontSize="small" sx={{ mr: 1 }} />
                Annuleren
            </Button>}
        </Toolbar>
        {!fetching && <FormEditorMolecule
            editing={editing}
            onSave={onSave}
            saving={saving}
            initialValues={{ title, description, items }}
        />}
        {fetching && <CircularProgress />}
    </>
}