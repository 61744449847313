import { Address, EmptyAddress } from "./address";
import { EmptyVestiging, KvkBasisProfiel } from "./kvk";

export enum CompanyType {
    BV = 1, // Besloten vennootschap
    NV = 2, // Naamloze vennootschap
    VOF = 3, // Vennootschap onder firma
    ZZP = 4, // Zelfstandige zonder personeel
    STICHTING = 5, // Stichting
    VERENIGING = 6, // Vereniging
    CV = 7, // Commanditaire vennootschap
    Ltd = 8, // Limited
    Inc = 9, // Incorporated
    Other = 1000 // Other
}

export interface Company extends KvkBasisProfiel {
    _id: string;
    btw: string;
    phone: string;
    billingAddress: string;
    genericAddress: string;
    vestigingsNummer: string;
    rechtsvorm: CompanyType;
    address: Address;
}

export class EmptyCompany implements Company {
    _id = '';
    btw = '';
    billingAddress = '';
    address: Address = EmptyAddress();
    phone = '';
    formeleRegistratiedatum = '';
    handelsnamen = [];
    indNonMailing = '';
    kvkNummer = '';
    materieleRegistratie = { datumAanvang: '' };
    naam = '';
    sbiActiviteiten = [];
    totaalWerkzamePersonen = 0;
    vestiging = EmptyVestiging();
    genericAddress = '';
    rechtsvorm = CompanyType.ZZP;
    vestigingsNummer: string = '';
}
