import { Box, Container, Typography } from "@mui/material";
import { ContainedButton, CootjeTitle, HeroPage } from "../../molecules";
const animation = require('../../../animations/hero-enter-credentials.json');
export default function RegistrationCompletePage() {
    return (
        <HeroPage top animation={animation} animationMaxWidth={400}>
            <Container maxWidth="sm">
                <Box sx={{ mt: 10 }} />
                <CootjeTitle title="Geregistreerd" />
                <Box pl={4}>
                    <Typography variant="h5" color={'secondary'} sx={{ mt: 4, mb: 2 }}>Registratie voltooid</Typography>
                    <Typography variant="body1">Uw registratie is voltooid. U kunt nu inloggen.</Typography>
                    <ContainedButton
                        sx={{ mt: 2 }}
                        onClick={() => {
                            window.location.href = '';
                        }}
                    >Inloggen</ContainedButton>
                </Box>
            </Container>
        </HeroPage>
    );
}