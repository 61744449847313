import { Box, Chip, Link, Typography } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import { Professional, WorkExperience } from "../../../../types/recruiter/professional";
import { useNavigate } from "react-router-dom";
import { Table } from "../Table";
import { recruiterRoutes } from "../../../../router/paths";

export const SkillsRenderer = (props: CustomCellRendererProps) => <Box display='flex'>{
    props.value.map((skill: string) => <Chip label={skill} key={skill} />)
}</Box>

export const FunctionRenderer = (props: CustomCellRendererProps) => <Box display='flex' alignItems={'center'} py={1}>
    <Box sx={{ borderRadius: 2, minWidth: 40, background: "#eee", px: 1, py: 0, lineHeight: 1 }}>
        <Typography variant="overline" sx={{ fontSize: '.6rem' }}>
            {props.value}
        </Typography>
    </Box>
</Box>

export const ExperiencesRenderer = (props: CustomCellRendererProps) => <Box display='flex' alignItems={'center'} py={1}>
    {
        props.value.map((experience: WorkExperience) => <Box key={experience.company + experience.title} sx={{ borderRadius: 2, minWidth: 40, background: "#34495e", px: 1, py: 0, lineHeight: 1, mr: 1 }}>
            <Typography color="white" variant="overline" sx={{ fontSize: '.6rem' }}>
                {experience.company}
            </Typography>
        </Box>)
    }

</Box>
export function ProfessionalsTable(props: {
    professionals: Professional[]
}) {
    const navigate = useNavigate();

    // Column Definitions: Defines the columns to be displayed.
    const colDefs = [
        {
            field: "name", headerName: "Naam", width: 200,
            cellRenderer: (row: any) => <Link onClick={() => navigate(recruiterRoutes.kandidaten.details(row.data._id))}>{row.data.name}</Link>,
        },
        { field: "title", headerName: "Functie", width: 250, cellRenderer: FunctionRenderer },
        { field: "city", headerName: "Plaats", width: 180 },
        { field: "skills", headerName: "Vaardigheden", flex: 1, cellRenderer: SkillsRenderer },
    ];

    return <Table id="Profs" slim columns={colDefs} data={props.professionals} />
}