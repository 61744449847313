import { Box, Checkbox, Typography } from "@mui/material";
import { MembershipRole } from "../../types/company/membership";
import { getMembershipFriendlyName } from "../../utils/membershipRoles";

export function RolesListMolecule(props: {
    onChanged: (roles: MembershipRole[]) => void;
    selectedRoles: MembershipRole[];
}) {
    const { selectedRoles } = props;

    const onSelectRole = (role: MembershipRole) => () => {
        if (selectedRoles.includes(role)) {
            props.onChanged(selectedRoles.filter(r => r !== role));
        } else {
            props.onChanged([...selectedRoles, role]);
        }
    }

    return <Box>
        {[
            MembershipRole.Owner,
            MembershipRole.AuthorizedSignatory,
            MembershipRole.Employed,
            MembershipRole.TimesheetApprover
        ].map(role => <Box display={'flex'} key={role} alignItems={'center'}>
            <Checkbox
                title={getMembershipFriendlyName(role)}
                checked={selectedRoles.includes(role)}
                onChange={onSelectRole(role)}
            />
            <Typography variant="overline">
                {getMembershipFriendlyName(role)}
            </Typography>
        </Box>
        )}
    </Box>
}