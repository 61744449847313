import { Button, Typography, Box } from "@mui/material";
import { FC, ReactNode } from "react";
import { DraggableComponent } from "./DraggableComponent";

export const ToolbarUIButton: FC<{ title: string, item: any, icon: ReactNode }> = ({ title, item, icon }) => <>
    <DraggableComponent data={{ item }}>
        <Button variant="text" sx={{ textTransform: 'none', p: 2 }}>
            {
                icon
            }
            <Typography variant="caption" color="secondary" ml={1} fontWeight={500} fontSize={14}>{title}</Typography>
        </Button>
    </DraggableComponent>
    <Box mr={1} />
</>