import React from 'react';
import { useDrag } from 'react-dnd';

interface DraggableComponentProps {
    children: React.ReactNode;
    data: any;
    disabled?: boolean;
}

export const DraggableComponent: React.FC<DraggableComponentProps> = ({ children, data: item, disabled }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'GENERIC',
        item,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    return (
        <div
            ref={disabled ? undefined : drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: disabled ? 'initial' : 'move',
            }}
        >
            {children}
        </div>
    );
};
