
export interface BankAccount {
    IBAN: string;
    Division: number;
    DivisionInlener: number;
}

export const defaultBankAccount = (): BankAccount => ({
    IBAN: '',
    Division: 0,
    DivisionInlener: 0
});