import { Company } from "../../../../types/company/company";
import { Address } from "../../../../types/company/address";
import { companyTypeString } from "../../../../utils/companyType";
import { ColumnProps, Table } from "../Table";
import { Link as MuiLink } from "@mui/material";
import { Link } from "../../Link";
import { organizationRoutes } from "../../../../router/paths";

export function CompaniesTable(props: {
    companies: Company[];
}) {
    const toMapsUrl = (address: Address) => `https://www.google.com/maps/place/${address.straatnaam}+${address.huisnummer},+${address.postcode}+${address.plaats}`.replaceAll(' ', '+');
    const colDefs: ColumnProps<Company>[] = [
        {
            headerName: "Naam",
            cellRenderer: (row) => {
                if (!row.data) return null;
                return <Link path={organizationRoutes.details(row.data._id)}>{row.data.naam}</Link>
            },
        },
        {
            headerName: "Plaats",
            cellRenderer: (row) => {
                const adr = row.data.address;
                if (!adr) return null;
                return <MuiLink onClick={() => window.open(toMapsUrl(adr))}>{adr.plaats}</MuiLink>
            },
        },
        {
            field: 'billingAddress',
            headerName: "Factuur email"
        },
        {
            field: "phone",
            headerName: "Telefoon"
        },
        {
            field: "kvkNummer",
            headerName: "KvK"
        },
        {
            headerName: "Soort",
            cellRenderer: (row) => companyTypeString(row.data!.rechtsvorm),
            flex: 1
        },
    ];

    return <Table id="Companies" slim columns={colDefs} data={props.companies} />;
}

