import { useEffect, useState } from 'react';

const useCtrlPressed = (): boolean => {
    const [isCtrlPressed, setIsCtrlPressed] = useState(false);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey)
            setIsCtrlPressed(true);
    }

    const handleKeyUp = (e: KeyboardEvent) => {
        if (!e.ctrlKey && !e.metaKey)
            setIsCtrlPressed(false);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    return isCtrlPressed;
};

export default useCtrlPressed;