import { generateGuid } from "../../utils/strings";


// Enum equivalent for TextWeight
export enum TextWeight {
    Regular = 1,
    Light = 2,
    Thin = 3,
    Bold = 4
}

// Interface equivalent for OrganizationForm
export interface OrganizationPdf {
    _id: string;
    title: string;
    description: string;
    storeReferenceId?: string;
    payload: PdfPayload;
}

// Interface equivalent for PdfPayload
export interface PdfPayload {
    components: PdfComponent[];
}

// Enum equivalent for PdfComponents
export enum PdfComponents {
    HorizontalLine = 1,
    Text = 3,
    Header = 4,
    Footer = 5,
    Checkbox = 6,
    TextField = 7,
    Signature = 8,
    Signatures = 9,
    LeftRightText = 10,
    Break = 11,
    Table = 12,
    HorizontalLineProps = 13,
    NextPage = 14
}

// Interface equivalent for PdfComponent
export interface PdfComponent {
    _id: string;
    component: PdfComponents;
}

// Interface equivalent for HorizontalLineComponent
export interface HorizontalLineComponent extends HorizontalLineProps, PdfComponent {
    component: PdfComponents.HorizontalLine;
}

export const defaultHorizontalLine = (): HorizontalLineComponent => ({
    _id: generateGuid(),
    component: PdfComponents.HorizontalLine,
    color: '#000',
    height: 1
});

// Interface equivalent for TextComponent
export interface TextComponent extends TextProps, PdfComponent {
    component: PdfComponents.Text;
}

export const DefaultTextComponent = (): TextComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Text,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    weight: TextWeight.Regular,
    italic: false,
    fontSize: 12
});

export const DefaultTitleComponent = (): TextComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Text,
    text: 'Inhoudelijke titel',
    weight: TextWeight.Bold,
    italic: false,
    fontSize: 16
});

// Interface equivalent for HeaderComponent
export interface HeaderComponent extends PdfComponent {
    imageUri: string;
    lines: TextProps[];
    component: PdfComponents.Header;
}

export const defaultHeaderComponent = (): HeaderComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Header,
    imageUri: 'https://www.compliancefactory.nl/wp-content/uploads/2021/05/NieuwLogo_Groot.png',
    lines: [
        {
            text: 'Compliance Factory',
            weight: TextWeight.Bold,
            italic: false,
            fontSize: 12
        },
        {
            text: '4105JX, Culemborg',
            weight: TextWeight.Regular,
            italic: false,
            fontSize: 12
        },
        {
            text: 'Belle van Zuylenlaan 1-7',
            weight: TextWeight.Regular,
            italic: false,
            fontSize: 12
        }
    ]
});

// Interface equivalent for FooterComponent
export interface FooterComponent extends PdfComponent {
    lines: TextProps[];
    component: PdfComponents.Footer;
}

export const defaultFooterComponent = (): FooterComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Footer,
    lines: [
        {
            text: 'Compliance Factory',
            weight: TextWeight.Bold,
            italic: false,
            fontSize: 12
        },
        {
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            weight: TextWeight.Regular,
            italic: false,
            fontSize: 12
        },
        {
            text: 'Consectetur adipiscing elit.',
            weight: TextWeight.Regular,
            italic: false,
            fontSize: 12
        }
    ]
});

// Interface equivalent for CheckboxComponent
export interface CheckboxComponent extends TextProps, PdfComponent {
    component: PdfComponents.Checkbox;
}

export const defaultCheckboxComponent = (): CheckboxComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Checkbox,
    text: 'Ja',
    weight: TextWeight.Regular,
    italic: false,
    fontSize: 12
});

// Interface equivalent for TextFieldComponent
export interface TextFieldComponent extends PdfComponent {
    widthRatio: number;
    height: number;
    placeholder?: string;
    component: PdfComponents.TextField;
}

export const defaultTextFieldComponent = (): TextFieldComponent => ({
    _id: generateGuid(),
    component: PdfComponents.TextField,
    widthRatio: 1,
    height: 50
});

// Interface equivalent for SignatureComponent
export interface SignatureComponent extends PdfComponent {
    content: string;
    component: PdfComponents.Signature;
}

export const defaultSignatureComponent = (): SignatureComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Signature,
    content: '**signature_right**'

});

// Interface equivalent for SignaturesComponent
export interface SignaturesComponent extends PdfComponent {
    leftContent: string;
    rightContent: string;
    component: PdfComponents.Signatures;
}

export const defaultSignaturesComponent = (): SignaturesComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Signatures,
    leftContent: '**signature_left**',
    rightContent: '**signature_right**'
});

// Interface equivalent for LeftRightTextComponent
export interface LeftRightTextComponent extends PdfComponent {
    left: TextProps;
    right: TextProps;
    component: PdfComponents.LeftRightText;
}

export const defaultLeftRightTextComponent = (): LeftRightTextComponent => ({
    _id: generateGuid(),
    component: PdfComponents.LeftRightText,
    left: {
        text: 'Tekst linkerkant',
        weight: TextWeight.Regular,
        italic: false,
        fontSize: 12
    },
    right: {
        text: 'Tekst rechterkant',
        weight: TextWeight.Regular,
        italic: false,
        fontSize: 12
    }
});

// Interface equivalent for BreakComponent
export interface BreakComponent extends PdfComponent {
    height: number;
    component: PdfComponents.Break;
}

export const defaultBreakComonent = (size: number): BreakComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Break,
    height: size
});

// Interface equivalent for TableComponent
export interface TableComponent extends PdfComponent {
    data: any[];
    columnProps: ColumnProps[];
    invisible: boolean;
    component: PdfComponents.Table;
}

export const defaultTable = (items: any[]): TableComponent => ({
    _id: generateGuid(),
    component: PdfComponents.Table,
    data: items,
    columnProps: [],
    invisible: false
});

// Interface equivalent for NextPageComponent
export interface NextPageComponent extends PdfComponent {
    component: PdfComponents.NextPage;
}

export const defaultNextPageComponent = (): NextPageComponent => ({
    _id: generateGuid(),
    component: PdfComponents.NextPage
});

// Interface equivalent for HorizontalLineProps
export interface HorizontalLineProps {
    color: string;
    width?: number;
    height: number;
}

// Enum equivalent for ColumnAlignment
export enum ColumnAlignment {
    Left = 1,
    Center = 2,
    Right = 3
}

// Interface equivalent for ColumnProps
export interface ColumnProps {
    index: number;
    flex: number;
    alignment: ColumnAlignment;
    textStyle?: TextProps;
}

// Interface equivalent for TextProps
export interface TextProps {
    text: string;
    weight: TextWeight;
    italic: boolean;
    fontSize: number;
}