import { Box, Button, Typography } from "@mui/material";
import { FormContainer, TextInput } from "../../molecules";
import { combinedValidations, passwordValidation, requiredValidation } from "../../validations";
import { emailValidation } from "../../validations/email";
import { useState } from "react";

export function CredentialsForm(props: {
    email: string;
    password: string;
    onChange: (credentials: { email: string, password: string }) => void;
}) {
    const [showPassword, setShowPassword] = useState(false);

    return <FormContainer>

        <Box>
            <TextInput
                label="Email"
                required
                validator={combinedValidations(requiredValidation, emailValidation)}
                value={props.email} onChange={
                    (email) => props.onChange({ email, password: props.password })
                } />

            <TextInput
                sx={{ mt: 2, mb: 3 }}
                label="Wachtwoord"
                type={showPassword ? 'text' : 'password'}
                required
                rightNeighbour={
                    <Button onClick={() => setShowPassword(!showPassword)}>
                        <Typography variant="caption">
                            {showPassword ? 'Verbergen' : 'Tonen'}
                        </Typography>
                    </Button>
                }
                validator={combinedValidations(requiredValidation, passwordValidation)}
                value={props.password} onChange={
                    (passsword) => props.onChange({ email: props.email, password: passsword })
                } />
        </Box>
    </FormContainer>;
}
