import { BaseApi } from "../base";
import { apiRoutes } from "../routes";
import { AddCompanyPayload, Membership, MembershipPayload, WhitelabelOwner, WhitelabelOwnerSettings, WhitelabelTheme } from "../../types/company";

class WhitelabelApi extends BaseApi {
    constructor() { super(apiRoutes.organization + "/whitelabel"); }

    public listMemberships = () =>
        this.fetchJson<Membership[]>(`memberships`);

    public createMembership = (personId: string, payload: MembershipPayload) =>
        this.fetchJson<Membership>(`memberships/${personId}`, 'POST', payload);

    public updateMembership = (membershipId: string, payload: MembershipPayload) =>
        this.fetch(`memberships/${membershipId}`, 'PUT', payload);

    public deleteMembership = (membershipId: string) =>
        this.fetch(`memberships/${membershipId}`, 'DELETE');


    public getOwner = () => this.fetchJson<WhitelabelOwner>(`owner`);
    public registerOwner = (payload: AddCompanyPayload) => this.fetchJson<WhitelabelOwnerSettings>("owner", "POST", payload);
    public getThemeSettings = async () => {
        const theme = await this.fetchJson<WhitelabelTheme>("owner/theme-settings");
        if (theme.themeColor.length === 0)
            return theme;

        theme.themeColor = `#${theme.themeColor}`;

        return theme;

    };

    public updateThemeColor = async (hexCode: string) => {
        const urlEncoded = hexCode.replace("#", "");
        await this.fetch(`owner/theme-settings/color?hex=${urlEncoded}`, "PUT")
    };

    public updateThemeLogo = async (logo: File): Promise<string> => {
        const formData = new FormData();
        formData.append('logo', logo);

        var response = await this.sendFormData(`owner/theme-settings/logo`, formData, 'PUT', true);
        return await response.text();
    };

}

const api = new WhitelabelApi();
export default api;