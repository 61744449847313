import { BankAccount, BankAccountType } from "./bankAccount";
import { Person } from "./person";
import { Permissions } from "../user";

export interface CredentialsPayload {
    email: string;
    password: string;
}

export interface CreatePersonPayload {
    person: Person;
    credentials?: CredentialsPayload;
}

export interface AddCompanyPayload {
    bankAccount?: BankAccount;
    kvK: string;
    btw: string;
    phone: string;
    billingAddress: string;
    genericAddress: string;
    vestigingsNummer: string;
}

export interface UpdateCompanyPayload {
    phone: string;
    billingAddress: string;
    genericAddress: string;
}

export const EmptyAddCompanyPayload = (): AddCompanyPayload => ({
    kvK: "",
    btw: "",
    phone: "",
    billingAddress: "",
    genericAddress: "",
    vestigingsNummer: "",
    bankAccount: {
        _id: "",
        bIC: "",
        iBAN: "",
        type: BankAccountType.Company
    }
})

export interface MembershipPayload {
    roles: number[];
    permissions: Permissions[];
}