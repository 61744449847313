import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ContainedButton, DotsLoader } from "../../molecules";
import { EmailEditorMolecule } from "../../molecules/email/EmailEditor";

import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";
import { SaveTwoTone } from "@mui/icons-material";

import api from "../../../api/email/templates";

export function EmailTemplateOrganism(props: {
    type: string;
}) {
    const { type } = props;
    const dispatch = useAppDispatch();
    const [html, setHtml] = useState<string>("");
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!type) return;

        setFetching(true);
        api
            .get(type)
            .then(res => setHtml(res))
            .finally(() => setFetching(false));

    }, [type]);

    const onSave = () => {
        setSaving(true);
        api
            .update(type, html)
            .then(() => dispatch(showToast({
                message: "Template opgeslagen",
                type: "success"
            })))
            .finally(() => setSaving(false));
    }

    const withNameButton = type === "5";
    const withGenericNameButton = type === "7"
    const withPwdResetLinkButton = type === "6";
    const withCredentialsLinkButton = type === "5";
    const withInviteLinkButton = type === "1" || type === "2" || type === "3" || type === "4";
    const withVacancyLinkButton = type === "7";


    if (fetching)
        return <DotsLoader message="Template word opgehaald" />

    return <Box>
        <ContainedButton onClick={onSave} sx={{ mb: 2 }} disabled={saving}>
            <SaveTwoTone sx={{ mr: 1 }} fontSize="small" />
            <Typography variant="caption">
                Opslaan
            </Typography>
        </ContainedButton>
        <EmailEditorMolecule html={html} onChange={setHtml}
            config={{
                withNameButton,
                withPwdResetLinkButton,
                withCredentialsLinkButton,
                withInviteLinkButton,
                withGenericNameButton,
                withVacancyLinkButton
            }}
        />
    </Box>
}