import { useEffect, useState } from "react";

import { Toolbar } from "../../molecules";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { AddTwoTone } from "@mui/icons-material";
import { settingRoutes } from "../../../router/paths";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../store/slices/toastSlice";
import { useAppDispatch } from "../../../store/hooks";

import { StoreItem, StoreItemType } from "../../../types/documents/Store";
import { DocumentStoreTable } from "../../molecules/tables/documents";
import { AddStoreItemDialog } from "../../molecules/AddItemDialog";

import api from "../../../api/documents/store";

export function DocumentsStoreOrganism() {
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState<StoreItem[]>([]);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setFetching(true);
        api
            .list()
            .then(setItems)
            .finally(() => setFetching(false));
    }, []);

    const onAddItem = async (title: string, description: string, type: StoreItemType) => {
        try {
            setFetching(true);
            await api.create({
                _id: '',
                title,
                description,
                type
            });
            const newItems = await api.list();
            setItems(newItems);
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het toevoegen van het document',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
            setShowDialog(false);
        }
    }

    const onDelete = async (item: StoreItem) => {
        try {
            setFetching(true);
            await api.delete(item._id);
            const newItems = items.filter(i => i !== item);
            setItems(newItems);
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het verwijderen van het formulier',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
        }
    }

    const onCopyList = async (item: StoreItem) => {
        try {
            setFetching(true);

            var template = await api.get(item._id);
            var result = await api.create({
                ...template,
                _id: '',
                title: item.title + ' (Kopie)'
            });

            nav(settingRoutes.documentStoreItem(result._id, result.type.toString()));
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het dupliceren van het formulier',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
        }
    }

    return <>
        <Typography sx={{ py: 1, mb: 2 }} variant="h6">Documenten Winkel</Typography>
        <Toolbar sx={{ mt: 2 }}>
            <Button onClick={() => setShowDialog(true)}>
                <AddTwoTone fontSize="small" sx={{ mr: 1 }} />
                Document Toevoegen
            </Button>
        </Toolbar>

        {
            showDialog && <AddStoreItemDialog
                itemName="Document"
                fetching={fetching}
                onAddItem={onAddItem}
                onDismiss={() => setShowDialog(false)} />
        }
        {
            fetching && <Box sx={{ p: 8, display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={30} />
            </Box>
        }
        {
            !fetching && <DocumentStoreTable onCopy={onCopyList} onDelete={onDelete} items={items} />
        }
    </>
}