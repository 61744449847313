export enum Compensations {
    KM = 1,
    OV = 2,
    THUISWERK = 3
}

export interface Compensation {
    type: Compensations;
    purchasePrice: string;
    salePrice?: string;
}