import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, CircularProgress } from "@mui/material";

import { Toolbar } from "../../molecules";
import { useAppDispatch } from "../../../store/hooks";
import { showToast } from "../../../store/slices/toastSlice";

import { StorePDFItem, StoreItemType } from "../../../types/documents/Store";
import { CancelTwoTone, DownloadTwoTone, EditTwoTone } from "@mui/icons-material";

import { PdfComponent } from "../../../types/documents/Pdf";
import { PdfEditorMolecule } from "../../molecules/pdf/PdfEditor";

import pdfTemplatesApi from "../../../api/documents/pdfs";
import storeApi from "../../../api/documents/store";
import { generateGuid } from "../../../utils/strings";


export function WhitelabelPdfEditorOrganism() {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [components, setComponents] = useState<PdfComponent[]>([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!id)
            return;

        setFetching(true);
        pdfTemplatesApi.get(id)
            .then((pdf) => {
                setTitle(pdf.title);
                setDescription(pdf.description);

                pdf.payload.components.forEach(c => c._id = generateGuid());

                setComponents(pdf.payload.components);
            })
            .finally(() => setFetching(false));
    }, [id]);

    const onSave = async (items: PdfComponent[], title: string, description: string) => {
        setSaving(true);
        await pdfTemplatesApi.update({
            _id: id!,
            title,
            description,
            payload: {
                components: items
            }
        } as any).then((result) => {

            if (result.ok) {
                dispatch(showToast({
                    message: 'PDF opgeslagen',
                    type: 'success'
                }))
            } else {
                dispatch(showToast({
                    message: 'Er is iets misgegaan',
                    type: 'error'
                }))
            }
        })
            .finally(() => setSaving(false));
    }

    return <>
        {!fetching && <PdfEditorMolecule
            editing={true}
            onSave={onSave}
            saving={saving}
            initialValues={{ title, description, components }}
        />}
        {fetching && <CircularProgress />}
    </>
}

export function StorePdfEditorOrganism() {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [components, setComponents] = useState<PdfComponent[]>([]);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!id)
            return;

        setFetching(true);
        storeApi.get(id)
            .then((storeItem) => {
                const item = storeItem as StorePDFItem;
                setTitle(item.title);
                setDescription(item.description);
                setComponents(item.payload.components);
            })
            .finally(() => setFetching(false));
    }, [id]);

    const onSave = async (items: PdfComponent[], title: string, description: string) => {

        setSaving(true);
        await storeApi.update({
            _id: id!,
            title,
            description,
            type: StoreItemType.PDF,
            payload: {
                components: items
            }
        } as StorePDFItem).then((result) => {
            if (result.ok)
                return dispatch(showToast({
                    message: 'PDF opgeslagen',
                    type: 'success'
                }));

            dispatch(showToast({
                message: 'Er is iets misgegaan met het opslaan van PDF',
                type: 'error'
            }))
        })
            .finally(() => setSaving(false));
    }

    const onImportDocument = async () => {
        try {
            await storeApi.import(id!);
            dispatch(showToast({
                message: 'PDF geïmporteerd',
                type: 'success'
            }))
        } catch (e) {
            dispatch(showToast({
                message: 'Er is iets misgegaan',
                type: 'error'
            }))
        }
    };

    return <>
        <Toolbar sx={{ mt: 2 }}>
            <Button color='secondary' onClick={onImportDocument}>
                <DownloadTwoTone fontSize="small" sx={{ mr: 1 }} />
                Importeren naar Whitelabel
            </Button>
            {!editing && <Button onClick={() => setEditing(true)}>
                <EditTwoTone fontSize="small" sx={{ mr: 1 }} />
                Bewerken
            </Button>}
            {editing && <Button onClick={() => setEditing(false)} color='error'>
                <CancelTwoTone fontSize="small" sx={{ mr: 1 }} />
                Annuleren
            </Button>}
        </Toolbar>
        {!fetching && <PdfEditorMolecule
            editing={editing}
            onSave={onSave}
            saving={saving}
            initialValues={{ title, description, components }}
        />}
        {fetching && <CircularProgress />}
    </>
}