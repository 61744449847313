import { Theme } from "@emotion/react";
import { BusinessTwoTone, AbcTwoTone, LocationOnTwoTone, ConstructionTwoTone } from "@mui/icons-material";
import { SxProps, Box } from "@mui/material";
import { FormContainer, TextInput, DateInput, TextArea } from "../../../molecules";
import { requiredValidation } from "../../../validations";

type DetailsProps = {
    title: string,
    reference?: string,
    company: string,
    location: string,
    closureDateTime: string,
    description: string
}

export function VacancyDetailsForm(props: {
    value: DetailsProps,
    onChange: (data: DetailsProps) => void
    sx?: SxProps<Theme>
}) {
    const { title, company, closureDateTime, description, location, reference } = props.value;

    return <FormContainer sx={props.sx}>
        <TextInput label="Klant" icon={BusinessTwoTone} tooltip="De naam van het bedrijf die word weergegeven op de vacature. Er zal een automatische sluiting plaats vinden."
            onChange={(company) => props.onChange({
                ...props.value,
                company: company as string
            })} value={company} />

        <Box sx={{ mt: 2 }} />

        <TextInput required validator={requiredValidation} label="Titel" icon={AbcTwoTone} tooltip="De (functie) titel van de vacature. Bijvoorbeeld: Accountmanager IT - Telecommunicatie"
            onChange={(title) => props.onChange({
                ...props.value,
                title: title as string
            })}
            value={title} />

        <Box sx={{ mt: 2 }} />
        <Box style={{ position: 'relative' }}>
            <TextArea required
                validator={requiredValidation}
                label="Omschrijving"
                icon={ConstructionTwoTone}
                inputSx={{ minHeight: 90 }}
                onChange={(description) => props.onChange({
                    ...props.value,
                    description
                })}
                value={description} />
        </Box>

        <Box sx={{ mt: 2 }} />

        <TextInput label="Referentie" icon={AbcTwoTone} tooltip="Een referentie nummer of code die word gebruikt om de vacature te identificeren."
            onChange={(_ref) => props.onChange({
                ...props.value,
                reference: _ref as string
            })}
            value={reference} />

        <Box sx={{ mt: 2 }} />
        <TextInput label="Locatie" icon={LocationOnTwoTone} tooltip="Waar de werkzaamheden zullen worden uitgevoerd"
            onChange={(location) => props.onChange({
                ...props.value,
                location: location as string
            })}
            value={location} />

        <Box sx={{ mt: 2 }} />
        <DateInput required icon="busy"
            onChange={(closureDateTime) => props.onChange({
                ...props.value,
                closureDateTime: closureDateTime.toISOString()
            })}
            value={new Date(closureDateTime)}
            label="Vacature sluiting" tooltip="Tot en met wanneer er ingeschreven kan worden op de vacature" />

    </FormContainer>
}