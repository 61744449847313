import { Contracts, Periods, TaoContractFase, TaoSalaryDate } from "../types/contracts";

export const periodsString = (value: Periods) => {
    switch (value) {
        case Periods.Day: return 'Dag';
        case Periods.Week: return 'Week';
        case Periods.FourWeeks: return '4 Wekelijks';
        case Periods.Month: return 'Maand';
        case Periods.Year: return 'Jaar';
        case Periods.Once: return 'Eenmalig';
        case Periods.Hour: return 'Uur';
    }
}

export const contractTypeString = (type: Contracts) => {
    switch (type) {
        case Contracts.Zzp: return 'ZZP';
        case Contracts.Tao: return 'TAO';
        case Contracts.Inhuur: return 'Inhuur';
    }
}

export const contractFaseString = (fase: TaoContractFase) => {
    switch (fase) {
        case TaoContractFase.A: return 'A';
        case TaoContractFase.B: return 'B';
        case TaoContractFase.C: return 'C';
        default: return '';
    }
}

export const salaryDateString = (value: TaoSalaryDate) => {
    switch (value.type) {
        case 1: return `Op de ${value.day}e na afloop van de maand`;
        case 2: return `Op de 25e van de huidige maand`;
        default: return '';
    }
}