
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    ClassicEditor,
    Heading,
    Bold,
    Essentials,
    Italic,
    Paragraph,
    Undo,
    Alignment,
    Image,
    Link,
    Font,
    ImageInsertViaUrl,
    ImageResize,
    ImageToolbar,
    HorizontalLine,
    FullPage
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import ToolbarLabelPlugin from './ToolbarLabelPlugin';
import { CredentialsLinkPlugin, GenericNamePlugin, InviteLinkPlugin, PwdResetLinkPlugin, UsernamePlugin, VacancyLinkPlugin } from './EmailPlugins';

export function EmailEditorMolecule(props: {
    html: string;
    onChange: (html: string) => void;
    config: {
        withNameButton?: boolean;
        withGenericNameButton?: boolean;
        withPwdResetLinkButton?: boolean;
        withCredentialsLinkButton?: boolean;
        withInviteLinkButton?: boolean;
        withVacancyLinkButton?: boolean;
    }
}) {
    const items = [
        'undo', 'redo', '|',
        'heading', '|',
        'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify', '|',
        'fontfamily', 'fontsize', 'bold', 'italic', 'horizontalLine', '|',
        'link', '|',
        'toolbarLabel'
    ];

    if (props.config.withNameButton)
        items.push('custom:name');

    if (props.config.withGenericNameButton)
        items.push('custom:genericName');

    if (props.config.withPwdResetLinkButton)
        items.push('custom:pwdResetLink');

    if (props.config.withCredentialsLinkButton)
        items.push('custom:credentialsLink');

    if (props.config.withInviteLinkButton)
        items.push('custom:inviteLink');

    if (props.config.withVacancyLinkButton)
        items.push('custom:vacancyPublishLink');

    return (
        <CKEditor
            editor={ClassicEditor} onChange={(_, editor) => props.onChange(editor.getData())}
            config={{
                image: {
                    toolbar: ['insertImageViaUrl'],
                    resizeUnit: 'px'
                },
                toolbar: { items, shouldNotGroupWhenFull: true },
                plugins: [
                    Heading,
                    Bold,
                    Essentials,
                    Italic,
                    Paragraph,
                    Undo,
                    Alignment,
                    Link,
                    Font,
                    Image,
                    ImageToolbar,
                    ImageResize,
                    ImageInsertViaUrl,
                    HorizontalLine,

                    ToolbarLabelPlugin,
                    UsernamePlugin,
                    PwdResetLinkPlugin,
                    InviteLinkPlugin,
                    VacancyLinkPlugin,
                    CredentialsLinkPlugin,
                    GenericNamePlugin,

                    FullPage
                ],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1 darkBlue' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2 darkBlue' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3 darkBlue' }
                    ]
                },
                initialData: props.html
            }}
        />
    );
}
