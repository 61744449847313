import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { Breakpoint, Container } from '@mui/material';

export interface StepProps {
    label: string;
    content: React.ReactNode;
}

export function FormStepper(props: {
    steps: StepProps[],
    activeStep: number,
    onStepClicked?: (step: number) => void,
    children?: React.ReactNode,
    maxWidth?: Breakpoint
}) {
    const { steps } = props;
    const renderSteps = () => steps.map((step, index) => (
        <Step key={step.label} completed={false}>
            <StepLabel onClick={() => props.onStepClicked && props.onStepClicked(index)} sx={{ cursor: props.onStepClicked && 'pointer !important' }}>
                <Typography variant={index === props.activeStep ? 'h6' : 'overline'} color="secondary">
                    {step.label}
                </Typography>
            </StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}>
                <Box mt={2} />
                {
                    step.content
                }

                {props.children}
            </StepContent>
        </Step>
    ));

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Container maxWidth={props.maxWidth ?? "sm"} sx={{ margin: 0, padding: 0 }}>
                <Stepper activeStep={props.activeStep} orientation="vertical" connector={<></>}>
                    {renderSteps()}
                </Stepper>
            </Container>
        </Box >
    );
}