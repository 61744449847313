
export const tutorialDescriptions = {
    companyDetailsForm: {
        sections: [
            {
                title: "",
                description:
                    `Gebruik het zoekveld om uw bedrijf middels de KvK op te zoeken. Als uw bedrijf niet gevonden wordt, kunt u het handmatig toevoegen door het KvK nummer in te vullen.

                    Nadat uw bedrijf is geselecteerd middels het zoeken zullen de vestigingen van uw bedrijf worden opgehaald. Selecteer vervolgens de vestiging die u wilt registreren.

                    Hierna zal Cootje uw bedrijfsgegevens ophalen en kunt u deze aanvullen.`
            },
            {
                title: 'Registratie per vestiging',
                description: `Het is verplicht om dit in combinatie met een vestiging te registreren.
                    Een andere vestiging dient dus apart te worden geregistreerd in combinatie met hetzelfde KvK-nummer.`
            },
            {
                title: 'Btw Validatie',
                description: `Wanneer u ook een BTW nummer aanlevert, zal deze automatisch gevalideerd worden bij VIES, deze dient dus overeen te komen met het geregistreerde KvK- nummer.`
            }
        ]
    },

    fincialForm: {
        sections: [
            {
                title: "",
                description: `Hier gaan we uw rekening leeg trekken.
                Harken noemen we dat hier :)`
            },
            {
                title: `Validatie`,
                description: `Er word gevalideerd of het formaat van het rekeningnummer klopt.Controleer deze dus goed.`
            },
            {
                title: `Niet verplicht`,
                description: `Indien u nog geen rekening heeft voor uw bedrijf kunt u deze leeg laten.
                
                Let op: Voor het opstellen van contracten is een rekeningnummer wel verplicht.`
            }
        ]
    },

    contactPersonForm: {
        sections: [
            {
                title: "Contactpersonen",
                description: `Hier kunt u contactpersonen van uw organisatie toevoegen.
                Voor ieder contactpersoon kunt u het volgende aangeven:

            1. Inloggen toegestaan
                2. Rollen
                3. Rechten
                
                Let op: Het is verplicht om minimaal één contactpersoon met de rol Tekenbevoegde toe te voegen om de registratie te kunnen afronden.`
            },
            {
                title: "Inlogggen toegestaan",
                description: `Indien u inloggen toestaat, kan deze persoon inloggen op het platform. Wanneer u voor Ja kiest, zal deze persoon een email ontvangen om zijn of haar wachtwoord in te stellen.`
            },
            {
                title: "Rollen",
                description: `Hier kunt u aangeven welke rollen deze persoon heeft binnen uw organisatie, hierworden nog geen rechten aan verleend.
                Deze rollen zullen gebruikt worden bij het opstellen van contracten.`
            },
            {
                title: "Rechten",
                description: `Hier kunt u aangeven welke rechten deze persoon heeft voor uw organisatie binnen dit platform.`
            }
        ]
    },

    professionalForm: {
        sections: [
            {
                title: "",
                description: `Indien u een professional wilt toevoegen aan uw organisatie, kunt u dat hier doen. U kunt aangeven of deze professional inloggegevens mag ontvangen.`,
            },
            {
                title: "Inloggegevens",
                description: `Indien u inloggegevens toestaat, zal deze persoon een email ontvangen om zijn of haar wachtwoord in te stellen.`
            },
            {
                title: "Professional Dashboard",
                description: `Wanneer een professional mag inloggen, kan deze inloggen op het platform en gebruik maken van het professional dashboard.
                Hier worden de uren gerigistreerd en statistieken van deze persoon getoond. Er zullen geen rechten worden verleend aan deze persoon om uw organisatie in te kunnen zien.`,
            }
        ]
    },

    documentsForm: {
        sections: [
            {
                title: "",
                description: `Hier kunt u de benodigde documenten aanleveren ten behoeve van de registratie van uw bedrijf.`
            },
            {
                title: "Validatie",
                description: `Onze contract managers zullen de nodige documenten controleren, wanneer hier een goed- of afkeuring op paats vind zal u hiervan een melding ontvangen.`
            },
            {
                title: 'Niet verplicht',
                description: `Het aanleveren van documenten is niet verplicht, echter is het wel noodzakelijk om contracten op te kunnen stellen. Dit kan dus ook in een later stadium.`

            }
        ]
    },
}