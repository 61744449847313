import { Box, CircularProgress, Typography } from "@mui/material";
import { AddCompanyPayload } from "../../../types/company/payloads";
import { AsyncAutocomplete, FormContainer, TextInput } from "../../molecules";
import { Row } from "../../molecules/Row";
import { requiredValidation } from "../../validations";
import { emailValidation } from "../../validations/email";
import { useState } from "react";
import { useParams } from "react-router-dom";

import kvkApi from "../../../api/organization/kvk";
import { KvKListVestigingItem, KvKSearchResult } from "../../../types/company/kvk";

export function OrganizationDetailsForm(props: {
    company: AddCompanyPayload;
    onChange: React.Dispatch<React.SetStateAction<AddCompanyPayload>>;
    onKvkValidated: (isValid: boolean) => void;
}) {
    const { company, onChange } = props;
    const [companyName, setCompanyName] = useState<string>("");
    const [searching, setSearching] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<KvKSearchResult[]>([]);
    const [vestigingen, setVestigingen] = useState<KvKListVestigingItem[]>([]);
    const [selectedVestiging, setSelectedVestiging] = useState<KvKListVestigingItem | null>(null);
    const [fetching, setFetching] = useState<boolean>(false);
    const [fetchingVestigingen, setFetchingVestigingen] = useState<boolean>(false);

    const params = useParams();
    const env = params.environment;
    const code = params.code;

    const onKvkChanged = (kvk: string) => {
        //Check if company.kvK is numeric and has 8 characters
        if (!kvk || isNaN(Number(kvk)) || kvk.length !== 8) {
            props.onKvkValidated(false);
            setSelectedVestiging(null);
            setCompanyName("");
            return;
        }

        setFetching(true);
        (
            (env && code)
                ? kvkApi.getNaamgevingFromInvite(env, code, kvk)
                : kvkApi.getNaamgeving(kvk)
        )
            .then(x => setCompanyName(x.naam))
            .catch(_ => {
                props.onKvkValidated(false);
                setCompanyName("");
                alert("KvK nummer is niet gevonden");
            })
            .then(() => listVestigingen(kvk))
            .finally(() => setFetching(false));
    }

    const onKvkCompanySelected = (option: KvKSearchResult | null) => {
        if (!option) return;

        onChange({ ...company, kvK: option.kvkNummer });
        onKvkChanged(option.kvkNummer);
    }

    const searchKvK = (search: string) => {
        setSearching(true);
        ((env && code) ? kvkApi.searchKvkFromInvite(env, code, search) : kvkApi.searchKvk(search))
            .then(setSearchResults)
            .finally(() => setSearching(false));
    }

    const listVestigingen = async (kvk: string) => {
        setSelectedVestiging(null);

        setFetchingVestigingen(true);
        const vestigingen = await ((env && code) ? kvkApi.listKvkVestigingenFromInvite(env, code, kvk) : kvkApi.listKvkVestigingen(kvk));
        setVestigingen(vestigingen);
        setFetchingVestigingen(false);

        if (vestigingen.length > 0)
            onSelectVestiging(vestigingen[0]);
    }

    const onSelectVestiging = (vestiging: KvKListVestigingItem) => {
        setSelectedVestiging(vestiging);
        props.onKvkValidated(true);
        props.onChange(c => ({ ...c, vestigingsNummer: vestiging.vestigingsnummer }));
    }

    return <FormContainer>
        <Box display={'flex'} alignItems={'center'} mb={2} mt={0}>
            <Typography variant="caption">Primaire handelsnaam: </Typography>
            {companyName && !fetching && <Typography variant="subtitle2" sx={{ ml: 1 }}>{companyName}</Typography>}
            {fetching && <CircularProgress size={20} sx={{ ml: 1 }} />}
        </Box>


        <Box display={'flex'} alignItems={'center'} mb={2} mt={0}>
            <AsyncAutocomplete
                sx={{ flex: 3 }}
                getTitleFromOption={(option) => option.naam}
                onChange={searchKvK}
                onSelect={onKvkCompanySelected}
                fetching={searching}
                disabled={fetchingVestigingen}
                options={searchResults}
                title="Handelsnaam zoeken bij KvK"
            />
            <TextInput disabled={fetching} value={company.kvK} required validator={requiredValidation} label="KvK nummer" sx={{ flex: 1, pl: 1 }}
                onFocusLost={() => onKvkChanged(company.kvK)}
                onChange={(kvK) => onChange({ ...company, kvK })} />
        </Box>

        <Box sx={{ mb: 2 }} display={'flex'}>
            <AsyncAutocomplete
                sx={{ flex: 3 }}
                getTitleFromOption={(option) => option!.vestigingsnummer + ": " + option!.volledigAdres}
                options={vestigingen}
                onSelect={v => v && onSelectVestiging(v)}
                title="Selecteer vestiging"
                required
                selected={selectedVestiging}
            />
        </Box>

        <Row sx={{ mb: 1, mt: 1 }}>
            <TextInput value={company.btw} label="Btw ID / Nummer" sx={{ flex: 1 }} onChange={(btw) => onChange({ ...company, btw })} />
        </Row>
        <TextInput value={company.phone} required validator={requiredValidation} label="Telefoon nummer" sx={{ my: 1 }} onChange={(phone) => onChange({ ...company, phone })} />

        <TextInput value={company.genericAddress} required validator={emailValidation} label="Algemeen e-mailadres" sx={{ mb: 1, mt: 1 }} onChange={(genericAddress) => onChange({ ...company, genericAddress })} />
        <TextInput value={company.billingAddress} required validator={emailValidation} label="Facturen e-mailadres" sx={{ mb: 1, mt: 1 }} onChange={(billingAddress) => onChange({ ...company, billingAddress })} />

    </FormContainer>;
}
