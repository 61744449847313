import { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { AddItemDialog, Toolbar } from "../../molecules";
import { AddTwoTone } from "@mui/icons-material";
import { settingRoutes } from "../../../router/paths";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../store/slices/toastSlice";
import { useAppDispatch } from "../../../store/hooks";

import api from "../../../api/documents/forms";
import { OrganizationForm } from "../../../types/documents/Form";
import { OrganizationFormsTable } from "../../molecules/tables/documents";

export function FormsListOrganism() {
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState<OrganizationForm[]>([]);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setFetching(true);
        api
            .list()
            .then(setItems)
            .finally(() => setFetching(false));
    }, []);

    const onAddList = async (title: string, description: string) => {
        try {
            setFetching(true);
            await api.create({
                _id: '',
                formFields: [],
                title,
                description
            });
            const newItems = await api.list();
            setItems(newItems);
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het toevoegen van het formulier',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
            setShowDialog(false);
        }
    }

    const onDelete = async (item: OrganizationForm) => {
        try {
            setFetching(true);
            await api.delete(item._id);
            const newItems = items.filter(i => i !== item);
            setItems(newItems);
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het verwijderen van het formulier',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
        }
    }

    const onCopyList = async (item: OrganizationForm) => {
        try {
            setFetching(true);

            var template = await api.get(item._id);
            var result = await api.create({
                ...template,
                _id: '',
                title: item.title + ' (Kopie)'
            });

            nav(settingRoutes.form(result._id));
        }
        catch (e) {
            dispatch(showToast({
                message: 'Er is iets fout gegaan bij het dupliceren van het formulier',
                type: 'error'
            }))
        }
        finally {
            setFetching(false);
        }
    }

    return <>
        <Typography sx={{ py: 1, mb: 2 }} variant="h6">Formulieren</Typography>

        <Toolbar sx={{ mt: 2 }}>
            <Button onClick={() => setShowDialog(true)}>
                <AddTwoTone fontSize="small" sx={{ mr: 1 }} />
                Formulier Toevoegen
            </Button>
        </Toolbar>

        {
            showDialog && <AddItemDialog
                itemName="Formulier"
                fetching={fetching}
                onAddItem={onAddList}
                onDismiss={() => setShowDialog(false)} />
        }
        {
            fetching && <Box sx={{ p: 8, display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={30} />
            </Box>
        }
        {
            !fetching && <OrganizationFormsTable onCopy={onCopyList} onDelete={onDelete} templates={items} />
        }
    </>
}