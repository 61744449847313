import { FC, useEffect, useState } from "react";
import { DocumentList, DocumentListTargets, DocumentTarget } from "../../types/documents";
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, SxProps, Typography } from "@mui/material";

import listsApi from '../../api/documents/lists';
import { ExpandLessTwoTone, ExpandMoreTwoTone, SearchTwoTone } from "@mui/icons-material";
import { createBaseDocumentId, documentTargetString, documentTypeIcon } from "../../utils/documents";
import { TextInput } from "./inputs";
import { ContainedButton } from "./Buttons";
import { RowCentered } from "./Row";

export const RequestedDocumentPicker: FC<{
    listType: DocumentListTargets,
    selected: string[],
    onSelect: (docs: {
        _id: string,
        target: DocumentTarget
    }[]) => void,
    onDeselect: (ids: string[]) => void,
    sx?: SxProps
}> = ({
    listType, onSelect, selected, sx, onDeselect
}) => {
        const [lists, setLists] = useState<DocumentList[]>([]);
        const [search, setSearch] = useState('');

        useEffect(() => {
            listsApi
                .list()
                .then(lists => lists.filter(list => list.target === listType))
                .then(setLists);
        }, [listType]);

        const filterListItems = (list: DocumentList) => {
            if (!search) return list;

            const filteredList = {
                ...list,
                items: list.items.filter(item => item.title.toLowerCase().includes(search.toLowerCase()))
            };

            return filteredList.items.length > 0
                ? filteredList
                : null;
        }

        const withSearch = lists.map(filterListItems).filter(Boolean).sort((a: DocumentList | null, b: DocumentList | null) => {
            if (a!.title.toLowerCase().startsWith('overige')) return 1;
            if (b!.title.toLowerCase().startsWith('overige')) return -1;
            return 0;
        });

        return <>
            <TextInput
                icon={SearchTwoTone}
                label="Documenten zoeken"
                value={search}
                leftNeighbour={<ContainedButton onClick={() => setSearch('')} sx={{ maxHeight: 32 }}>Wissen</ContainedButton>}
                onChange={setSearch}
            />

            <List sx={sx}>
                {withSearch.map(list => <RequestedDocumentPickerList
                    forceOpened={search.length > 0}
                    key={list!._id}
                    list={list!}
                    selected={selected}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                />)}
            </List>

            <Typography variant="caption" sx={{ mt: 2 }}>
                {selected.length} documenten geselecteerd:
            </Typography>
            <br />
            <Typography variant="body2">
                {selected.map(id => {
                    const list = lists.find(list => list.items.some(item => createBaseDocumentId(item) === id));
                    const item = list?.items.find(item => createBaseDocumentId(item) === id);
                    return item ? ` ${item.title}` : null;
                }).join(', ')}
            </Typography>
        </>
    };

const RequestedDocumentPickerList: FC<{
    forceOpened?: boolean,
    list: DocumentList,
    selected: string[],
    onSelect: (docs: {
        _id: string,
        target: DocumentTarget
    }[]) => void,
    onDeselect: (ids: string[]) => void
}> = ({ list, onSelect, selected, forceOpened, onDeselect }) => {
    const [opened, setOpened] = useState(false);
    const shouldBeOpened = forceOpened || opened;

    const onSelectAll = (e: any) => {
        e.stopPropagation();

        const docs = list.items.map(d => ({
            _id: createBaseDocumentId(d),
            target: d.targetType
        }));

        onSelect(docs);
    };

    return <>
        <ListItemButton role={undefined} onClick={() => setOpened(!opened)} dense disableRipple>
            <ListItemIcon>
                {
                    shouldBeOpened
                        ? <ExpandLessTwoTone />
                        : <ExpandMoreTwoTone />
                }
            </ListItemIcon>
            <Typography>{list.title}</Typography>
            <Button onClick={onSelectAll} variant="text" sx={{ ml: 2 }}>Selecteer alle</Button>
        </ListItemButton>
        {
            shouldBeOpened && list.items.map(item => {
                const id = item.documentReferenceId || createBaseDocumentId(item);
                const Icon = documentTypeIcon(item.type);
                const checked = selected.includes(id);

                return <ListItem
                    key={id}
                    onClick={() => checked ? onDeselect([id]) : onSelect([{
                        _id: id,
                        target: item.targetType
                    }])}
                    sx={{ pl: 4 }}
                    dense
                >
                    <RowCentered sx={{
                        width: '100%',
                        borderRadius: 1,
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        },
                        mb: .5
                    }}>
                        <Checkbox checked={checked} tabIndex={-1} sx={{ p: 0 }} />
                        <Icon sx={{ mx: 2 }} color={checked ? 'primary' : 'secondary'} />
                        <Typography variant="body2" sx={{ mr: 2 }}>{item.title}</Typography>
                        <Typography fontStyle={'italic'} variant="caption">{documentTargetString(item.targetType)}</Typography>
                    </RowCentered>
                </ListItem >
            })
        }
    </>
}