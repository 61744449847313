import { FormItem } from "./Form";
import { PdfPayload } from "./Pdf";

// Enum equivalent for StoreItemType
export enum StoreItemType {
    Form = 1,
    PDF = 2
}

// Interface equivalent for StoreItem
export interface StoreItem {
    _id: string;
    title: string;
    description: string;
    type: StoreItemType;
}

// Interface equivalent for StoreFormItem
export interface StoreFormItem extends StoreItem {
    formFields: FormItem[];
}

// Interface equivalent for StorePDFItem
export interface StorePDFItem extends StoreItem {
    payload: PdfPayload;
}