import React from 'react';
import { useDrop } from 'react-dnd';

interface DroppableAreaProps {
    onDrop: (item: any) => void;
    children?: React.ReactNode;
}

export const DroppableArea: React.FC<DroppableAreaProps> = ({ onDrop, children }) => {
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: 'GENERIC',
        drop: (item) => {
            onDrop(item);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const isActive = isOver && canDrop;

    return (
        <div ref={drop}
            style={{
                backgroundColor: '#f6f6f6',
                borderRadius: 4,
                height: isActive ? 30 : 20,
            }}
        >
            {children}
        </div>
    );
};
