import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { InvoiceDetails, ReferenceValue } from "../../../types/contracts";
import { IconLabel, TextInput, YesNoToggle } from "../../molecules";
import { Row, RowCentered } from "../../molecules/Row";
import { CustomerInvoiceDetails, SupplierInvoiceDetails, VatTypes } from "../../../types/contracts/InvoiceDetails";
import { SelectMolecule } from "../../molecules/MultiSelect";
import { LowPriorityTwoTone } from "@mui/icons-material";

const mw = 500;

export const ContractInvoiceEditForm: FC<{
    invoiceDetails: InvoiceDetails,
    hideSupplier?: boolean,
    onChange?: (value: InvoiceDetails) => void,
}> = ({ onChange, invoiceDetails, hideSupplier }) => {
    return <Box>
        <Box display={'flex'} flexWrap={'wrap'}>
            <CustomerFinancial details={invoiceDetails.customerInvoiceDetails} onChange={(value) => onChange?.({ ...invoiceDetails, customerInvoiceDetails: value })} />
            <Box width={16} />
            {
                !hideSupplier && <>
                    <SupplierFinancial details={invoiceDetails.supplierInvoiceDetails} onChange={(value) => onChange?.({ ...invoiceDetails, supplierInvoiceDetails: value })} />
                    <Box width={16} />
                </>
            }
            {
                invoiceDetails.customerInvoiceDetails.references.length > 0 && <>
                    <CustomerReferences references={invoiceDetails.customerInvoiceDetails.references} onChange={(value) => onChange?.({ ...invoiceDetails, customerInvoiceDetails: { ...invoiceDetails.customerInvoiceDetails, references: value } })} />
                    <Box width={16} />
                </>
            }
        </Box>
    </Box >
}

const BTWTypeInput: FC<{
    value: VatTypes,
    onChange?: (value: VatTypes) => void,
}> = ({ value, onChange }) => {
    return <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="secondary">BTW Situatie</Typography>
        <SelectMolecule
            sx={{ width: '100%' }}
            onClick={onChange}
            selected={[value]}
            items={[
                { label: 'Standaard', value: VatTypes.Default },
                { label: 'KOR', value: VatTypes.KOR },
                { label: 'BTW Verlegd', value: VatTypes.BTWVerlegd, flex: 1 },
                { label: 'BTW Vrijgesteld', value: VatTypes.BTWVrijgesteld, flex: 1 },
                { label: 'Vrijstelling KO', value: VatTypes.VrijstellingKO, flex: 1 },
            ]}
        />
    </Box>
}

const CustomerFinancial: FC<{
    details: CustomerInvoiceDetails,
    onChange?: (value: CustomerInvoiceDetails) => void,
}> = ({ onChange, details }) => {
    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: mw, mb: 2, borderRadius: 1 }}>
        <Typography variant="overline">Klant Factuur</Typography>

        <BTWTypeInput value={details.vatType} onChange={vatType => onChange?.({ ...details, vatType })} />

        <TextInput sx={{ mt: 2 }}
            label="BTW-Verlegnummer"
            value={details.vatReverseChargeNumber}
            onChange={vatReverseChargeNumber => onChange?.({ ...details, vatReverseChargeNumber })}
        />

        <TextInput sx={{ mt: 2 }}
            label="Ter attentie van"
            value={details.name}
            onChange={name => onChange?.({ ...details, name })}
        />


        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ pr: 1, flex: 2 }}
                label="Postcode"
                value={details.zipCode}
                onChange={zipCode => onChange?.({ ...details, zipCode })}
            />
            <TextInput
                sx={{ flex: 2 }}
                label="Plaats"
                value={details.city}
                onChange={city => onChange?.({ ...details, city })}
            />
        </Row>

        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ pr: 1, flex: 3 }}
                label="Straat"
                value={details.address}
                onChange={address => onChange?.({ ...details, address })}
            />
            <TextInput
                sx={{ flex: 2 }}
                label="Land"
                value={details.country}
                onChange={country => onChange?.({ ...details, country })}
            />
        </Row>


        <Row sx={{ mt: 2 }}>
            <TextInput
                sx={{ pr: 1, flex: 2 }}
                label="Telefoon"
                value={details.phone}
                onChange={phone => onChange?.({ ...details, phone })}
            />

            <TextInput
                sx={{ flex: 3 }}
                label="Email"
                value={details.email}
                onChange={email => onChange?.({ ...details, email })}
            />
        </Row>

    </Box>
}

const SupplierFinancial: FC<{
    details: SupplierInvoiceDetails,
    onChange?: (value: SupplierInvoiceDetails) => void,
}> = ({ onChange, details }) => {
    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: mw, mb: 2, borderRadius: 1 }}>
        <Typography variant="overline">Leverancier Factuur</Typography>

        <BTWTypeInput value={details.vatType} onChange={vatType => onChange?.({ ...details, vatType })} />

        <IconLabel sx={{ mt: 2 }} label="SNA Gecertificeerd" />
        <YesNoToggle
            value={details.snaCertified}
            onChange={snaCertified => onChange?.({ ...details, snaCertified })}
        />

        <Row sx={{ mt: 2 }}>
            <TextInput sx={{ flex: 3, pr: 1 }}
                label="G-rekening"
                value={details.gBankAccount}
                onChange={gBankAccount => onChange?.({ ...details, gBankAccount })}

            />

            <TextInput sx={{ flex: 1 }}
                label="G-rekening Verdeling"
                value={details.gDivision}
                onChange={gDivision => onChange?.({ ...details, gDivision })}
            />
        </Row>
    </Box>
}


const CustomerReferences: FC<{
    references: ReferenceValue[],
    onChange?: (value: ReferenceValue[]) => void,
}> = ({ onChange, references }) => {

    const renderReference = (ref: ReferenceValue) => {
        const handleChange = (value: string) => {
            const newRefs = references.map(r => r.ref === ref.ref ? { ...r, value } : r);
            onChange?.(newRefs);
        }

        return <TextInput
            key={ref._id}
            sx={{ flex: 1, pr: 1, mt: 2, minWidth: 300 }}
            label={ref.name}
            value={ref.value}
            onChange={handleChange}
        />
    }

    return <Box flex={1} p={2} sx={{ background: 'rgba(240,240,240, .3)', minWidth: mw, mb: 2, borderRadius: 1 }}>
        <RowCentered>
            <LowPriorityTwoTone color="primary" sx={{ mr: 1 }} />
            <Typography variant="overline">Klant Referenties</Typography>
        </RowCentered>

        <Box sx={{ mt: 1 }} />
        <Box display={'flex'} flexWrap={'wrap'}>
            {references.map(renderReference)}
        </Box>
    </Box>
}
