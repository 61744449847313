import { Typography } from "@mui/material";
import { SelectMolecule } from "./MultiSelect";

export function YesNoToggle(props: {
    value: boolean;
    onChange: (value: boolean) => void;
    title?: string;
}) {
    return <>
        {props.title && <Typography fontWeight={100} mb={2} variant="body2">{props.title}</Typography>}
        <SelectMolecule
            onClick={props.onChange}
            selected={[props.value]}
            items={[
                { label: 'Ja', value: true },
                { label: 'Nee', value: false }
            ]}
        />
    </>
};