import { VacancyDetails } from "../../types/recruiter/vacancy";

export const validateVacancy = (vacancy: VacancyDetails): string | null => {
    const { title, description, startDate, endDate, hoursPerWeek } = vacancy;

    if (title.trim().length === 0)
        return 'Titel is verplicht';

    if (description.trim().length === 0)
        return 'Beschrijving is verplicht';

    if (startDate >= endDate)
        return 'Startdatum moet voor einddatum zijn';

    if (hoursPerWeek <= 0)
        return 'Uren per week moet groter zijn dan 0';

    return null;
};