import { AccessTimeTwoTone, AutoGraphTwoTone, BusinessTwoTone, DashboardTwoTone, DescriptionTwoTone, HandshakeTwoTone, PersonTwoTone, WorkTwoTone } from "@mui/icons-material";
import { contractenRoutes, genericRoutes, organizationRoutes, personRoutes, recruiterRoutes, settingRoutes } from "./paths";
import { DrawerItemProps } from "../ui/molecules";

export const dashboardModule: DrawerItemProps = {
    title: 'Dashboard',
    icon: <DashboardTwoTone color="primary" />,
    link: genericRoutes.dashboard
}

export const recruiterModule: DrawerItemProps = {
    title: 'Recruiter',
    icon: <WorkTwoTone color="primary" />,
    items: [
        {
            title: 'Vacatures',
            link: recruiterRoutes.vacatures.list
        },
        {
            title: 'Kandidaten',
            link: recruiterRoutes.kandidaten.list
        },
        {
            title: 'Overzicht',
            link: recruiterRoutes.progress.appliances
        },
        {
            title: 'Notificaties',
            link: recruiterRoutes.notificaties
        },
    ]
}

export const contractenModule: DrawerItemProps = {
    title: 'Contracten',
    icon: <HandshakeTwoTone color="primary" />,
    items: [
        {
            title: 'Alle contracten',
            link: contractenRoutes.list
        }
    ]
}

export const companyModule: DrawerItemProps = {
    icon: <BusinessTwoTone color="primary" />,
    title: 'Bedrijven',
    items: [
        {
            title: 'Alle bedrijven',
            link: organizationRoutes.all
        },
        {
            title: 'Leveranciers',
            link: organizationRoutes.suppliers
        },
        {
            title: 'Inleners',
            link: organizationRoutes.customers
        },
        {
            title: 'Categorieën',
            link: settingRoutes.categories
        }]
}

export const personModule: DrawerItemProps = {
    icon: <PersonTwoTone color="primary" />,
    title: 'Personen',
    items: [
        {
            title: 'Alle',
            link: personRoutes.list
        }
    ]
}

export const selfProfileModule: DrawerItemProps = {
    title: 'Profiel',
    icon: <PersonTwoTone color="primary" />,
    link: personRoutes.self
}

export const selfTimesheetsModule: DrawerItemProps = {
    title: 'Timesheets',
    icon: <AccessTimeTwoTone color="primary" />,
    link: personRoutes.self
}

export const selfInvoicesModule: DrawerItemProps = {
    title: 'Facturen',
    icon: <DescriptionTwoTone color="primary" />,
    link: personRoutes.self
}


export const selfStatsModule: DrawerItemProps = {
    title: 'Overzichten',
    icon: <AutoGraphTwoTone color="primary" />,
    link: personRoutes.self
}