import { MembershipRole } from "../../types/company/membership";
import { Person } from "../../types/company/person";
import { Permissions } from "../../types/user";
import { emailValidation } from "./email";

export const personValidation = (person: Person, roles?: MembershipRole[], permissions?: Permissions[]): string | null => {
    if (!Boolean(person.firstName))
        return 'Voornaam is verplicht';

    if (!Boolean(person.lastName))
        return 'Achternaam is verplicht';

    if (emailValidation(person.email))
        return emailValidation(person.email)!;

    if (!Boolean(person.initials))
        return 'Initialen zijn verplicht';

    if (roles) {
        if (roles.length === 0)
            return 'Minimaal één rol is verplicht';
    }

    if (permissions) {
        if (permissions.length === 0)
            return 'Minimaal één permissie is verplicht';
    }

    return null;
}