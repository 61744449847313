import { useNavigate } from 'react-router-dom';
import { organizationRoutes } from '../../../router/paths';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { companiesSelector, createCompany } from '../../../store/slices/companiesSlice';
import { OrganizationForm } from '../../forms/company';
import { AddCompanyPayload } from '../../../types/company/payloads';


export function CreateCompanyOrganism() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const loadingState = useAppSelector(companiesSelector).fetchingCreate;
    const onComplete = (payload: AddCompanyPayload) => {
        dispatch(createCompany(payload))
            .then((result) => {
                if (result.meta.requestStatus === 'fulfilled')
                    navigate(organizationRoutes.customers);
            });
    };

    return <OrganizationForm loading={loadingState === 'fetching'} onCompleted={onComplete} />;
}
