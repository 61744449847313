import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { RequestedDocument, DocumentState } from "../../../../types/documents/Documents"
import { ColumnProps, Table } from "../Table";
import { PreviewButton } from "../../Buttons";
import { documentTargetIcon, documentTargetString, documentTypeIcon, documentTypeString } from "../../../../utils/documents";
import { RowCentered } from "../../Row";

export function RequestedDocumentsTable(props: {
    items: RequestedDocument[];
    itemsInProgress: string[];
    onDownload?: (item: RequestedDocument) => void;
    onApprove?: (item: RequestedDocument) => void;
    onDeny?: (item: RequestedDocument) => void;
}) {
    const ApproveDenyButtons = (row: { item: RequestedDocument }) => <Box>
        <Button onClick={() => props.onApprove?.(row.item)} variant="text" color="primary">Goedkeuren</Button>
        <Button onClick={() => props.onDeny?.(row.item)} variant="text" color="error">Afkeuren</Button>
    </Box>

    const StatusLabel = (row: { item: RequestedDocument }) => <Box>
        {row.item.state === DocumentState.AwaitingSignature && <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: '#eee' }} variant="overline" color={'secondary'}>Nog niet ondertekend</Typography>}
        {row.item.state === DocumentState.Requested && <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: '#eee' }} variant="overline" color={'secondary'}>Nog niet aangeleverd</Typography>}
        {row.item.state === DocumentState.Approved && <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: '#eee' }} variant="overline" color={'primary'}>Goedgekeurd</Typography>}
        {row.item.state === DocumentState.Denied && <Typography sx={{ borderRadius: 2, px: 2, py: 1, background: '#eee' }} variant="overline" color={'error'}>Afgekeurd</Typography>}
    </Box>

    const colDefs: ColumnProps<RequestedDocument>[] = [
        {
            headerName: "Bekijk",
            width: 150,
            cellRenderer: (row) => <PreviewButton disabled={row.data.state === DocumentState.Requested || row.data.state === DocumentState.AwaitingSignature} onClick={() => props.onDownload?.(row.data)} />
        },
        {
            headerName: "Soort",
            width: 170,
            cellRenderer: (row) => {
                const Icon = documentTypeIcon(row.data.type);
                return <RowCentered>
                    <Icon color="primary" sx={{ mr: 2 }} />
                    {documentTypeString(row.data.type)}
                </RowCentered>
            }
        },
        {
            headerName: "Voor",
            width: 170,
            cellRenderer: (row) => {
                const Icon = documentTargetIcon(row.data.targetType);
                return <RowCentered>
                    <Icon color="primary" sx={{ mr: 2 }} />
                    {documentTargetString(row.data.targetType)}
                </RowCentered>
            }
        },
        {
            field: "title",
            headerName: "Titel"
        },
        {
            field: "description",
            headerName: "Omschrijving"
        },
        {
            headerName: "Status",
            flex: 1,
            cellRenderer: (row) => {
                if (props.itemsInProgress.includes(row.data._id))
                    return <CircularProgress size={20} />

                return row.data.state === DocumentState.Verification
                    ? <ApproveDenyButtons item={row.data} />
                    : <StatusLabel item={row.data} />
            }
        }
    ];

    return <Table id="DocListItems" columns={colDefs} data={props.items} />
}