import { useNavigate } from "react-router-dom";
import { CommentEditorInput, RelationStatusButton } from "../..";
import { Professional } from "../../../../types/recruiter/professional";
import { VacancyRelation, VacancyRelationState } from "../../../../types/recruiter/relations";
import { ColumnProps, Table } from "../Table";
import { Box, CircularProgress, Link } from "@mui/material";
import { recruiterRoutes } from "../../../../router/paths";
import { MatchScore } from "../../../../types/recruiter/matchScore";

type RowData = {
    professional: Professional;
    relation?: VacancyRelation;
    score?: number;
};

export function VacancyProfessionalsTable(props: {
    professionals: Professional[];
    scores: MatchScore[];
    relations: VacancyRelation[];
    onRelationChange: (relation: VacancyRelation, newState: VacancyRelationState) => void;
    onCommentChange: (relation: VacancyRelation, comment: string) => void;
}) {
    const nav = useNavigate();

    const data: RowData[] = props.professionals.map(professional => ({
        professional,
        relation: props.relations.find(r => r.professionalId === professional._id),
        score: props.scores.find(s => s.professionalId === professional._id)?.score
    }));

    const colDefs: ColumnProps<RowData>[] = [
        {
            field: 'relation.state',
            width: 270,
            cellRenderer: (row: { data: RowData }) => <RelationStatusButton onChange={(value) => props.onRelationChange(row.data.relation!, value)} state={row.data.relation?.status!} />,
            headerName: "Status"
        },
        {
            field: "score",
            width: 120,
            sort: 'desc',
            headerName: "Score",
            valueGetter: (data) => data.data.score !== undefined ? data.data.score : 0,
            cellRenderer: (data) => data.data.score !== undefined ? `${data.data.score}%` : <CircularProgress size={'small'} color="primary" sx={{ width: 15 }} />
        },
        {
            field: "professional.name",
            headerName: "Naam",
            width: 200,
            cellRenderer: (row: { data: RowData }) => <Link onClick={() => nav(recruiterRoutes.kandidaten.details(row.data.professional._id))}>{row.data.professional.name}</Link>,
        },
        {
            flex: 1,
            field: "relation.comment",
            headerName: "Opmerking",
            cellRenderer: (row: { data: RowData }) =>
                <Box mt={1}>
                    <CommentEditorInput initialValue={row.data.relation?.comment ?? ""} onSubmit={
                        (value) => props.onCommentChange(row.data.relation!, value)
                    } />
                </Box>
        }
    ];

    return <Table id="VacProf" columns={colDefs} data={data} />;
}

