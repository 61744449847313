import { InhuurContract, TaoContract, ZzpContract } from "../../types/contracts";
import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class ContractDefaultValuesApi extends BaseApi {
    constructor() { super(apiRoutes.contracts + "/contractendefaultvalues"); }

    public get = async (type: string) => await this.fetchJson<ZzpContract | TaoContract | InhuurContract>(type);
    public update = async (data: ZzpContract | TaoContract | InhuurContract) => await this.fetch(data._id, 'PUT', data);
}

const contractsApi = new ContractDefaultValuesApi();
export default contractsApi;