import { Box, Typography } from "@mui/material";
import { ContainedButton } from "./Buttons";

export function WelcomeSection(props: {
    onCompleted: () => void,
    sectionFor: string
}) {
    return <Box>
        <Box mt={2} />
        <Typography variant="h6">Welkom bij het registratie formulier voor nieuwe {props.sectionFor}.</Typography>
        <Box mt={2} />
        <Typography variant="body2" color="secondary" fontWeight={100}>Dit formulier is bedoeld voor het registreren van een nieuwe {props.sectionFor}.</Typography>
        <Typography variant="body2" color="secondary" fontWeight={100}>Er zullen een aantal stappen worden getoond voor het opvoeren van de benodigde gegevens.</Typography>
        <Box mt={2} />
        <Typography variant="body2" color="secondary" fontWeight={100}>Aan de linker kant van het scherm zal er extra uitleg worden gegeven omtrent de gegevens die u op dat moment worden gevraagd om aan te leveren.</Typography>
        <Box mt={4} />
        <ContainedButton onClick={props.onCompleted}>Beginnen</ContainedButton>
    </Box>
}