import { Box, Tooltip } from "@mui/material";
import { storeToken } from "../../utils/cache";
import { ExitToAppOutlined } from "@mui/icons-material";

export function LogoutButtonOrganism() {
    const handleLogout = () => {
        storeToken('');
        window.location.href = '/';
    };

    return <Tooltip title="Afmelden">
        <Box onClick={handleLogout} sx={{ position: 'absolute', right: 8, top: 8, cursor: 'pointer' }}>
            <ExitToAppOutlined fontSize="small" color="primary" />
        </Box>
    </Tooltip>
}