import { ColumnProps, Table } from "../Table";
import { BaseContract } from "../../../../types/contracts";
import { Link } from "../../Link";
import { contractenRoutes, organizationRoutes, personRoutes } from "../../../../router/paths";

export function ContractsTable(props: {
    contracts: BaseContract[];
}) {

    const colDefs: ColumnProps<BaseContract>[] = [
        {
            headerName: "Contractnummer",
            field: "contractnumber",
            cellRenderer: (props) => <Link path={contractenRoutes.details(props.data.contractnumber)}>
                {props.data.contractnumber}
            </Link>,
        },
        {
            headerName: "Professional",
            cellRenderer: (props) => <Link path={personRoutes.details(props.data.professional._id)}>
                {props.data.professional.name}
            </Link>,
        },
        {
            headerName: "Tekenbevoegde Klant",
            width: 300,
            cellRenderer: (props) => <Link path={personRoutes.details(props.data.authorizedSignatoryHirer._id)}>
                {props.data.authorizedSignatoryHirer.name}
            </Link>,
        },
        {
            headerName: "Leverancier",
            flex: 1,
            cellRenderer: (props) => <Link path={organizationRoutes.details(props.data.supplier._id)}>
                {props.data.supplier.traderName}
            </Link>,
        }
    ];

    return <Table id="contracts-table" slim columns={colDefs} data={props.contracts} />;
}

