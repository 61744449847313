import { Vacancy } from '../types/recruiter/vacancy';
import { containsIgnoreCase } from '../utils/strings';

export const filterByTitle = (vacancies: Vacancy[], title: string): Vacancy[] => {
    return vacancies.filter(vacancy => containsIgnoreCase(vacancy.title, title));
};

export const filterByCompany = (vacancies: Vacancy[], company: string): Vacancy[] => {
    return vacancies.filter(vacancy => containsIgnoreCase(vacancy.company, company));
}

export const filterByLocation = (vacancies: Vacancy[], location: string): Vacancy[] => {
    return vacancies.filter(vacancy => containsIgnoreCase(vacancy.location, location));
}

export const filterByBranche = (vacancies: Vacancy[], branche: string): Vacancy[] => {
    return vacancies.filter(vacancy => containsIgnoreCase(vacancy.branche, branche));
}

export const filterByDescription = (vacancies: Vacancy[], description: string): Vacancy[] => {
    return vacancies.filter(vacancy => containsIgnoreCase(vacancy.description, description));
}

export const filterByRequiredSkill = (vacancies: Vacancy[], requiredSkill: string): Vacancy[] => {
    return vacancies.filter(vacancy => vacancy.requiredSkills.includes(requiredSkill));
}

export const filterByHourPrice = (vacancies: Vacancy[], hourPrice: number): Vacancy[] => {
    return vacancies.filter(vacancy => vacancy.hourPrice <= hourPrice);
}

export const filterByHoursPerWeek = (vacancies: Vacancy[], hoursPerWeek: number): Vacancy[] => {
    return vacancies.filter(vacancy => vacancy.hoursPerWeek >= hoursPerWeek);
}

export const filterByStartDate = (vacancies: Vacancy[], startDate: string): Vacancy[] => {
    return vacancies.filter(vacancy => vacancy.startDate >= startDate);
}

export const filterByEndDate = (vacancies: Vacancy[], endDate: string): Vacancy[] => {
    return vacancies.filter(vacancy => vacancy.endDate <= endDate);
}

export const filterByClosureDateTime = (vacancies: Vacancy[], closureDateTime: string): Vacancy[] => {
    return vacancies.filter(vacancy => vacancy.closureDateTime <= closureDateTime);
}

export const filterByReference = (vacancies: Vacancy[], reference: string): Vacancy[] => {
    return vacancies.filter(vacancy => containsIgnoreCase(vacancy.reference ?? '', reference));
}

export const filterBySearch = (vacancies: Vacancy[], search: string): Vacancy[] => {
    return vacancies.filter(vacancy => {
        return containsIgnoreCase(vacancy.title, search) ||
            containsIgnoreCase(vacancy.company, search) ||
            containsIgnoreCase(vacancy.branche, search) ||
            containsIgnoreCase(vacancy.description, search) ||
            containsIgnoreCase(vacancy.location, search) ||
            containsIgnoreCase(vacancy.reference ?? '', search) ||
            vacancy.requiredSkills.some(skill => containsIgnoreCase(skill, search))
    });
}