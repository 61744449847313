import { FC, useState } from "react";
import Lottie from "react-lottie-player";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AddOutlined, AddTwoTone, SearchTwoTone } from "@mui/icons-material";
import { contractenRoutes } from "../../../router/paths";
import { hydrateContractsModule } from "../../../store/combinedActions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ContainedButton, DotsLoader, TextInput, Toolbar } from "../../molecules";

import animation from "../../../animations/signcontract.json";
import { contractsSelector } from "../../../store/slices/contractsSlice";
import { ContractsTable } from "../../molecules/tables/contracts/Contracts";

export const ContractenListOrganism: FC = () => {
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const state = useAppSelector(contractsSelector);
    const [search, setSearch] = useState<string>('');

    const { fetchingList, contracts } = state;

    return <Box>
        <Toolbar>
            <Button onClick={() => nav(contractenRoutes.create)} variant="text">
                <AddTwoTone fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="caption">Contract Toevoegen</Typography>
            </Button>
        </Toolbar>
        {fetchingList === 'fetching' && <DotsLoader message="- Contracten worden opgehaald -" />}
        {fetchingList === 'completed' && contracts.length > 0 && <>
            <Box display={'flex'} alignItems={'flex-end'} mb={-1}>
                <Box>
                    <TextInput icon={SearchTwoTone} label="Zoeken" sx={{ my: 2, minWidth: 300 }} value={search} onChange={v => setSearch(v as string)} />
                </Box>
                <Box>
                    <Button onClick={() => hydrateContractsModule(dispatch)} variant="text" sx={{ mb: 2, ml: 1 }}>
                        <Typography variant="caption">Vernieuwen</Typography>
                    </Button>
                </Box>
            </Box>
            <ContractsTable contracts={contracts} />
        </>
        }
        {fetchingList === 'completed' && contracts.length === 0 && <Box display={'flex'} flexDirection={'column'} alignItems='center' width="100%">
            <Box sx={{ zIndex: 10 }} flexDirection={'column'} display={'flex'}>
                <Typography sx={{ mt: 4, textAlign: 'center' }} variant="overline">U heeft nog geen actieve contracten.</Typography>
            </Box>
            <Box sx={{ maxWidth: 250 }}>
                <Lottie loop play
                    rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                    animationData={animation}
                />
            </Box>
            <ContainedButton sx={{ mt: 2 }} onClick={() => nav(contractenRoutes.create)}>
                <AddOutlined fontSize="small" sx={{ mr: 1 }} />
                Contract Aanmaken
            </ContainedButton>
        </Box>}

    </Box>
}