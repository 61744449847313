import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { hydrateToken } from "../store/slices/authSlice";
import { SideMenuOrganism } from "../ui/organisms";
import { hydrateContractsModule, hydrateOrgModule, hydrateRecruiterModule } from "../store/combinedActions";
import { AnonymousRoutes } from "./routes/Anonymous";
import { RecruiterRoutes } from "./routes/Recruiter";
import { CompanyRoutes } from "./routes/Companies";
import { companiesSelector } from "../store/slices/companiesSlice";
import { vacanciesSelector } from "../store/slices/vacanciesSlice";
import { PersonRoutes } from "./routes/Persons";
import ProcessLoginPage from "../ui/pages/miscellaneous/ProcessingLogin";
import NotFoundPage from "../ui/pages/miscellaneous/404";
import { WhitelabelSettingsRoutes } from "./routes/WhitelabelSettingsRoutes";
import { DasboardRoutes } from "./routes/Dashboard";
import { createPermissionValidator } from "../utils/permissions";
import { PlatformUser } from "../types/user";
import { Permissions } from "../types/user";
import { ContractRoutes } from "./routes/Contracts";
import { contractsSelector } from "../store/slices/contractsSlice";

export function AppRouter() {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(hydrateToken());
    }, [dispatch]);

    if (auth.hydratingToken) return <ProcessLoginPage />

    return <Router>
        {auth.user && <ModuleDataLoader user={auth.user} />}

        <Routes>
            {
                auth.user && <Route Component={SideMenuOrganism} path="/">
                    {DasboardRoutes(auth.user)}
                    {RecruiterRoutes(auth.user)}
                    {ContractRoutes(auth.user)}
                    {CompanyRoutes(auth.user)}
                    {PersonRoutes(auth.user)}
                    {WhitelabelSettingsRoutes(auth.user)}

                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            }
            {
                !auth.user && AnonymousRoutes()
            }
        </Routes>
    </Router>
}

function ModuleDataLoader(props: { user: PlatformUser }) {
    const dispatch = useAppDispatch();

    const vacanciesState = useAppSelector(vacanciesSelector);
    const companiesState = useAppSelector(companiesSelector);
    const contractsState = useAppSelector(contractsSelector);

    const validator = createPermissionValidator(props.user);

    useEffect(() => {

        if (validator([Permissions.VacatureReader]) && vacanciesState.fetchingList === 'init')
            hydrateRecruiterModule(dispatch);

        if (validator([Permissions.CompanyReader]) && companiesState.fetchingList === 'init')
            hydrateOrgModule(dispatch);

        if (validator([Permissions.ContractReader]) && contractsState.fetchingList === 'init')
            hydrateContractsModule(dispatch);

    }, [dispatch, vacanciesState.fetchingList, companiesState.fetchingList, contractsState.fetchingList, validator]);

    return null;
}
