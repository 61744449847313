export const getTokenParam = (): string | null => getQueryParam('token');
export const getColorParam = (): string | null => getQueryParam('theme');

export const removeTokenParam = () => removeQueryParam('token');

export const getQueryParam = (param: string): string | null => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
};

export const removeQueryParam = (param: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(param);

    if (searchParams.toString() === '') {
        window.history.pushState({}, '', window.location.pathname);
        return;
    }

    window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
};

export const setQueryParam = (param: string, value: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(param, value);
    window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
};