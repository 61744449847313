import { FC } from "react";
import { DotsLoader } from "../../molecules";
import { useAppSelector } from "../../../store/hooks";

import { useParams } from "react-router-dom";
import { contractsSelector } from "../../../store/slices/contractsSlice";
import { ContractAssignmentForm } from "../../forms/contract";
import { Box, Typography } from "@mui/material";

export const ContractAssignmentOrganism: FC = () => {
    const { id } = useParams<{ id: string }>();
    const contracts = useAppSelector(contractsSelector).contracts;
    const contract = contracts?.find(c => c.contractnumber === id);

    if (!contract)
        return <DotsLoader message={"Contract word geladen"} />;

    return <Box mt={2}>
        <Typography color="secondary" variant="h6" mb={2}>Opdracht Specificaties</Typography>
        <ContractAssignmentForm
            kind={contract.contractKind}
            value={contract.assignment}
        />
    </Box>
}