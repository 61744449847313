import { BaseApi } from "../base";
import { apiRoutes } from "../routes";

class PersonApi extends BaseApi {
    constructor() { super(apiRoutes.organization + "/credentials"); }

    public sendCredentialsInvite = (personId: string) => this.fetch(`/${personId}/send`, "POST");
    public registerCredentials = (env: string, code: string, email: string, password: string) => this.fetch(`/${env}/${code}`, "POST", { email, password });

    public requestPasswordReset = (email: string) => this.fetch(`${email}/reset-password`, "POST");
    public processPasswordReset = (code: string, password: string) => this.fetch(`reset-password/${code}`, "POST", password);

}

const api = new PersonApi();
export default api;