import { generateGuid } from "../../utils/strings";

export interface Organisation {
    _id: string;
    kvk: string;
    locationNumber: string;
    traderName: string;
    address: string;
}

export const defaultOrganisation = (): Organisation => ({
    _id: generateGuid(),
    kvk: '',
    locationNumber: '',
    traderName: '',
    address: ''
})