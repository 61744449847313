import { generateGuid } from "../../utils/strings";

export interface Division {
    _id: string;
    name: string;
    purchaseJournal: string;
    salesJournal: string;
}

export const defaultDivision = (): Division => ({
    _id: generateGuid(),
    name: '',
    purchaseJournal: '',
    salesJournal: ''
})